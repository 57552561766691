//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "@/services/constants";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import SuccessDialog from "@/components/cards/SuccessDialog";

export default {
  name: "MappingList",
  async mounted() {
    this.$showLoader();

    // ----------------- Check Authorize ---------------------------
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);
    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed",
        "Please Logout And Login Again",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");
    if(this.$route.params.mode == "Edit"){
      this.disableShow = true;
    }
    await this.loaddatashow();
    await this.loaddata();
    this.$hideLoader();
  },
  components: { SuccessDialog, SuccessDialogPush },
  data() {
    return {
      orckw_id: 0,
      objectgroup_id: 0,
      disableShow: false,
      disabledNotice: false,
      Mode: this.$route.params.mode,
      work_type_show: "",
      notice_show: "",
      keyword_add: "",
      keyword_edit: "",
      userId: 0,
      authorize: [],
      authorize_view: false,
      authorize_add: false,
      authorize_edit: false,
      authorize_del: false,
      authorize_id: 0,
      load_bg: false,
      dialogAddKeyword: false,
      dialogAdd: false,
      dialogAddData: false,
      dialogDelete: false,
      isShowPassword: false,
      fullPage: true,
      search: "",
      desserts: [],
      checkedit: [],
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      search_ct: "",
      itemdata: {
        contact_type: 0,
        contact_name: "",
        contact_person: "",
        position: "",
        email: "",
        address: "",
        city: "",
        country: "",
        telephone: "",
        fax: "",
        status: "A",
      },
      edititemdata: {
        contact_type: 0,
        contact_name: "",
        contact_person: "",
        position: "",
        email: "",
        address: "",
        city: "",
        country: "",
        telephone: "",
        fax: "",
      },
      contactid_del: 0,
      oparation: "",
      headers: [
        {
          text: "No",
          value: "no",
          class: "bg-colorth",
          divider: true,
          width: "10%",
          align: "end"
        },
        {
          text: "Keyword",
          value: "kw",
          class: "bg-colorth",
          divider: true,
          // width: "10%",
        },
        
        // {
        //   text: "",
        //   value: "actions",
        //   class: "bg-colorth",
        //   divider: true,
        //   width: "140px",
        //   align: "center"
        // },
      ],
    };
  },
  methods: {
    async loaddatashow(){
      const res_doctype = await api.getdocumenttypeByID(this.$route.params.doctype_id)
      this.work_type_show = res_doctype.data[0].documenttype_code;
      console.log(res_doctype.data[0].documenttype_code)
      if(res_doctype.data[0].objectgroup_id == 1){
        const res_notice = await api.getnoticeByID(this.$route.params.notice_id)
        this.notice_show = res_notice.data[0].notice_code;
        this.objectgroup_id = res_doctype.data[0].objectgroup_id;
        this.disabledNotice = true;
      }
    },
    async loaddata() {
      const res_data = await api.getOCRKWListCon(this.$route.params.doctype_id,this.$route.params.notice_id);
      this.desserts = res_data.data;
      this.desserts.forEach((item,index) => {
        item.no = index + 1;
      });
      this.checkedit = this.desserts;
      console.log(this.desserts)
    },
    async additem(){
      if (this.keyword_add == "") {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
                true,
                "Failed",
                "Please enter Keyword Data",
                "text-h5 red--text text-center"
            );
            return;
      }
      const addItemdata = {
          doctype_id: this.$route.params.doctype_id,
          notice_type: this.$route.params.notice_id,
          objectgroup_id: this.objectgroup_id,
          kw: this.keyword_add,
      }
      // console.log(addItemdata)
      this.$showLoader()
      const res_add =  await api.createOCRKW(addItemdata)
      this.keyword_add = "";
      // window.location.reload()
      await this.loaddata();
      this.$hideLoader();
    },
    async editItem(data) {
      if (data.kw == "") {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
                true,
                "Failed",
                "Please enter Keyword Data",
                "text-h5 red--text text-center"
            );
            return;
      }
       // console.log(data)
      this.$showLoader()
      await api.updateOCRKW(data.ocrkw_id, { kw: `${data.kw}`})
      // window.location.reload()
      await this.loaddata();
      this.$hideLoader();
    },
    async deleteItem(orckw_id){
      this.dialogDelete = true;
      this.orckw_id = orckw_id;
    },
    closeDelete(){
      this.dialogDelete = false;
      this.orckw_id = 0;
    },
    async deleteItemConfirm(){
      this.$showLoader()
      await api.deleteOCRKW(this.orckw_id);
      this.dialogDelete = false;
      // window.location.reload();
      await this.loaddata();
      this.$hideLoader();
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
  beforeCreate() {
    this.$store.state.navMenu = true;
  },
};
