//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "../services/constants";
import StockCard from "@/components/cards/StockCard";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "./computing/tolocalestringnumber";
import unity from "@/unity/unity";

export default {
  name: "New Activity",
  data() {
    return {
      dialogAdd: false,
      dialogSendMail: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      Emp_list: [],
      jobtitle_list: [],
      charge_list: [
        { value: "T/C", name: "Time Charge" },
        { value: "N/C", name: "No Charge" },
        { value: "F/C", name: "Fix Charge" },
        { value: "OFF", name: "ทำงานออฟฟิต" },
      ],
      customer_list: [],
      menuDateCheckin: false,
      menuDateCheckout: false,
      dataItem: {
        userId: 0,
        customer_id: 0,
        jobtitle_id: 0,
        // checkin: "",
        // checkout: "",
        checkin: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        checkout: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        timecheckin: "",
        timecheckout: "",
        timecheckinadd: "",
        timecheckoutadd: "",
        hours: "",
        chargetype: "",
        prospect: "",
        detail: "",
        durationhour: 0,
        durationminute: 0,
      },
      addItem: {
        chargeType: "",
        images: "",
        company_id: 0,
        isCheckedOut: 1,
        userId: 0,
        departmentId: 0,
        jobTitleId: 0,
        customerId: 0,
        prospect: "",
        detail: "",
        status: "A",
        caseType: null,
      },
    };
  },
  components: {
    StockCard,
    SuccessDialog,
    SuccessDialogPush,
  },
  computed: {
    computedDateCheckInFormatted() {
      return unity.formatDate(this.dataItem.checkin);
    },
    computedDateCheckOutFormatted() {
      return unity.formatDate(this.dataItem.checkout);
    },
  },

  watch: {},

  async mounted() {
    await api.checkVersion();
    this.loadEmp();
    this.loadCustomer();
    this.loadJobtitle();
    this.$hideLoader();
    this.$forceUpdate();
    const userId = localStorage.getItem(server.USER_ID);
    if (userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
  },
  methods: {
    async loadEmp() {
      const result = await api.getAccountslist_by_ComID(
        localStorage.getItem(server.COMPANYID)
      );

      this.Emp_list = result.data;
    },
    async loadCustomer() {
      const result = await api.getAllCustomerListactiveCompany(
        { 
          params: {
            company_id: localStorage.getItem(server.COMPANYID),
          },
        }
      );
      this.customer_list = result.data;
    },
    async loadJobtitle() {
      const result = await api.getAllJobtitleByCompany(
        localStorage.getItem(server.COMPANYID)
      );

      this.jobtitle_list = result.data;
    },
    async getWorkHourDuration() {
      // if(this.dataItem.timecheckin != ""){
      //   let timecheckin_c = this.dataItem.timecheckin
      //       .split("T")[1]
      //       .split(".000")[0];
      //   if(timecheckin_c < "08:30:00"){
      //     this.$store.state.global_dialog = true;
      //     this.setupAlertDialog(
      //       true,
      //       "Check your information again",
      //       "กรุณาเลือกช่วงเวลา ( Check-IN:Time ) ให้ถูกต้อง",
      //       "text-h5 red--text text-center"
      //     );
      //     return;
      //   }
      // }
      // if(this.dataItem.timecheckout != ""){
      //   let timecheckout_c = this.dataItem.timecheckout
      //       .split("T")[1]
      //       .split(".000")[0];
      //   if(timecheckout_c > "17:30:00"){
      //     this.$store.state.global_dialog = true;
      //     this.setupAlertDialog(
      //       true,
      //       "Check your information again",
      //       "กรุณาเลือกช่วงเวลา ( Check-Out:Time ) ให้ถูกต้อง",
      //       "text-h5 red--text text-center"
      //     );
      //     return;
      //   }
      // }
      if (
        this.dataItem.checkin != "" &&
        this.dataItem.timecheckin != "" &&
        this.dataItem.checkout != "" &&
        this.dataItem.timecheckout != ""
      ) {
        let startDate =
          this.dataItem.checkin +
          " " +
          this.dataItem.timecheckin.split("T")[1].split(".000")[0];
        let endDate =
          this.dataItem.checkout +
          " " +
          this.dataItem.timecheckout.split("T")[1].split(".000")[0];
        startDate = new Date(startDate);
        endDate = new Date(endDate);
        let days = parseInt((endDate - startDate) / (1000 * 60 * 60 * 24));
        let hours = parseInt(
          (Math.abs(endDate - startDate) / (1000 * 60 * 60)) % 24
        );
        if (hours > 4) {
          hours -= 1;
        }
        let minutes = parseInt(
          (Math.abs(endDate.getTime() - startDate.getTime()) / (1000 * 60)) % 60
        );
        // console.log("day: " + days)
        // console.log("hour: " + hours + "min" + minutes)
        this.dataItem.durationhour = days * 8 + hours;
        this.dataItem.durationminute = minutes;
      }
    },
    async save() {
      // alert(this.dataItem.checkin + ":" + this.dataItem.checkout)

      if (this.dataItem.userId == "" || this.dataItem.userId == null) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Employee ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (
        this.dataItem.jobtitle_id == "" ||
        this.dataItem.jobtitle_id == null
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Jobtitle ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.dataItem.customer_id == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Customer ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.dataItem.checkin == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Date Check-In ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.dataItem.timecheckin == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Time Check-In ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.dataItem.checkout == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Date Check-Out ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.dataItem.timecheckout == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Time Check-Out ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.dataItem.chargetype == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Charge-Type ",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (this.dataItem.detail == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Details ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.dataItem.durationhour < 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "duration hour ต้องมากกว่าหรือเท่ากับ 0 ",
          "text-h5 red--text text-center"
        );
        return;
      }
      this.dataItem.timecheckinadd = this.dataItem.timecheckin
        .split("T")[1]
        .split(".000")[0];
      this.dataItem.timecheckoutadd = this.dataItem.timecheckout
        .split("T")[1]
        .split(".000")[0];
      //console.log(this.dataItem)

      // //get data costomer
      const res_cusdetail = await api.getAccountByid(this.dataItem.userId);
      //console.log(res_cusdetail.data);
      // //setdata for add
      this.addItem.chargeType = this.dataItem.chargetype;
      this.addItem.company_id = res_cusdetail.data[0].company_id;
      this.addItem.checkInDate = `${this.dataItem.checkin} ${this.dataItem.timecheckinadd}`;
      this.addItem.userId = this.dataItem.userId;
      this.addItem.departmentId = res_cusdetail.data[0].dep_id;
      this.addItem.jobTitleId = this.dataItem.jobtitle_id;
      this.addItem.customerId = this.dataItem.customer_id;
      this.addItem.prospect = this.dataItem.prospect;
      this.addItem.detail = this.dataItem.detail.replace(/'/g, "\\'");
      this.addItem.checkOutDate = `${this.dataItem.checkout} ${this.dataItem.timecheckoutadd}`;
      this.addItem.duration =
        parseInt(this.dataItem.durationhour * 60) +
        parseInt(this.dataItem.durationminute);

      // console.log(this.addItem)
      //add data
      const res_addAct = await api.createActivity(this.addItem);
      console.log(res_addAct.status);
      if (res_addAct.status == 200 || res_addAct.status == 201) {
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "Information Saved",
          "Information successfully saved",
          "text-h5 green--text text-center",
          "/my-activity"
        );
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้",
          "text-h5 red--text text-center"
        );
      }
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
  async created() {},
};
