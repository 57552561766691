//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server, imageUrl } from "@/services/constants";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";

export default {
  async mounted() {
    // if (api.isLoggedIn()) {
    //   this.$router.push("/dashboard");
    // }
    await this.loaddata();
    this.$hideLoader();
  },
  components: { SuccessDialog, SuccessDialogPush },
  data() {
    return {
      defaultwordbuttonsig1: "My Signature",
      showbuttonsavesig1:false,
      showsig1: false,
      showpreviewimage: null,
      preview_image: null,
      image_data: null,
      dataaccount: [],
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      dialogAdd: false,
      isShowCurrentPassword: false,
      isShowPassword: false,
      isShowPassword2: false,
      account: {
        currentpassword:"",
        password: "",
        confirm_password: "",
        phone: "",
        signature: "",
        oldimage:"",
      },
    };
  },
  computed: {},
  watch: {},
  methods: {
  async showdefaultsignature() {
      this.$nextTick(() => {
        this.$refs.firstsignaturePad.resizeCanvas();
        this.$refs.firstsignaturePad.fromDataURL(this.account.signature);

        this.$refs.firstsignaturePad.lockSignaturePad();
        this.$refs.firstsignaturePad.resizeCanvas();
        this.$refs.firstsignaturePad.lockSignaturePad();
      });
    },
    async clearsig1() {
      this.$refs.firstsignaturePad.resizeCanvas();
      this.$refs.firstsignaturePad.clearSignature();
      this.account.signature = "";
    },
    async savesig1() {
      this.defaultwordbuttonsig1 = "Edit";
      this.showbuttonsavesig1 = false;

      const { isEmpty, data } = this.$refs.firstsignaturePad.saveSignature();
      this.account.signature = data;
      this.$refs.firstsignaturePad.resizeCanvas();
      this.$refs.firstsignaturePad.fromDataURL(this.account.signature);
      //alert(this.signature)
      this.showbuttonsavesig1 = false;
      this.$refs.firstsignaturePad.lockSignaturePad();
    },
    editsig1() {
      this.showsig1 = true;
      this.showbuttonsavesig1 = true;
      //await Vue.nextTick();
      this.$refs.firstsignaturePad.resizeCanvas();
      this.$refs.firstsignaturePad.fromDataURL(this.account.signature);
      this.$refs.firstsignaturePad.openSignaturePad();
    },
    previewMultiImage: function(event) {
      this.filenameattach = [];
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_image = e.target.result;
            this.getImage(e.target.result, "preview");
          };
          if (input.files[index].type.split("/")[0] === "image") {
            this.image_data = input.files[index];
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
    },
    async loaddata() {
      const result = await api.getAccountid(
        localStorage.getItem(server.USER_ID)
      );
      if (result.data[0]) {
        this.dataaccount = result.data[0];
        this.account.phone = this.dataaccount.phone;
        this.account.signature = this.dataaccount.signature;
        this.account.oldimage = this.dataaccount.image;
        // this.dataaccount.showimage = `${imageUrl}/${this.dataaccount.image}`
       await this.getImage(null, "load");
       await this.showdefaultsignature();
       this.showsig1 = true;
      }
    },
   async getImage(data, type) {
      if (type == "load") {
        this.showpreviewimage = `${imageUrl}/${this.dataaccount.image}`;
        return `${imageUrl}/${this.dataaccount.image}`;
      } else {
        this.showpreviewimage = `${data}`;
        return `${data}`;
      }
    },
    async submit() {
      if (this.account.currentpassword !== "" || this.account.password !== "" ||this.account.confirm_password !== "" ) {
      if (this.account.currentpassword == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please Input Current Password",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (this.account.password == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please Input New Password",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (this.account.confirm_password == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Please Input Confirm Password",
          "text-h5 red--text text-center"
        );
        return;
      }
    }

      if (this.account.password != this.account.confirm_password) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Password and Confirm Password not match",
          "text-h5 red--text text-center"
        );
        return;
      }
      let formData = new FormData();
      if (this.image_data) {
        formData.append("image", this.image_data);
      }
      if (this.account.currentpassword !== "" && this.account.password !== "" && this.account.confirm_password !== "" ) {
      formData.append("currentpassword", this.account.currentpassword);
      formData.append("password", this.account.password);
      }
      formData.append("phone", this.account.phone);
      formData.append("signature", this.account.signature);
      formData.append("oldimage", this.account.oldimage);
      
      const res_reset = await api.updateMyprofile(
        localStorage.getItem(server.USER_ID),
        formData
      );
      
      if (res_reset.status == 200 || res_reset.status == 201) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialogPush(
          true,
          "Success",
          "Update data success",
          "text-h5 green--text text-center",
        );
        // "/dashboard"
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Update data Failed",
          "text-h5 red--text text-center"
        );
      }
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
  async beforeCreate() {
    this.$store.state.navMenu = true;
  },
};
