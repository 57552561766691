//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "../services/constants";
import StockCard from "@/components/cards/StockCard";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import unity from "@/unity/unity";

export default {
  name: "NewAdvance",
  data(vm) {
    return {
      timesheet_name: "",
      charge_list: [
        { value: "T/C", name: "T/C" },
        { value: "N/C", name: "N/C" },
        { value: "F/C", name: "F/C" },
        { value: "OFF", name: "OFF" },
      ],
      // charge_list: [
      //   { value: "T/C", name: "Time Charge" },
      //   { value: "N/C", name: "No Charge" },
      //   { value: "F/C", name: "Fix Charge" },
      //   { value: "OFF", name: "ทำงานออฟฟิต" },
      // ],
      showActivity_create: "",
      datedate_request: "",
      noheadertimesheet: "",
      dateheadertimesheet: "",
      dialogDeleteForm: false,
      disableinstatus: false,
      dialogadddetail: false,
      menuaddcheckin: false,
      menuaddcheckout: false,
      menuadd: false,
      menuadd2: false,
      hidebtninsert: false,
      showtitletimecheckin: false,
      menutime: false,
      menutimecheckin: false,
      menutimeadd: false,
      menutime2: false,
      menutimeadd2: false,
      timedatefrom: "00:00:00",
      timedateto: "00:00:00",
      dialogtomytimesheet: false,
      dialogedit: false,
      showbtnadd: true,
      advance_code: [],
      advance_code_list: [],
      advance_code_id: 0,
      dialogAdd: false,
      dialogSendMail: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      customer: [],
      jobtitle_list: [],
      customer_list: [],
      customer_id: 0,
      options: {
        penColor: "#000000",
        backgroundColor: "rgb(255,255,255)",
      },
      initial_data2: "",
      initial_data: "",
      pickbank: false,
      pickcheque: false,
      checkcheque: false,
      checkchequefilled: false,
      showsig1: false,
      showsig2: true,
      showbuttonsavesig1: false,
      showbuttonsavesig2: false,
      defaultwordbuttonsig1: "Signature",
      defaultwordbuttonsig2: "Signature",
      totalleftoveramount: 0.0,
      sumwithdrawamount: 0.0,
      sunamountcleared: 0.0,
      showallamount: "",

      selectbankname: "",
      setusername: "",
      department: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateCurrent: unity.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      datefrom: vm.formatDate(new Date().toISOString().substr(0, 10)),
      dateto: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      menuadd: false,
      modal: false,
      menu2: false,
      menuadd2: false,
      search: "",
      selectedProductId: "",
      confirmDeleteDlg: false,
      mDataArray: [],
      // mDataArray: [{ id: "1", detail: "gg", amout: "1000" }],
      fullPage: true,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Job Title",
          value: "jobtitle_name",
          class: "bg-colorth tabletextwhite",
          sortable: false,
          divider: true,
          width: "15%",
        },
        {
          text: "abbname",
          value: "abbname_e_n",
          class: "bg-colorth tabletextwhite",
          sortable: false,
          divider: true,
        },
        {
          text: "Customer",
          value: "customer_name",
          class: "bg-colorth tabletextwhite",
          sortable: false,
          divider: true,
          width: "15%",
        },
        {
          text: "Check-In",
          value: "checkin",
          class: "bg-colorth tabletextwhite",
          sortable: false,
          divider: true,
        },
        {
          text: "Check-Out",
          value: "checkout",
          class: "bg-colorth tabletextwhite",
          sortable: false,
          divider: true,
        },
        {
          text: "Hour",
          value: "showhours",
          class: "bg-colorth tabletextwhite",
          sortable: false,
          divider: true,
          width: "5%",
        },
        {
          text: "Approve Hour",
          value: "showactualchargehour",
          class: "bg-colorth tabletextwhite",
          sortable: false,
          divider: true,
          width: "5%",
        },
        {
          text: "Charge Type",
          value: "chargetypename",
          class: "bg-colorth tabletextwhite",
          sortable: false,
          divider: true,
          width: "7%",
        },
        {
          text: "Prospect",
          value: "prospect",
          class: "bg-colorth tabletextwhite",
          sortable: false,
          divider: true,
          width: "7%",
        },
        {
          text: "Details",
          value: "detail",
          class: "bg-colorth tabletextwhite",
          sortable: false,
          divider: true,
          width: "25%",
        },
        {
          text: "",
          value: "actions",
          class: "bg-colorth tabletextwhite",
          sortable: false,
          divider: true,
          align: "center",
          width: "7%",
        },
      ],
      desserts: [],
      headerItem: {
        status: 0,
        remark: "",
        update_by: localStorage.getItem(server.USER_ID),
      },
      showaddItem: {
        jobtitle_id: 0,
        customer_id: 0,
        // checkin: "",
        // checkout: "",
        checkin: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        checkout: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        timecheckin: "",
        timecheckout: "",
        hours: "",
        chargetype: "",
        prospect: "",
        detail: "",
        durationhour: 0,
        durationminute: 0,
      },
      addItem: {
        timesheet_hid: 0,
        jobtitle_id: 0,
        customer_id: 0,
        employeeid: 0,
        checkin: "",
        checkout: "",
        hours: "",
        chargetype: "",
        prospect: "",
        detail: "",
      },
      editedIndex: -1,
      showeditedItem: {
        jobtitle_id: 0,
        customer_id: 0,
        checkin: "",
        checkout: "",
        timecheckin: "",
        timecheckout: "",
        hours: "",
        chargetype: "",
        prospect: "",
        detail: "",
        durationhour: 0,
        durationminute: 0,
        tualchargehour: 0,
        tualchargeminute: 0,
      },
      editedItem_id: 0,
      editedItem: {
        jobtitle_id: 0,
        customer_id: 0,
        checkin: "",
        checkout: "",
        hours: "",
        actualchargehour: "",
        chargetype: "",
        prospect: "",
        detail: "",
      },
      defaultItem: {
        code: "",
        description: "",
        amount: 0,
      },

      lbl_date_request: false,
      dialogSignature: false,
      dialogSaveDraft: false,
      messageSaveDraft: "",
      paymentType: "",
      bankName: "",
      bankNumber: "",
      chequeNumber: "",
      remark: "",
      sendMail: {
        name: "",
        email: "",
        advance_id: "",
        link: "",
      },
      account: {
        signature: "",
      },
      advance_log: {
        module: "TS",
        module_id: 0,
        approve_id: 0,
        approve_status: 0,
        approve_date: null,
      },
    };
  },
  components: {
    StockCard,
    SuccessDialog,
    SuccessDialogPush,
  },
  computed: {
    version() {
      return this.initial_data;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    computedDateCheckInFormatted() {
      return unity.formatDate(this.showaddItem.checkin);
    },
    computedDateCheckOutFormatted() {
      return unity.formatDate(this.showaddItem.checkout);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  async mounted() {
    // this.$showLoader();
    this.$hideLoader();

    this.$forceUpdate();
    this.loaddataheader();
    this.loaddatadetails();
    this.loadCustomer();
    this.loadJobtitle();
    this.loaddepartment();
    const res_acc = await api.getAccountDetail(
      localStorage.getItem(server.USER_ID)
    );

    // alert(res_acc.data[0].department_i_d);

    // console.log("res_acc : ", JSON.stringify(res_acc.data[0].signature));

    this.user_sign = res_acc.data[0].signature;

    this.showsig1 = true;
    this.dialogSignature = true;
    // this.showdefaultsignature();
    this.savesig1();
    // this.$refs.ApproversignaturePad.lockSignaturePad();

    // let yourUrlString = window.location;
    // alert(yourUrlString);

    // let parser = document.createElement("a");
    // parser.href = yourUrlString;

    // parser.protocol; // => "http:"
    // parser.hostname; // => "example.com"
    // parser.port; // => "3000"
    // parser.pathname; // => "/pathname/"
    // parser.search; // => "?search=test"
    // parser.hash; // => "#hash"
    // parser.host; // => "example.com:3000"

    // alert(parser.pathname);

    const userId = localStorage.getItem(server.USER_ID);
    if (userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
    // console.log("Login mounted : " + api.isLoggedIn());
    // console.log("Login mounted : " + userId);
    // await this.loadProducts();
  },
  methods: {
    closeDialogSendmail() {
      this.dialogSendMail = false;
      this.$router.push("/advance-list");
    },
    async loaddepartment() {
      // console.log("loadAdvanceCode");

      let company_id = localStorage.getItem(server.COMPANYID);

      // console.log("loadAdvanceCode company_id:" + company_id);

      const result = await api.getAllAdvanceCodeByCompany(company_id);

      // console.log("loadAdvanceCode : ", JSON.stringify(result.data));
      this.advance_code = result.data;

      result.data.forEach((item) => {
        this.advance_code_list.push(item.code + " " + item.description);
      });
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    async savedetailtimesheet() {},
    async savetimesheetApprove() {
      this.headerItem.status = 3;

      this.advance_log.module_id = this.$route.params.id;
      this.advance_log.approve_id = localStorage.getItem(server.USER_ID);
      this.advance_log.approve_status = 3;
      this.advance_log.approve_date = new Date();

      const res_h = await api.getupdatetimesheetH(
        this.$route.params.id,
        this.headerItem
      );

      if (res_h.status == 200 || res_h.status == 201) {
        const res_log = await api.addAdvanceLog(this.advance_log);
        if (res_log.status == 200 || res_log.status == 201) {
          this.loaddatadetails();
          this.dialogadddetail = false;
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "Information Saved",
            "Information successfully saved",
            "text-h5 green--text text-center",
            "/approve-time-sheet"
          );
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "ไม่สามารถบันทึกได้",
            "text-h5 red--text text-center"
          );
        }
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้",
          "text-h5 red--text text-center"
        );
      }
    },

    async savetimesheetReturn() {
      this.headerItem.status = 1;

      this.advance_log.module_id = this.$route.params.id;
      this.advance_log.approve_id = localStorage.getItem(server.USER_ID);
      this.advance_log.approve_status = 4;
      this.advance_log.approve_date = new Date();
      const res_h = await api.getupdatetimesheetH(
        this.$route.params.id,
        this.headerItem
      );

      if (res_h.status == 200 || res_h.status == 201) {
        const res_log = await api.addAdvanceLog(this.advance_log);
        if (res_log.status == 200 || res_log.status == 201) {
          this.loaddatadetails();
          this.dialogadddetail = false;
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "Information Saved",
            "Information successfully saved",
            "text-h5 green--text text-center",
            "/approve-time-sheet"
          );
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "ไม่สามารถบันทึกได้",
            "text-h5 red--text text-center"
          );
        }
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้",
          "text-h5 red--text text-center"
        );
      }
    },

    async savetimesheetReject() {
      this.headerItem.status = 5;

      this.advance_log.module_id = this.$route.params.id;
      this.advance_log.approve_id = localStorage.getItem(server.USER_ID);
      this.advance_log.approve_status = 5;
      this.advance_log.approve_date = new Date();
      const res_h = await api.getupdatetimesheetH(
        this.$route.params.id,
        this.headerItem
      );

      if (res_h.status == 200 || res_h.status == 201) {
        const res_log = await api.addAdvanceLog(this.advance_log);
        if (res_log.status == 200 || res_log.status == 201) {
          this.loaddatadetails();
          this.dialogadddetail = false;
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "Information Saved",
            "Information successfully saved",
            "text-h5 green--text text-center",
            "/approve-time-sheet"
          );
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "ไม่สามารถบันทึกได้",
            "text-h5 red--text text-center"
          );
        }
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้",
          "text-h5 red--text text-center"
        );
      }
    },

    async deleteFormtimesheet() {
      // this.$showLoader();
      const res_add_d = await api.getdeletebyTSIdtimesheetD(
        this.$route.params.id
      );
      const res_add_h = await api.getdeletetimesheetH(this.$route.params.id);

      if (res_add_h.data == 1) {
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "ลบข้อมูล",
          "ลบข้อมูลสำเร็จ",
          "text-h5 green--text text-center",
          "/approve-time-sheet"
        );
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "ลบข้อมูล",
          "ไม่สามารถลบข้อมูลได้",
          "text-h5 red--text text-center"
        );
      }

      this.$hideLoader();
    },

    async loaddatadetails() {
      const result = await api.getdetailstimesheetD(this.$route.params.id);
      this.desserts = result.data;
      this.desserts.forEach((x) => {
        let setdatetimecheckin = x.checkin.split(" ");
        let setdatetimecheckout = x.checkout.split(" ");
        x.checkin = `${setdatetimecheckin[0]}T${setdatetimecheckin[1]}`;
        x.checkout = `${setdatetimecheckout[0]}T${setdatetimecheckout[1]}`;

        // x.checkin = setdatetimecheckin[0];
        //    x.checkout = setdatetimecheckout[0];
        // x.timecheckin = setdatetimecheckin[1];
        // x.timecheckout = setdatetimecheckout[1];
      });
      console.log(result.data);
    },
    async loaddataheader() {
      const result = await api.getByIdtimesheetH(this.$route.params.id);
      this.timesheet_name = result.data[0].timesheet_name;
      this.headerItem.remark = result.data[0].remark;
      this.headerItem.status = result.data[0].status;
      this.noheadertimesheet = result.data[0].timesheet_id;
      this.dateheadertimesheet = result.data[0].created_at;
      const resultdetail = await api.approvedetailtimesheetH(
        this.$route.params.id
      );
      this.datedate_request = resultdetail.data[0].approve_sucess_date;

      const res_acc = await api.getAccountDetail(
        resultdetail.data[0].employee_id
      );

      this.initial_data2 = res_acc.data[0].signature;

      this.setusername =
        res_acc.data[0].prename_e_n +
        " " +
        res_acc.data[0].firstname_e_n +
        " " +
        res_acc.data[0].lastname_e_n;

      // alert(res_acc.data[0].department_i_d);
      const res_department = await api.getDepartmentByID(
        res_acc.data[0].department_i_d
      );

      this.department = res_department.data[0].name;
      this.showdefaultsignature();
      // if (this.headerItem.status == 2) {
      //   this.disableinstatus = true;
      // } else {
      //   this.disableinstatus = false;
      // }
    },
    async openaddItemdetail() {
      this.showaddItem = {
        jobtitle_id: 0,
        customer_id: 0,
        checkin: "",
        checkout: "",
        timecheckin: "",
        timecheckout: "",
        hours: "",
        chargetype: "",
        prospect: "",
        detail: "",
        durationhour: 0,
        durationminute: 0,
      };
      this.dialogadddetail = true;
    },

    async saveaddItem() {
      if (
        this.showaddItem.jobtitle_id == "" ||
        this.showaddItem.jobtitle_id == null
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Jobtitle ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showaddItem.customer_id == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Customer ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showaddItem.checkin == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Date Check-In ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showaddItem.timecheckin == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Time Check-In ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showaddItem.checkout == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Date Check-Out ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showaddItem.timecheckout == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Time Check-Out ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showaddItem.chargetype == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Charge-Type ",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (this.showaddItem.detail == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Details ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showaddItem.durationhour < 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "duration hour ต้องมากกว่าหรือเท่ากับ 0 ",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (
        this.showaddItem.durationhour == "" ||
        this.showaddItem.durationhour == undefined ||
        this.showaddItem.durationhour == null
      ) {
        this.showaddItem.durationhour = 0;
      }

      if (this.showaddItem.durationminute < 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "duration minute ต้องมากกว่าหรือเท่ากับ 0 ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (
        this.showaddItem.durationminute == "" ||
        this.showaddItem.durationminute == undefined ||
        this.showaddItem.durationminute == null
      ) {
        this.showaddItem.durationminute = 0;
      }
      this.$showLoader();

      this.showaddItem.timecheckin = this.showaddItem.timecheckin
        .split("T")[1]
        .split(".000")[0];
      this.showaddItem.timecheckout = this.showaddItem.timecheckout
        .split("T")[1]
        .split(".000")[0];

      this.addItem.timesheet_hid = this.$route.params.id;
      this.addItem.jobtitle_id = this.showaddItem.jobtitle_id;
      this.addItem.customer_id = this.showaddItem.customer_id;
      this.addItem.employeeid = localStorage.getItem(server.USER_ID);
      this.addItem.checkin = `${this.showaddItem.checkin} ${this.showaddItem.timecheckin}`;
      this.addItem.checkout = `${this.showaddItem.checkout} ${this.showaddItem.timecheckout}`;
      // this.addItem.hours = parseFloat(
      //   Math.abs(
      //     new Date(this.addItem.checkin) - new Date(this.addItem.checkout)
      //   ) /
      //     (1000 * 60 * 60)
      // ).toFixed(2);
      this.addItem.chargetype = this.showaddItem.chargetype;
      this.addItem.prospect = this.showaddItem.prospect;
      this.addItem.detail = this.showaddItem.detail;
      this.addItem.hours =
        parseInt(this.showaddItem.durationhour * 60) +
        parseInt(this.showaddItem.durationminute);

      const res_add_d = await api.getaddtimesheetD(this.addItem);
      if (res_add_d.status == 200 || res_add_d.status == 201) {
        await this.loaddatadetails();
        this.dialogadddetail = false;
        this.updatecustomer_timesheet_h();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "Information successfully saved",
          "text-h5 green--text text-center"
        );
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้",
          "text-h5 red--text text-center"
        );
      }

      this.$hideLoader();
    },

    async closeaddItem() {
      this.dialogadddetail = false;
    },

    async openeditItem(item) {
      console.log(item);
      this.editedIndex = this.desserts.indexOf(item);
      this.showeditedItem = Object.assign({}, item);
      this.showActivity_create = item.activity_create;
      this.showeditedItem.durationhour = parseInt(
        this.showeditedItem.hours / 60
      );
      this.showeditedItem.durationminute = parseInt(
        this.showeditedItem.hours % 60
      );

      this.showeditedItem.tualchargehour = parseInt(
        this.showeditedItem.actualchargehour / 60
      );
      this.showeditedItem.tualchargeminute = parseInt(
        this.showeditedItem.actualchargehour % 60
      );
      let getdatetimecheckin = this.showeditedItem.checkin.split(".000")[0];
      let getdatetimecheckout = this.showeditedItem.checkout.split(".000")[0];

      this.showeditedItem.checkin = getdatetimecheckin.split("T")[0];
      this.showeditedItem.checkout = getdatetimecheckout.split("T")[0];
      this.showeditedItem.timecheckin = getdatetimecheckin.split("T")[1];
      this.showeditedItem.timecheckout = getdatetimecheckout.split("T")[1];
      this.dialogedit = true;
    },
    async saveeditItem() {
      if (
        this.showeditedItem.jobtitle_id == "" ||
        this.showeditedItem.jobtitle_id == null
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Jobtitle ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (
        this.showeditedItem.customer_id == 0 ||
        this.showeditedItem.customer_id == null
      ) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Customer ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showeditedItem.checkin == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Date Check-In ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showeditedItem.timecheckin == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Time Check-In ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showeditedItem.checkout == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Date Check-Out ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showeditedItem.timecheckout == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Time Check-Out ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showeditedItem.chargetype == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Charge-Type ",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (this.showeditedItem.detail == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Details ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.showeditedItem.durationhour < 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "duration hour ต้องมากกว่าหรือเท่ากับ 0 ",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (
        this.showeditedItem.durationhour == "" ||
        this.showeditedItem.durationhour == undefined ||
        this.showeditedItem.durationhour == null
      ) {
        this.showeditedItem.durationhour = 0;
      }
      if (this.showeditedItem.durationminute < 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "duration minute ต้องมากกว่าหรือเท่ากับ 0 ",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (
        this.showeditedItem.durationminute == "" ||
        this.showeditedItem.durationminute == undefined ||
        this.showeditedItem.durationminute == null
      ) {
        this.showeditedItem.durationminute = 0;
      }

      if (this.showeditedItem.tualchargehour < 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "tualcharge hour ต้องมากกว่าหรือเท่ากับ 0 ",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (
        this.showeditedItem.tualchargehour == "" ||
        this.showeditedItem.tualchargehour == undefined ||
        this.showeditedItem.tualchargehour == null
      ) {
        this.showeditedItem.tualchargehour = 0;
      }

      if (this.showeditedItem.tualchargeminute < 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "tualcharge minute ต้องมากกว่าหรือเท่ากับ 0 ",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (
        this.showeditedItem.tualchargeminute == "" ||
        this.showeditedItem.tualchargeminute == undefined ||
        this.showeditedItem.tualchargeminute == null
      ) {
        this.showeditedItem.tualchargeminute = 0;
      }
      this.editedItem_id = 0;
      // if (this.desserts.length <= 0) {
      //   // alert("กรุณาเลือกลูกค้า");
      //   // this.messageSaveDraft = "กรุณากรอกรายละเอียด Advance Detail";
      //   // this.dialogSaveDraft = true;
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "Check your information again",
      //     "กรุณากรอกรายละเอียด TimeSheet Detail",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }
      if (this.showeditedItem.timecheckin.search("T") !== -1) {
        this.showeditedItem.timecheckin = this.showeditedItem.timecheckin
          .split("T")[1]
          .split(".000")[0];
      } else {
        this.showeditedItem.timecheckin = this.showeditedItem.timecheckin;
      }
      if (this.showeditedItem.timecheckout.search("T") !== -1) {
        this.showeditedItem.timecheckout = this.showeditedItem.timecheckout
          .split("T")[1]
          .split(".000")[0];
      } else {
        this.showeditedItem.timecheckout = this.showeditedItem.timecheckout;
      }

      this.editedItem_id = this.showeditedItem.timesheet_d_id;
      this.editedItem.jobtitle_id = this.showeditedItem.jobtitle_id;
      this.editedItem.customer_id = this.showeditedItem.customer_id;
      this.editedItem.checkin = `${this.showeditedItem.checkin} ${this.showeditedItem.timecheckin}`;
      this.editedItem.checkout = `${this.showeditedItem.checkout} ${this.showeditedItem.timecheckout}`;
      // this.editedItem.hours = this.showeditedItem.hours;
      this.editedItem.hours =
        parseInt(this.showeditedItem.durationhour * 60) +
        parseInt(this.showeditedItem.durationminute);
      this.editedItem.actualchargehour =
        parseInt(this.showeditedItem.tualchargehour * 60) +
        parseInt(this.showeditedItem.tualchargeminute);
      this.editedItem.chargetype = this.showeditedItem.chargetype;
      this.editedItem.prospect = this.showeditedItem.prospect;
      this.editedItem.detail = this.showeditedItem.detail;
      console.log(this.editedItem);
      const res_d = await api.getupdatetimesheetD(
        this.editedItem_id,
        this.editedItem
      );

      if (res_d.status == 200 || res_d.status == 201) {
        this.loaddatadetails();
        this.dialogedit = false;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "Information successfully saved",
          "text-h5 green--text text-center"
        );
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้",
          "text-h5 red--text text-center"
        );
      }
    },
    async closeeditItem() {
      this.dialogedit = false;
      //   this.$nextTick(() => {
      //     this.editedItem = Object.assign({}, this.defaultItem);
      //     this.editedIndex = -1;
      //   });
    },

    deleteItem(item) {
      // this.editedIndex = this.desserts.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      this.editedItem_id = item.timesheet_d_id;
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      // this.desserts.splice(this.editedIndex, 1);
      const resdel = await api.getdeletetimesheetD(this.editedItem_id);
      if (resdel.data == 1) {
        await this.loaddatadetails();
        this.updatecustomer_timesheet_h();
        this.closeDelete();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "ลบข้อมูล",
          "ลบข้อมูลสำเร็จ",
          "text-h5 green--text text-center"
        );
      } else {
        this.loaddatadetails();
        this.closeDelete();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "ลบข้อมูล",
          "ไม่สามารถลบข้อมูลได้",
          "text-h5 red--text text-center"
        );
      }
    },

    closeSaveDraft() {
      this.dialogSaveDraft = false;
    },

    closeDelete() {
      this.editedItem_id = 0;
      this.dialogDelete = false;
      // this.$nextTick(() => {
      //   this.editedItem = Object.assign({}, this.defaultItem);
      //   this.editedIndex = -1;
      // });
    },
    async loadJobtitle() {
      const result = await api.getAllJobtitleByCompany(
        localStorage.getItem(server.COMPANYID)
      );

      this.jobtitle_list = result.data;
    },

    async loadCustomer() {
      // console.log("loadCustomer");
      const result = await api.getAllCustomerListactiveCompany(
        {
          params: {
            company_id: localStorage.getItem(server.COMPANYID),
          },
        }
      );
      // console.log("result.data : ", JSON.stringify(result.data));
      this.customer_list = result.data;
    },

    editsig1() {
      this.dialogSignature = true;
      this.lbl_date_request = false;
      this.showsig1 = true;
      this.showbuttonsavesig1 = true;
      this.$refs.ApproversignaturePad.openSignaturePad();
    },
    editsig2() {
      this.showsig2 = true;
      this.showbuttonsavesig2 = true;
      this.$refs.RequestersignaturePad.openSignaturePad();
    },
    clearsig1() {
      this.lbl_date_request = false;
      this.$refs.ApproversignaturePad.clearSignature();
    },
    clearsig2() {
      this.$refs.RequestersignaturePad.clearSignature();
    },
    async savesig1() {
      this.lbl_date_request = true;
      this.defaultwordbuttonsig1 = "Edit";
      const { isEmpty, data } = this.$refs.ApproversignaturePad.saveSignature();
      this.showbuttonsavesig1 = false;
      this.$refs.ApproversignaturePad.lockSignaturePad();
      // console.log("user_sign : " + data);
      // console.log("user_sign1 : " + this.user_sign);
      this.user_sign = data;
      this.account.signature = data;

      await api.updateAccount(
        localStorage.getItem(server.USER_ID),
        this.account
      );
      loader.hide();
    },
    savesig2() {
      this.defaultwordbuttonsig2 = "Edit";
      const {
        isEmpty,
        data,
      } = this.$refs.RequestersignaturePad.saveSignature();
      this.showbuttonsavesig2 = false;
      this.$refs.RequestersignaturePad.lockSignaturePad();
    },

    showdefaultsignature() {
      this.$nextTick(() => {
        // this.$refs.signaturePaddefault.fromDataURL(this.initial_data);
        // this.$refs.signaturePaddefault.lockSignaturePad();
        this.$refs.ApproversignaturePad.fromDataURL(this.user_sign);
        this.$refs.ApproversignaturePad.lockSignaturePad();
        this.$refs.RequestersignaturePad.fromDataURL(this.initial_data2);
        this.$refs.RequestersignaturePad.lockSignaturePad();
      });
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month - 1}-${day}`;
    },

    closetomytimesheet() {
      this.dialogtomytimesheet = true;
    },
    confirmtomytimesheet() {
      this.dialogtomytimesheet = false;
      this.$router.push("/approve-time-sheet");
    },
    canceltomytimesheet() {
      this.dialogtomytimesheet = false;
    },
    async saveeditdetails() {
      console.log(this.desserts);
      // this.editedItem.hours = this.showeditedItem.hours;
      this.desserts.forEach(async (x, index) => {
        x.hours = parseInt(x.showhours * 60);
        // +
        // parseInt(x.durationminute);
        x.actualchargehour = parseInt(x.showactualchargehour * 60);
        console.log(x);
        // return;
        const res_d = await api.getupdatetimesheetD(x.timesheet_d_id, x);
        if (index == this.desserts.length - 1) {
          this.updatecustomer_timesheet_h();
          if (res_d.status == 200 || res_d.status == 201) {
            this.loaddatadetails();
            this.dialogedit = false;
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Information Saved",
              "Information successfully saved",
              "text-h5 green--text text-center"
            );
          } else {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Information Saved",
              "ไม่สามารถบันทึกได้",
              "text-h5 red--text text-center"
            );
          }
        }
      });

      // +
      // parseInt(this.desserts.tualchargeminute);
      // this.desserts.chargetype = this.desserts.chargetype;
      // this.desserts.prospect = this.desserts.prospect;
      // this.desserts.detail = this.desserts.detail;
    },
    async updatecustomer_timesheet_h() {
      let firstcheckcusforupdatetimesheet_h = 0;
      let checkcusforupdatetimesheet_h = 0;
      let datadifferent = false;
      this.desserts.forEach(async (x, index) => {
        if (index == 0) {
          if (this.desserts.length == 1) {
            checkcusforupdatetimesheet_h = x.customer_id;
          }
          firstcheckcusforupdatetimesheet_h = x.customer_id;
        } else {
          if (datadifferent == false) {
            if (firstcheckcusforupdatetimesheet_h == x.customer_id) {
              checkcusforupdatetimesheet_h = x.customer_id;
            } else {
              checkcusforupdatetimesheet_h = 0;
              datadifferent = true;
            }
          }
        }
        if (index == this.desserts.length - 1) {
          await api.getupdatetimesheetH(this.$route.params.id, {
            customer_id: checkcusforupdatetimesheet_h,
          });
          // if (res_d.status == 200 || res_d.status == 201) {
          //   this.loaddatadetails();
          //   this.dialogedit = false;
          //   this.$store.state.global_dialog = true;
          //   this.setupAlertDialog(
          //     true,
          //     "Information Saved",
          //     "Information successfully saved",
          //     "text-h5 green--text text-center"
          //   );
          // } else {
          //   this.$store.state.global_dialog = true;
          //   this.setupAlertDialog(
          //     true,
          //     "Information Saved",
          //     "ไม่สามารถบันทึกได้",
          //     "text-h5 red--text text-center"
          //   );
          // }
        }
      });
    },
  },
  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
    // this.showdefaultsignature();
  },
  async created() {
    // this.showdefaultsignature();
  },
};
