//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "@/services/constants";   
export default {
    name: "D-Documnent",
    data(vm) {
        return {
            doctype_id_show: 0,
            search: "",
            disabledAdd: true,
            authorize_view: false,
            authorize_add: false,
            authorize_edit: false,
            authorize_del: false,
            toggleEnable: false,
            selectstatus: "",
            mDataArray: [],
            doctyype_list: [],
            doctitle_list: [],
            doctyype_drop: [],
            headers: [],
            doctitle_id_list: []
        };
    },
    async beforeCreate() {
        console.log("beforeCreate");
        this.$store.state.navMenu = true ;
    },
    beforeUpdate() {},
    computed: {
        itemsForSelected() {
            if (this.search.length) {
                return this.dataSource.filter((item) => this.search.includes(item));
            }
            return this.dataSource;
        },
    },
    async mounted() {
        await api.checkVersion();
        this.userId = localStorage.getItem(server.USER_ID);
        let yourUrlString = window.location;
        // alert(yourUrlString);
    
        let parser = document.createElement("a");
        parser.href = yourUrlString;
    
        this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);
    
        // alert("authorize_id:" + this.authorize_id);
        if (this.authorize_id == null || this.authorize_id == 0) {
            // this.$router.push("/login");
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
            true,
            "Authorize Failed",
            "Please Logout And Login Again",
            "text-h5 red--text text-center"
            );
            this.$router.back();
        }
        const router_path = parser.pathname.replace("/", "").split("/")[0];           
    
        const res_auth = await api.getAuthorize(this.userId, router_path);

    
        this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
        this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
        this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
        this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;
    

    
        if (!this.authorize_view) {
            this.$router.back();
        }
    
        // ----------------- Check Authorize ---------------------------
    
        let comp_id = localStorage.getItem(server.COMPANYID);
    
        this.userId = localStorage.getItem(server.USER_ID);
        if (this.userId && api.isLoggedIn()) {
            // this.$router.push("/");
        } else {
            this.$store.state.isLogged = false;
            this.$router.push("/login");
        }

        //load data
        await this.loadDepAuth();            
        this.$hideLoader();
    },
    methods: {
        async loadDepAuth() {
            const res_doctype = await api.getDoctypeList();
            this.doctyype_list = res_doctype.data;
            this.doctyype_drop = this.doctyype_list.filter((item) => {
                let res_dep_id = [];
                res_dep_id = item.department_authorization.split(',')
                for(let i=0;i < res_dep_id.length;i++){
                    if(localStorage.getItem(server.DEPARTMENT_ID) == res_dep_id[i]){
                        return item;
                    }
                }
            });
            if(this.$route.params.id){
            this.doctype_id_show = parseInt(this.$route.params.id);
            this.loaddoctypebydep();
        }
            // console.log(this.doctyype_drop)
        },
        async loaddoctypebydep(){
            // alert(this.doctype_id_show)
            this.headers = [];
            this.doctitle_id_list = [];
            const res_doctitle = await api.getDoctypeTemplateByID(this.doctype_id_show)
            const res_checDoctrans = await api.getDocTransByDoctye(this.doctype_id_show)
            console.log(res_doctitle.data)
            this.doctitle_list = res_doctitle.data;
            this.doctitle_list.forEach((item,index)=>{
                // console.log(item)
                
                let head_title = {}
                head_title = {
                    text: `${item.title}`,
                    value: `title${item.id}`,
                    class: "bg-colorth tabletextwhite",
                    align: "start",
                    divider: true,
                }
                this.headers.push(head_title)
                this.doctitle_id_list.push(item.id)
            });
            if(this.headers.length > 0){
                let head_title1 = {};
                let head_title2 = {};
                head_title1 = {
                    text: "File Name",
                    value: "file_name",
                    align: "start",
                    class: "bg-colorth tabletextwhite",
                    divider: true,
                    width: "101px"
                }
                head_title2 = {
                    text: "",
                    value: "actions",
                    align: "center",
                    class: "bg-colorth tabletextwhite",
                    divider: true,
                    width: "101px"
                }
                this.headers.push(head_title1)
                this.headers.push(head_title2)
            }
            // console.log(this.headers)
            if(this.doctype_id_show != 0){
                this.disabledAdd = false;
            }
            if(res_checDoctrans.data.length > 0){
                const res_dataT = await api.createDocTransListT({doctitle_id: this.doctitle_id_list})
                console.log(res_dataT.data)
                this.mDataArray = res_dataT.data;
            }else{
                this.mDataArray = []
            }
        },
        async openAdd(){
            this.$router.push(`/d-document-add/Add/${this.doctype_id_show}`);
        },
        async openeditItem(doctrans_hid){
            // alert(doctrans_hid)
            // this.$router.push(`/d-document-operation/Edit/${this.doctype_id_show}/${doctrans_hid}`);
            const url = `/d-document-operation/Edit/${this.doctype_id_show}/${doctrans_hid}`;
            window.open(url, '_blank'); 
        },
        async openshowItem(doctrans_hid){
            // this.$router.push(`/d-document-operation/Show/${this.doctype_id_show}/${doctrans_hid}`);
            const url = `/d-document-operation/Show/${this.doctype_id_show}/${doctrans_hid}`;
            window.open(url, '_blank');
        },
    },
};
