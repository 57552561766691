//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "@/services/constants";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import SuccessDialog from "@/components/cards/SuccessDialog";

export default {
  name: "MappingList",
  async mounted() {
    this.$showLoader();
    await api.checkVersion();

    // ----------------- Check Authorize ---------------------------
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);
    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed",
        "Please Logout And Login Again",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(this.userId, router_path);

    console.log("res_auth:" + JSON.stringify(res_auth.data));

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    // console.log("res_auth:" + JSON.stringify(res_auth.data));
    // console.log("authorize_view:" + this.authorize_view);
    // console.log("authorize_add:" + this.authorize_add);
    // console.log("authorize_edit:" + this.authorize_edit);
    // console.log("authorize_del:" + this.authorize_del);
    // this.$router.back();

    if (!this.authorize_view) {
      this.$router.back();
    }

    // // ----------------- Check Authorize ---------------------------
    await this.loaddata();
    this.$hideLoader();
  },
  components: { SuccessDialog, SuccessDialogPush },
  data() {
    return {
      userId: 0,
      authorize: [],
      authorize_view: false,
      authorize_add: false,
      authorize_edit: false,
      authorize_del: false,
      authorize_id: 0,
      load_bg: false,
      dialogAddKeyword: false,
      dialogAdd: false,
      dialogAddData: false,
      dialogDelete: false,
      isShowPassword: false,
      fullPage: true,
      search: "",
      mDataArray: [],
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      search_ct: "",
      itemdata: {
        contact_type: 0,
        contact_name: "",
        contact_person: "",
        position: "",
        email: "",
        address: "",
        city: "",
        country: "",
        telephone: "",
        fax: "",
        status: "A",
      },
      edititemdata: {
        contact_type: 0,
        contact_name: "",
        contact_person: "",
        position: "",
        email: "",
        address: "",
        city: "",
        country: "",
        telephone: "",
        fax: "",
      },
      contactid_del: 0,
      oparation: "",
      headers: [
        {
          text: "Work Type",
          value: "documenttype_code",
          class: "bg-colorth",
          divider: true,
          // width: "8%",
        },
        {
          text: "Notice Type",
          value: "notice_code",
          class: "bg-colorth",
          divider: true,
          // width: "10%",
        },
        
        {
          text: "",
          value: "actions",
          class: "bg-colorth",
          divider: true,
          align:"center",
          width: "140px",
        },
      ],
    };
  },
  methods: {
    async loaddata() {
      // this.$showLoader();
      this.load_bg = true;
      var tmp = [];
      const result = await api.getOCRKWList();
      this.mDataArray =  result.data;
      console.log(this.mDataArray)
      this.load_bg = false;
    },
    async openeditItem(mode,item) {
      if(mode == "Edit"){
        this.$router.push(`/edit-keyword/${mode}/${item.doctype_id}/${item.notice_type}`);
      }else{
        this.$router.push(`/show-keyword/${mode}/${item.doctype_id}/${item.notice_type}`);
      }
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
  beforeCreate() {
    this.$store.state.navMenu = true;
  },
};
