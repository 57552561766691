//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "@/services/constants";
export default {
  name: "group-menu",
  data: () => ({
    image: null,
    dialog: false,
    search: "",
    dialogDelete: false,
    dialogEdit: false,
    dialogAdd: false,
    text_color: "text-h5 green--text text-center",
    title: "green",
    message: "green",
    headers: [
      {
        text: "ID",
        align: "start",
        value: "ocr_id",
      },
      { text: "File Name", value: "file_name" },
      { text: "File No", value: "file_no" },
      { text: "Application No", value: "app_no" },
      { text: "Status", value: "status" },
      { text: "Actions", value: "actions", sortable: false },
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      cgm_name: "",
      user_create: 0,
      user_update: 0,
    },
    editedItem2: {
      cgm_name: "",
      user_update: 0,
    },
    edit_item_id: 0,
    defaultItem: {
      name: "",
      calories: 0,
      fat: 0,
      carbs: 0,
      protein: 0,
    },
    fullPage: true,
    authorize_view: false,
    authorize_add: false,
    authorize_edit: false,
    authorize_del: false,
    authorize_id: 0,
  }),
  components: {
    SuccessDialog,
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },

  async mounted() {
    await api.checkVersion();
    // ----------------- Check Authorize ---------------------------
    const userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;
    // alert(yourUrlString);
    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);
    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed",
        "Please Logout And Login Again",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");
    // alert(router_path);
    const res_auth = await api.getAuthorize(userId, router_path);

    console.log("res_auth:" + JSON.stringify(res_auth.data));

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    // console.log("res_auth:" + JSON.stringify(res_auth.data));
    // console.log("authorize_view:" + this.authorize_view);
    // console.log("authorize_add:" + this.authorize_add);
    // console.log("authorize_edit:" + this.authorize_edit);
    // console.log("authorize_del:" + this.authorize_del);
    // this.$router.back();

    if (!this.authorize_view) {
      this.$router.back();
    }

    // ----------------- Check Authorize ---------------------------

    // const userId = localStorage.getItem(server.USER_ID);
    if (userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  async created() {
    await this.initialize();
  },

  methods: {
    async getMasterPTFileNoSearch(application_no) {
      let file_no = "";
      const res_get = await api.getMasterPTFileNoSearch(application_no);
      console.log(
        "getMasterPTFileNoSearch res_get1:" + JSON.stringify(res_get.data)
      );
      file_no = res_get.data[0].file_no;
      console.log("getMasterPTFileNoSearch res_get2:" + file_no);
      return file_no;
    },
    async openeAppoinmentOperation(file_no) {
      // this.$router.push(`/appointment-patent-add/${file_no}`);
      window.open(`/appointment-patent-add/${file_no}`);
    },
    async textOCRToApplicationNo(ocr_text) {
      let text_arr = ocr_text.split(/\r?\n/);
      let application_no = "";
      let shouldSkip = false;
      // console.log("text_arr:" + JSON.stringify(text_arr));

      text_arr.forEach((element) => {
        // console.log("text_arr element1:" + element);
        if (shouldSkip == false) {
          if (element.includes("เลขที่")) {
            shouldSkip = true;
            let text = element.split("เลขที่");
            console.log("text_arr element2:" + JSON.stringify(text));
            application_no = text[1].trim();
          }
        }
      });

      let tmp = application_no.split(" ");
      application_no = tmp[0];
      console.log("application_no:" + application_no);
      return application_no;
    },
    onPickFile() {
      this.$refs.fileInput.click();
    },
    async onFilePicked(event) {
      this.$showLoader();
      const files = event.target.files;
      let filename = files[0].name;
      const fileReader = new FileReader();
      fileReader.addEventListener("load", () => {
        this.imageUrl = fileReader.result;
      });
      fileReader.readAsDataURL(files[0]);
      this.image = files[0];
      // console.log(this.image);

      let formData = new FormData();

      formData.append("file", this.image);
      formData.append("file_name", filename);
      formData.append("doc_type", "P");
      // console.log(formData);
      // console.log(this.image);
      // console.log(filename);

      // check duplicate file_name
      let checkDuplicate = await api.getOCRbyFileName(filename);
      console.log("checkDuplicate:" + JSON.stringify(checkDuplicate.data));
      if (checkDuplicate.data.length > 0) {
        this.$hideLoader();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Duplicate File",
          "Please Select Another File",
          "text-h5 red--text text-center"
        );
        return;
      }

      let LastID = await api.findLastIDOCR();
      console.log("LastID:" + JSON.stringify(LastID.data));

      if (LastID.data.length == 0) {
        LastID = 1;
      } else {
        LastID = parseInt(LastID.data[0].ocr_id) + 1;
      }
      console.log("LastID:" + LastID);
      formData.append("ocr_id", LastID);

      const res_upload = await api.addImageFileOCR(LastID, formData);
      console.log("res_upload:" + JSON.stringify(res_upload.data));

      if (res_upload.data.ocr_id) {
        const readTextOCR2 = await api.readTextOCR(
          res_upload.data.ocr_id,
          res_upload.data.file_name
        );

        console.log("readTextOCR2 : " + JSON.stringify(readTextOCR2.data));
        const readTextOCR = await api.getOCRbyID(res_upload.data.id);

        console.log("readTextOCR : Success");
        console.log("readTextOCR : " + JSON.stringify(readTextOCR.data));
        if (readTextOCR.data[0].ocr_text) {
          console.log("readTextOCR : in IF");
          let file_no = "";
          let app_no = await this.textOCRToApplicationNo(
            readTextOCR.data[0].ocr_text
          );
          console.log("readTextOCR app_no:" + app_no);

          if (app_no) {
            console.log("app_no : in IF");

            file_no = await this.getMasterPTFileNoSearch(app_no);
            console.log("file_no:" + file_no);
            if (file_no) {
              let data = {
                file_no: file_no,
                app_no: app_no,
                status: "S",
              };
              //update status found file_no
              const res_update = await api.updateOCR(
                res_upload.data.ocr_id,
                data
              );
              console.log("res_update 1:" + JSON.stringify(res_update.data));
            } else {
              //update status file_no = null
              let data = {
                app_no: app_no,
                status: "F",
              };
              //update status found file_no
              const res_update = await api.updateOCR(
                res_upload.data.ocr_id,
                data
              );
              console.log("res_update 2:" + JSON.stringify(res_update.data));
            }
          } else {
            console.log("app_no : not IF");
            //update status file_no = null
            let data = {
              status: "F",
            };
            //update status found file_no
            const res_update = await api.updateOCR(
              res_upload.data.ocr_id,
              data
            );
            console.log("res_update 3:" + JSON.stringify(res_update.data));
          }

          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Upload File Success",
            "File Name: " + filename,
            "text-h5 green--text text-center"
          );
          // this.$router.push("/ocr/" + LastID);
          this.initialize();
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Upload File Failed",
            "File Name: " + filename,
            "text-h5 red--text text-center"
          );
        }
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Upload File Failed",
          "File Name: " + filename,
          "text-h5 red--text text-center"
        );
      }

      this.$hideLoader();
    },
    async initialize() {
      //   alert("initialize");
      const res_get = await api.getPatentOCR();
      this.desserts = res_get.data;
      // this.$showLoader();
      this.$hideLoader();

      //   console.log("initialize desserts: " + JSON.stringify(res_get.data));
    },

    editItem(item) {
      this.edit_item_id = item.id;
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.edit_item_id = item.ocr_id;
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    async deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      const res_del = await api.deleteOCR(this.edit_item_id);
      if (res_del.status == 200) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Success",
          "Delete data success",
          "text-h5 green--text text-center"
        );
        this.initialize();
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Delete data Failed",
          "text-h5 red--text text-center"
        );
      }
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save() {
      if (this.editedIndex > -1) {
        this.editedItem2.user_update = localStorage.getItem(server.USER_ID);
        this.editedItem2.cgm_name = this.editedItem.cgm_name;
        const res_edit = await api.updateGroupMenu(
          this.edit_item_id,
          this.editedItem2
        );
        if (res_edit.status == 200) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Success",
            "Edit data success",
            "text-h5 green--text text-center"
          );
          this.initialize();
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed",
            "Edit data Failed",
            "text-h5 red--text text-center"
          );
        }
      } else {
        // alert("create");
        this.editedItem.user_create = localStorage.getItem(server.USER_ID);
        const res_add = await api.createGroupMenu(this.editedItem);
        if (res_add.status == 200) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Success",
            "Add data success",
            "text-h5 green--text text-center"
          );
          this.initialize();
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed",
            "Add data Failed",
            "text-h5 red--text text-center"
          );
        }
      }

      this.close();
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
  beforeCreate() {
    this.$store.state.navMenu = true;
  },
};
