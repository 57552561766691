//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "@/services/constants";
import { language } from "../language/Leavelist.js";
import unity from "@/unity/unity";

export default {
  name: "leave-list",
  data(vm) {
    return {
      search_status: "",
      day_sick: 0,
      day_sick_h: 0,
      day_business: 0,
      day_business_h: 0,
      day_vacation: 0,
      day_vacation_h: 0,
      day_oth: 0,
      day_oth_h: 0,
      day_use_leave: 0,
      day_use_leave_d: 0,
      day_use_leave_h: 0,
      menu: false,
      menu2: false,
      search: "",
      toggleEnable: false,
      selectstatus: "",
      dataAll: [],
      status: ["Request", "Approve", "Inprocess", "Reject"],
      datefrom: null,
      dateto: null,
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      seticonsearchmore: { icon: "mdi mdi-chevron-down", action: false },
      setlanguage: language.en,
      defaultlanguage: "en",
      // regexlang: "th",
      chooselanguage: [
        { name: "en", flag: "gb" },
        { name: "th", flag: "th" },
      ],
      aa: "สวัสดี",
      dialogEdit: false,
      dialogDelete: false,
      mDataArray: [],
      position: [],
      approver: [],
      department: [],
      department_list: [],
      position_list: [],
      company: [],
      authorize: [],
      headers: [
        {
          text: "No.",
          value: "id",
          class: "bg-colorth tabletextwhite",
          width: "50px",
          sortable: false,
        },
        {
          text: "Date from",
          value: "startDate2",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Date to",
          value: "endDate2",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Requested",
          value: "min_amount",
          class: "bg-colorth tabletextwhite",
        },

        {
          text: "Leave Type",
          value: "leave_type",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Status",
          value: "approve_name",
          class: "bg-colorth tabletextwhite",
        },
        { text: "Actions", value: "actions", sortable: false },
      ],
      dataAll: [],
      TypeLeaveArray: [],
    };
  },

  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },

  computed: {
    computeddatefromFormatted() {
      return unity.formatDate(this.datefrom);
    },
    computeddateToFormatted() {
      return unity.formatDate(this.dateto);
    },
    itemsForSelected() {
      if (this.search.length) {
        return this.dataSource.filter((item) => this.search.includes(item));
      }
      return this.dataSource;
    },
  },

  async mounted() {
    await api.checkVersion();
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);

    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed",
        "Please Logout And Login Again",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(this.userId, router_path);

    // console.log("res_auth:" + JSON.stringify(res_auth.data));

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    // console.log("res_auth:" + JSON.stringify(res_auth.data));
    // console.log("authorize_view:" + this.authorize_view);
    // console.log("authorize_add:" + this.authorize_add);
    // console.log("authorize_edit:" + this.authorize_edit);
    // console.log("authorize_del:" + this.authorize_del);
    // this.$router.back();

    if (!this.authorize_view) {
      this.$router.back();
    }

    // ----------------- Check Authorize ---------------------------

    let comp_id = localStorage.getItem(server.COMPANYID);

    this.userId = localStorage.getItem(server.USER_ID);
    if (this.userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
    await this.loadQuotaType();
    await this.loaddataLeave(this.userId);
    await this.loaddataLeaveUsed(this.userId);
  },
  methods: {
    async loadQuotaType(){
      const res_q = await api.getListUseByUserIDV2byCompany(
        Number(localStorage.getItem(server.USER_ID)),
        Number(localStorage.getItem(server.COMPANYID))
      );
      console.log("res_q.data")
      console.log(res_q.data)

      var leave_type_box = [];
      for(const[index,item] of res_q.data.entries()){
        let json_psuh_array = {
          type: item.leave_type
        }
        
        this.day_use_leave += Number(item.user_day);

        if (item.quota_day) {
          item.user_d = parseInt(Number(item.balance));
          item.user_h = (item.balance - item.user_d) * 8;
        }
        leave_type_box.push(item)
      }

      this.day_use_leave_d = parseInt(this.day_use_leave);
      this.day_use_leave_h = (this.day_use_leave - parseInt(this.day_use_leave)) * 8;

      this.TypeLeaveArray = leave_type_box.reduce((acc, leave) => {
        const found = acc.find(item => item.leave_type === leave.leave_type);
        if (found) {
          found.user_d += leave.user_d;
          found.user_h += leave.user_h;
        } else {
          acc.push({ ...leave });
        }
        return acc;
      }, []);

      console.log(this.TypeLeaveArray)

    },
    findChaege() {
      // alert(this.search_status)
      this.mDataArray = this.dataAll.filter((item) => {
        if (this.datefrom == null || this.dateto == null) {
          if (this.search_status != null) {
            if (item.approve_name == this.search_status) {
              return item;
            }
          } else {
            return item;
          }
        }
        if (this.datefrom != null && this.dateto != null) {
          if (this.search_status != null) {
            if (
              item.approve_name == this.search_status &&
              item.startDateS >= this.datefrom &&
              item.endDateS <= this.dateto
            ) {
              return item;
            }
          } else {
            if (
              item.startDateS >= this.datefrom &&
              item.endDateS <= this.dateto
            ) {
              return item;
            }
          }
        }
      });
    },
    finddatafromdate() {
      this.mDataArray = this.dataAll.filter((item) => {
        if (this.search_status == "") {
          if (this.datefrom != null && this.dateto != null) {
            if (
              item.startDateS >= this.datefrom &&
              item.endDateS <= this.dateto
            ) {
              return item;
            }
          }
          if (this.datefrom == null && this.dateto == null) {
            return item;
          }
        } else {
          if (this.datefrom != null && this.dateto != null) {
            if (
              item.startDateS >= this.datefrom &&
              item.endDateS <= this.dateto &&
              item.approve_name == this.search_status
            ) {
              return item;
            }
          }
          if (this.datefrom == null && this.dateto == null) {
            if (item.approve_name == this.search_status) {
              return item;
            }
          }
        }
      });
    },
    fillterCard(data_search) {
      // this.search = data_search;

      this.mDataArray = this.dataAll.filter((item) => {
        if(item.leave_type == data_search){
          return item;
        }else if (data_search == "99") {
          return item;
        } else {
          if (item.leaveTypeId == data_search) {
            return item;
          }
        }
      });
    },
    editItem(item) {
      this.$router.push(`/leave-request-edit/${item.id}`);
    },
    showsearchmore() {
      this.toggleEnable = !this.toggleEnable;
      this.seticonsearchmore.action = !this.seticonsearchmore.action;
      if (this.seticonsearchmore.action === true) {
        this.seticonsearchmore.icon = "mdi mdi-chevron-up";
      } else {
        this.seticonsearchmore.icon = "mdi mdi-chevron-down";
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month - 1}-${day}`;
    },
    onClicktoadSolist(id) {
      this.$router.push(`/edit-leave/${id}`);
    },
    async loaddataLeave(id) {
      const result = await api.getListApproveByUserIDbyCompany(
        id,
        localStorage.getItem(server.COMPANYID)
      );

      // console.log("loaddataLeave", JSON.stringify(result.data));

      result.data.forEach((item) => {
        var tmp_data = {
          id: 40,
          startDate: "2022-07-11T10:00:00.000Z",
          endDate: "2022-07-11T10:30:00.000Z",
          startDate2: "2022-07-11 17:00:00",
          endDate2: "2022-07-11 17:30:00",
          min_amount: 30,
          leaveTypeId: 1,
          leave_type: "ลาป่วย",
          amount: "0.50",
          description: "ป่วย",
          description_approve: "kkkkk",
          approve_id: 2,
          approve_name: "Approve",
          approver_level1: 80,
        };
        tmp_data.id = item.id;
        tmp_data.startDate = item.startDate;
        tmp_data.endDate = item.endDate;
        tmp_data.startDate2 = item.startDate2;
        tmp_data.endDate2 = item.endDate2;
        tmp_data.startDateS = item.startDateS;
        tmp_data.endDateS = item.endDateS;
        tmp_data.min_amount = this.quotaListConvert(item.min_amount);
        tmp_data.leaveTypeId = item.leaveTypeId;
        tmp_data.leave_type = item.leave_type;
        tmp_data.amount = item.amount;
        tmp_data.description = item.description;
        tmp_data.description_approve = item.description_approve;
        tmp_data.approve_id = item.approve_id;
        tmp_data.approve_name = item.approve_name;
        tmp_data.approver_level1 = item.approver_level1;
        this.mDataArray.push(tmp_data);
        this.dataAll.push(tmp_data);
      });

      // this.dataAll = result.data;
      console.log("dateAll:")
      console.log(this.mDataArray);
      //   console.log("loadLeave", JSON.stringify(this.dataAll));
      // this.mDataArray = result.data;

      // this.$showLoader();
      this.$hideLoader();
    },

    quotaListConvert: function(H) {
      var d = 0;
      var h = 0;
      var m = 0;
      // var tmp1 = parseFloat(H * 480);
      var tmp1 = parseFloat(H);
      var tmp_final;

      var tmp_d = tmp1 / 480;

      d = Math.floor(tmp_d);
      h = Math.floor((tmp_d - d) * 8);
      m = Math.floor(((tmp_d - d) * 8 - h) * 60);

      tmp_final = `${d} Days ${h} Hour ${m} Minute`;

      return tmp_final;
    },

    // async loaddataLeaveUsed(id) {
    //   const result = await api.getListUseByUserIDV2byCompany(
    //     id,
    //     localStorage.getItem(server.COMPANYID)
    //   );

    //   // console.log("loaddataLeaveUsed", JSON.stringify(result.data));

    //   // 1 ป่วย
    //   // 2 ลากิจ
    //   // 3 ลาพักร้อน
    //   // 4 ลาคลอด
    //   // 5 ลาเกณฑ์ทหาร
    //   // 6 ลาไม่รับเงินเดือน
    //   // 7 ลาฝึกอบรม
    //   // 99 ลาอื่นๆ
    //   for(const[index,item] of result.data.entries()){
 
    //     //   day_sick: 0,
    //     // day_business: 0,
    //     // day_vacation: 0,
    //     // day_oth: 0,
    //     //     day_use_leave: 0,
    //     console.log("itemleaveshow");
    //     console.log(item);
    //     this.day_use_leave += Number(item.user_day);
    //     for(const[index_box,item_box] of this.TypeLeaveArray.entries()){

          // if (item.leave_type == item_box.type) {
          //   if (item.quota_day) {
          //     this.day_sick += parseInt(item.balance);
          //   }
          // } else {
          //   if (item.quota_day) {
          //     this.day_oth += parseInt(item.balance);
          //     this.day_oth_h +=
          //       (Number(item.balance) - parseInt(item.balance)) * 8;
          //     this.day_use_leave += Number(item.user_day);
          //     // this.day_use_leave += parseInt(item.user_day);
          //     // this.day_use_leave_h += (Number(item.user_day) - parseInt(item.user_day))*8;
          //   }
          // }
    //     }
    //   }
    //   this.day_use_leave_d = parseInt(this.day_use_leave);
    //   this.day_use_leave_h =
    //     (this.day_use_leave - parseInt(this.day_use_leave)) * 8;
    // },
  },
};
