//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "@/services/constants";
import { language } from "../language/Leavelist.js";
import html2pdf from "html2pdf.js";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "../computing/tolocalestringnumber";
import PrintReceiptDejTab from "./PrintReceiptDejTab";
import PrintReceiptStandardTab from "./PrintReceiptStandardTab";
import PrintReceiptDetailDejTab from "./PrintReceiptDetailDejTab";
import PrintReceiptDetailStandardTab from "./PrintReceiptDetailStandardTab";
var convert = require("decimal-number-to-words");
import unity from "@/unity/unity";

export default {
  name: "invoice",
  data(vm) {
    return {
      getcompany_id: localStorage.getItem(server.COMPANYID),
      main_customer_id:0,
      desserts:[],
      mDataArrayforinv:[],
      inv_subtotal: 0,
      wth_ratetotal: 0,
      tax_ratetotal: 0,
      amount_total: 0,
      wth_rate:0,
      company_search: "",
      department_search: 0,
      department_list: [],
      company_list: [
        {
          company_name: "บริษัท เดชอุดม จำกัด",
          company_code: "D" 
        },
        {
          company_name: "สำนักงานทนายความ",
          company_code: "L" 
        }
      ],
      bank_name_show: "",
      bank_no_show: "",
      curency_show_tb: "",
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      search_status: "",
      status_search: ["Submit","Cancel"],
      dialogAddS: false,
      dialogAdd: false,
      tax_rate: 0,
      dialogAddData: "1",
      dialogSelect: "1",
      exchange_rate: 1,
      vat_type:"",
      menu: false,
      menu2: false,
      search: "",
      currency_print: "",
      currency_show: "",
      toggleEnable: false,
      dialogSelectPrint: false,
      dataAll: [],
      datefrom: null,
      dateto: null,
      seticonsearchmore: { icon: "mdi mdi-chevron-down", action: false },
      setlanguage: language.en,
      mDataArray: [],
      authorize: [],
      headers: [
        {
          text: "Receive No.",
          value: "trc_id",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Doc Date",
          value: "date_show",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Customer Name",
          value: "cus_name",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Amount",
          value: "trc_sub_amoumt_s",
          class: "bg-colorth tabletextwhite",
          align: "end",
        },
        {
          text: "Currency",
          value: "currency",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Status",
          value: "status",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Print",
          value: "actions",
          class: "bg-colorth tabletextwhite",
        },
      ],
      check_cus_name: false,
      payment_by_print: "",
      main_id_print: 0,
      inv_id: 0,
      inv_detail_id: 0,
      tax_detail_rate: 0,
      vat: 0,
      showprint: "",
      showprintDetail: "",
      textamount_total_en: "",
      setlanguage: language.en,
      print_header_list: [
        {
          id: "1",
          com_title: "RECEIPT & TAX INVOICE",
          com_name: "",
          inv_company_name_th: "",
          inv_company_address: "",
          inv_company_branch: "",
          inv_company_tel: "",
          inv_company_email: "",
          inv_tax_id: "",
          com_eng_1: "DEJ-UDOM & ASSOCIATES LTD.",
          com_eng_2: "ATTORNEYS AT LAW",
          com_thai: "บริษัท เดชอุดม แอนด์ แอสโซซิเอทส์ จำกัด",
          com_add_name: "CHARN ISSARA TOWER 1, 2nd FLOOR,",
          com_add_no: "942/69 RAMA IV ROAD,",
          com_add_city: "KWAENG SURIYAWONG, KHET BANGRAK,",
          com_add_country: "BANGKOK 10500, THAILAND",
          com_add_tel: "Tel : 0-2233-0055, 0-2267-5152",
          com_add_email: "E-MAIL : account@dejudom.com",
          com_add_tax_id: `TAX ID. No. 0105534015348/"HEAD OFFICE"`,
        },
        {
          id: "2",
          com_title: "RECEIPT & TAX INVOICE",
          com_name: "",
          inv_company_name_th: "",
          inv_company_address: "",
          inv_company_branch: "",
          inv_company_tel: "",
          inv_company_email: "",
          inv_tax_id: "",
          com_eng_1: "DEJ-UDOM & ASSOCIATES",
          com_eng_2: "ATTORNEYS AT LAW",
          com_thai: "สำนักกฎหมาย เดชอุดม แอนด์ แอสโซซิเอทส์",
          com_add_name: "CHARN ISSARA TOWER 1, 2nd FLOOR,",
          com_add_no: "942/69 RAMA IV ROAD,",
          com_add_city: "KWAENG SURIYAWONG, KHET BANGRAK,",
          com_add_country: "BANGKOK 10500, THAILAND",
          com_add_tel: "Tel : 0-2233-0055, 0-2267-5152",
          com_add_email: "E-MAIL : account@dejudom.com",
          com_add_tax_id: `TAX ID. No. 0992000028227/"HEAD OFFICE"`,
        },
      ],
    
      print_header_list_detail: [
        {
          id: "1",
          com_title: "RECEIPT & TAX INVOICE",
          com_name: "",
          inv_company_name_th: "",
          inv_company_address: "",
          inv_company_branch: "",
          inv_company_tel: "",
          inv_company_email: "",
          inv_tax_id: "",
          com_eng_1: "DEJ-UDOM & ASSOCIATES LTD.",
          com_eng_2: "ATTORNEYS AT LAW",
          com_thai: "บริษัท เดชอุดม แอนด์ แอสโซซิเอทส์ จำกัด",
          com_add_name: "CHARN ISSARA TOWER 1, 2nd FLOOR,",
          com_add_no: "942/69 RAMA IV ROAD,",
          com_add_city: "KWAENG SURIYAWONG, KHET BANGRAK,",
          com_add_country: "BANGKOK 10500, THAILAND",
          com_add_tel: "Tel : 0-2233-0055, 0-2267-5152",
          com_add_email: "E-MAIL : account@dejudom.com",
          com_add_tax_id: `TAX ID. No. 0105534015348/"HEAD OFFICE"`,
        },
        {
          id: "2",
          com_title: "RECEIPT & TAX INVOICE",
          com_name: "",
          inv_company_name_th: "",
          inv_company_address: "",
          inv_company_branch: "",
          inv_company_tel: "",
          inv_company_email: "",
          inv_tax_id: "",
          com_eng_1: "DEJ-UDOM & ASSOCIATES",
          com_eng_2: "ATTORNEYS AT LAW",
          com_thai: "สำนักกฎหมาย เดชอุดม แอนด์ แอสโซซิเอทส์",
          com_add_name: "CHARN ISSARA TOWER 1, 2nd FLOOR,",
          com_add_no: "942/69 RAMA IV ROAD,",
          com_add_city: "KWAENG SURIYAWONG, KHET BANGRAK,",
          com_add_country: "BANGKOK 10500, THAILAND",
          com_add_tel: "Tel : 0-2233-0055, 0-2267-5152",
          com_add_email: "E-MAIL : account@dejudom.com",
          com_add_tax_id: `TAX ID. No. 0992000028227/"HEAD OFFICE"`,
        },
      ],
      print_header: null,
      print_detail_header: null,
      print_table_detail: [],
      print_table_Rcdetail: [],
      pageAll: 1,
      pageAllDetail: 1,
      opensummarize: false,
      opensummarizeDetail: false,
      datashowAll: [],
      datashowAllDetail: [],
      dataforshowprpo: [],
      setdatatoshow: [],
      setdatatoshowDetail: [],
      branch_cus: "",
      checklawoffice:false,
      heighttabledetail: "0px",
    };
  },
  components: {
    SuccessDialog,
  },
  async beforeCreate() {
    this.$store.state.navMenu = true;
  },
  async created() {},
  beforeUpdate() {},
  components: {
    PrintReceiptDejTab,
    PrintReceiptStandardTab,
    PrintReceiptDetailDejTab,
    PrintReceiptDetailStandardTab,
  },
  computed: {
    computeddatefromFormatted() {
      return unity.formatDate(this.datefrom);
    },
    computeddateToFormatted() {
      return unity.formatDate(this.dateto);
    },
    itemsForSelected() {
      if (this.search.length) {
        return this.dataSource.filter((item) => this.search.includes(item));
      }
      return this.dataSource;
    },
  },
  async mounted() {
    await api.checkVersion();
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);

    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed",
        "Please Logout And Login Again",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(this.userId, router_path);

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    if (!this.authorize_view) {
      this.$router.back();
    }

    // ----------------- Check Authorize ---------------------------

    let comp_id = localStorage.getItem(server.COMPANYID);

    this.userId = localStorage.getItem(server.USER_ID);
    if (this.userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
    await this.loaddata();
    await this.loadDepartmentByCompanyId();
  },
  methods: {
    async loadDepartmentByCompanyId() {
      let result = await api.getAllDepartment_By_CompanyID(localStorage.getItem(server.COMPANYID));
      this.department_list = result.data;
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAddS = status;
      this.text_color = text_color;
    },
    showsearchmore() {
      this.toggleEnable = !this.toggleEnable;
      this.seticonsearchmore.action = !this.seticonsearchmore.action;
      if (this.seticonsearchmore.action === true) {
        this.seticonsearchmore.icon = "mdi mdi-chevron-up";
      } else {
        this.seticonsearchmore.icon = "mdi mdi-chevron-down";
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month - 1}-${day}`;
    },
    findChaege() {

    

      let data_s = [];
      data_s = this.dataAll;
      if(this.company_search != ""){
        // alert("com")
        data_s = data_s.filter((item) => {
            if (item.company_trc == this.company_search) {
              return item;
            }
        })
      }
      if(this.department_search != 0){
        // alert("dept")
        data_s = data_s.filter((item) => {
          if (item.dep_id == this.department_search) {
              return item;
          }
        })
      }
      if(this.search_status != ""){
        // alert("status")
        data_s = data_s.filter((item) => {
          if (item.status == this.search_status){
              return item;
          }
        })
      }

      if (this.datefrom != null && this.dateto != null) {
        // alert("date")
        data_s = data_s.filter((item) => {
          if (item.date >= this.datefrom && item.date <= this.dateto) {
            return item;
          }
        })
      }


      if( 
        this.company_search == "" &&
        this.department_search == 0 &&
        this.search_status == "" &&
        this.datefrom == null &&
        this.dateto == null
      ){
        data_s = this.dataAll;
      }

      this.mDataArray = data_s;
    },
    // finddatafromdate() {
    //   this.mDataArray = this.dataAll.filter((item) => {
    //     if (this.search_status == "") {
    //       if (this.datefrom != null && this.dateto != null) {
    //         if (item.date >= this.datefrom && item.date <= this.dateto) {
    //           return item;
    //         }
    //       }
    //       if (this.datefrom == null && this.dateto == null) {
    //         return item;
    //       }
    //     } else {
    //       if (this.datefrom != null && this.dateto != null) {
    //         if (
    //           item.date >= this.datefrom &&
    //           item.date <= this.dateto &&
    //           item.status == this.search_status
    //         ) {
    //           return item;
    //         }
    //       }
    //       if (this.datefrom == null && this.dateto == null) {
    //         if (item.status == this.search_status) {
    //           return item;
    //         }
    //       }
    //     }
    //   });
    // },
    async loaddata() {
      const result = await api.getDataReceiveADHQ(Number(localStorage.getItem(server.COMPANYID)));
      this.dataAll = result.data;
      this.dataAll.forEach((item) => {
        item.trc_sub_amoumt_s = tolocalestringnumber(Number(item.trc_sub_amoumt));
        if (!item.trc_id) {
          item.trc_id = "####";
        }
      });

      this.mDataArray = result.data;
     
      this.$hideLoader();
    },
    OpenDialog(item_id) {
      this.dialogAdd = true;
    },
    async toInvCNforRecrive() {
      let company_inv = "";
      if (this.dialogAddData == "1") {
        company_inv = "D";
      } else if (this.dialogAddData == "2") {
        company_inv = "L";
      } else {
      }
      this.$router.push(`/receive-select-document/${company_inv}`);
    },
    async onClicktoeditRC(id) {
      this.$router.push(`/receive-adjsut-edit/${id}`);
    },
    async onClicktoPrint(data) {
      if (data.currency_print ==  "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check information",
          "Fail  Currency Null Data",
          "text-h5 red--text text-center"
        );
        return;
      }

      this.main_customer_id = data.customer_id;
      this.main_id_print = data.id;
      this.currency_print =  data.currency;
      this.vat = data.tax_rate;
      this.exchange_rate = data.exchange_rate;
      await this.loadinv_handcal();
      await this.loadCustomer();
      await this.SumDataT();
      this.dialogSelectPrint = true;
    },
    async loadRef_TypeRcById() {
      this.print_header= {};
      const res_inv_id = await api.getDataReceiveDByIDOne(this.main_id_print);
     
      this.inv_id = res_inv_id.data[0].inv_hid;
      this.tax_rate = res_inv_id.data[0].tax_rate;
      this.payment_by_print = res_inv_id.data[0].payment_by;
      this.bank_name_show = res_inv_id.data[0].bank_name;
      this.bank_no_show = res_inv_id.data[0].bank_no;
      const res_hresult = await api.getdataByIdInvoice_HRC(
        res_inv_id.data[0].inv_hid
      );
      if (res_hresult.data[0].company_inv == "D") {
        this.getcompany_id = 1;
        this.print_header = this.print_header_list[0];
        this.checklawoffice = false;
        this.heighttabledetail = "270px";
      } else if (res_hresult.data[0].company_inv == "L") {
        this.getcompany_id = 1;
        this.print_header = this.print_header_list[1];
        this.checklawoffice = true;
        this.heighttabledetail = "300px";
      }else{
        this.getcompany_id = res_hresult.data[0].company_inv;
        this.heighttabledetail = "365px";
        this.print_header.com_title = "RECEIPT & TAX INVOICE";
        this.print_header.com_name = res_hresult.data[0].com_name;
        this.print_header.inv_company_address = res_hresult.data[0].inv_company_address;
        this.print_header.inv_company_branch = res_hresult.data[0].inv_company_branch;
        this.print_header.inv_company_tel = res_hresult.data[0].inv_company_tel;
        this.print_header.inv_company_email = res_hresult.data[0].inv_company_email;
        this.print_header.inv_tax_id = res_hresult.data[0].inv_tax_id;
        this.print_header.inv_company_name_th = res_hresult.data[0].inv_company_name_th;
        this.wth_rate = res_hresult.data[0].wth_rate;
      }
  
      if (res_inv_id.data[0].payment_by == "cash") {
        this.print_header.payment_by = "Cash";
      } else if (res_inv_id.data[0].payment_by == "Bank") {
        this.print_header.payment_by = "Transfer";
      } else if (res_inv_id.data[0].payment_by == "cheque") {
        this.print_header.payment_by = "Cheque";
      } else {
        this.print_header.payment_by = "Other";
      }
      this.print_header.payment_detail =
        res_inv_id.data[0].payment_comment;
        this.print_header.inv_id = res_inv_id.data[0].trc_id;

      this.print_header.invoice_date = res_hresult.data[0].date_show;

      this.print_header.wth_rate = res_hresult.data[0].wth_rate;
      this.print_header.tax_rate = this.tax_rate;
      this.print_header.wth_rate_amount = parseFloat(
        (res_hresult.data[0].sub_total * res_hresult.data[0].wth_rate) / 100
      );

    },
    async loadRef_TypeRcDetailById() {
      this.print_detail_header= {};
      const res_inv_id = await api.getDataReceiveDByIDOne(this.main_id_print);

      this.inv_detail_id = res_inv_id.data[0].inv_hid;
      this.tax_detail_rate = res_inv_id.data[0].tax_rate;
      this.payment_by_print = res_inv_id.data[0].payment_by;
      this.bank_name_show = res_inv_id.data[0].bank_name;
      this.bank_no_show = res_inv_id.data[0].bank_no;
      let inv_no = "";
      res_inv_id.data.forEach((item, index) => {
        if (index < res_inv_id.data.length - 1) {
          inv_no = inv_no + item.inv_id + ", ";
        } else {
          inv_no = inv_no + item.inv_id;
        }
      });

      const res_hresult = await api.getdataByIdInvoice_HRC(
        res_inv_id.data[0].inv_hid
      );
      
      if (res_hresult.data[0].company_inv == "D") {
        this.getcompany_id = 1;
        this.print_detail_header = this.print_header_list_detail[0];
        this.checklawoffice = false;
        this.heighttabledetail = "270px";
      } else if (res_hresult.data[0].company_inv == "L") {
        this.getcompany_id = 1;
        this.print_detail_header = this.print_header_list_detail[1];
        this.checklawoffice = true;
        this.heighttabledetail = "300px";
      }else{
        this.getcompany_id = res_hresult.data[0].company_inv;
        this.heighttabledetail = "365px";
        this.print_detail_header.com_title = "RECEIPT & TAX INVOICE";
        this.print_detail_header.com_name = res_hresult.data[0].com_name;
        this.print_detail_header.inv_company_address = res_hresult.data[0].inv_company_address;
        this.print_detail_header.inv_company_branch = res_hresult.data[0].inv_company_branch;
        this.print_detail_header.inv_company_tel = res_hresult.data[0].inv_company_tel;
        this.print_detail_header.inv_company_email = res_hresult.data[0].inv_company_email;
        this.print_detail_header.inv_tax_id = res_hresult.data[0].inv_tax_id;
        this.print_detail_header.inv_company_name_th = res_hresult.data[0].inv_company_name_th;
        this.wth_rate = res_hresult.data[0].wth_rate;
      }

      this.print_detail_header.invoice_no = inv_no;
      if (res_inv_id.data[0].payment_by == "cash") {
        this.print_detail_header.payment_by = "Cash";
      } else if (res_inv_id.data[0].payment_by == "Bank") {
        this.print_detail_header.payment_by = "Transfer";
      } else if (res_inv_id.data[0].payment_by == "cheque") {
        this.print_detail_header.payment_by = "Cheque";
      } else {
        this.print_detail_header.payment_by = "Other";
      }
      this.print_detail_header.payment_detail = res_inv_id.data[0].payment_comment;
      this.print_detail_header.inv_id = res_inv_id.data[0].trc_id;

      this.print_detail_header.invoice_date = res_hresult.data[0].date_show;

      this.print_detail_header.wth_rate = res_hresult.data[0].wth_rate;
      this.print_detail_header.tax_rate = this.vat;
      this.print_detail_header.wth_rate_amount = parseFloat(
        (res_hresult.data[0].sub_total * res_hresult.data[0].wth_rate) / 100
      );

 

    },
    async loadInvSoData() {
      //data in table
      var tmp_inv_data;
      var sub_total = 0;
     
      const res_inv_rc = await api.getDataPrint(this.main_id_print);

      tmp_inv_data = res_inv_rc.data;

      tmp_inv_data.forEach((item, index) => {
        var data = {
          no: 0,
          inv_id: "",
          date: null,
          credit_term: 0,
          currency: "",
          amount: 0,
        };

        data.no = index + 1;
        data.inv_id = item.inv_id;
        data.date = item.date_show;
        data.credit_term = item.credit_term;
        data.currency = item.currency;
      
        data.amount = Number(item.Amount);
        if(this.vat_type == "E"){
          data.amount_s = Number(item.Amount);
        }else if(this.vat_type == "I"){
          data.amount_s = Number(item.Amount);
        }else{
          return;
        }
        data.amount_s = parseFloat(data.amount_s);
        this.print_table_detail.push(data);
        sub_total = Number(sub_total) + Number(data.amount);
      });
      sub_total = sub_total.toFixed(2);

      if(this.vat_type == "E"){
        this.print_header.sub_total = parseFloat(Number(sub_total).toFixed(2));
        this.print_header.tax_rate_amount = parseFloat(
          Number((this.print_header.sub_total * this.vat) / 100).toFixed(2)
        );
        this.print_header.amount_total = parseFloat(
          Number(this.print_header.sub_total) + Number(this.print_header.tax_rate_amount)
        );
        console.log("this.print_header.sub_total: " + this.print_header.sub_total)
        console.log("this.print_header.tax_rate_amount: " + this.print_header.tax_rate_amount)
      }else if(this.vat_type == "I"){
        this.print_header.amount_total = parseFloat(sub_total);
        this.print_header.sub_total = parseFloat(
          (sub_total * 100 ) / (100 + Number(this.vat))
        )
        this.print_header.tax_rate_amount = parseFloat(
          this.print_header.amount_total - this.print_header.sub_total
        );
      }else{
        return;
      }

      const checker_th = /([\u0E00-\u0E7F]+)/gmu;
      let matches = this.print_header.customer_name.match(checker_th);
      // const checker_th = /([\u0E00-\u0E7F]+)/gmu;
      if(matches){
          this.check_cus_name = false;
      }else{
          this.check_cus_name = true;
      }


      if(this.check_cus_name){

        this.textamount_total_en = convert.toWords(this.print_header.amount_total.toFixed(2));
        let setcheckscratch = false;
        this.textamount_total_en = this.textamount_total_en.replace(/\b./g, function(m){ 
          if(m == ","){
            return ""; 
          }
          if(m == "-"){
            setcheckscratch = true
            return m; 
          }else{
            if(setcheckscratch){
              setcheckscratch = false
              return m; 
            }
            setcheckscratch = false
            return m.toUpperCase(); 
          }
        });

      }else{
       
          //start เปลี่ยนเป็นตัวอ่านไทย 
          // อาเรย์สำหรับแปลงตัวเลขเป็นข้อความไทย
          let thaiNumbers = ['', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'];
          let thaiUnits = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน'];

          // แยกตัวจำนวนเต็มและทศนิยมห
          this.print_header.amount_total = Math.round(this.print_header.amount_total * 100) / 100;
          let [integerPart, decimalPart] = this.print_header.amount_total.toString().split('.');
          
          // แปลงส่วนทศนิยมเป็นข้อความไทย (สตางค์)
          let  de_check = 0;

          if(decimalPart){
            if(decimalPart.split('').length == 1){
              if(Number(decimalPart) >= 10){
                decimalPart = decimalPart;
              }else if(Number(decimalPart) >0){
                decimalPart = Number(decimalPart) * 10;
              }else{
                de_check = 1;
              }
            }
          }else{
            de_check = 1;
          }
          let decimalText = '';
          if(de_check == 0){
            decimalPart = String(decimalPart);

            
            let numArrays = decimalPart.split('').map(Number);
            numArrays.forEach((num, index) => {
              let units = thaiUnits[numArrays.length - index - 1];
              let digits = thaiNumbers[num];
              if (num !== 0) {
                if (num === 1 && index === numArrays.length - 1) { // ตรวจสอบว่าเป็นตำแหน่งสุดท้าย
                  decimalText += 'เอ็ด' + units;
                } else if (num === 2 && index === numArrays.length - 2 && numArrays.length >= 2) {
                  decimalText += 'ยี่' + units;
                } else if (num === 1 && index === numArrays.length - 2 && numArrays.length >= 2) {
                  decimalText += units;
                } else {
                  decimalText += digits + units;
                }
              }
            });

            decimalText = decimalText + "สตางค์";
          }

          // แปลงส่วนจำนวนเต็มเป็นข้อความไทย
          let integerText = '';

          let numArray = integerPart.split('').map(Number);
          numArray.forEach((num, index) => {
            let unit = thaiUnits[numArray.length - index - 1];
            let digit = thaiNumbers[num];
            if (num !== 0) {
                if (num === 1 && index === numArray.length - 1) { // ตรวจสอบว่าเป็นตำแหน่งสุดท้าย
                  integerText += 'เอ็ด' + unit;
                } else if (num === 2 && index === numArray.length - 2 && numArray.length >= 2) {
                  integerText += 'ยี่' + unit;
                } else if (num === 1 && index === numArray.length - 2 && numArray.length >= 2) {
                  integerText += unit;
                } else {
                  integerText += digit + unit;
                }
            }
          });

          integerText = integerText + "บาท";



          let result = "";
          result = integerText + decimalText;
          this.textamount_total_en = result;
      }

    },
    async loadInvSoDataOth() {
      //data in table
      var tmp_inv_data;
      var sub_total = 0;
      var gran_total = 0;
      var sub_total_oth = 0;
      var gran_total_oth = 0;
      const res_inv_rc = await api.getDataPrint(this.main_id_print);

      tmp_inv_data = res_inv_rc.data;
      console.log("res_inv_rc.data:")
      console.log("")
      tmp_inv_data.forEach((item, index) => {
        var data = {
          no: 0,
          inv_id: "",
          date: null,
          credit_term: 0,
          currency: "",
          amount: 0,
          amount_s: 0,
          amount_s_ex: 0,
          amount_sub: 0
        };
        data.no = index + 1;
        data.inv_id = item.inv_id;
        data.date = item.date_show;
        data.credit_term = item.credit_term;
        data.currency = item.currency;
        data.amount = Number(item.inv_amount_total);
        if(this.vat_type == "E"){
          data.amount_s = Number(item.inv_amount_total).toFixed(2);
        }else if(this.vat_type == "I"){
          // data.amount_s = (data.amount  inv_sub_total(data.amount * (this.vat / 100))).toFixed(2);
          data.amount_s = Number(item.inv_amount_total).toFixed(2);
        }else{
          return;
        }
        data.amount_sub = Number(item.inv_sub_total).toFixed(2);

        data.amount_s_ex = Number(data.amount_s) * this.exchange_rate ;
        data.amount_s_ex =  Number(data.amount_s_ex.toFixed(2));
        data.amount_s = parseFloat(data.amount_s);
        data.amount_s_ex = parseFloat(data.amount_s_ex);
        this.print_table_detail.push(data);

 
        this.curency_show_tb = "";
        this.curency_show_tb = item.currency;

        sub_total_oth = Number(sub_total_oth) + Number(data.amount_sub);
        gran_total_oth = Number(gran_total_oth) + Number(data.amount_s);
        gran_total = Number(gran_total) + Number(data.amount_s_ex);

      });
      sub_total_oth = sub_total_oth.toFixed(2)
      gran_total_oth = gran_total_oth.toFixed(2)
      gran_total = gran_total.toFixed(2)
      console.log("gran_total_oth") 
      console.log(gran_total_oth) 
      // if(this.vat_type == "E"){
      //   this.print_header.sub_total_oth = parseFloat(Number(gran_total_oth).toFixed(2));
      //   this.print_header.tax_rate_amount_oth = parseFloat(Number(this.print_header.sub_total_oth * (this.vat/100)).toFixed(2));
      //   this.print_header.amount_total_oth = parseFloat(Number(this.print_header.sub_total_oth + this.print_header.tax_rate_amount_oth).toFixed(2));

      //   this.print_header.amount_total = parseFloat(Number(this.print_header.amount_total_oth * this.exchange_rate).toFixed(2));
      //   this.print_header.sub_total = parseFloat(Number(this.print_header.sub_total_oth * this.exchange_rate).toFixed(2));
      //   this.print_header.tax_rate_amount = parseFloat(this.print_header.amount_total - this.print_header.sub_total);
      //   //
      // }else  if(this.vat_type == "I"){
        this.print_header.amount_total_oth = parseFloat(Number(gran_total_oth).toFixed(2));
        this.print_header.sub_total_oth = parseFloat(Number(sub_total_oth).toFixed(2));
        this.print_header.tax_rate_amount_oth = parseFloat(this.print_header.amount_total_oth -  this.print_header.sub_total_oth);

        this.print_header.amount_total = parseFloat(Number(this.print_header.amount_total_oth * this.exchange_rate).toFixed(2));
        this.print_header.sub_total = parseFloat(Number(this.print_header.amount_total  / (1 + this.vat/100)).toFixed(2));
        this.print_header.tax_rate_amount = parseFloat(this.print_header.amount_total - this.print_header.sub_total);
      // }else{
        
      // }
      console.log("this.print_header") 
      console.log(this.print_header)
      // this.print_header.amount_total_oth = parseFloat(gran_total_oth);
      // this.print_header.amount_total = parseFloat(gran_total);

      // sub_total_oth = (this.print_header.amount_total_oth / (1 + (this.vat / 100))).toFixed(2) 
      // sub_total = (this.print_header.amount_total / (1 + (this.vat / 100))).toFixed(2) 
      // this.print_header.sub_total_oth = parseFloat(sub_total_oth);
      // this.print_header.sub_total = parseFloat(sub_total);

      
      // this.print_header.tax_rate_amount_oth = parseFloat(this.print_header.amount_total_oth - sub_total_oth)
      // this.print_header.tax_rate_amount = parseFloat(this.print_header.amount_total - sub_total)

      const checker_th = /([\u0E00-\u0E7F]+)/gmu;
      let matches = this.print_header.customer_name.match(checker_th);
      // const checker_th = /([\u0E00-\u0E7F]+)/gmu;
      if(matches){
          this.check_cus_name = false;
      }else{
          this.check_cus_name = true;
      }


      if(this.check_cus_name){

        this.textamount_total_en = convert.toWords(this.print_header.amount_total.toFixed(2));
        let setcheckscratch =false;
        this.textamount_total_en = this.textamount_total_en.replace(/\b./g, function(m){ 
          if(m == ","){
            return ""; 
          }
          if(m == "-"){
            setcheckscratch = true
            return m; 
          }else{
            if(setcheckscratch){
              setcheckscratch = false
              return m; 
            }
            setcheckscratch = false
            return m.toUpperCase(); 
          }
        });
      }else{
          //start เปลี่ยนเป็นตัวอ่านไทย 
          // อาเรย์สำหรับแปลงตัวเลขเป็นข้อความไทย
          var thaiNumbers = ['', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'];
          var thaiUnits = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน'];

          // แยกตัวจำนวนเต็มและทศนิยมห
          this.print_header.amount_total = Math.round(this.print_header.amount_total * 100) / 100;
          var [integerPart, decimalPart] = this.print_header.amount_total.toString().split('.');
          
          // แปลงส่วนทศนิยมเป็นข้อความไทย (สตางค์)
          let  de_check = 0;
          if(decimalPart){
            if(decimalPart.split('').length == 1){
              if(Number(decimalPart) >= 10){
                decimalPart = decimalPart;
              }else if(Number(decimalPart) >0){
                decimalPart = Number(decimalPart) * 10;
              }else{
                de_check = 1;
              }
            }
          }else{
            de_check = 1;
          }
          
          let decimalText = '';
          if(de_check == 0){
            decimalPart = String(decimalPart);

            
            var numArrays = decimalPart.split('').map(Number);
            numArrays.forEach((num, index) => {
              var units = thaiUnits[numArrays.length - index - 1];
              var digits = thaiNumbers[num];
              if (num !== 0) {
                if (num === 1 && index === numArrays.length - 1) { // ตรวจสอบว่าเป็นตำแหน่งสุดท้าย
                  decimalText += 'เอ็ด' + units;
                } else if (num === 2 && index === numArrays.length - 2 && numArrays.length >= 2) {
                  decimalText += 'ยี่' + units;
                } else if (num === 1 && index === numArrays.length - 2 && numArrays.length >= 2) {
                  decimalText += units;
                } else {
                  decimalText += digits + units;
                }
              }
            });

            decimalText = decimalText + "สตางค์";
          }

          // แปลงส่วนจำนวนเต็มเป็นข้อความไทย
          let integerText = '';

          var numArray = integerPart.split('').map(Number);
          numArray.forEach((num, index) => {
            var unit = thaiUnits[numArray.length - index - 1];
            var digit = thaiNumbers[num];
            if (num !== 0) {
                if (num === 1 && index === numArray.length - 1) { // ตรวจสอบว่าเป็นตำแหน่งสุดท้าย
                  integerText += 'เอ็ด' + unit;
                } else if (num === 2 && index === numArray.length - 2 && numArray.length >= 2) {
                  integerText += 'ยี่' + unit;
                } else if (num === 1 && index === numArray.length - 2 && numArray.length >= 2) {
                  integerText += unit;
                } else {
                  integerText += digit + unit;
                }
            }
          });

          integerText = integerText + "บาท";



          var result = "";
          result = integerText + decimalText;
          this.textamount_total_en = result;
      }
      // this.print_header.sub_total = sub_total;

    },
    async loadRcData() {
      //data in table
      var tmp_inv_data;
      var sub_total = 0;
      const res_inv_rc = await api.getDataReceiveDByID(this.main_id_print);

      tmp_inv_data = res_inv_rc.data;

      tmp_inv_data.forEach((item, index) => {
        var data = {
          description: "",
          quantity: 0,
          amount: 0,
        };

        data.description = item.inv_detail;
        data.quantity = parseFloat(item.inv_qty);
        data.amount = parseFloat(item.inv_lineamount);
        this.print_table_Rcdetail.push(data);
        sub_total = sub_total + parseFloat(item.inv_lineamount);
      });
      if(this.vat_type == "E"){
        this.print_detail_header.sub_total = parseFloat(Number(sub_total).toFixed(2));
        this.print_detail_header.tax_rate_amount = parseFloat(
          Number((this.print_detail_header.sub_total * this.tax_detail_rate) / 100).toFixed(2)
        );
        this.print_detail_header.amount_total = parseFloat(
          this.print_detail_header.sub_total +
            this.print_detail_header.tax_rate_amount
        );
      }else if(this.vat_type == "I"){
        this.print_detail_header.amount_total = parseFloat(Number(sub_total).toFixed(2));
        this.print_detail_header.sub_total = parseFloat(
          Number(sub_total / (1 + this.tax_detail_rate/100)).toFixed(2)
        );
        this.print_detail_header.tax_rate_amount = parseFloat(
          this.print_detail_header.amount_total - this.print_detail_header.sub_total
        );
      }else{

      }

      // this.textamount_total_en = convert.toWords(
      //   this.print_detail_header.amount_total.toFixed(2)
      // );

      //check lag
      const checker_th = /([\u0E00-\u0E7F]+)/gmu;
      let matches = this.print_detail_header.customer_name.match(checker_th);
      // const checker_th = /([\u0E00-\u0E7F]+)/gmu;
      if(matches){
          this.check_cus_name = false;
      }else{
          this.check_cus_name = true;
      }

      if(this.check_cus_name){
        
        this.textamount_total_en = convert.toWords(
          this.print_detail_header.amount_total.toFixed(2)
        );

        let setcheckscratch =false;
        this.textamount_total_en = this.textamount_total_en.replace(/\b./g, function(m){ 
          if(m == ","){
            return ""; 
          }
          if(m == "-"){
            setcheckscratch = true
            return m; 
          }else{
            if(setcheckscratch){
              setcheckscratch = false
              return m; 
            }
            setcheckscratch = false
            return m.toUpperCase(); 
          }
        });
      }else{
        var result = "";

          //start เปลี่ยนเป็นตัวอ่านไทย 
          // อาเรย์สำหรับแปลงตัวเลขเป็นข้อความไทย
          var thaiNumbers = ['', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'];
          var thaiUnits = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน'];

          // แยกตัวจำนวนเต็มและทศนิยมห
          this.print_detail_header.amount_total = Math.round(this.print_detail_header.amount_total * 100) / 100;
          var [integerPart, decimalPart] = this.print_detail_header.amount_total.toString().split('.');
          
          let  de_check = 0;
            if(decimalPart){
              if(decimalPart.split('').length == 1){
                if(Number(decimalPart) >= 10){
                  decimalPart = decimalPart;
                }else if(Number(decimalPart) >0){
                  decimalPart = Number(decimalPart) * 10;
                }else{
                  de_check = 1;
                }
              }
            }else{
              de_check = 1;
            }
            
            let decimalText = '';
            if(de_check == 0){
              decimalPart = String(decimalPart);

              
              let numArrays = decimalPart.split('').map(Number);
              numArrays.forEach((num, index) => {
                let units = thaiUnits[numArrays.length - index - 1];
                let digits = thaiNumbers[num];
                if (num !== 0) {
                  if (num === 1 && index === numArrays.length - 1) { // ตรวจสอบว่าเป็นตำแหน่งสุดท้าย
                    decimalText += 'เอ็ด' + units;
                  } else if (num === 2 && index === numArrays.length - 2 && numArrays.length >= 2) {
                    decimalText += 'ยี่' + units;
                  } else if (num === 1 && index === numArrays.length - 2 && numArrays.length >= 2) {
                    decimalText += units;
                  } else {
                    decimalText += digits + units;
                  }
                }
              });

              decimalText = decimalText + "สตางค์";
            }

            // แปลงส่วนจำนวนเต็มเป็นข้อความไทย
            let integerText = '';

            let numArray = integerPart.split('').map(Number);
            numArray.forEach((num, index) => {
              let unit = thaiUnits[numArray.length - index - 1];
              let digit = thaiNumbers[num];
              if (num !== 0) {
                if (num === 1 && index === numArray.length - 1) { // ตรวจสอบว่าเป็นตำแหน่งสุดท้าย
                  integerText += 'เอ็ด' + unit;
                } else if (num === 2 && index === numArray.length - 2 && numArray.length >= 2) {
                  integerText += 'ยี่' + unit;
                } else if (num === 1 && index === numArray.length - 2 && numArray.length >= 2) {
                  integerText += unit;
                } else {
                  integerText += digit + unit;
                }
              }
            });

            integerText = integerText + "บาท";

        

            result = integerText + decimalText;
        this.textamount_total_en = result;
        console.log("this.textamount_total_en: " + this.textamount_total_en)
      }
    
    },
    async loadMainInvSoData() {
      // var tmp_inv_data;
      // this.print_header = {};
      const res_inv_so = await api.getdataByIdInvoice_HRC(this.inv_id);
      if (res_inv_so.data[0].company_inv == "D") {
        this.getcompany_id = 1;
        this.print_header = this.print_header_list[0];
      } else if (res_inv_so.data[0].company_inv == "L") {
        this.getcompany_id = 1;
        this.print_header = this.print_header_list[1];
      }else{
        this.getcompany_id = res_inv_so.data[0].company_inv;
        this.print_header.com_title = "RECEIPT & TAX INVOICE";
        this.print_header.com_name = res_inv_so.data[0].com_name;
        this.print_header.inv_company_address = res_inv_so.data[0].inv_company_address;
        this.print_header.inv_company_branch = res_inv_so.data[0].inv_company_branch;
        this.print_header.inv_company_tel = res_inv_so.data[0].inv_company_tel;
        this.print_header.inv_company_email = res_inv_so.data[0].inv_company_email;
        this.print_header.inv_tax_id = res_inv_so.data[0].inv_tax_id;
        this.print_header.inv_company_name_th = res_inv_so.data[0].inv_company_name_th;
      }
      this.currency_show =  res_inv_so.data[0].currency;
      this.print_header.customer_name = res_inv_so.data[0].customer_name;
      // this.print_header.invoice_date = res_inv_so.data[0].date;
      this.print_header.addressall = res_inv_so.data[0].addressall;
      this.print_header.customer_address = res_inv_so.data[0].customer_address;

      this.print_header.province_invoice = res_inv_so.data[0].province_invoice;
      this.print_header.country_invoice = res_inv_so.data[0].country_invoice;
      this.print_header.zipcode_invoice = res_inv_so.data[0].zipcode_invoice;

      this.print_header.period_cover = res_inv_so.data[0].inv_id
        ? res_inv_so.data[0].inv_id
        : res_inv_so.data[0].pinv_id;
      this.print_header.customer_contact_name =
        res_inv_so.data[0].customer_contact_name;
      this.print_header.customer_taxid = res_inv_so.data[0].customer_taxid;
      this.branch_cus = res_inv_so.data[0].customer_branch;
      this.print_header.tax_rate = this.tax_rate;
      // alert(this.branch_cus)
    },
    async loadMainRcData() {
      // this.print_detail_header = {};
      // var tmp_inv_data;
      const res_inv_so = await api.getdataByIdInvoice_HRC(this.inv_detail_id);
      if (res_inv_so.data[0].company_inv == "D") {
        this.getcompany_id = 1;
        this.print_detail_header = this.print_header_list_detail[0];
      } else if (res_inv_so.data[0].company_inv == "L") {
        this.getcompany_id = 1;
        this.print_detail_header = this.print_header_list_detail[1];
      }else{
        this.getcompany_id = res_inv_so.data[0].company_inv;
        this.print_detail_header.com_title = "RECEIPT & TAX INVOICE";
        this.print_detail_header.com_name = res_inv_so.data[0].com_name;
        this.print_detail_header.inv_company_address = res_inv_so.data[0].inv_company_address;
        this.print_detail_header.inv_company_branch = res_inv_so.data[0].inv_company_branch;
        this.print_detail_header.inv_company_tel = res_inv_so.data[0].inv_company_tel;
        this.print_detail_header.inv_company_email = res_inv_so.data[0].inv_company_email;
        this.print_detail_header.inv_tax_id = res_inv_so.data[0].inv_tax_id;
        this.print_detail_header.inv_company_name_th = res_inv_so.data[0].inv_company_name_th;

      }
      this.currency_show =  res_inv_so.data[0].currency;
      this.print_detail_header.customer_name = res_inv_so.data[0].customer_name;
      // this.print_header.invoice_date = res_inv_so.data[0].date;
      this.print_detail_header.addressall = res_inv_so.data[0].addressall;
      this.print_detail_header.customer_address =
        res_inv_so.data[0].customer_address;

      this.print_detail_header.province_invoice =
        res_inv_so.data[0].province_invoice;
      this.print_detail_header.country_invoice =
        res_inv_so.data[0].country_invoice;
      this.print_detail_header.zipcode_invoice =
        res_inv_so.data[0].zipcode_invoice;

      this.print_detail_header.period_cover = res_inv_so.data[0].inv_id
        ? res_inv_so.data[0].inv_id
        : res_inv_so.data[0].pinv_id;
      this.print_detail_header.customer_contact_name =
        res_inv_so.data[0].customer_contact_name;
      this.print_detail_header.customer_taxid = res_inv_so.data[0].customer_taxid;
      // alert(res_inv_so.data[0].customer_taxid)
      this.branch_cus = res_inv_so.data[0].customer_branch;
      this.print_detail_header.tax_rate = this.vat;
    },
    async checkcontent() {
   
      this.datashowAll = [];
      // alert(data.length);
      this.setdatatoshow = [];
      let linedetailprpo = 23;
      let datainlineprpo = 80;
      let addnewbutget = 1;
      let getdata = [];
      let getnewdata = [];
      let hllinedetails = 0;
      this.pageAll = 1;
      let newhllinedetails = 0;
      for (let i = 0; i < this.print_table_detail.length; i++) {
        let stringchecklength = "";
        let lengthofdataforshowprpo = this.print_table_detail[i].inv_id.length;

        let maiek = "่"; //ไม้เอก
        let maito = "้"; //ไม้โท
        let maitree = "๊"; //ไม้ตรี
        let maijattawa = "๋"; //ไม้จัตวา
        let vowelei = "ิ"; //สระอิ
        let vowelee = "ี"; //สระอี
        let voweleue = "ึ"; //สระอึ
        let voweleu = "ื"; //สระอือ
        let vowelu = "ุ"; //สระอุ
        let voweluu = "ู"; //สระอู
        let maihun = "ั"; //ไม้หันอากาศ
        let maiyamok = "็"; //ไม้เอก
        newhllinedetails = 0;
        getnewdata = [];
        this.print_table_detail[i].no = i + 1;

        for (let j = 0; j < this.print_table_detail[i].inv_id.length; j++) {
          if (
            stringchecklength[j] == maiek ||
            stringchecklength[j] == maito ||
            stringchecklength[j] == maitree ||
            stringchecklength[j] == maijattawa ||
            stringchecklength[j] == vowelei ||
            stringchecklength[j] == vowelee ||
            stringchecklength[j] == voweleue ||
            stringchecklength[j] == voweleu ||
            stringchecklength[j] == vowelu ||
            stringchecklength[j] == voweluu ||
            stringchecklength[j] == maihun ||
            stringchecklength[j] == maiyamok
          ) {
            lengthofdataforshowprpo--;
          }
        }
        newhllinedetails = Math.ceil(lengthofdataforshowprpo / datainlineprpo);

        // if (this.print_table_detail[i].first_item == 1) {
        //   newhllinedetails++;
        // }

        // newhllinedetails = Math.ceil(
        //   this.print_table_detail[i].NAME.length / datainlineprpo
        // );

        // newhllinedetails += addnewbutget;
        getnewdata.push(this.print_table_detail[i]);
       
        if (hllinedetails + newhllinedetails > linedetailprpo) {
          this.setdatatoshow[this.pageAll - 1] = getdata;
          this.pageAll++;

          hllinedetails = 0;
          getdata = [];
          getdata.push(this.print_table_detail[i]);
          hllinedetails += newhllinedetails;
          if (i == this.print_table_detail.length - 1) {
            this.setdatatoshow[this.pageAll - 1] = getdata;
            getdata = [];
          }
        } else {
          hllinedetails += newhllinedetails;

          getdata.push(this.print_table_detail[i]);

          if (i == this.print_table_detail.length - 1) {
            this.setdatatoshow[this.pageAll - 1] = getdata;
            getdata = [];
          }
        }
      }
      this.opensummarize = true;
   

      this.setdatatoshow.forEach((item, iitem) => {
       
        if (iitem == this.setdatatoshow.length - 1) {
          item[this.setdatatoshow[iitem].length - 1].lastdata = "lastindex";
        }

        this.datashowAll.push(item);
      });
 
    },
    async checkcontentdetail() {
 
      this.datashowAllDetail = [];
      // alert(data.length);
      this.setdatatoshowDetail = [];
      let linedetailprpo = 17;
      let datainlineprpo = 50;
      let addnewbutget = 1;
      let getdata = [];
      let getnewdata = [];
      let hllinedetails = 0;
      this.pageAllDetail = 1;
      let newhllinedetails = 0;
      for (let i = 0; i < this.print_table_Rcdetail.length; i++) {
        let stringchecklength = "";
        let lengthofdataforshowprpo = this.print_table_Rcdetail[i].description
          .length;

        let maiek = "่"; //ไม้เอก
        let maito = "้"; //ไม้โท
        let maitree = "๊"; //ไม้ตรี
        let maijattawa = "๋"; //ไม้จัตวา
        let vowelei = "ิ"; //สระอิ
        let vowelee = "ี"; //สระอี
        let voweleue = "ึ"; //สระอึ
        let voweleu = "ื"; //สระอือ
        let vowelu = "ุ"; //สระอุ
        let voweluu = "ู"; //สระอู
        let maihun = "ั"; //ไม้หันอากาศ
        let maiyamok = "็"; //ไม้เอก
        newhllinedetails = 0;
        getnewdata = [];
        this.print_table_Rcdetail[i].no = i + 1;

        for (
          let j = 0;
          j < this.print_table_Rcdetail[i].description.length;
          j++
        ) {
          if (
            stringchecklength[j] == maiek ||
            stringchecklength[j] == maito ||
            stringchecklength[j] == maitree ||
            stringchecklength[j] == maijattawa ||
            stringchecklength[j] == vowelei ||
            stringchecklength[j] == vowelee ||
            stringchecklength[j] == voweleue ||
            stringchecklength[j] == voweleu ||
            stringchecklength[j] == vowelu ||
            stringchecklength[j] == voweluu ||
            stringchecklength[j] == maihun ||
            stringchecklength[j] == maiyamok
          ) {
            lengthofdataforshowprpo--;
          }
        }
        newhllinedetails = Math.ceil(lengthofdataforshowprpo / datainlineprpo);
       

        getnewdata.push(this.print_table_Rcdetail[i]);
      
        if (hllinedetails + newhllinedetails > linedetailprpo) {
        
          this.setdatatoshowDetail[this.pageAllDetail - 1] = getdata;
          this.pageAllDetail++;

          hllinedetails = 0;
          getdata = [];
          getdata.push(this.print_table_Rcdetail[i]);
          hllinedetails += newhllinedetails;
          if (i == this.print_table_Rcdetail.length - 1) {
            this.setdatatoshowDetail[this.pageAllDetail - 1] = getdata;
            getdata = [];
          }
        } else {
          hllinedetails += newhllinedetails;

          getdata.push(this.print_table_Rcdetail[i]);

          if (i == this.print_table_Rcdetail.length - 1) {
            this.setdatatoshowDetail[this.pageAllDetail - 1] = getdata;
            getdata = [];
          }
        }
      }
      this.opensummarizeDetail = true;
     

      this.setdatatoshowDetail.forEach((item, iitem) => {
       
        if (iitem == this.setdatatoshowDetail.length - 1) {
          item[this.setdatatoshowDetail[iitem].length - 1].lastdata =
            "lastindex";
        }

        this.datashowAllDetail.push(item);
      });
   
    },
    async exportToPDF() {
      // this.$refs.html2Pdf.generatePdf();

      this.showprint = "";
      let element = document.getElementById("mydivhtmltobase");
      const opt = {
        margin: [0, 0, 0, 0],
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        // enableLinks: false,
        html2canvas: {
          scale: 2,
          dpi: 192,
          letterRendering: true,
          useCORS: true,
          // ignoreElements: (e) => {
          //   return e.classList.contains("cke_pagebreak") ||
          //     e.classList.contains("html2pdf__page-break")
          //     ? true
          //     : false;
          // },
        },
        jsPDF: {
          unit: "cm",
          format: "A4",
          orientation: "portrait",
          putOnlyUsedFonts: true,
          pagesplit: true,
        },
        // pagebreak: { mode: ["avoid-all"], after: ".cke_pagebreak" },
      };

      let abcd = await html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get("pdf")
        // .output("bloburl")
        .then(function(pdf) {
         
          const totalPages = pdf.internal.getNumberOfPages();
          pdf.deletePage(totalPages);
          return pdf.output("bloburl");
        });
      // console.log(abcd);
      window.open(abcd);
      let blob = await fetch(abcd).then((r) => r.blob());
      this.showprint = "display:none;";

      return;
    },
    async exportToPDFDetail() {
      // this.$refs.html2Pdf.generatePdf();

      this.showprintDetail = "";
      let element = document.getElementById("mydivhtmltobaseDetail");
      const opt = {
        margin: [0, 0, 0, 0],
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        // enableLinks: false,
        html2canvas: {
          scale: 2,
          dpi: 192,
          letterRendering: true,
          useCORS: true,
          // ignoreElements: (e) => {
          //   return e.classList.contains("cke_pagebreak") ||
          //     e.classList.contains("html2pdf__page-break")
          //     ? true
          //     : false;
          // },
        },
        jsPDF: {
          unit: "cm",
          format: "A4",
          orientation: "portrait",
          putOnlyUsedFonts: true,
          pagesplit: true,
        },
        // pagebreak: { mode: ["avoid-all"], after: ".cke_pagebreak" },
      };

      let abcd = await html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get("pdf")
        // .output("bloburl")
        .then(function(pdf) {
         
          const totalPages = pdf.internal.getNumberOfPages();
          pdf.deletePage(totalPages);
          return pdf.output("bloburl");
        });
      // console.log(abcd);
      window.open(abcd);
      let blob = await fetch(abcd).then((r) => r.blob());
      this.showprintDetail = "display:none;";

      return;
    },
    async printReceive() {
      if (this.dialogSelect == "1") {
        // alert(this.currency_print)
        this.datashowAll = [];
        this.print_table_detail = [];
        this.dialogSelectPrint = false;
        this.$showLoader();
        await this.loadRef_TypeRcById();
        await this.loadMainInvSoData();
        if(this.currency_print == "THB"){
          await this.loadInvSoData();    
        }else{
          await this.loadInvSoDataOth(); 
        }
        await this.checkcontent();
        await this.exportToPDF();
        this.$hideLoader();
      } else {
        this.datashowAllDetail = [];
        this.print_table_Rcdetail = [];
        this.dialogSelectPrint = false;
        this.$showLoader();
        await this.loadRef_TypeRcDetailById();
        await this.loadMainRcData();
        await this.loadRcData();
        await this.checkcontentdetail();
        await this.exportToPDFDetail();
        this.$hideLoader();
      }
    },
    async SumDataT(){
      
      let sum_data = 0;
      this.mDataArrayforinv.forEach((item)=>{
        item.inv_lineamount = Number(stringcommatonumber(item.inv_lineamount));
        sum_data = sum_data + Number(item.inv_lineamount);
        item.inv_lineamount = tolocalestringnumber(item.inv_lineamount);
      })
    
      if(this.vat_type == "E"){
      this.inv_subtotal = tolocalestringnumber(sum_data);
      this.wth_ratetotal = tolocalestringnumber(Number(this.wth_rate)/100 * sum_data);
      this.tax_ratetotal = tolocalestringnumber(Number(this.vat/100 * sum_data));
      this.amount_total = tolocalestringnumber(sum_data - (Number(this.wth_rate)/100 * sum_data) +(Number(this.vat/100 * sum_data)));
    }else if(this.vat_type == "I"){
        this.amount_total = tolocalestringnumber(sum_data /(1+Number(this.wth_rate)/100 ));
        this.wth_ratetotal = tolocalestringnumber(Number(sum_data - stringcommatonumber(this.amount_total)).toFixed(2));
        this.inv_subtotal = tolocalestringnumber(Number((stringcommatonumber(this.amount_total) / (1 + this.vat / 100))).toFixed(2));
        this.tax_ratetotal = tolocalestringnumber(Number((stringcommatonumber(this.amount_total) - stringcommatonumber(this.inv_subtotal))).toFixed(2));

      
    }else{

    }
    },
    async loadinv_handcal(){
      const res_rc_detail = await api.getDataReceiveDByID(this.main_id_print);
      this.desserts = res_rc_detail.data;
      this.desserts.forEach(async(item, index) => {
        item.inv_qty = tolocalestringnumber(item.inv_qty);
        item.inv_price = tolocalestringnumber(item.inv_lineamount / item.inv_qty);
        item.inv_lineamount = tolocalestringnumber(item.inv_lineamount);
if(index == this.desserts.length-1){
  this.mDataArrayforinv = this.desserts;
}
      });
   
    },
    async loadCustomer() {
      if (this.main_customer_id != 0) {
        const res_customer = await api.getCustomersById(this.main_customer_id);
        if(res_customer.data[0]){
          this.vat_type = res_customer.data[0].vat_type;
        }
        
      }
    },
  },
};
