//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

  import SuccessDialog from "@/components/cards/SuccessDialog";
  import api from "@/services/api";
  import { server } from "@/services/constants";
  import html2pdf from "html2pdf.js";
  import unity from "@/unity/unity";
  import {
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  } from "vue-xlsx";
  import {
      tolocalestringnumber,
      stringcommatonumber,
    } from "./computing/tolocalestringnumber";
  export default {
    async mounted() {
      // this.$showLoader();
      await api.checkVersion();
      this.mycompany_id = localStorage.getItem(server.COMPANYID);
      await this.loadDepartmentByCompanyId();
      await this.loadCustomer();
      await this.loadEmp();
      this.$hideLoader();
    },
    components: {
      SuccessDialog,
      XlsxRead,
      XlsxTable,
      XlsxSheets,
      XlsxJson,
      XlsxWorkbook,
      XlsxSheet,
      XlsxDownload,
    },
    computed: {
      computedDateFromFormatted() {
        return unity.formatDate(this.datasearch.datefrom);
      },
      computedDateToFormatted() {
        return unity.formatDate(this.datasearch.dateto);
      },
    },
    data() {
      return {
        showsearchsum: false,
        employeeisdisable:false,
        exportleavereport: "summary_activity_report.xlsx",
        sheets: [
          {
            name: "summary_activity_report",
            data: [],
          },
        ],
        search:"",
        headers:[
          {
            text: "Department",
            value: "dep_name",
            class: "bg-colorth tabletextwhite",
          },
          {
            text: "Employee Name",
            value: "UserName",
            class: "bg-colorth tabletextwhite",
          },
          {
            text: "F/C",
            value: "F_C",
            class: "bg-colorth tabletextwhite",
            align: "end"
          },
          {
            text: "N/C",
            value: "N_C",
            class: "bg-colorth tabletextwhite",
            align: "end"
          },
          {
            text: "T/C",
            value: "T_C",
            class: "bg-colorth tabletextwhite",
            align: "end"
          },
          {
            text: "OFF",
            value: "OFF",
            class: "bg-colorth tabletextwhite",
            align: "end"
          },
          {
            text: "Total",
            value: "Total",
            class: "bg-colorth tabletextwhite",
            align: "end"
          },
        ],
        mDataArray:[],
        dataAll:[],
        sum_show_table: [], 
        showchargetype: "",
        checkdepartmentname: "",
        lengthAlldatasearch: 0,
        menudateto: false,
        menudatefrom: false,
        mycompany_id: 0,
        emp_list: [],
        datasearch: {    
          department: 0,
          employee_id: 0,
          datefrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
          dateto: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
            .toISOString()
            .substr(0, 10),
          company_id: localStorage.getItem(server.COMPANYID),
        },
        dateCurrent: this.formatDateday(new Date().toISOString().substr(0, 10)),
        getnamesignature: [],
        prpoheader: [],
        memoref_list: [],
        no_list: [],
        mysignature: "",
        approvesignature: "",
        opensummarize: false,
        datashowAll: [],
        setdatatoshow: [],
        getheightcontentpr: 0,
        pageAll: 1,
        previewattachimage: "",
        
        department_list: [],
        charge_list: [
          { value: "T/C", name: "Time Charge" },
          { value: "N/C", name: "No Charge" },
          { value: "F/C", name: "Fix Charge" },
          { value: "OFF", name: "ทำงานออฟฟิต" },
        ],
        isShowPassword: false,
        fullPage: true,
        dialogEdit: false,
        dialogDelete: false,
        dialogAdd: false,
        dialogSendMail: false,
        text_color: "text-h5 green--text text-center",
        title: "green",
        message: "green",
        link: "",
        closeDelete: false,
        deleteItemConfirm: false,
        emp_list: [],
        jobtitle_list: [],
        customer_list: [],
        status_list: [
          {
            name: "Draft",
            status_no: 1,
          },
          {
            name: "P.invoice",
            status_no: 2,
          },
          {
            name: "Invoice",
            status_no: 3,
          },
          {
            name: "Cancel",
            status_no: 4,
          },
          {
            name: "Credit Note",
            status_no: 5,
          },
          {
            name: "Receive",
            status_no: 6,
          },
        ],
        dataformatpr: {
          title: "",
          subtitle: "Summary TimeSheets Activity Report",
          dateto: "",
          datefrom: ""
        },
      };
    },
    methods: {
        async loadEmp() {
            const result = await api.getAccountslist_by_ComID(
                localStorage.getItem(server.COMPANYID)
            );

            this.emp_list = result.data;
        },
        onCreated(wb) {
            // console.log(wb);
        },
        onChange(wb) {

          console.log("wb-------------");
          console.log(wb);

          const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 7 } }];
          wb.Sheets.summary_activity_report.A1 = {
            t: "s",
            v: `Export Summary TimeSheets Activity as of   ${unity.formatDate(this.datasearch.datefrom)} to  ${unity.formatDate(this.datasearch.dateto)}`,
          };

          wb.Sheets.summary_activity_report.B1 = { t: "s", c: 0, v: "" };
          wb.Sheets.summary_activity_report.C1 = { t: "s", c: 0, v: "" };
          wb.Sheets.summary_activity_report.D1 = { t: "s", c: 0, v: "" };
          wb.Sheets.summary_activity_report.E1 = { t: "s", c: 0, v: "" };
          wb.Sheets.summary_activity_report.F1 = { t: "s", c: 0, v: "" };
          wb.Sheets.summary_activity_report.G1 = { t: "s", c: 0, v: "" };


          wb.Sheets.summary_activity_report.A3 = { t: "s", v: "Department Name" };
          wb.Sheets.summary_activity_report.B3 = { t: "s", v: "Employee Name" };
          wb.Sheets.summary_activity_report.C3 = { t: "s", v: "F/C" };
          wb.Sheets.summary_activity_report.D3 = { t: "s", v: "N/C" };
          wb.Sheets.summary_activity_report.E3 = { t: "s", v: "T/C" };
          wb.Sheets.summary_activity_report.F3 = { t: "s", v: "OFF" };
          wb.Sheets.summary_activity_report.G3 = { t: "s", v: "Totle" };


          wb.Sheets.summary_activity_report["!merges"] = merge;
        },

        async loadDepartmentByCompanyId() {
            let result = await api.getAllDepartment_By_CompanyID(this.mycompany_id);
            this.department_list = result.data;
        },
        async loadCustomer() {
            const result = await api.getAllCustomerListactiveCompany(
            {
                params: {
                company_id: localStorage.getItem(server.COMPANYID),
                },
            }
            );
            this.customer_list = result.data;
            console.log(this.customer_list)
        },
    
        findChaege(chargetype) {
            this.charge_list.filter((item, index) => {
            if (item.value == chargetype) {
                this.showchargetype = item.name;
                return;
            }
            });
        },
    
        async SearchAlldata() {
            this.datashowAll = [];
            this.lengthAlldatasearch = 0;
            this.dataAll = [];
            this.mDataArray = [];

            if (!this.datasearch.datefrom) {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
                true,
                "ค้นหาข้อมูล",
                "กรุณาเลือก DateFrom",
                "text-h5 red--text text-center"
            );
            return;
            }
            if (!this.datasearch.dateto) {
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
                true,
                "ค้นหาข้อมูล",
                "กรุณาเลือก DateTo",
                "text-h5 red--text text-center"
            );
            return;
            }

            // console.log(this.datasearch)

            // this.$showLoader();

            var resultsearch = await api.getdataReportSumAct({params: this.datasearch});
            let copany_datat = await api.getCompanyByID(localStorage.getItem(server.COMPANYID))
    
            console.log(resultsearch.data)
    
            if (resultsearch.data.length > 0) {
                this.dataAll = resultsearch.data;
                this.mDataArray = this.dataAll;
                
                // show pdf
    
                await this.setexporttoxlsx();
                this.dataformatpr.title = copany_datat.data[0].name;
                this.dataformatpr.datefrom = unity.formatDate(this.datasearch.datefrom);
                this.dataformatpr.dateto = unity.formatDate(this.datasearch.dateto) ;
    
                this.dataforshowprpo = resultsearch.data;
                await this.checkcontent();
                this.$hideLoader();
            }else{
                this.$hideLoader();
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                  true,
                  "ค้นหาข้อมูล",
                  "ไม่มีข้อมูล",
                  "text-h5 red--text text-center"
                );
                return;
            }
    
        
        },
        async setexporttoxlsx() {
          let setexportxlsx = [{}, {}];
          this.mDataArray.forEach((x, index) => {
            setexportxlsx.splice(index + 2, 0, {
              dep_name: x.dep_name,
              UserName: x.UserName,
              F_C: x.F_C,
              N_C: x.N_C,
              T_C: x.T_C,
              OFF: x.OFF,
              Total: x.Total,
            });
          });
          this.sheets[0].data = setexportxlsx;
        },
        
        formatDatetomonthdayyear(date) {
            if (!date) return null;
    
            const [day, month, year] = date.split("/");
            return `${month.padStart(2, "0")}/${day.padStart(2, "0")}/${year}`;
        },
        formatDateday(date) {
            if (!date) return null;
            const [year, month, day] = date.split("-");
            return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
        },
        changeformatDatetoinsert(date) {
            if (!date) return null;
    
            const [day, month, year] = date.split("/");
            return `${year}-${month}-${day}`;
        },
        async checkcontent() {
            
            let totalclient = 0;
            this.setdatatoshow = [];
            let linedetailprpo = 40;
            let datainlineprpo = 27;
            let getdata = [];
            let getnewdata = [];
            let hllinedetails = 0;
            this.pageAll = 1;
            let newhllinedetails = 0;
    
            console.log("this.dataforshowprpo")
            console.log(this.dataforshowprpo)
            for (let i = 0; i < this.dataforshowprpo.length; i++) {
    
    
    
            let maiek = "่"; //ไม้เอก
            let maito = "้"; //ไม้โท
            let maitree = "๊"; //ไม้ตรี
            let maijattawa = "๋"; //ไม้จัตวา
            let vowelei = "ิ"; //สระอิ
            let vowelee = "ี"; //สระอี
            let voweleue = "ึ"; //สระอึ
            let voweleu = "ื"; //สระอือ
            let vowelu = "ุ"; //สระอุ
            let voweluu = "ู"; //สระอู
            let maihun = "ั"; //ไม้หันอากาศ
            let maiyamok = "็"; //ไม้เอก
    
            newhllinedetails = 0;
            getnewdata = [];
    
            this.dataforshowprpo[i].no = i + 1;
    
            let stringchecklength = "";
    
            if(this.dataforshowprpo[i].UserName != null){
                let lengthofdataforshowprpo = this.dataforshowprpo[i].UserName.length;
                stringchecklength = this.dataforshowprpo[i].UserName;
    
                for (let j = 0; j < this.dataforshowprpo[i].UserName.length; j++) {
                if (
                    stringchecklength[j] == maiek ||
                    stringchecklength[j] == maito ||
                    stringchecklength[j] == maitree ||
                    stringchecklength[j] == maijattawa ||
                    stringchecklength[j] == vowelei ||
                    stringchecklength[j] == vowelee ||
                    stringchecklength[j] == voweleue ||
                    stringchecklength[j] == voweleu ||
                    stringchecklength[j] == vowelu ||
                    stringchecklength[j] == voweluu ||
                    stringchecklength[j] == maihun ||
                    stringchecklength[j] == maiyamok
                ) {
                    lengthofdataforshowprpo--;
                }
                }
    
                newhllinedetails = Math.ceil(lengthofdataforshowprpo / datainlineprpo);
                console.log(newhllinedetails)
                getnewdata.push(this.dataforshowprpo[i]);
    
                if (hllinedetails + newhllinedetails > linedetailprpo || this.dataforshowprpo[i].changedepartment == true) {
                console.log(i + " ============== " + this.dataforshowprpo.length);
                this.setdatatoshow[this.pageAll - 1] = getdata;
                this.pageAll++;
    
                hllinedetails = 0;
                getdata = [];
                getdata.push(this.dataforshowprpo[i]);
                hllinedetails += newhllinedetails;
                if (i == this.dataforshowprpo.length - 1) {
                    this.dataforshowprpo[i].totalclient = totalclient.toFixed(2);
                    this.setdatatoshow[this.pageAll - 1] = getdata;
                    getdata = [];
                }
                } else {
                hllinedetails += newhllinedetails;
    
                getdata.push(this.dataforshowprpo[i]);
    
                if (i == this.dataforshowprpo.length - 1) {
                    this.dataforshowprpo[i].totalclient = totalclient.toFixed(2);
                    this.setdatatoshow[this.pageAll - 1] = getdata;
                    getdata = [];
                }
                }
            }else{
                if(this.dataforshowprpo[i].dep_name == null){
                this.dataforshowprpo[i].dep_name = "-";
                }
                let lengthofdataforshowprpo = this.dataforshowprpo[i].dep_name.length;
                stringchecklength = this.dataforshowprpo[i].dep_name;
    
                for (let j = 0; j < this.dataforshowprpo[i].dep_name.length; j++) {
                if (
                    stringchecklength[j] == maiek ||
                    stringchecklength[j] == maito ||
                    stringchecklength[j] == maitree ||
                    stringchecklength[j] == maijattawa ||
                    stringchecklength[j] == vowelei ||
                    stringchecklength[j] == vowelee ||
                    stringchecklength[j] == voweleue ||
                    stringchecklength[j] == voweleu ||
                    stringchecklength[j] == vowelu ||
                    stringchecklength[j] == voweluu ||
                    stringchecklength[j] == maihun ||
                    stringchecklength[j] == maiyamok
                ) {
                    lengthofdataforshowprpo--;
                }
                }
    
                newhllinedetails = Math.ceil(lengthofdataforshowprpo / datainlineprpo);
    
                getnewdata.push(this.dataforshowprpo[i]);
    
                if (hllinedetails + newhllinedetails > linedetailprpo || this.dataforshowprpo[i].changedepartment == true) {
                console.log(i + " ============== " + this.dataforshowprpo.length);
                this.setdatatoshow[this.pageAll - 1] = getdata;
                this.pageAll++;
    
                hllinedetails = 0;
                getdata = [];
                getdata.push(this.dataforshowprpo[i]);
                hllinedetails += newhllinedetails;
                if (i == this.dataforshowprpo.length - 1) {
                    this.dataforshowprpo[i].totalclient = totalclient.toFixed(2);
                    this.setdatatoshow[this.pageAll - 1] = getdata;
                    getdata = [];
                }
                } else {
                hllinedetails += newhllinedetails;
    
                getdata.push(this.dataforshowprpo[i]);
    
                if (i == this.dataforshowprpo.length - 1) {
                    this.dataforshowprpo[i].totalclient = totalclient.toFixed(2);
                    this.setdatatoshow[this.pageAll - 1] = getdata;
                    getdata = [];
                }
                }
            }
            }
    
            this.pageAll += this.lengthAlldatasearch - 1;
            this.opensummarize = true;
    
            this.setdatatoshow.forEach((item, iitem) => {
    
            if (iitem == this.setdatatoshow.length - 1) {
                item[this.setdatatoshow[iitem].length - 1].lastdata = "lastindex";
            }
    
            this.datashowAll.push(item);
            });
            console.log("this.datashowAll")
            console.log(this.datashowAll)
    
        },
    
        async exportToPDF(advance_id) {
            this.$showLoader();
            // this.$refs.html2Pdf.generatePdf();
            let element = document.getElementById("mydivhtmltobase");
    
            const opt = {
            margin: [0, 0, 0, 0],
            image: {
                type: "jpeg",
                quality: 0.98,
            },
            // enableLinks: false,
            html2canvas: {
                scale: 2,
                dpi: 192,
                letterRendering: true,
                useCORS: true,
                // ignoreElements: (e) => {
                //   return e.classList.contains("cke_pagebreak") ||
                //     e.classList.contains("html2pdf__page-break")
                //     ? true
                //     : false;
                // },
            },
            jsPDF: {
                unit: "cm",
                format: "A4",
                orientation: "portrait",
                putOnlyUsedFonts: true,
                pagesplit: true,
            },
            // pagebreak: { mode: ["avoid-all"], after: ".cke_pagebreak" },
            };
    
            let abcd = await html2pdf()
            .from(element)
            .set(opt)
            .toPdf()
            .get("pdf")
            // .output("bloburl")
            .then(function(pdf) {
                console.log(pdf);
                const totalPages = pdf.internal.getNumberOfPages();
                pdf.deletePage(totalPages);
                // var filtered = pdf.internal.pages.filter(function(el) {
                //   return el != null;
                // });
    
                // pdf.internal.pages = filtered;
                // for (let i = 0; i < totalPages; i++) {
                //   if (i == totalPages - 1) {
                //     pdf.internal.pages.splice(i, 1);
                //   }
                // }
                // pdf.addPage();
    
                // console.log(pdf);
                return pdf.output("bloburl");
            });
            // console.log(abcd);
            window.open(abcd);
            let blob = await fetch(abcd).then((r) => r.blob());
            // console.log(blob);
            // let formData = new FormData();
            // formData.append(`file`, blob, "test.pdf");
            // const resultadd = api.creatememoMaster(formData);
            // console.log(resultadd);
    
            // };
            this.$hideLoader();
        },
        setupAlertDialog(status, title, message, text_color) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
        },
        setupAlertDialogPush(status, title, message, text_color, link) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
            this.link = link;
        },
        },
        beforeCreate() {
        this.$store.state.navMenu = true;
        },
};
  