//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const moment = require('moment-timezone');
import api from "@/services/api";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import {
  server,
  imagemasterTMUrl,
  filemasterTMUrl,
} from "@/services/constants";
import pdfvuer from "pdfvuer";
import html2pdf from "html2pdf.js";

export default {
  name: "MasterPatent",
  async mounted() {
    this.$showLoader();
    await api.checkVersion();
    // ----------------- Check Authorize ---------------------------
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);
    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed",
        "Please Logout And Login Again",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(this.userId, router_path);

    // console.log("res_auth:" + JSON.stringify(res_auth.data));

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    // console.log("res_auth:" + JSON.stringify(res_auth.data));
    // console.log("authorize_view:" + this.authorize_view);
    // console.log("authorize_add:" + this.authorize_add);
    // console.log("authorize_edit:" + this.authorize_edit);
    // console.log("authorize_del:" + this.authorize_del);
    // this.$router.back();
    if (this.userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
    if (!this.authorize_view) {
      this.$router.back();
    }

    // ----------------- Check Authorize ---------------------------

    await this.loaddataLimit200();
    this.$hideLoader();
  },
  components: { SuccessDialog, SuccessDialogPush },
  data(vm) {
    return {
      userId: 0,
      authorize: [],
      authorize_view: false,
      authorize_add: false,
      authorize_edit: false,
      authorize_del: false,
      authorize_id: 0,
      itemsPerPage: 100,
      load_bg: false,
      isShowPassword: false,
      fullPage: true,
      search: "",
      mDataArray: [],
      mDataArraypopup: [],
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      dialogShow: false,
      ShowfileNO: "",
      search_pt: "",
      headers: [
        {
          text: "File No.",
          value: "file_no",
          class: "bg-colorth",
          divider: true,
          width: "105px",
        },
        {
          text: "Application No.",
          value: "app_no",
          class: "bg-colorth",
          divider: true,
          width: "12%",
        },
        {
          text: "Patent No.",
          value: "patent_no",
          class: "bg-colorth",
          divider: true,
          width: "8%",
        },
        {
          text: "Client Ref.",
          value: "client_ref",
          class: "bg-colorth",
          divider: true,
          width: "10%",
        },
        {
          text: "Client Name",
          value: "name_of_client",
          class: "bg-colorth",
          divider: true,
          width: "10%",
        },
        {
          text: "Status",
          value: "status",
          class: "bg-colorth",
          divider: true,
          width: "8%",
        },
        {
          text: "Next Annuity Date",
          value: "next_annuity_date",
          class: "bg-colorth",
          divider: true,
          width: "104px",
        },
        {
          text: "Owner",
          value: "owner",
          class: "bg-colorth",
          divider: true,
          width: "7%",
        },
        {
          text: "Agent",
          value: "agent",
          class: "bg-colorth",
          divider: true,
          width: "8%",
        },
        {
          text: "Associate",
          value: "associate",
          class: "bg-colorth",
          divider: true,
          width: "9%",
        },
        {
          text: "",
          value: "actions",
          align: "start",
          class: "bg-colorth",
          divider: true,
          width: "117px",
        },
      ],
      headerspopup: [
        {
          text: "Work Type",
          value: "documenttype_code",
          class: "bg-colorth",
          divider: true,
          width: "10%",
        },
        {
          text: "File No.",
          value: "refno",
          class: "bg-colorth",
          divider: true,
          width: "10%",
        },
        {
          text: "Client Name",
          value: "trademark_name",
          class: "bg-colorth",
          divider: true,
          width: "10%",
        },
        {
          text: "Application No.",
          value: "app_no",
          class: "bg-colorth",
          divider: true,
          width: "14%",
        },
        {
          text: "Receive Date",
          value: "receive_date",
          class: "bg-colorth",
          divider: true,
          width: "104px",
        },
        {
          text: "Deadline Date",
          value: "enddate",
          class: "bg-colorth",
          divider: true,
          width: "104px",
        },
        {
          text: "Internal Date",
          value: "internaldate",
          class: "bg-colorth",
          divider: true,
          width: "104px",
        },
        {
          text: "Title",
          value: "title",
          class: "bg-colorth",
          divider: true,
          width: "13%",
        },
        {
          text: "Remark",
          value: "remark",
          class: "bg-colorth",
          divider: true,
          width: "10%",
        },
        {
          text: "",
          value: "actions",
          width: "15%",
          align: "left",
          divider: true,
        },
      ],
      dialogAdd: false,
      pageSize: 3,
      pageSizes: [3, 6, 9],
      pageCount: 0,
      itemsPerPage: 100,
      load_bg: false,
      page: 1,
      numPages: 0,
      pdfdata: undefined,
      errors: [],
      scale: "page-width",
      showprint: "display:none;",
      print_data: {},
      previewimageedit: null,
    };
  },
  methods: {
    async openPrintAppNo(item) {
      this.$showLoader();
      this.print_data = {};
      this.previewimageedit = null;
      const res_pt = await api.printPT(item.masterpt_id)
      this.print_data = res_pt.data[0];
      console.log(this.print_data)

        var dataAdd = {
            user_id: localStorage.getItem(server.USER_ID),
            log_in_out_id: localStorage.getItem(server.LOGIN_ID),
            action: "Export",
            table_name: "masterpt",
            table_id: item.masterpt_id,
            date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }

        await api.addTransectionLog(dataAdd)

        var dataEditLog = {
            last_update: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
        }
        await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEditLog)

      setTimeout(async () => {
        await this.exportToPDF();
        this.$hideLoader();
      }, "2000");
    },
    async exportToPDF(advance_id) {
      // this.$refs.html2Pdf.generatePdf();

      this.showprint = "";
      let element = document.getElementById("mydivhtmltobase");
      const opt = {
        margin: [0, 0, 0, 0],
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        // enableLinks: false,
        html2canvas: {
          scale: 2,
          dpi: 192,
          letterRendering: true,
          useCORS: true,
          // ignoreElements: (e) => {
          //   return e.classList.contains("cke_pagebreak") ||
          //     e.classList.contains("html2pdf__page-break")
          //     ? true
          //     : false;
          // },
        },
        jsPDF: {
          unit: "cm",
          format: "A4",
          orientation: "portrait",
          putOnlyUsedFonts: true,
          pagesplit: true,
        },
        // pagebreak: { mode: ["avoid-all"], after: ".cke_pagebreak" },
      };

      let abcd = await html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get("pdf")
        // .output("bloburl")
        .then(function(pdf) {
          console.log(pdf);
          const totalPages = pdf.internal.getNumberOfPages();
          pdf.deletePage(totalPages);
          return pdf.output("bloburl");
        });
      // console.log(abcd);
      window.open(abcd);
      let blob = await fetch(abcd).then((r) => r.blob());
      this.showprint = "display:none;";

      return;
    },
    async openeditTableAppointment(appointment_id) {
      window.open(`/appointment-patent-edit/${appointment_id}`);
    },
    async loaddataLimit200() {
      // this.$showLoader();
      var tmp = [];
      const result = await api.getAllMasterPTLimit200();
      tmp = result.data;
      this.mDataArray = tmp;
    },
    async loaddata() {
      // this.$showLoader();
      this.load_bg = true;
      var tmp = [];
      const result = await api.getAllMasterPT();
      tmp = result.data;
      this.mDataArray = this.mDataArray.concat(tmp);
      this.load_bg = false;
    },
    async openeditItem(masterpt_id) {
      // this.$router.push(`/master-patent-edit/${masterpt_id}`);
      window.open(`/master-patent-edit/${masterpt_id}`);
    },
    async openeAppoinmentList(file_no) {
      const result = await api.getfile_noPT(file_no);
      console.log(result.data);
      if (result.data) {
        this.mDataArraypopup = result.data;
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Show data ",
          "Data not found ",
          "text-h5 red--text text-center"
        );
        return;
      }
      this.dialogShow = true;
      this.ShowfileNO = file_no;
    },
    async openeAppoinmentOperation(file_no) {
      this.$router.push(`/appointment-patent-add/${file_no}`);
    },
    async closedialogShow() {
      this.dialogShow = false;
    },
    async onSearch() {
      // alert("5555")
      const result = await api.getMasterPTSearch({
        params: { search_pt: this.search_pt.trim() },
      });
      this.mDataArray = result.data;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
  beforeCreate() {
    this.$store.state.navMenu = true;
  },
};
