//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "../services/constants";
import html2pdf from "html2pdf.js";
import unity from "@/unity/unity";
import {
  XlsxRead,
  XlsxTable,
  XlsxSheets,
  XlsxJson,
  XlsxWorkbook,
  XlsxSheet,
  XlsxDownload,
  utils
} from "vue-xlsx";

export default {
  async mounted() {
    // this.$showLoader();
    await api.checkVersion();
    this.mycompany_id = localStorage.getItem(server.COMPANYID);
    await this.loadEmp();
    await this.loadCustomer();
    await this.loadJobtitle();
    await this.loadLeaveType();
    await this.loadDepartmentByCompanyId();
    await this.loadCompany();
      this.$hideLoader();
  },
  components: {
    SuccessDialog,
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  computed: {
    computedDateFromFormatted() {
      return unity.formatDate(this.datasearch.datefrom);
    },
    computedDateToFormatted() {
      return unity.formatDate(this.datasearch.dateto);
    },
  },
  data() {
    return {
      employeeisdisable:false,

      exportleavequota: "leavereport.xlsx",
      sheetName: "Leave Report",
      sheets: [
        {
          name: "leavereport",
          data: [],
        },
      ],

      search:"",
      headers:[{
          text: "Employee",
          value: "fullnameth",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Leave Type",
          value: "leave_type_name",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "date time from",
          value: "date_time_from_show",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "date time to",
          value: "date_time_to_show",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "",
          value: "leave_time",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Description",
          value: "description",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "",
          value: "actions",
          class: "bg-colorth tabletextwhite",
        },],
      mDataArray:[],
      showchargetype: "",
      checkdepartmentname: "",
      lengthAlldatasearch: 0,
      menudateto: false,
      menudatefrom: false,
      mycompany_id: 0,
      datasearch: {
        employeename: "",
        department: 0,
        leave_type:0,
        // datefrom: "",
        // dateto: "",
        datefrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        dateto: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        // company_id: 13,
        company_id: localStorage.getItem(server.COMPANYID),
      },
      dateCurrent: this.formatDateday(new Date().toISOString().substr(0, 10)),
      getnamesignature: [],
      prpoheader: [],
      memoref_list: [],
      no_list: [],
      mysignature: "",
      approvesignature: "",
      opensummarize: false,
      datashowAll: [],
      setdatatoshow: [],
      getheightcontentpr: 0,
      pageAll: 1,
      previewattachimage: "",
      
      department_list: [],
      charge_list: [
        { value: "T/C", name: "Time Charge" },
        { value: "N/C", name: "No Charge" },
        { value: "F/C", name: "Fix Charge" },
        { value: "OFF", name: "ทำงานออฟฟิต" },
      ],
      isShowPassword: false,
      fullPage: true,
      dialogEdit: false,
      dialogDelete: false,
      dialogAdd: false,
      dialogSendMail: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      closeDelete: false,
      deleteItemConfirm: false,
      emp_list: [],
      jobtitle_list: [],
      customer_list: [],
      dataformatpr: {
        title: "",
        subtitle: "Leave Report",
        paymenttype: "Payment Type",
        companyname_addressrow1:
          "เลขที่ 142 อาคาร ทู แปซิฟิค เพลส ชั้น20 ถนนสุขุมวิท แขวงคลองเตย",
        companyname_addressrow2: "เขตคลองเตย กรุงเทพฯ 10110",
        pr_number: "เลขที่ใบขอซื้อ/PR Number",
        pr_date: "วันที่/PR Date",
        companyname_tel: "โทรศัพท์/Tel. 02-6530489-9",
        companyname_fax: "โทรสาร/Fax. 02-6533706",
        prtitle_th: "ใบขอซื้อ",
        prtitle_en: "PURCHASE REQUISTION",
      },
    };
  },
  methods: {
    onCreated(wb) {
      // console.log(wb);
    },
    onChange(wb) {
      console.log("wb-------------");
      console.log(wb);
      const merge = [{ s: { r: 0, c: 0 }, e: { r: 0, c: 6 } }];
      wb.Sheets.leavereport.A1 = {
        t: "s",
        v: `Export Leave Report as of   ${unity.formatDate(this.datasearch.datefrom)} to  ${unity.formatDate(this.datasearch.dateto)}`,
      };

      wb.Sheets.leavereport.B1 = { t: "s", c: 0, v: "" };
      wb.Sheets.leavereport.C1 = { t: "s", c: 0, v: "" };
      wb.Sheets.leavereport.D1 = { t: "s", c: 0, v: "" };
      wb.Sheets.leavereport.E1 = { t: "s", c: 0, v: "" };
      wb.Sheets.leavereport.F1 = { t: "s", v: "" };


      wb.Sheets.leavereport.A3 = { t: "s", v: "Employee Name" };
      wb.Sheets.leavereport.B3 = { t: "s", v: "Leave Type" };
      wb.Sheets.leavereport.C3 = { t: "s", v: "date time from" };
      wb.Sheets.leavereport.D3 = { t: "s", v: "date time to" };
      wb.Sheets.leavereport.E3 = { t: "s", v: "Amount" };
      wb.Sheets.leavereport.F3 = { t: "s", v: "Description" };


      wb.Sheets.leavereport["!merges"] = merge;
    },

    async loadLeaveType(){
      const result = await api.getLeaveTypeAll(Number(localStorage.getItem(server.COMPANYID)));
      this.leave_type_list = result.data;
    },

    async loadEmp() {
      const result = await api.getAccountslist_by_ComID(
        localStorage.getItem(server.COMPANYID)
      );

      
      if(localStorage.getItem(server.ROLE) == "EMPLOYEE"){
        result.data.filter((x,index)=>{
          if(x.id ==  localStorage.getItem(server.USER_ID)){
            this.emp_list =x;
            this.datasearch.employeename = x.id;
            this.employeeisdisable = true;
          }
        })
      }else{
        this.emp_list = result.data;
        this.employeeisdisable = false;
      }
    },
    async loadJobtitle() {
      const result = await api.getAllJobtitleByCompany(
        localStorage.getItem(server.COMPANYID)
      );

      this.jobtitle_list = result.data;
    },
    async loadCustomer() {
      const result = await api.getAllCustomerListByCompany(
        localStorage.getItem(server.COMPANYID)
      );
      this.customer_list = result.data;
    },
    async loadCompany() {
    let copany_datat = await api.getCompanyByID(localStorage.getItem(server.COMPANYID));
    this.dataformatpr.title = copany_datat.data[0].name;
    },
    async loadDepartmentByCompanyId() {
      let result = await api.getAllDepartment_By_CompanyID(this.mycompany_id);
      this.department_list = result.data;
    },
    findChaege(chargetype) {
      this.charge_list.filter((item, index) => {
        if (item.value == chargetype) {
          this.showchargetype = item.name;
          return;
        }
      });
    },
    async OnSearchDepartment() {
      // console.log(this.datasearch.department);
    },
    async SearchAlldata() {
      this.datashowAll = [];
      this.mDataArray = [];
      // if (!this.datasearch.chargetype) {
      //   this.$store.state.global_dialog = true;
      //   this.setupAlertDialog(
      //     true,
      //     "ค้นหาข้อมูล",
      //     "กรุณาเลือก ChargeType",
      //     "text-h5 red--text text-center"
      //   );
      //   return;
      // }
      if (!this.datasearch.datefrom) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "ค้นหาข้อมูล",
          "กรุณาเลือก DateFrom",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (!this.datasearch.dateto) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "ค้นหาข้อมูล",
          "กรุณาเลือก DateTo",
          "text-h5 red--text text-center"
        );
        return;
      }
      this.lengthAlldatasearch = 0;
      this.datashowAll = [];
      let resultsearch = await api.searchleavereport({
        params: this.datasearch,
      });
      console.log(resultsearch)
      console.log(resultsearch.data)
      if (resultsearch.data.length == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "ค้นหาข้อมูล",
          "ไม่มีข้อมูล",
          "text-h5 red--text text-center"
        );
        return;
      }else{
        console.log(resultsearch.data)
        this.dataforshowprpo = resultsearch.data;
          
        
        this.dataforshowprpo.forEach(async(x,index) => {
            x.forEach(i=>{
              if(i.summarize !== true){
                this.mDataArray.push(i);
              }
            })
            
           
            this.dataforshowprpo = x
            await this.checkcontent(this.dataforshowprpo);
        })
        await this.setexporttoxlsx();
      }

     
    },
    async setexporttoxlsx() {
      let setexportxlsx = [{}, {}];
      this.mDataArray.forEach((x, index) => {
        setexportxlsx.splice(index + 2, 0, {
          employee: x.fullnameth,
          leave_type: x.leave_type_name,
          date_time_from_show: x.date_time_from_show,
          date_time_to_show: x.date_time_to_show,
            leave_time: x.leave_time,
            description: x.description,
        });
      });
      this.sheets[0].data = setexportxlsx;
    },
    
    formatDatetomonthdayyear(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${month.padStart(2, "0")}/${day.padStart(2, "0")}/${year}`;
    },
    formatDateday(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    changeformatDatetoinsert(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    async checkcontent(data) {
      let totalclient = 0;
      // console.log("send data ====");
      // console.log(this.dataforshowprpo);
      // alert(data.length);
      this.setdatatoshow = [];
      let linedetailprpo = 30;
      let datainlineprpo = 27;
      let addnewbutget = 1;
      let getdata = [];
      let getnewdata = [];
      let hllinedetails = 0;
      this.pageAll = 1;
      let newhllinedetails = 0;
      for (let i = 0; i < this.dataforshowprpo.length; i++) {
        let stringchecklength = "";
        let lengthofdataforshowprpo = this.dataforshowprpo[i].description.length;

        let maiek = "่"; //ไม้เอก
        let maito = "้"; //ไม้โท
        let maitree = "๊"; //ไม้ตรี
        let maijattawa = "๋"; //ไม้จัตวา
        let vowelei = "ิ"; //สระอิ
        let vowelee = "ี"; //สระอี
        let voweleue = "ึ"; //สระอึ
        let voweleu = "ื"; //สระอือ
        let vowelu = "ุ"; //สระอุ
        let voweluu = "ู"; //สระอู
        let maihun = "ั"; //ไม้หันอากาศ
        let maiyamok = "็"; //ไม้เอก
        newhllinedetails = 0;
        getnewdata = [];
        this.dataforshowprpo[i].no = i + 1;
        totalclient += parseFloat(
          this.dataforshowprpo[i].duration
            ? this.dataforshowprpo[i].duration
            : 0
        );
        if (i == 0) {
          this.checkdepartmentname = this.dataforshowprpo[i].userId;
          this.dataforshowprpo[i].firstpeople = true;
          // this.dataforshowprpo[i].changedepartment = true;
        } else {
          if (
            this.checkdepartmentname !== this.dataforshowprpo[i].userId
          ) {
            this.dataforshowprpo[i].changedepartment = true;
            this.checkdepartmentname = this.dataforshowprpo[i].userId;
            hllinedetails++;
          }
        }
        stringchecklength = this.dataforshowprpo[i].description;

        // let myArrayname = this.dataforshowprpo[i].NAME.split("\n");
        // for (let a = 0; a < myArrayname.length; a++) {
        //   let lengthnewline = myArrayname[a].length;
        //   for (let b = 0; b < myArrayname[a].length; b++) {
        //     if (
        //       myArrayname[a][b] == maiek ||
        //       myArrayname[a][b] == maito ||
        //       myArrayname[a][b] == maitree ||
        //       myArrayname[a][b] == maijattawa ||
        //       myArrayname[a][b] == vowelei ||
        //       myArrayname[a][b] == vowelee ||
        //       myArrayname[a][b] == voweleue ||
        //       myArrayname[a][b] == voweleu ||
        //       myArrayname[a][b] == vowelu ||
        //       myArrayname[a][b] == voweluu ||
        //       myArrayname[a][b] == maihun ||
        //       myArrayname[a][b] == maiyamok
        //     ) {
        //       lengthnewline--;
        //     }

        //   }

        for (let j = 0; j < this.dataforshowprpo[i].description.length; j++) {
          if (
            stringchecklength[j] == maiek ||
            stringchecklength[j] == maito ||
            stringchecklength[j] == maitree ||
            stringchecklength[j] == maijattawa ||
            stringchecklength[j] == vowelei ||
            stringchecklength[j] == vowelee ||
            stringchecklength[j] == voweleue ||
            stringchecklength[j] == voweleu ||
            stringchecklength[j] == vowelu ||
            stringchecklength[j] == voweluu ||
            stringchecklength[j] == maihun ||
            stringchecklength[j] == maiyamok
          ) {
            lengthofdataforshowprpo--;
          }
        }
        newhllinedetails = Math.ceil(lengthofdataforshowprpo / datainlineprpo);

        // newhllinedetails = Math.ceil(
        //   this.dataforshowprpo[i].NAME.length / datainlineprpo
        // );

        // newhllinedetails += addnewbutget;
        getnewdata.push(this.dataforshowprpo[i]);
        // console.log(getdata.length);
        // console.log(hllinedetails + newhllinedetails + getdata.length);
        // console.log(hllinedetails);
        // console.log(newhllinedetails);
        // console.log(getdata.length);
        // console.log("=================================");
        if (hllinedetails + newhllinedetails > linedetailprpo || this.dataforshowprpo[i].changedepartment == true) {
          console.log(i + " ============== " + this.dataforshowprpo.length);
          this.setdatatoshow[this.pageAll - 1] = getdata;
          this.pageAll++;

          hllinedetails = 0;
          getdata = [];
          getdata.push(this.dataforshowprpo[i]);
          hllinedetails += newhllinedetails;
          if (i == this.dataforshowprpo.length - 1) {
            this.dataforshowprpo[i].totalclient = totalclient.toFixed(2);
            this.setdatatoshow[this.pageAll - 1] = getdata;
            getdata = [];
          }
        } else {
          hllinedetails += newhllinedetails;

          getdata.push(this.dataforshowprpo[i]);

          if (i == this.dataforshowprpo.length - 1) {
            this.dataforshowprpo[i].totalclient = totalclient.toFixed(2);
            this.setdatatoshow[this.pageAll - 1] = getdata;
            getdata = [];
          }
        }
      }
      this.pageAll += this.lengthAlldatasearch - 1;
      this.opensummarize = true;
      // console.log(this.pageAll);
      // console.log("showwwwwwwwwwwwwwwwwwwww");
      // console.log(this.setdatatoshow);

      this.setdatatoshow.forEach((item, iitem) => {
        // console.log(item);
        // console.log(iitem + " " + this.setdatatoshow[0].length);
        if (iitem == this.setdatatoshow.length - 1) {
          item[this.setdatatoshow[iitem].length - 1].lastdata = "lastindex";
        }

        this.datashowAll.push(item);
      });
      // console.log(this.datashowAll);
    },
    async exportToPDF(advance_id) {
      // this.$refs.html2Pdf.generatePdf();
      let element = document.getElementById("mydivhtmltobase");

      const opt = {
        margin: [0, 0, 0, 0],
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        // enableLinks: false,
        html2canvas: {
          scale: 2,
          dpi: 192,
          letterRendering: true,
          useCORS: true,
          // ignoreElements: (e) => {
          //   return e.classList.contains("cke_pagebreak") ||
          //     e.classList.contains("html2pdf__page-break")
          //     ? true
          //     : false;
          // },
        },
        jsPDF: {
          unit: "cm",
          format: "A4",
          orientation: "portrait",
          putOnlyUsedFonts: true,
          pagesplit: true,
        },
        // pagebreak: { mode: ["avoid-all"], after: ".cke_pagebreak" },
      };

      let abcd = await html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get("pdf")
        // .output("bloburl")
        .then(function(pdf) {
          console.log(pdf);
          const totalPages = pdf.internal.getNumberOfPages();
          pdf.deletePage(totalPages);
          // var filtered = pdf.internal.pages.filter(function(el) {
          //   return el != null;
          // });

          // pdf.internal.pages = filtered;
          // for (let i = 0; i < totalPages; i++) {
          //   if (i == totalPages - 1) {
          //     pdf.internal.pages.splice(i, 1);
          //   }
          // }
          // pdf.addPage();

          // console.log(pdf);
          return pdf.output("bloburl");
        });
      // console.log(abcd);
      window.open(abcd);
      let blob = await fetch(abcd).then((r) => r.blob());
      // console.log(blob);
      // let formData = new FormData();
      // formData.append(`file`, blob, "test.pdf");
      // const resultadd = api.creatememoMaster(formData);
      // console.log(resultadd);

      // };
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
  },
  beforeCreate() {
    this.$store.state.navMenu = true;
  },
};
