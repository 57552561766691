//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server, imageUrl } from "@/services/constants";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import html2pdf from "html2pdf.js";
import VueHtml2pdf from "vue-html2pdf";
import unity from "@/unity/unity";

export default {
  name: "approve-leave-confirm",
  components: { SuccessDialogPush, VueHtml2pdf },
  data(vm) {
    return {
      day_select: "multiple",
      leave_hours: [
        { text: "1 hour", value: 1 },
        { text: "2 hours", value: 2 },
        { text: "3 hours", value: 3 },
        { text: "Half day", value: 4 },
        { text: "5 hours", value: 5 },
        { text: "6 hours", value: 6 },
        { text: "7 hours", value: 7 },
        { text: "Full day", value: 8 },
      ],
      menu_start: false,
      menu_end: false,
      leave_start_hours: "",
      leave_end_hours: "",
      dialogConfirm: false,
      approve_end_flow_status: false,
      approve_detail_data: [],
      imageshowpopup: "",
      fullName: "",
      employee_id: "",
      dialogDelete: false,
      dialogSave: false,
      dialogReject: false,
      preview: null,
      image: null,
      preview_list: [],
      oldpreview_list: [],
      image_list: [],
      menu: false,
      menu2: false,
      search: "",
      toggleEnable: false,
      selectstatus: "",
      dropdownLeave: [],
      dataAll: [],
      mDataArray: [],
      amountShow: "",
      headers: [
        {
          text: "Period",
          value: "period",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Leave Type",
          value: "leave_type",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Quota (Day)",
          value: "quota_day",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Used",
          value: "user_day",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Balance (Day)",
          value: "balance",
          class: "bg-colorth tabletextwhite",
        },
      ],
      leave_data: {
        leaveType: 0,
        leaveTypeId: 0,
        approve_id_now: 0,
        startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        description: "",
        description_approve: "",
        userId: 0,
        departmentId: 0,
        companyId: 0,
        amount: 0,
        images: null,
        lq_id: 0,
      },
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
    };
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },
  computed: {
    computedDateFromFormatted() {
      return unity.formatDate(this.leave_data.startDate);
    },
    computedDateToFormatted() {
      return unity.formatDate(this.leave_data.endDate);
    },
  },
  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
  async mounted() {
    await api.checkVersion();
    this.$store.state.global_dialog_push = false;
    this.$store.state.global_dialog = false;

    console.log("mounted");
    this.userId = localStorage.getItem(server.USER_ID);
    console.log("mounted2222");
    console.log("userId:" + this.userId);

    await this.loaddataLeaveUsed(this.$route.params.employee_id);
    await this.loadLeaveApproveDetail();

    this.$hideLoader();
  },

  methods: {
    async loadLeaveApproveDetail() {
      // const res = await api.getListApproveDetailByRequestId(
      const res = await api.getListApproveDetailNoZeroIdByRequestId(
        this.$route.params.id
      );

      this.approve_detail_data = res.data;
      // console.log(
      //   "approve_detail_data",
      //   JSON.stringify(this.approve_detail_data)
      // );
    },
    exportToPDF() {
      this.$refs.html2Pdf.generatePdf();
    },
    async onDialogSave() {
      this.dialogSave = true;
    },
    async onDialogReject() {
      this.dialogReject = true;
    },
    async onSave() {
      this.dialogSave = false;

      var count_approve = 0;
      var user_approve_id = 0;
      var approve_id = 0;
      var user_approve_id_next = 0;
      var now_index = 0;
      var next_index = 0;
      var next_status = false;
      var flow_end_status = false;

      count_approve = this.approve_detail_data.length;

      this.approve_detail_data.forEach(async (item, index) => {
        if (!next_status) {
          if (item.status == 1) {
            console.log("count_approve:", "item.status == 1");
            now_index = index;
            next_index = index + 1;
            next_status = true;
            user_approve_id = item.approve_id;
            approve_id = item.id;
            console.log("count_approve item.approve_id:", item.approve_id);
          }
        }
      });
      //   approve_id = this.approval_status[next_index].approve_id;

      if (now_index == count_approve - 1) {
        flow_end_status = true;
      } else {
        user_approve_id_next = this.approve_detail_data[next_index].approve_id;
      }

      console.log("count_approve now_index:", now_index);
      console.log("count_approve next_index:", next_index);

      console.log("count_approve count_approve:", count_approve);
      console.log("count_approve flow_end_status:", flow_end_status);
      console.log("count_approve user_approve_id_next:", user_approve_id_next);

      this.leave_data.userId = localStorage.getItem(server.USER_ID);

      var save_data = {
        leave_id: this.$route.params.id,
        approve_detail_id: approve_id,
        user_approve_id: user_approve_id,
        user_approve_id_next: user_approve_id_next,
        flow_end_status: flow_end_status,
        approval_remark: this.leave_data.description_approve,
      };

      console.log("count_approve save_data:", JSON.stringify(save_data));

      // var body = {
      //   status: status,
      //   leave_type_id: this.leave_data.leaveTypeId,
      //   employee_id: this.employee_id,
      //   userId: localStorage.getItem(server.USER_ID),
      //   description_approve: this.leave_data.description_approve,
      // };

      this.$showLoader();

      // const res_h = await api.updateApprove(this.$route.params.id, body);
      const res_save = await api.saveApproval(save_data);
      // if (res_h.status == 200 || res_h.status == 201) {
      if (res_save.data.save_sta) {
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "Information Saved",
          "Information successfully saved",
          "text-h5 green--text text-center",
          "/approver-leave"
        );
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "บันทึกข้อมูลไม่สำเร็จ",
          "text-h5 red--text text-center"
        );
      }
      this.$hideLoader();
    },
    async onReject() {
      this.dialogReject = false;

      console.log("saveReject:");

      var request_master_id = this.$route.params.id;

      var save_data = {
        request_master_id: parseInt(request_master_id),
        user_action: parseInt(localStorage.getItem(server.USER_ID)),
        approval_remark: this.leave_data.description_approve,
      };

      this.$showLoader();

      const res_save = await api.saveReject(save_data);

      this.$hideLoader();

      if (res_save.data.save_sta) {
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "Information Saved",
          "Information successfully saved",
          "text-h5 green--text text-center",
          "/approver-leave"
        );
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Failed",
          "Save data Failed",
          "text-h5 red--text text-center"
        );
      }
    },

    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    saveItemConfirm() {
      this.dialogSave = true;
    },
    closeSave() {
      this.dialogSave = false;
    },
    closeReject() {
      this.dialogReject = false;
    },
    deleteItemConfirm(imageurl) {
      // this.indeximage = index;
      this.imageshowpopup = imageurl;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.indeximage = null;
      this.dialogDelete = false;
    },
    async removeimage(index) {
      this.preview_list.splice(this.indeximage, 1);
      this.image_list.splice(this.indeximage, 1);

      this.dialogDelete = false;
    },
    async loaddataLeaveUsed(id) {
      const result = await api.getListUseByUserIDV2byCompany(
        id,
        localStorage.getItem(server.COMPANYID)
      );
      // this.dataAll = result.data;

      // console.log("loaddataLeaveUsed result : ", JSON.stringify(result.data));

      var data = [];

      result.data.forEach((item) => {
        var obj = {
          leave_type_id: 1,
          use_leave: "0.0625",
          leave_period_from: "2021-12-31T17:00:00.000Z",
          leave_period_to: "2022-12-30T17:00:00.000Z",
          leave_type: "ลาป่วย",
          quota_day: "30.0000",
          user_day: "0.0625",
          balance: "29.9375",
        };

        obj.use_leave = item.use_leave;
        obj.leave_period_from = item.leave_period_from;
        obj.leave_period_to = item.leave_period_to;
        obj.leave_type = item.leave_type;
        obj.period = item.period;
        // obj.quota_day = item.quota_day;
        // obj.quota_day = this.quotaListConvert(item.quota_day);
        obj.quota_day = this.quotaListConvert(item.quota_day);
        obj.user_day = this.quotaListConvert(item.user_day);
        obj.balance = this.quotaListConvert(item.balance);
        // obj.balance = item.balance;
        // obj.balance = this.quotaListConvert(item.balance);

        data.push(obj);
      });

      this.dataAll = data;

      // console.log("dateAll:")
      //   console.log(this.dataAll)
      // console.log("loadLeave Used : ", JSON.stringify(this.dataAll));

      const result_dropsown = await api.getListByUserIDV3byCompany(
        id,
        localStorage.getItem(server.COMPANYID)
      );
      this.dropdownLeave = result_dropsown.data;

      // console.log(
      //   "getListByUserIDV3byCompany:",
      //   JSON.stringify(result_dropsown.data)
      // );

      // this.$showLoader();
      const res_data = await api.getListApproveByRequestID(
        this.$route.params.id
      );

      console.log(res_data.data)
      console.log("res_data.data")
      this.mDataArray = res_data.data;
      // console.log("getListApproveByRequestID:", JSON.stringify(res_data.data));


      this.mDataArray.forEach((x, index) => {
        this.leave_data.leaveTypeId = x.leaveTypeId;
        this.leave_data.approve_id_now = x.approve_id_now;
        this.leave_data.startDate = x.startDateS;

        this.day_select = x.type_count;

        this.leave_start_hours = x.startHour;
        this.leave_end_hours = x.endHour;

        this.leave_data.endDate = x.endDateS;
        this.leave_data.description = x.description;
        this.leave_data.amount = x.amount;
        this.leave_data.leaveType = x.leaveType;
        this.leave_data.lq_id = x.lq_id;

        if (x.image == "" || x.image == null) {
          this.leave_data.images = imageUrl + "/personal.png";
        } else {
          this.leave_data.images = imageUrl + "/" + x.image;
        }
        // this.leave_data.images = x.image;
        this.fullName = x.fullName;
        this.employee_id = x.employee_id;
        // console.log("x.images:", x.image);
        // console.log("x.amount:", x.amount);

        let setiamggfile = x.preview_image.split(",");
        if (setiamggfile[0] == "") {
        } else {
          setiamggfile.forEach((x) => {
            this.oldpreview_list.push(`${imageUrl}/${x}`);
          });
        }
      });
      this.amountShow = this.quotaListConvert(this.leave_data.amount / 8);
      // console.log("oldpreview_list:", JSON.stringify(this.oldpreview_list));

      console.log("this.leave_data")
      console.log(this.leave_data)

      // this.$hideLoader();
    },
    quotaListConvert: function(H) {
      var d = 0;
      var h = 0;
      var m = 0;
      var tmp1 = parseFloat(H * 480);
      var tmp_final;

      var tmp_d = tmp1 / 480;

      d = Math.floor(tmp_d);
      h = Math.floor((tmp_d - d) * 8);
      m = Math.floor(((tmp_d - d) * 8 - h) * 60);

      tmp_final = `${d} Day ${h} Hour`;

      return tmp_final;
    },

    previewMultiImage: function(event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          };
          if (input.files[index].type.split("/")[0] === "image") {
            this.image_list.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
      console.log(this.image_list);
    },
  },
};
