//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server, POAUrl } from "../services/constants";

export default {
  async mounted() {
    await this.loadCompany();

    this.$hideLoader();
    this.$store.state.navMenu = false;
    this.$store.state.isLogged = false;
    const userId = localStorage.getItem(server.USER_ID);

    // console.log("Login mounted : " + api.isLoggedIn());
    // console.log("Login mounted : " + userId);
    if (userId && api.isLoggedIn()) {
      this.$router.push("/");
    }
  },
  components: {
    SuccessDialog,
  },
  data() {
    return {
      isShowPassword: false,
      account: {
        company_code:"",
        username: "",
        password: "",
        keep_me_logged_in:false,
      },
      fullPage: true,
      dialog: false,
      companyAll: [],
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
    };
  },
  methods: {
    async previewnoticefilepdf(file_name) {
            let pdf_newTab = window.open(
                `${POAUrl}/file_login/DTime_Poster.pdf`
            );

              return;
         },
    goWeb() {
      this.dialog = false;
      this.$router.push("/dashboard");
    },
    changeCompany(val) {
      // console.log("changeCompany:", val);
      localStorage.setItem(server.COMPANYID, val);
    },
    async loadCompany() {
      var result = await api.getAllCompany();
      this.companyAll = result.data;
    },
    submit() {
      // this.$notify({
      //   group: "auth",
      //   type: "warn",
      //   title: "<h1>Login Failed</h1>",
      //   text: "<h3>Please check your username and password</h3>",
      //   duration: 1000,
      //   speed: 500,
      //   width: "100%",
      // });

      this.$showLoader();

      // this.$router.push("/dashboard");
      this.$store
        .dispatch({
          type: "doLogin",
          company_code: this.account.company_code,
          username: this.account.username,
          password: this.account.password,
          
        })
        .then((val) => {
          // console.log("Login success : " + this.$store.getters["isLogin"]);
          this.$hideLoader();
          if (this.$store.getters["isLogin"]) {
            if (localStorage.getItem(server.ROLE) == "ADMIN") {
              this.dialog = true;
            } else {
              this.$router.push("/dashboard");
            }
          }
          // this.$router.push("/stock");
        })
        .catch((error) => {
              this.$hideLoader();
              this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Login Fail",
                    "Incorrect Company Code or Username or Password",
                    "text-h5 red--text text-center"
              );
              return;
        });
    },
    forgotPassword() {
      // alert("Forgot Password");
      this.$router.push("/forgot-password");
    },
    onCancel() {
      // console.log("User cancelled the loader.");
    },
    setupAlertDialog(status, title, message, text_color) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
      },
  },
};
