//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import api from "@/services/api";
import { server,INVUrl } from "@/services/constants";
import InvoiceSalseorderTab from "./InvoiceSalseorderTab";
import InvoiceTimesheetTab from "./InvoiceTimesheetTab";
import InvoiceAdvanceTab from "./InvoiceAdvanceTab";
import InvoiceMiscellaneousTab from "./InvoiceMiscellaneousTab";
import AdditionalNoteTab from "./AdditionalNoteTab";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "../computing/tolocalestringnumber";
import PrintInvoiceDejTab from "./PrintInvoiceDejTab";
import PrintInvoiceStandardTab from "./PrintInvoiceStandardTab";
var convert = require("decimal-number-to-words");
import html2pdf from "html2pdf.js";
import unity from "@/unity/unity";
import pdfvuer from "pdfvuer";
const moment = require('moment-timezone');
export default {
  async beforeMount() {
    this.$showLoader();
    this.caltotalAll();
    if (this.$route.params.mode == "invoice") {
      this.titleheader = "Invoice";
      this.add_h.inv_type = "I";
      this.callsoopentab = true;
    } else if (this.$route.params.mode == "performa") {
      this.titleheader = "Proforma Invoice";
      this.add_h.inv_type = "P";
      this.callsoopentab = true;
    } else {
      this.getdatafrominv_h = true;
      this.loaddatainv();
    }
    this.$hideLoader();
  },
  async mounted() {
    this.$showLoader();
    this.caltotalAll();
    // this.$showLoader();
    if (this.$route.params.mode == "invoice") {
      this.titleheader = "Invoice";
      this.add_h.inv_type = "I";
      this.add_h.company_inv = this.$route.params.company_inv;
      this.callsoopentab = true;
    } else if (this.$route.params.mode == "performa") {
      this.titleheader = "Proforma Invoice";
      this.add_h.inv_type = "P";
      this.add_h.company_inv = this.$route.params.company_inv;
      this.callsoopentab = true;
    } else {
      this.getdatafrominv_h = true;
      await this.loaddatainv();
    }

    this.$hideLoader();
  },
  components: {
    SuccessDialog,
    SuccessDialogPush,
    InvoiceSalseorderTab,
    InvoiceTimesheetTab,
    InvoiceAdvanceTab,
    InvoiceMiscellaneousTab,
    AdditionalNoteTab,
    PrintInvoiceDejTab,
    PrintInvoiceStandardTab,
  },
  computed: {
    version() {
      return this.initial_data;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    computedDateFormatted() {
      return unity.formatDate(this.date);
    },
  },
  data() {
    return {
      getcompany_id: localStorage.getItem(server.COMPANYID),
       check_cus_name: false,
    preview_list2: [],
              getpreview_list: [],
              image: null,
              previewimage: null,
              previewimageedit: null,
              image_list: [],
              preview_list: [],
              dialogpreviewimage: false,
              checkfileapreview: false,
              previewpopupfile: [],
              previewpopupfile2: [],
              dialogpreviewfile: false,
              dialogDelete: false,
              dialogDelete_old: false,
              dialogDeleteimage: false,
              filename: [],
              filename_old: [],
              imagename: "",
              load_bg: false,
              checkfileadd: false,
              checkimageadd: false,
              old_index: -1,
              //--
      mode_oper: "edit",
      po_no_cus: "",
      attention_cus: "",
      contact_position_cus:"",
      contact_position:"",
      vat_type:"",
      vat_type_cus:"",
      vat_cus: 0,
      payment_term_cus: 0,
      selected_showE: [],
      oper_sel: 1,
      selected_showAv: [],
      oper_sel_Av: 1,
      callsoopentab: false,
      dialogDeleteForm: false,
      datainv_disable: false,
      showinv_id: "####",
      menu2: false,
      desserts: [],
      getdatefrom_inv_so: [],
      getdatafrominv_h: false,
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      addinv_docref_so: {
        inv_hid: 0,
        ref_type: "SO",
        ref_id: 0,
      },
      addinv_docref_ts: {
        inv_hid: 0,
        ref_type: "TS",
        ref_id: 0,
      },
      addinv_docref_ad: {
        inv_hid: 0,
        ref_type: "AD",
        ref_id: 0,
      },
      addinv_so: {
        inv_hid: 0,
        so_seq: 0,
        item_id: "",
        item_name: "",
        item_qty: 0,
        item_um: "",
        item_price: 0,
        item_disc: 0,
        item_line_amt: 0,
        user_create: localStorage.getItem(server.USER_ID),
      },
      getdatefrom_inv_h: {
        customerid: 0,
        customer_code: "",
        customer_name: "",
        customer_address: "",
        customer_taxid: "",
        customer_branch: "",
        customer_paymentterm: 0,
        customer_vat: 0,
        vat_type:"",
        saleorder_id: "",
        currency: "",
      },
      period_cover: "",
      wth_rate: 0,
      tax_rate: 0,
      wth_ratetotal: 0,
      tax_ratetotal: 0,
      amount_total: 0,
      invoice_address: "",
      so_total: this.$store.state.inv_so_total,
      ts_total: 0,
      ad_total: this.$store.state.inv_advance_total,
      mis_total: this.$store.state.inv_miscellaneous_total,
      inv_subtotal: 0,
      titleheader: "Invoice",
      activetab: 1,
      isShowPassword: false,
      fullPage: true,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      add_h: {
        inv_id: "",
        pinv_id: "",
        company_id: localStorage.getItem(server.COMPANYID),
        inv_date: "",
        period_cover: "",
        inv_type: "",
        customerid: 0,
        customer_address: "",
        customer_taxid: "",
        customer_branch: "",
        customer_paymentterm: 0,
        customer_vat: 0,
        vat_type:"",
        so_total: 0,
        ts_total: 0,
        ad_total: 0,
        mis_total: 0,
        sub_total: 0,
        wth_rate: 0,
        tax_rate: 0,
        amount_total: 0,
        company_inv: "",
        so_ref: 0,
        additional_top: "",
        additional_bottom: "",
        po_no: "",
        attention: "",
        user_create: localStorage.getItem(server.USER_ID),
      },
      disableinstatus: true,
      ts_status_oper: "draft",
      tsTAB_IdforCus: this.$route.params.id,
      getdataByCusStatus: "",
      ts_id: [],
      ad_id: [],
      ts_idTab: [],
      ad_idTab: [],
      ts_idTab: 0,
      datainv_mis: [],
      ad_inv_id: [],
      id_invoice: 0,
      print_header_list: [
      {
        id: "1",
        com_title: "STATEMENT",
        com_name:"",
        inv_company_name_th:"",
        inv_company_address:"",
        inv_company_branch: "",
        inv_company_tel: "",
        inv_company_email: "",
        inv_tax_id: "",
        com_eng_1: "DEJ-UDOM & ASSOCIATES LTD.",
        com_eng_2: "ATTORNEYS AT LAW",
        com_thai: "บริษัท เดชอุดม แอนด์ แอสโซซิเอทส์ จำกัด",
        com_add_name: "CHARN ISSARA TOWER 1, 2nd FLOOR,",
        com_add_no: "942/69 RAMA IV ROAD,",
        com_add_city: "KWAENG SURIYAWONG, KHET BANGRAK,",
        com_add_country: "BANGKOK 10500, THAILAND",
        com_add_tel: "Tel : 0-2233-0055, 0-2267-5152",
        com_add_fax: "Fax : 0-2236-6681, 0-2233-0227",
        com_add_email: "E-MAIL : account@dejudom.com",
        com_add_tax_id: `TAX ID. No. 0105534015348/"HEAD OFFICE"`,
      },
      {
        id: "2",
        com_title: "STATEMENT",
        com_name:"",
        inv_company_name_th:"",
        inv_company_address:"",
        inv_company_branch: "",
        inv_company_tel: "",
        inv_company_email: "",
        inv_tax_id: "",
        com_eng_1: "DEJ-UDOM & ASSOCIATES",
        com_eng_2: "ATTORNEYS AT LAW",
        com_thai: "สำนักกฎหมาย เดชอุดม แอนด์ แอสโซซิเอทส์",
        com_add_name: "CHARN ISSARA TOWER 1, 2nd FLOOR,",
        com_add_no: "942/69 RAMA IV ROAD,",
        com_add_city: "KWAENG SURIYAWONG, KHET BANGRAK,",
        com_add_country: "BANGKOK 10500, THAILAND",
        com_add_tel: "Tel : 0-2233-0055, 0-2267-5152",
        com_add_fax: "Fax : 0-2236-6681, 0-2233-0227",
        com_add_email: "E-MAIL : account@dejudom.com",
        com_add_tax_id: `TAX ID. No. 0992000028227/"HEAD OFFICE"`,
      },
    ],
      print_header: {},
      print_table_detail: [],
      pageAll: 1,
      opensummarize: false,
      datashowAll: [],
      setdatatoshow: [],
      dataforshowprpo: [],
      getheightcontentpr: 0,
      opensummarizetimesheet: false,
      pageAlltimesheet: 1,
      setdatatoshowtimesheet: [],
      print_table_detailtimesheet: [],
      datashowAlltimesheet: [],
      print_detail_timekeeper: [],
      datashowAlltimekeeper: [],
      showprint: "display:none;",
      haveSO: false,
      haveAD: false,
      indexinvADMIS: false,
      showcurrency: "",
      dialogSelectTitleData: "1",
      dialogSelectTitle: false,
      dialogconverttoinv: false,
      inv_ts_list: [],
      checklawoffice:false,
      heighttabledetail:"546px",
    };
  },
  methods: {
    async exportToPDF(advance_id) {
      // this.$refs.html2Pdf.generatePdf();

      this.showprint = "";
      let element = document.getElementById("mydivhtmltobase");
      const opt = {
        margin: [0, 0, 0, 0],
        image: {
          type: "jpeg",
          quality: 0.98,
        },
        // enableLinks: false,
        html2canvas: {
          scale: 2,
          dpi: 192,
          letterRendering: true,
          useCORS: true,
          // ignoreElements: (e) => {
          //   return e.classList.contains("cke_pagebreak") ||
          //     e.classList.contains("html2pdf__page-break")
          //     ? true
          //     : false;
          // },
        },
        jsPDF: {
          unit: "cm",
          format: "A4",
          orientation: "portrait",
          putOnlyUsedFonts: true,
          pagesplit: true,
        },
        // pagebreak: { mode: ["avoid-all"], after: ".cke_pagebreak" },
      };

      let abcd = await html2pdf()
        .from(element)
        .set(opt)
        .toPdf()
        .get("pdf")
        // .output("bloburl")
        .then(function(pdf) {
         
          const totalPages = pdf.internal.getNumberOfPages();
          pdf.deletePage(totalPages);
          return pdf.output("bloburl");
        });
      
      window.open(abcd);
      let blob = await fetch(abcd).then((r) => r.blob());
      this.showprint = "display:none;";

      return;
    },
    async openconverttoinv() {
      this.dialogconverttoinv = true;
    },
    async confirmconverttoinv() {
      const result_converttoinv = await api.updateInvoice_H_Geninv_id(
        this.$route.params.id,
        { 
          inv_type: "I", 
          company_id: this.add_h.company_id,
          inv_date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD')
        }
      );
      if (
        result_converttoinv.status == 200 ||
        result_converttoinv.status == 201
      ) {
        this.$hideLoader();
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "Convert to Invoice",
          "Convert to Invoice successfully",
          "text-h5 green--text text-center",
          `/new-invoice/${this.$route.params.id}/inv_h/edit`
        );
      } else {
        this.$hideLoader();
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Convert to Invoice",
          "Convert to Invoice Failed ",
          "text-h5 red--text text-center"
        );
      }
      this.dialogconverttoinv = false;
    },
    async getselecttitle() {
      if(localStorage.getItem(server.COMPANYID) == "1" || localStorage.getItem(server.COMPANYID) == 1){
        this.dialogSelectTitleData = "1";
        this.dialogSelectTitle = true;
  }else{
    await this.getPrintOutData();
  }
     
    },
    async toprintfromselecttitle() {
      if (this.dialogSelectTitleData == "1") {
        this.print_header_list[0].com_title = "STATEMENT";
        this.print_header_list[1].com_title = "STATEMENT";
      } else if (this.dialogSelectTitleData == "2") {
        this.print_header_list[0].com_title = "INVOICE";
        this.print_header_list[1].com_title = "INVOICE";
      } else if(this.dialogSelectTitleData == "3"){
        this.print_header_list[0].com_title = "Proforma Invoice";
        this.print_header_list[1].com_title = "Proforma Invoice";
      } else {
        this.print_header_list[0].com_title = "";
        this.print_header_list[1].com_title = "";
      }

      await this.getPrintOutData();
      this.dialogSelectTitle = false;
    },
    async getPrintOutData() {
      // console.log(this.id_invoice);
      this.inv_id = this.id_invoice;
      // this.showprint = "";
      this.haveSO = false;
      this.haveAD = false;
      this.datashowAll = [];
      this.print_table_detail = [];
      this.print_table_detailtimesheet = [];
      this.print_detail_timekeeper = [];
      this.dialogPrintOut = false;
      // alert(`Print Out Invoice ID: ${this.inv_id}, ${this.dialogPrintOutData}`);
      // if (this.dialogPrintOutData == "1") {
      //   this.print_header = this.print_header_list[0];
      // } else if (this.dialogPrintOutData == "2") {
      //   this.print_header = this.print_header_list[1];
      // }
      // console.log("print_header:", JSON.stringify(this.print_header));
      this.$showLoader();

      await this.loadRef_TypeCNById();
      await this.loadInvSoData();
      // await this.loadMainInvSoData();
      // await this.loadInvADData();
      await this.loadInvDocRefData();
      await this.loadInvTSGetTimekeeper();
      await this.loadInvMISData();
      await this.checkcontent();
      await this.checkcontenttimesheet();
      await this.exportToPDF();
      // this.showprint = "display:none;";
      this.$hideLoader();
    },
    async loadRef_TypeCNById() {
      // const res_docref_so = await api.getRef_TypeCNById(this.inv_id);
      // console.log(res_docref_so.data[0]);
      const res_hresult = await api.getdataByIdInvoice_H(this.inv_id);

      if (res_hresult.data[0].company_inv == "D") {
        this.getcompany_id = 1;
        this.heighttabledetail = "499px";
        this.print_header = this.print_header_list[0];
        this.checklawoffice = false;
      } else if (res_hresult.data[0].company_inv == "L") {
        this.getcompany_id = 1;
        this.heighttabledetail = "529px";
        this.print_header = this.print_header_list[1];
        this.checklawoffice = true;
      }else{
        this.getcompany_id = res_hresult.data[0].company_inv;
        this.print_header.com_name = res_hresult.data[0].com_name;
      this.print_header.inv_company_address = res_hresult.data[0].inv_company_address;
      this.print_header.inv_company_branch = res_hresult.data[0].inv_company_branch;
      this.print_header.inv_company_tel = res_hresult.data[0].inv_company_tel;
      this.print_header.inv_company_email = res_hresult.data[0].inv_company_email;
      this.print_header.inv_tax_id = res_hresult.data[0].inv_tax_id;
      this.print_header.inv_company_name_th = res_hresult.data[0].inv_company_name_th;

      if(res_hresult.data[0].inv_type == 'I'){
        this.print_header.com_title = "INVOICE";
      }else if(res_hresult.data[0].inv_type == 'P'){
        this.print_header.com_title = "Proforma Invoice";
      }else{
        this.print_header.com_title = "";
      }
      
    //   if (this.dialogSelectTitleData == "1") {
    //   this.print_header.com_title = "STATEMENT";
    //   this.print_header.com_title = "STATEMENT";
    // } else if (this.dialogSelectTitleData == "2") {
    //   this.print_header.com_title = "INVOICE";
    //   this.print_header.com_title = "INVOICE";
    // } else if(this.dialogSelectTitleData == "3"){
    //   this.print_header.com_title = "Proforma Invoice";
    //   this.print_header.com_title = "Proforma Invoice";
    // } else {
    //   this.print_header.com_title = "";
    //   this.print_header.com_title = "";
    // }
      }

      this.print_header.docref = res_hresult.data[0].po_no;
      this.print_header.attention = res_hresult.data[0].attention;
      this.print_header.contact_position = res_hresult.data[0].contact_position;
      this.print_header.inv_id = res_hresult.data[0].inv_id
        ? res_hresult.data[0].inv_id
        : res_hresult.data[0].pinv_id;
      this.print_header.customer_name = res_hresult.data[0].customer_name;
      this.print_header.invoice_date = res_hresult.data[0].date_show;
      this.print_header.addressall = res_hresult.data[0].addressall;
      this.print_header.customer_address = res_hresult.data[0].customer_address;

      this.print_header.province_invoice = res_hresult.data[0].province_invoice;
      this.print_header.country_invoice = res_hresult.data[0].country_invoice;
      this.print_header.zipcode_invoice = res_hresult.data[0].zipcode_invoice;

      this.print_header.period_cover = res_hresult.data[0].period_cover;
      this.print_header.customer_contact_name =
        res_hresult.data[0].customer_contact_name;
      this.print_header.customer_taxid = res_hresult.data[0].customer_taxid;
      this.print_header.customer_branch = res_hresult.data[0].customer_branch;

      this.print_header.ts_total = parseFloat(res_hresult.data[0].ts_total);
      this.print_header.sub_total = parseFloat(res_hresult.data[0].sub_total);
      this.print_header.wth_rate = res_hresult.data[0].wth_rate;
      this.print_header.tax_rate = res_hresult.data[0].tax_rate;
      this.print_header.wth_rate_amount = parseFloat(
        (res_hresult.data[0].sub_total * res_hresult.data[0].wth_rate) / 100
      );
      this.print_header.tax_rate_amount = parseFloat(
        (res_hresult.data[0].sub_total * res_hresult.data[0].tax_rate) / 100
      );

    const checker_th = /([\u0E00-\u0E7F]+)/gmu;
    let matches = this.print_header.customer_name.match(checker_th);
    // const checker_th = /([\u0E00-\u0E7F]+)/gmu;
    if(matches){
        this.check_cus_name = false;
    }else{
        this.check_cus_name = true;
    }

    if(this.check_cus_name){
      this.textamount_total_en = convert.toWords(
        res_hresult.data[0].amount_total
      )

      let setcheckscratch =false;
      this.textamount_total_en = this.textamount_total_en.replace(/\b./g, function(m){ 
        if(m == ","){
          return ""; 
        }
        if(m == "-"){
          setcheckscratch = true
          return m; 
        }else{
          if(setcheckscratch){
            setcheckscratch = false
            return m; 
          }
          setcheckscratch = false
          return m.toUpperCase(); 
        }
      });
    }else{
      var result = "";
      // อาเรย์สำหรับแปลงตัวเลขเป็นข้อความไทย
        var thaiNumbers = ['', 'หนึ่ง', 'สอง', 'สาม', 'สี่', 'ห้า', 'หก', 'เจ็ด', 'แปด', 'เก้า'];
        var thaiUnits = ['', 'สิบ', 'ร้อย', 'พัน', 'หมื่น', 'แสน', 'ล้าน'];

        // แยกตัวจำนวนเต็มและทศนิยมห
        res_hresult.data[0].amount_total = Math.round(res_hresult.data[0].amount_total * 100) / 100;
        var [integerPart, decimalPart] = res_hresult.data[0].amount_total.toString().split('.');
        
        // แปลงส่วนทศนิยมเป็นข้อความไทย (สตางค์)
        let  de_check = 0;
          if(decimalPart){
            if(decimalPart.split('').length == 1){
              if(Number(decimalPart) >= 10){
                decimalPart = decimalPart;
              }else if(Number(decimalPart) >0){
                decimalPart = Number(decimalPart) * 10;
              }else{
                de_check = 1;
              }
            }
          }else{
            de_check = 1;
          }
          
          let decimalText = '';
          if(de_check == 0){
            decimalPart = String(decimalPart);

            
            var numArrays = decimalPart.split('').map(Number);
            numArrays.forEach((num, index) => {
              var units = thaiUnits[numArrays.length - index - 1];
              var digits = thaiNumbers[num];
              if (num !== 0) {
                if (num === 1 && index === numArrays.length - 1) { // ตรวจสอบว่าเป็นตำแหน่งสุดท้าย
                  decimalText += 'เอ็ด' + units;
                } else if (num === 2 && index === numArrays.length - 2 && numArrays.length >= 2) {
                  decimalText += 'ยี่' + units;
                } else if (num === 1 && index === numArrays.length - 2 && numArrays.length >= 2) {
                  decimalText += units;
                } else {
                  decimalText += digits + units;
                }
              }
            });

            decimalText = decimalText + "สตางค์";
          }

          // แปลงส่วนจำนวนเต็มเป็นข้อความไทย
          let integerText = '';

          var numArray = integerPart.split('').map(Number);
          numArray.forEach((num, index) => {
            var unit = thaiUnits[numArray.length - index - 1];
            var digit = thaiNumbers[num];
            if (num !== 0) {
                if (num === 1 && index === numArray.length - 1) { // ตรวจสอบว่าเป็นตำแหน่งสุดท้าย
                  integerText += 'เอ็ด' + unit;
                } else if (num === 2 && index === numArray.length - 2 && numArray.length >= 2) {
                  integerText += 'ยี่' + unit;
                } else if (num === 1 && index === numArray.length - 2 && numArray.length >= 2) {
                  integerText += unit;
                } else {
                  integerText += digit + unit;
                }
            }
          });

          integerText = integerText + "บาท";

         

          result = integerText + decimalText;
          this.textamount_total_en = result;
    }
      
      this.print_header.amount_total = parseFloat(
        res_hresult.data[0].amount_total
      );
    },
    async loadInvSoData() {
      var tmp_inv_data;
      const res_inv_so = await api.printInvSO(this.inv_id);
 

      tmp_inv_data = res_inv_so.data;
      if (tmp_inv_data.length > 0) {
        this.haveSO = true;
      }

      tmp_inv_data.forEach((inv_so, index) => {
        var data = {
          report_type: "SO",
          report_desc: "",
          report_amount: 0,
          first_item: 0,
          solastdata: "",
        };
        if (index == tmp_inv_data.length - 1) {
          data.solastdata = "SOlastindex";
        }
        // if (index == 0) {
        //   data.first_item = 1;
        // }

        data.report_desc = inv_so.report_desc;
        data.report_amount = parseFloat(inv_so.report_amount);
        this.print_table_detail.push(data);
      });

     
    },
    async loadInvDocRefData() {
      const res_inv_doc_ref = await api.printInvDocRef(this.inv_id);
      
      this.indexinvADMIS = true;

      for await (const inv_doc_ref of res_inv_doc_ref.data) {
        if (inv_doc_ref.ref_type == "AD") {
          await this.loadInvAdvanceDetailData(inv_doc_ref.ref_id);
        }

        if (inv_doc_ref.ref_type == "TS") {
          await this.loadInvTimeSheetDetailData(inv_doc_ref.ref_id);
        }
      }

      
      // this.dataforshowprpo = this.print_table_detail;

      // await this.checkcontent();
    },
    async loadInvTSGetTimekeeper() {
      this.datashowAlltimekeeper = [];
      if (this.print_detail_timekeeper.length <= 0) {
        return;
      }
      let data = {};
      // data.hid = this.print_detail_timekeeper;
      data.hid = [this.$route.params.id];
      const resultc = await api.getAllInvTSBytimesheetCreeper_hid(data);
      this.datashowAlltimekeeper = resultc.data;
      if(this.datashowAlltimekeeper.length > 0){
      this.datashowAlltimekeeper.forEach((item) => {
        // item.tc_rateChargeHour_bath = (
        //   (Number(item.tc_actualchargehour) / 60) *
        //   Number(item.tc_rate)
        // ).toFixed(2);
         item.tc_rateChargeHour_bath = (
          Number(item.tc_hours)
        ).toFixed(2);
        item.tc_nocharge = Math.abs(
          (
            (Number(item.tc_actualchargehour) - Number(item.tc_hours)) /
            60
          ).toFixed(2)
        );
        item.tc_nochargeCal = (
          Math.abs(
            (Number(item.tc_actualchargehour) - Number(item.tc_hours)) / 60
          ) * Number(item.tc_rate)
        ).toFixed(2);

        //sum all
        this.sum_chargehour =
          this.sum_chargehour + Number(item.tc_actualchargehour / 60);
        this.sum_rateChargehour =
          this.sum_rateChargehour + Number(item.tc_rateChargeHour_bath);
        this.sum_noCharge_hour =
          this.sum_noCharge_hour + Number(item.tc_nocharge);
        this.sum_noCharge_bath =
          this.sum_noCharge_bath + Number(item.tc_nochargeCal);
        this.sum_grandTotal = this.sum_rateChargehour;

        // //show data in table
        item.tc_rate_show = tolocalestringnumber(item.tc_rate);
        // item.tc_actualchargehour_show = tolocalestringnumber(
        //   Number(item.tc_actualchargehour / 60).toFixed(2)
        // );
        item.tc_actualchargehour_show = tolocalestringnumber(
          Number(item.tc_actualchargehour).toFixed(2)
        );
        item.tc_rateChargeHour_bath_show = tolocalestringnumber(
          item.tc_rateChargeHour_bath
        );
        item.tc_nocharge_show = tolocalestringnumber(item.tc_nocharge);
        item.tc_nochargeCal_show = tolocalestringnumber(item.tc_nochargeCal);
      });
    }
    },
    async loadInvMISData() {
      var tmp_inv_data;
      // const res_docref_so = await api.getRef_TypeCNById(this.inv_id);
      const res_inv_mis = await api.printInvMIS(this.inv_id);
   
      tmp_inv_data = res_inv_mis.data;

      tmp_inv_data.forEach((inv_mis, index) => {
        var data = {
          report_type: "ADMIS",
          report_desc: "",
          report_amount: 0,
          first_item: 0,
        };

        if (index == 0 && this.haveAD == false) {
          data.first_item = 1;
        }
        data.report_desc = inv_mis.report_desc;
        data.report_amount = parseFloat(inv_mis.report_amount);
        this.print_table_detail.push(data);
      });
      
    },
    async loadInvTimeSheetDetailData(ref_id) {
      var tmp_inv_data;
      this.print_detail_timekeeper.push(ref_id);
      const res_inv_time_sheet_detail = await api.printInvTS(ref_id);
    
      tmp_inv_data = res_inv_time_sheet_detail.data;

      tmp_inv_data.forEach((inv_time_sheet_detail) => {
        var data = {
          report_type: "TS",
          report_desc: "",
          report_amount: 0,
          report_date: "",
          lawyer: "",
        };

        data.report_desc = inv_time_sheet_detail.report_desc;
        data.report_amount = parseFloat(inv_time_sheet_detail.report_amount);
        data.report_date = inv_time_sheet_detail.report_date;
        data.lawyer = inv_time_sheet_detail.lawyer;
        this.print_table_detailtimesheet.push(data);
      });

    },
    async loadInvAdvanceDetailData(ref_id) {
      var tmp_inv_data;
      const res_inv_advance_detail = await api.printInvAD(ref_id);
    
      tmp_inv_data = res_inv_advance_detail.data;
      if (tmp_inv_data.length > 0) {
        this.haveAD = true;
      }

      tmp_inv_data.forEach((inv_advance_detail, index) => {
        var data = {
          report_type: "ADMIS",
          report_desc: "",
          report_amount: 0,
          first_item: 0,
        };
        if (this.indexinvADMIS == true && index == 0) {
          data.first_item = 1;
        }

        data.report_desc = inv_advance_detail.report_desc;
        data.report_amount = parseFloat(inv_advance_detail.report_amount);
        this.print_table_detail.push(data);
      });
      this.indexinvADMIS = false;
;
    },
    async checkcontent() {
    
      this.datashowAll = [];
      // alert(data.length);
      this.setdatatoshow = [];
      let linedetailprpo = 24;
      let datainlineprpo = 67;
      let addnewbutget = 1;
      let getdata = [];
      let getnewdata = [];
      let hllinedetails = 0;
      this.pageAll = 1;
      let newhllinedetails = 0;
      let checkamount_morethen_zero = false;
      for (let i = 0; i < this.print_table_detail.length; i++) {
        let stringchecklength = "";
      
        
        let lengthofdataforshowprpo = this.print_table_detail[i].report_desc
          .length;

        let maiek = "่"; //ไม้เอก
        let maito = "้"; //ไม้โท
        let maitree = "๊"; //ไม้ตรี
        let maijattawa = "๋"; //ไม้จัตวา
        let vowelei = "ิ"; //สระอิ
        let vowelee = "ี"; //สระอี
        let voweleue = "ึ"; //สระอึ
        let voweleu = "ื"; //สระอือ
        let vowelu = "ุ"; //สระอุ
        let voweluu = "ู"; //สระอู
        let maihun = "ั"; //ไม้หันอากาศ
        let maiyamok = "็"; //ไม้เอก
        newhllinedetails = 0;
        getnewdata = [];
        this.print_table_detail[i].no = i + 1;

        if(this.print_table_detail[i].report_desc.split("\n").length-1 > 0){
          let dataarray = this.print_table_detail[i].report_desc.split("\n");
          hllinedetails += this.print_table_detail[i].report_desc.split("\n").length-1;
          let lengthindexinarray = 0;
          for(let i = 0;i < dataarray.length;i++){
            lengthindexinarray = 0;
            lengthindexinarray = dataarray[i].length;

          for (
          let j = 0;
          j < dataarray[i].length;
          j++
        ) {
          if (
            stringchecklength[j] == maiek ||
            stringchecklength[j] == maito ||
            stringchecklength[j] == maitree ||
            stringchecklength[j] == maijattawa ||
            stringchecklength[j] == vowelei ||
            stringchecklength[j] == vowelee ||
            stringchecklength[j] == voweleue ||
            stringchecklength[j] == voweleu ||
            stringchecklength[j] == vowelu ||
            stringchecklength[j] == voweluu ||
            stringchecklength[j] == maihun ||
            stringchecklength[j] == maiyamok
          ) {
            lengthindexinarray--;
          }
        }

        newhllinedetails = Math.ceil(lengthindexinarray / datainlineprpo);


      }
          
        }else{
        for (
          let j = 0;
          j < this.print_table_detail[i].report_desc.length;
          j++
        ) {
          if (
            stringchecklength[j] == maiek ||
            stringchecklength[j] == maito ||
            stringchecklength[j] == maitree ||
            stringchecklength[j] == maijattawa ||
            stringchecklength[j] == vowelei ||
            stringchecklength[j] == vowelee ||
            stringchecklength[j] == voweleue ||
            stringchecklength[j] == voweleu ||
            stringchecklength[j] == vowelu ||
            stringchecklength[j] == voweluu ||
            stringchecklength[j] == maihun ||
            stringchecklength[j] == maiyamok
          ) {
            lengthofdataforshowprpo--;
          }
        }
        newhllinedetails = Math.ceil(lengthofdataforshowprpo / datainlineprpo);
      }
      
        if (
          this.print_table_detail[i].first_item == 1 ||
          this.print_table_detail[i].solastdata == "SOlastindex" ||
          this.haveSO == false
        ) {
          newhllinedetails + 2;
        }
        if(i > 0){
        if (this.print_table_detail[i].report_amount > 0 && checkamount_morethen_zero == false && this.print_table_detail[i-1].report_amount == 0) {
          checkamount_morethen_zero = true;
          this.print_table_detail[i].amount_morethen_zero = "yes";
          newhllinedetails + 1;
        }
      }
        // newhllinedetails = Math.ceil(
        //   this.print_table_detail[i].NAME.length / datainlineprpo
        // );

        // newhllinedetails += addnewbutget;
        getnewdata.push(this.print_table_detail[i]);
       
        if (hllinedetails + newhllinedetails > linedetailprpo) {
         
          this.setdatatoshow[this.pageAll - 1] = getdata;
          this.pageAll++;

          hllinedetails = 0;
          getdata = [];
          getdata.push(this.print_table_detail[i]);
          hllinedetails += newhllinedetails;
          if (i == this.print_table_detail.length - 1) {
            this.setdatatoshow[this.pageAll - 1] = getdata;
            getdata = [];
          }
        } else {
          hllinedetails += newhllinedetails;

          getdata.push(this.print_table_detail[i]);

          if (i == this.print_table_detail.length - 1) {
            this.setdatatoshow[this.pageAll - 1] = getdata;
            getdata = [];
          }
        }
      }
      this.opensummarize = true;
     

      this.setdatatoshow.forEach((item, iitem) => {
       
        if (iitem == this.setdatatoshow.length - 1) {
          item[this.setdatatoshow[iitem].length - 1].lastdata = "lastindex";
        }

        this.datashowAll.push(item);
      });
     
    },

    async checkcontenttimesheet() {
     
      this.datashowAlltimesheet = [];
      // alert(data.length);
      this.setdatatoshowtimesheet = [];
      let linedetailprpo = 42;
      let datainlineprpo = 60;
      let addnewbutget = 1;
      let getdata = [];
      let getnewdata = [];
      let hllinedetails = 0;
      this.pageAlltimesheet = 1;
      let newhllinedetails = 0;
      for (let i = 0; i < this.print_table_detailtimesheet.length; i++) {
        let stringchecklength = "";
        let lengthofdataforshowprpo = this.print_table_detailtimesheet[i]
          .report_desc.length;

        let maiek = "่"; //ไม้เอก
        let maito = "้"; //ไม้โท
        let maitree = "๊"; //ไม้ตรี
        let maijattawa = "๋"; //ไม้จัตวา
        let vowelei = "ิ"; //สระอิ
        let vowelee = "ี"; //สระอี
        let voweleue = "ึ"; //สระอึ
        let voweleu = "ื"; //สระอือ
        let vowelu = "ุ"; //สระอุ
        let voweluu = "ู"; //สระอู
        let maihun = "ั"; //ไม้หันอากาศ
        let maiyamok = "็"; //ไม้เอก
        newhllinedetails = 0;
        getnewdata = [];
        this.print_table_detailtimesheet[i].no = i + 1;

        for (
          let j = 0;
          j < this.print_table_detailtimesheet[i].report_desc.length;
          j++
        ) {
          if (
            stringchecklength[j] == maiek ||
            stringchecklength[j] == maito ||
            stringchecklength[j] == maitree ||
            stringchecklength[j] == maijattawa ||
            stringchecklength[j] == vowelei ||
            stringchecklength[j] == vowelee ||
            stringchecklength[j] == voweleue ||
            stringchecklength[j] == voweleu ||
            stringchecklength[j] == vowelu ||
            stringchecklength[j] == voweluu ||
            stringchecklength[j] == maihun ||
            stringchecklength[j] == maiyamok
          ) {
            lengthofdataforshowprpo--;
          }
        }
        newhllinedetails = Math.ceil(lengthofdataforshowprpo / datainlineprpo);
        

        // newhllinedetails = Math.ceil(
        //   this.print_table_detailtimesheet[i].NAME.length / datainlineprpo
        // );

        // newhllinedetails += addnewbutget;
        getnewdata.push(this.print_table_detailtimesheet[i]);
       
        if (hllinedetails + newhllinedetails > linedetailprpo) {
       
          this.setdatatoshowtimesheet[this.pageAlltimesheet - 1] = getdata;
          this.pageAlltimesheet++;

          hllinedetails = 0;
          getdata = [];
          getdata.push(this.print_table_detailtimesheet[i]);
          hllinedetails += newhllinedetails;
          if (i == this.print_table_detailtimesheet.length - 1) {
            this.setdatatoshowtimesheet[this.pageAlltimesheet - 1] = getdata;
            getdata = [];
          }
        } else {
          hllinedetails += newhllinedetails;

          getdata.push(this.print_table_detailtimesheet[i]);

          if (i == this.print_table_detailtimesheet.length - 1) {
            this.setdatatoshowtimesheet[this.pageAlltimesheet - 1] = getdata;
            getdata = [];
          }
        }
      }
      this.opensummarizetimesheet = true;
   

      this.setdatatoshowtimesheet.forEach((item, iitem) => {
       
        if (iitem == this.setdatatoshowtimesheet.length - 1) {
          item[this.setdatatoshowtimesheet[iitem].length - 1].lastdata =
            "lastindex";
        }

        this.datashowAlltimesheet.push(item);
      });
      
    },

    async loaddatainv() {
    this.$showLoader();
    const res_hresult = await api.getdataByIdInvoice_H(this.$route.params.id);
    const res_docref_so = await api.getRef_TypeSOById(this.$route.params.id);
   

    this.id_invoice = res_hresult.data[0].id;
    this.date = res_hresult.data[0].date;
    this.period_cover = res_hresult.data[0].period_cover;
    this.add_h.inv_id = res_hresult.data[0].inv_id;
    this.add_h.pinv_id = res_hresult.data[0].pinv_id;
    if (this.add_h.inv_id) {
      this.showinv_id = this.add_h.inv_id;
    } else if (this.add_h.pinv_id) {
      this.showinv_id = this.add_h.pinv_id;
    } else {
      this.showinv_id = "####";
    }
    if (
      res_hresult.data[0].status == "P.invoice" ||
      res_hresult.data[0].status == "Invoice" ||
      res_hresult.data[0].status == "Credit Note" ||
      res_hresult.data[0].status == "Receive"
    ) {
      // this.datainv_disable = true;
      // this.ts_status_oper = "Submit";
    } else {
      // this.ts_status_oper = "draft";
    }
    if (res_hresult.data[0].inv_type == "P") {
      this.titleheader = "P.Invoice";
    } else if (res_hresult.data[0].inv_type == "I") {
      this.titleheader = "Invoice";
    }

    this.add_h.inv_type = res_hresult.data[0].inv_type;
    this.add_h.customerid = res_hresult.data[0].customer_id;
    this.add_h.customer_address = res_hresult.data[0].customer_address;
    this.add_h.customer_taxid = res_hresult.data[0].customer_taxid;
    this.add_h.customer_branch = res_hresult.data[0].customer_branch;
    this.add_h.customer_paymentterm =
      res_hresult.data[0].customer_paymentterm;
    this.add_h.customer_vat = res_hresult.data[0].customer_vat;
    this.add_h.vat_type = res_hresult.data[0].vat_type;
    this.add_h.company_inv = res_hresult.data[0].company_inv;
    if(res_hresult.data[0].additional_top != ""){
      this.filename_old = [];
      this.filename_old = res_hresult.data[0].additional_top.split(",");
      // this.add_h.additional_top = res_hresult.data[0].additional_top;
    }
    
    this.add_h.additional_bottom = "";

    this.getdatefrom_inv_h.customerid = res_hresult.data[0].customer_id;
    this.getdatefrom_inv_h.customer_code = res_hresult.data[0].customer_code;
    this.getdatefrom_inv_h.customer_name = res_hresult.data[0].customer_name;
    this.getdatefrom_inv_h.po_no = res_hresult.data[0].po_no;
    this.getdatefrom_inv_h.attention = res_hresult.data[0].attention;
    this.getdatefrom_inv_h.contact_position = res_hresult.data[0].contact_position;
    this.getdatefrom_inv_h.customer_address =
      res_hresult.data[0].customer_address;
    this.getdatefrom_inv_h.customer_taxid =
      res_hresult.data[0].customer_taxid;
    this.getdatefrom_inv_h.customer_branch =
      res_hresult.data[0].customer_branch;
    this.getdatefrom_inv_h.customer_paymentterm =
      res_hresult.data[0].customer_paymentterm;
    this.getdatefrom_inv_h.customer_vat = res_hresult.data[0].customer_vat;
    this.getdatefrom_inv_h.vat_type = res_hresult.data[0].vat_type;
    this.getdatefrom_inv_h.currency = res_hresult.data[0].currency;
      this.showcurrency = res_hresult.data[0].currency;
      this.getdatefrom_inv_h.saleorder_id = res_hresult.data[0].saleorder_id;
      this.getdatefrom_inv_h.dept = res_hresult.data[0].dept;
      this.getdatefrom_inv_h.dept_type = res_hresult.data[0].dept_type;
    if (res_docref_so.data[0]) {
      // this.getdatefrom_inv_h.saleorder_id =
      //   res_docref_so.data[0].saleorder_id;
      
    }

    this.inv_subtotal = res_hresult.data[0].sub_total;
    this.wth_rate = res_hresult.data[0].wth_rate;
    this.tax_rate = res_hresult.data[0].tax_rate;
    this.amount_total = res_hresult.data[0].amount_total;
    this.amount_total = tolocalestringnumber(this.amount_total);
    this.so_total = res_hresult.data[0].so_total;
    this.so_total = tolocalestringnumber(this.so_total);
    this.ts_total = res_hresult.data[0].ts_total;
    this.ts_total = tolocalestringnumber(this.ts_total);
    this.ad_total = res_hresult.data[0].ad_total;
    this.ad_total = tolocalestringnumber(this.ad_total);
    this.mis_total = res_hresult.data[0].mis_total;
    this.mis_total = tolocalestringnumber(this.mis_total);

    const res_misresult = await api.getdataByIdInv_Mis(this.$route.params.id);
    this.datainv_mis = res_misresult.data;

    this.calgrandtotal();
    // this.caltotalAll();
    // this.calwth_rate();
    // this.caltax_rate();

    const res_soresult = await api.getdataByIdInv_SO(this.$route.params.id);
    this.getdatefrom_inv_so = res_soresult.data;

    const res_tsid = await api.getTs_hListId(this.$route.params.id);
    const dataAllTsId = res_tsid.data;
   
    let indexAD = 0;
    let indexTS = 0;
    if (dataAllTsId.length > 0) {
      for (let i = 0; i < dataAllTsId.length; i++) {
        if (dataAllTsId[i].ref_type == "TS") {
          this.ts_id[indexTS] = dataAllTsId[i].ref_id;
          indexTS += 1;
        } else if (dataAllTsId[i].ref_type == "AD") {
          this.ad_id[indexAD] = dataAllTsId[i].ref_id;
          indexAD += 1;
        }
      }
    }
    this.ts_idTab = this.ts_id;
    this.ad_idTab = this.ad_id;
    this.ad_inv_id = this.ad_id;
    if (this.getdatafrominv_h == true) {
      this.getdataByCusStatus = "INV";
    }
    this.callsoopentab = true;
    this.$hideLoader();
  },
    async saveinvDraftandSubmit(mode) {
     
      this.add_h.customer_paymentterm = this.payment_term_cus;
      this.add_h.customer_vat = this.vat_cus;
      this.add_h.po_no = this.po_no_cus;
      this.add_h.attention = this.attention_cus;
      this.add_h.contact_position = this.contact_position_cus;
      this.add_h.vat_type = this.vat_type_cus;
      this.add_h.inv_date = this.date;
      this.add_h.period_cover = this.period_cover;
      this.add_h.so_total = parseFloat(
        stringcommatonumber(this.so_total.toString())
      );
      this.add_h.ts_total = parseFloat(
        stringcommatonumber(this.ts_total.toString())
      );
      this.add_h.ad_total = parseFloat(
        stringcommatonumber(this.ad_total.toString())
      );
      this.add_h.mis_total = parseFloat(
        stringcommatonumber(this.mis_total.toString())
      );
      this.add_h.sub_total = parseFloat(
        stringcommatonumber(this.inv_subtotal.toString())
      );
      this.add_h.wth_rate = parseFloat(this.wth_rate);
      this.add_h.tax_rate = parseFloat(this.tax_rate);
      this.add_h.amount_total = parseFloat(
        stringcommatonumber(this.amount_total.toString())
      );
      this.add_h.so_ref = this.$route.params.id;

      this.$showLoader();
      if (this.getdatafrominv_h == true) {

        let formData = new FormData();

        this.image_list.forEach((x, index) => { formData.append(`file`, x); });

        let res_up_file = await api.addInvoice_HFile(this.$route.params.id,formData)

        var old_name = "";

        if(this.filename_old.length > 0){
          this.filename_old.forEach((item,index) =>{
            if(index > 0){
              old_name += "," + item;
            }else{
              old_name += item;
            }

          })
        } 

        var file_all = "";
        if(res_up_file.data.additional_top == ""){
          file_all = old_name
        }else{
          if(old_name == ""){
            file_all = res_up_file.data.additional_top;
          }else{
            file_all = res_up_file.data.additional_top + "," + old_name;
          }
          
        }

        this.add_h.additional_top = file_all;
        this.add_h.additional_bottom = "";
        
        let update_hresult = [];
        if (mode == "Update") {
          update_hresult = await api.updateInvoice_H(
            this.$route.params.id,
            this.add_h
          );
        } else {
          return;
        }

        //add so
        if (update_hresult.status == 200 || update_hresult.status == 201) {
          await api.deleteInv_SO(this.$route.params.id);
          this.desserts.forEach((x, index) => {
            this.addinv_so.inv_hid = this.$route.params.id;
            this.addinv_so.so_seq = index + 1;
            this.addinv_so.item_name = x.description;
            this.addinv_so.item_qty =  parseFloat(stringcommatonumber(x.unit.toString()));
            this.addinv_so.item_price = parseFloat(stringcommatonumber(x.PricePerUnit.toString()));
            this.addinv_so.item_line_amt = parseFloat(stringcommatonumber(x.amount.toString()));
            api.addInv_SO(this.addinv_so);
          });

          //add mis
          await api.deleteInv_Mis(this.$route.params.id);
          this.datainv_mis.forEach(async (data, index) => {
            data.inv_hid = this.$route.params.id;
            data.mis_seq = index+1;
            data.amount = parseFloat(stringcommatonumber(data.amount.toString()));
            await api.addInv_Mis(data);
          });

          //add advance
          await api.delete_docrefAD(this.$route.params.id);
          if (this.ad_inv_id.length > 0) {
            for (let i = 0; i < this.ad_inv_id.length; i++) {
              await api.updateAdvanceH(this.ad_inv_id[i], { status: 9 });
            }
            if (this.ad_id.length > 0) {
              for (let i = 0; i < this.ad_id.length; i++) {
                this.addinv_docref_ad.inv_hid = this.$route.params.id;
                this.addinv_docref_ad.ref_id = this.ad_id[i];
                await api.addInv_Docref(this.addinv_docref_ad);
                //stam status Advance_H = 10 Invioce
                await api.updateAdvanceH(this.ad_id[i], { status: 10 });
              }
            }
          } else {
            if (this.ad_id.length > 0) {
             
              for (let i = 0; i < this.ad_id.length; i++) {
                this.addinv_docref_ad.inv_hid = this.$route.params.id;
                this.addinv_docref_ad.ref_id = this.ad_id[i];
                await api.addInv_Docref(this.addinv_docref_ad);
                //stam status Advance_H = 10 Invioce
                await api.updateAdvanceH(this.ad_id[i], { status: 10 });
              }
            }
          }
          //add inv ts
          if (this.inv_ts_list.length > 0) {
          
            await api.deleteInvTS(this.$route.params.id);
            for (let i = 0; i < this.inv_ts_list.length; i++) {
              let addDataInvTS = {};
              addDataInvTS.inv_hid = this.$route.params.id;
              addDataInvTS.user_id = this.inv_ts_list[i].emp_id;
              addDataInvTS.emp_rate = Number(this.inv_ts_list[i].tc_rate);
              addDataInvTS.tc_actualchargehour = Number(
                this.inv_ts_list[i].tc_actualchargehour_show
              );
              addDataInvTS.tc_ratechargehour_bath = Number(
                this.inv_ts_list[i].tc_rateChargeHour_bath
              );
              let req_addInvTS = await api.addInvTS(addDataInvTS);
              if (req_addInvTS.status == 200 || req_addInvTS.status == 201) {
                //add sucess
              } else {
                await api.deleteInvTS(this.$route.params.id);
                break;
              }
            }
          }
          //add doc_ref
          await api.delete_docrefTS(this.$route.params.id);
          if (this.ts_id) {
            if (this.ts_id.length > 0) {
              for (let i = 0; i < this.ts_id.length; i++) {
                this.addinv_docref_ts.inv_hid = this.$route.params.id;
                this.addinv_docref_ts.ref_id = this.ts_id[i];
               
                await api.addInv_Docref(this.addinv_docref_ts);
              }
            }
          }
          this.$hideLoader();
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "Information Saved",
            "Information successfully saved",
            "text-h5 green--text text-center",
            `/reloadpage`
          );
        } else {
          this.$hideLoader();
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "ไม่สามารถบันทึกได้",
            "text-h5 red--text text-center"
          );
        }
      } else {
      }
    },

    async getdatafromSOcomponent(
      customer_id,
      invoice_address,
      tax_id,
      branch,
      payment_term,
      vat,
      vat_type,
      testsumso,
      so_hid
    ) {
      if (this.getdatafrominv_h === false) {
        if (!payment_term) {
          payment_term = 0;
        }
        this.so_total = testsumso;
        this.so_total = tolocalestringnumber(this.so_total);
        this.tax_rate = vat;
        this.add_h.customerid = customer_id;
        this.add_h.customer_address = invoice_address;
        this.add_h.customer_taxid = tax_id;
        this.add_h.customer_branch = branch;
        this.add_h.customer_paymentterm = payment_term;
        this.add_h.customer_vat = vat;
        this.add_h.vat_type = vat_type;
        this.invoice_address = invoice_address;
        this.addinv_docref_so.ref_id = so_hid;
  
      }

      this.caltotalAll();
      this.calwth_rate();
      this.caltax_rate();
    },
    async getdatafromSOtable(dataintable) {
      this.desserts = dataintable;
    },
    async getdatafromTStotal(TStotal) {
      this.ts_total = TStotal;
      this.ts_total = tolocalestringnumber(this.ts_total);
      this.caltotalAll();
      this.calwth_rate();
      this.caltax_rate();
    },
    async getdatafromAdtotal(Adtotal) {
      this.ad_total = Adtotal;
      this.ad_total = tolocalestringnumber(this.ad_total);
      this.caltotalAll();
      this.calwth_rate();
      this.caltax_rate();
    },
    async getTsId(TS_ID) {
      this.ts_id = TS_ID;
    },
    async getAdId(AS_ID) {
      this.ad_id = AS_ID;
    },
    async getAdInvioceId(AD_Invioce_Id) {
      this.ad_inv_id = AD_Invioce_Id;
    },
    async getSelected_showE(Selected_ShowE) {
      this.selected_showE = Selected_ShowE;
    },
    async getmode_oper_ts(modets) {
      this.mode_oper = modets;
    },
    async getOper_sel(Oper_Sel) {
      this.oper_sel = Oper_Sel;
    },
    async getInvTsList(invTs_List) {
      this.inv_ts_list = invTs_List;
    },
    async getSelected_showEAv(Selected_ShowAv) {
      this.selected_showAv = Selected_ShowAv;
    },
    async getOper_selAv(Oper_SelAv) {
      this.oper_sel_Av = Oper_SelAv;
    },
    async getdatafromSOisheader(testsumso) {
      this.so_total = testsumso;
      this.so_total = tolocalestringnumber(this.so_total);
      this.caltotalAll();
      this.calwth_rate();
      this.caltax_rate();
    },

    async getdatafromMis(data) {
      this.mis_total = 0;
      this.datainv_mis = [];
      this.datainv_mis = data;
      this.datainv_mis.forEach((x, index) => {
        x.amount = parseFloat(stringcommatonumber(x.amount.toString()));
        this.mis_total += parseFloat(x.amount);
      });
      this.mis_total = tolocalestringnumber(this.mis_total);
      this.caltotalAll();
      this.calwth_rate();
      this.caltax_rate();
    },
    async getdatafromadditional(data) {
      
      this.add_h.additional_top = data.additional_top;
      this.add_h.additional_bottom = data.additional_bottom;
    },

    caltotalAll() {
      // this.inv_subtotal =
      //   parseFloat(stringcommatonumber(this.so_total.toString())) +
      //   parseFloat(stringcommatonumber(this.ts_total.toString())) +
      //   parseFloat(stringcommatonumber(this.ad_total.toString())) +
      //   parseFloat(stringcommatonumber(this.mis_total.toString()));
      // this.inv_subtotal = tolocalestringnumber(this.inv_subtotal);
      this.calgrandtotal();
    },
    tabClicked(selectedTab) {
      // console.log("Current tab re-clicked:" + selectedTab.tab.name);
    },
    tabChanged(selectedTab) {
      // console.log("Tab changed to:" + selectedTab.tab.name);
    },
    closeDelete() {},
    deleteItemConfirm() {},
    calwth_rate() {
      // this.wth_ratetotal = parseFloat(
      //   (parseFloat(stringcommatonumber(this.inv_subtotal.toString())) *
      //     parseFloat(stringcommatonumber(this.wth_rate.toString()))) /
      //     100
      // );
      // this.wth_ratetotal = tolocalestringnumber(this.wth_ratetotal);
      this.calgrandtotal();
    },
    caltax_rate() {
      // this.tax_ratetotal = parseFloat(
      //   (parseFloat(stringcommatonumber(this.inv_subtotal.toString())) *
      //     parseFloat(stringcommatonumber(this.tax_rate.toString()))) /
      //     100
      // );
      // this.tax_ratetotal = tolocalestringnumber(this.tax_ratetotal);
      this.calgrandtotal();
    },

    calgrandtotal() {
    this.inv_subtotal =
      parseFloat(stringcommatonumber(this.so_total.toString())) +
      parseFloat(stringcommatonumber(this.ts_total.toString())) +
      parseFloat(stringcommatonumber(this.ad_total.toString())) +
      parseFloat(stringcommatonumber(this.mis_total.toString()));
    this.inv_subtotal = tolocalestringnumber(this.inv_subtotal);
  
    if(this.add_h.vat_type == "E"){
      this.tax_ratetotal = parseFloat(
      (parseFloat(stringcommatonumber(this.inv_subtotal.toString())) *
        parseFloat(stringcommatonumber(this.tax_rate.toString()))) /
        100
    );
    this.tax_ratetotal = tolocalestringnumber(this.tax_ratetotal);
    this.wth_ratetotal = parseFloat(
      (parseFloat(stringcommatonumber(this.inv_subtotal.toString())) *
        parseFloat(stringcommatonumber(this.wth_rate.toString()))) /
        100
    );
    this.wth_ratetotal = tolocalestringnumber(this.wth_ratetotal);
   

      this.amount_total = parseFloat(
      parseFloat(stringcommatonumber(this.inv_subtotal.toString())) -
        parseFloat(stringcommatonumber(this.wth_ratetotal.toString())) +
        parseFloat(stringcommatonumber(this.tax_ratetotal.toString()))
    ).toFixed(2);
    this.amount_total = tolocalestringnumber(this.amount_total);
    
    }else if(this.add_h.vat_type == "I"){
      
      this.amount_total = (stringcommatonumber(this.inv_subtotal)/(1 + this.wth_rate / 100));
      this.wth_ratetotal = (stringcommatonumber(this.inv_subtotal) - this.amount_total).toFixed(2);
      this.inv_subtotal = (this.amount_total / (1 + this.tax_rate / 100)).toFixed(2);
      this.tax_ratetotal = (this.amount_total - this.inv_subtotal).toFixed(2);
    
      this.amount_total = tolocalestringnumber(this.amount_total);
      this.inv_subtotal = tolocalestringnumber(this.inv_subtotal);
      this.tax_ratetotal = tolocalestringnumber(this.tax_ratetotal);
      this.wth_ratetotal = tolocalestringnumber(this.wth_ratetotal);
     
    }else{
    }
  },
    async deleteItemConfirmForm() {
      if (this.getdatafrominv_h == true) {
        this.$showLoader();
        await api.deleteInvoice_H(this.$route.params.id);
        await api.deleteInv_SO(this.$route.params.id);
        await api.delete_docrefTS(this.$route.params.id);
        await api.delete_docrefAD(this.$route.params.id);
        await api.delete_docrefSO(this.$route.params.id);
        await api.deleteInvTS(this.$route.params.id);
      
        if (this.ad_inv_id.length > 0) {
          for (let i = 0; i < this.ad_inv_id.length; i++) {
            await api.updateAdvanceH(this.ad_inv_id[i], { status: 9 });
          }
        }
        this.$hideLoader();
        this.dialogDeleteForm = false;
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "ลบข้อมูล",
          "ลบข้อมูลสำเร็จ",
          "text-h5 green--text text-center",
          "/invoice"
        );
      } else {
        this.dialogDeleteForm = false;
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "ลบข้อมูล",
          "ลบข้อมูลสำเร็จ",
          "text-h5 green--text text-center",
          "/invoice"
        );
      }
    },
    async closeDeleteForm() {
      this.dialogDeleteForm = false;
    },
    setupAlertDialog(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    async getdatavatcus(VAT_CUS) {
      this.vat_cus = VAT_CUS;
      // alert(this.vat_cus)
    },
    async getdatapaymentcus(payment_CUS) {
      this.payment_term_cus = payment_CUS;
      this.add_h.customer_paymentterm = payment_CUS;
      // alert(this.payment_term_cus)
    },
    async getdataPonoCus(PONO_CUS) {
      this.po_no_cus = PONO_CUS;
      // alert(this.po_no_cus)
    },
    async getdataAttentionCus(attention) {
      this.attention_cus = attention;
    },
    async getdatacontact_positionCus(contact_position) {
      this.contact_position_cus = contact_position;
    },
    async getdatavat_type(vat_type){
    this.vat_type_cus = vat_type;
    this.add_h.vat_type = vat_type;
    this.calgrandtotal();
  },
       //---------------function file------------//
  async popupdeletefile(index) {
          this.fileindex = index;
          this.dialogDelete = true;
        },

  async popupdeletefile_old(index) {
          this.old_index = index;
          this.dialogDelete_old = true;
        },

        async closeDelete() {
          this.fileindex = -1;
          this.dialogDelete = false;
        },

        async closeDelete_old() {
          this.old_index = -1;
          this.dialogDelete_old = false;
        },
        async deleteItemConfirm() {
         
          this.preview_list2 = [];
          this.getpreview_list.splice(this.fileindex, 1);
          this.image_list.splice(this.fileindex, 1);
          await this.getPdfforAdd(this.getpreview_list);

          this.filename.splice(this.fileindex, 1);
          this.preview_list2 = this.preview_list;
          this.fileindex = -1;
          this.dialogDelete = false;
        },
        async deleteItemConfirm_old() {
          this.dialogDelete_old = false;
          this.filename_old.splice(this.old_index, 1);

      
        },
        async popupdeleteimage() {
          this.dialogDeleteimage = true;
        },
        async closeDeleteimage() {
          this.dialogDeleteimage = false;
        },
        async deleteItemConfirmimage() {
          this.checkimageadd = false;
          this.image = null;
          this.previewimage = "";
          this.imagename = "";
          this.dialogDeleteimage = false;
        },
        async previewnoticefilepdf(file_name) {
          this.previewpopupfile2 = [];
         
          let file_name_s = file_name;
          let pdf_newTab = window.open(
              `${INVUrl}/${this.$route.params.id}/${file_name_s}`
          );

            return;
        },
        async popuppreviewfile(fileindex) {
          let pdf_newTab = window.open("");
          pdf_newTab.document.write(
            "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
              this.getpreview_list[fileindex] +
              "'></iframe></body></html>"
          );

          return;
        },
        async closepreviewfile() {
          this.dialogpreviewfile = false;
        },
        async popuppreviewimage() {
          this.dialogpreviewimage = true;
        },
        async closepreviewimage() {
          this.dialogpreviewimage = false;
        },
        previewImage: function(event) {
          var input = event.target;
          if (input.files) {
            var reader = new FileReader();
            reader.onload = (e) => {
              this.previewimage = e.target.result;
              this.checkimageadd = true;
            };
            this.image = input.files[0];
            reader.readAsDataURL(input.files[0]);
          }

          this.imagename = this.image.name;
        },
        async previewMultiImage(event) {
          this.filename = [];
          this.preview_list = [];
          this.getpreview_list = [];
          // this.image_list = [];
          var input = event.target;
          var count = input.files.length;
          var index = 0;

          if (input.files) {
            while (count--) {
              var reader = new FileReader();
              reader.onload = (e) => {
                this.getpreview_list.push(e.target.result);
                this.preview_list.push(e.target.result);
              };
              if (input.files[index].type === "application/pdf") {
                this.image_list.push(input.files[index]);
                reader.readAsDataURL(input.files[index]);
              }
              index++;
            }
          }

          await this.getPdfforAdd(this.getpreview_list);

          this.preview_list2 = this.preview_list;

          this.image_list.forEach((x,index) => {
            this.filename.push(x.name);
          });
            
          this.checkfileeadd = true;
  
        },

        reset: function() {
          this.image = null;
          this.previewimage = null;
          this.image_list = [];
          this.preview_list = [];
        },
        async getPdfforpreview(datafile) {
          this.previewpopupfile = [];
          let getfile = [];
          getfile = datafile;
          this.previewpopupfile.push(pdfvuer.createLoadingTask(`${getfile}`));
          this.previewpopupfile.forEach((item, index) => {
            item.then((pdf) => {
              this.numPagespreview = pdf.numPages;
            });
          });
        
        },

        async getPdfforAdd(item) {
          this.preview_list = [];
          let getfile = [];
          getfile = item;
          getfile.forEach((file, index) => {
            this.preview_list.push(pdfvuer.createLoadingTask(`${file}`));
          });
          this.preview_list.forEach((item, index) => {
            item.then((pdf) => {
              this.numPages = pdf.numPages;
              // window.onscroll = function() {
              //   changePage();
              //   stickyNav();
              // };
              // Get the offset position of the navbar
              // var sticky = $("#buttons")[0].offsetTop;
              // // Add the sticky class to the this.$refs.nav when you reach its scroll position. Remove "sticky" when you leave the scroll position
              // function stickyNav() {
              //   if (window.pageYOffset >= sticky) {
              //     $("#buttons")[0].classList.remove("hidden");
              //   } else {
              //     $("#buttons")[0].classList.add("hidden");
              //   }
              // }
              function changePage() {
                var i = 1,
                  count = Number(pdf.numPages);
                do {
                  if (
                    window.pageYOffset >=
                      this.findPos(document.getElementById(i)) &&
                    window.pageYOffset <=
                      this.findPos(document.getElementById(i + 1))
                  ) {
                    this.page = i;
                  }
                  i++;
                } while (i < count);
                if (
                  window.pageYOffset >= this.findPos(document.getElementById(i))
                ) {
                  this.page = i;
                }
              }
            });
          });
          this.checkfileadd = true;
        },
        handle_pdf_link: function(params) {
          // Scroll to the appropriate place on our page - the Y component of
          // params.destArray * (div height / ???), from the bottom of the page div
          var page = document.getElementById(String(params.pageNumber));
          page.scrollIntoView();
        },
        findPos(obj) {
          return obj.offsetTop;
        },
        //--------------------end funcfile--------------------//
  },
  beforeCreate() {
    // console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
};
