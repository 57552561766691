//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "../services/constants";
import StockCard from "@/components/cards/StockCard";
import SuccessDialog from "@/components/cards/SuccessDialog";
import { imageUrl } from "@/services/constants";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import unity from "@/unity/unity";

export default {
  name: "edit-advance",
  data() {
    return {
      preview: null,
      image: null,
      preview_list: [],
      oldpreview_list: [],
      image_list: [],
      sumamount: 0.0,
      sumclearamount: 0.0,
      showbtnadd: true,
      showbtnapprove: false,
      advance_code: [],
      advance_code_list: [],
      advance_code_id: 0,
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      customer: [],
      customer_list: [],
      customer_id: 0,
      options: {
        penColor: "#000000",
        backgroundColor: "rgb(255,255,255)",
      },
      initial_requester: "",
      initial_approver: "",
      initial_accountant: "",
      oldinitial_accountant: "",
      initial_data: "",
      pickbank: false,
      pickcheque: false,
      checkcheque: false,
      checkchequefilled: false,
      showsig1: false,
      showsig2: false,
      showsig3: true,
      showbuttonsavesig1: false,
      showbuttonsavesig2: false,
      showbuttonsavesig3: false,
      defaultwordbuttonsig1: "Signature",
      defaultwordbuttonsig2: "Signature",
      totalleftoveramount: 0.0,
      sumwithdrawamount: 0.0,
      sunamountcleared: 0.0,
      showallamount: "",
      desserts: [
        {
          no: "1",
          detail: "",
          withdrawamount: 0,
        },
      ],
      selectbankname: "",
      bankname: [
        "ธนาคารกรุงเทพ",
        "ธนาคารกสิกรไทย",
        "ธนาคารกรุงไทย",
        "ธนาคารทหารไทยธนชาต",
        "ธนาคารไทยพาณิชย์",
        "ธนาคารกรุงศรีอยุธยา",
        "ธนาคารเกียรตินาคินภัทร",
        "ธนาคารซีไอเอ็มบีไทย",
        "ธนาคารทิสโก้",
        "ธนาคารยูโอบี",
        "ธนาคารไทยเครดิตเพื่อรายย่อย",
        "ธนาคารแลนด์ แอนด์ เฮ้าส์",
        "ธนาคารไอซีบีซี (ไทย)",
        "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย",
        "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
        "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย",
        "ธนาคารออมสิน",
        "ธนาคารอาคารสงเคราะห์",
        "ธนาคารอิสลามแห่งประเทศไทย",
      ],
      radioType: ["cash", "cheque"],
      radioGroup: "",
      setusername: "",
      role: localStorage.getItem(server.ROLE),
      department: "DCM",
      daterequester: "",
      dateapprover: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateCurrent: unity.formatDate(
        new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10)
      ),
      menu: false,
      modal: false,
      menu2: false,
      search: "",
      selectedProductId: "",
      confirmDeleteDlg: false,
      mDataArray: [],
      // mDataArray: [{ id: "1", detail: "gg", amout: "1000" }],
      fullPage: true,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "Code",
          value: "advance_title",
          class: "bg-colorth tabletextwhite",
          width: "25%",
          sortable: false,
        },
        {
          text: "Description",
          value: "description",
          class: "bg-colorth tabletextwhite",
          width: "50%",
          sortable: false,
        },
        {
          text: "Amount",
          value: "amount",
          class: "bg-colorth tabletextwhite",
          width: "25%",
          sortable: false,
        },
        // {
        //   text: "Clear Amount",
        //   value: "clear_amount",
        //   class: "bg-colorth tabletextwhite  text-center",
        //   width: "20%",
        //   sortable: false,
        // },
      ],
      desserts: [],
      jobtitle_list: [],
      emp_list: [],
      emp_draw: 0,
      emp_draw_jobtitle: 0,
      editedIndex: -1,
      editedItem: {
        code: "",
        description: "",
        amount: 0,
      },
      defaultItem: {
        code: "",
        description: "",
        amount: 0,
      },
      advance_d: {
        id: "",
        advance_hid: "",
        advance_title: "",
        description: "",
        amount: 0,
        clear_amount: 0,
      },
      requester_detail: {
        signature: "",
      },
      approver_detail: {
        signature: "",
      },
      advance_h: {
        id: "",
        advance_id: "",
        // image: [],
        // customer_id: 0,
        // employee_id: 0,
        // department_id: 0,
        // advance_date: null,
        // sum_amount: 0,
        // clear_date: null,
        accountant_payment_type: null,
        accountant_bank_name: null,
        accountant_bank_number: null,
        accountant_cheque_number: null,
        status: null,
        accountant_remark: null,
        // create_by: null,
        // create_date: null,
      },
      advance_log: {
        module: "AD",
        module_id: 0,
        approve_id: 0,
        approve_status: 0,
        approve_date: null,
      },
      lbl_date_request: false,
      dialogSignature: false,
      dialogSaveDraft: false,
      messageSaveDraft: "",
      paymentType: "",
      bankName: "",
      bankNumber: "",
      chequeNumber: "",
      remark: "",
      approver_remark: "",
      accountant_remark: "",
      main_id: "",
      customer_name: "",
      advance_date_show: "",
    };
  },
  components: {
    StockCard,
    SuccessDialog,
    SuccessDialogPush,
  },
  computed: {
    version() {
      return this.initial_data;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    computedExpectedClearDateFormatted() {
      return unity.formatDate(this.date);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  async mounted() {
    this.$forceUpdate();

    this.initialize();
    this.loadEmp();
    this.loadJobtitle();
    this.loadCustomer();
    this.loadAdvanceCode();

    const res_acc = await api.getAccountDetail(
      localStorage.getItem(server.USER_ID)
    );
    this.initial_accountant = res_acc.data[0].signature;
    this.oldinitial_accountant = this.initial_accountant;

    const detailadvanceid = await api.getDetailiIdAdvance(
      this.$route.params.id
    );
    console.log(detailadvanceid);
    let getemployee_id = detailadvanceid.data[0].employee_id;
    let getapprove_sucess_id = detailadvanceid.data[0].approve_sucess_id;
    let departmentname = "";
    this.dateapprover = unity.formatDate(
      detailadvanceid.data[0].approve_sucess_date
    );

    if (getemployee_id !== 0) {
      let requester_detail = await api.getAccountDetail(getemployee_id);
      this.requester_detail.signature = requester_detail.data[0].signature;
      departmentname = requester_detail.data[0].department_i_d;
      this.setusername =
        requester_detail.data[0].prename_e_n +
        " " +
        requester_detail.data[0].firstname_e_n +
        " " +
        requester_detail.data[0].lastname_e_n;
      this.initial_requester = this.requester_detail.signature;
      this.oldinitial_requester = this.initial_requester;
    }
    if (getapprove_sucess_id !== 0) {
      let approver_detail = await api.getAccountDetail(getapprove_sucess_id);

      this.approver_detail.signature = approver_detail.data[0].signature;
      this.initial_approver = this.approver_detail.signature;
    }
    const res_department = await api.getDepartmentByID(departmentname);

    if (res_department.data[0].name) {
      this.department = res_department.data[0].name;
    }

    // console.log("res_acc : ", JSON.stringify(res_acc.data[0].signature));
    // alert(this.requester_detail.signature);

    this.showsig1 = true;
    this.dialogSignature = true;
    this.showdefaultsignature();
    this.savesig1();

    const userId = localStorage.getItem(server.USER_ID);
    if (userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
  },
  methods: {
    async removeimage(index) {
      this.preview_list.splice(index, 1);
      this.image_list.splice(index, 1);
      console.log(this.preview_list);
      console.log(this.image_list);
    },
    async loadEmp() {
      const result = await api.getAccountslist_by_ComID(
        localStorage.getItem(server.COMPANYID)
      );

      this.emp_list = result.data;
    },
    async loadJobtitle() {
      const result = await api.getAllJobtitleByCompany(
        localStorage.getItem(server.COMPANYID)
      );

      this.jobtitle_list = result.data;
    },
    async loadAdvanceCode() {
      // console.log("loadAdvanceCode");

      let company_id = localStorage.getItem(server.COMPANYID);

      // console.log("loadAdvanceCode company_id:" + company_id);

      const result = await api.getAllAdvanceCodeByCompany(company_id);

      // console.log("loadAdvanceCode : ", JSON.stringify(result.data));
      this.advance_code = result.data;

      result.data.forEach((item) => {
        this.advance_code_list.push(item.code + " " + item.description);
      });
    },
    onSelectAdvanceCodeChange(acvance_code) {
      // var tmp = null;
      // tmp = this.customer.filter((c) => c.name.includes(search_customer));

      // this.customer_id = tmp[0].id;
      // console.log("acvance_code : " + acvance_code);
      var tmp = acvance_code.split(" ");
      this.editedItem.advance_title = tmp[0];
      this.editedItem.description = tmp[1];
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    async savepaid() {
      if (this.initial_accountant == "" || this.initial_accountant == null) {
        // alert("กรุณาเลือกลูกค้า");
        // this.messageSaveDraft = "กรุณาเลือกลงลายมือชื่อ";
        // this.dialogSaveDraft = true;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณาเลือกลงลายมือชื่อ",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (this.paymentType == "") {
        // alert("กรุณาเลือกลูกค้า");
        // this.messageSaveDraft = "กรุณาเลือกวิธีการชำระเงิน";
        // this.dialogSaveDraft = true;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณาเลือกวิธีการชำระเงิน",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (this.paymentType == "transfer") {
        // alert("กรุณาเลือกลูกค้า");
        if (this.selectbankname == "" || this.bankNumber == null) {
          // this.messageSaveDraft = "กรุณากรอกข้อมูลธนาคารให้ถูกต้อง";
          // this.dialogSaveDraft = true;
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Check your information again",
            "กรุณากรอกข้อมูลธนาคารให้ถูกต้อง",
            "text-h5 red--text text-center"
          );
          return;
        }
        this.chequeNumber = "";
      } else if (this.paymentType == "cheque") {
        // alert("กรุณาเลือกลูกค้า");
        if (this.chequeNumber == "") {
          // this.messageSaveDraft = "กรุณากรอกเลขที่เช็คให้ถูกต้อง";
          // this.dialogSaveDraft = true;
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Check your information again",
            "กรุณากรอกเลขที่เช็คให้ถูกต้อง",
            "text-h5 red--text text-center"
          );
          return;
        }
        this.selectbankname = "";
        this.bankNumber = "";
      } else if (this.paymentType == "cash") {
        this.chequeNumber = "";
        this.selectbankname = "";
        this.bankNumber = "";
      }

      this.$showLoader();

      this.advance_log.module_id = this.$route.params.id;
      this.advance_log.approve_id = localStorage.getItem(server.USER_ID);
      this.advance_log.approve_status = 6;
      this.advance_log.approve_date = new Date();

      // this.advance_h.customer_id = this.customer_id;
      // this.advance_h.employee_id = localStorage.getItem(server.USER_ID);
      // this.advance_h.department_id = localStorage.getItem(server.DEPARTMENT_ID);
      // this.advance_h.advance_date = this.dateCurrent;
      // this.advance_h.sum_amount = this.sumwithdrawamount;
      // this.advance_h.image = this.image_list;
      // this.advance_h.clear_date = this.date;
      this.advance_h.accountant_payment_type = this.paymentType;
      this.advance_h.accountant_bank_name = this.selectbankname;
      this.advance_h.accountant_bank_number = this.bankNumber;
      this.advance_h.accountant_cheque_number = this.chequeNumber;
      this.advance_h.status = 6;
      this.advance_h.accountant_remark = this.accountant_remark;
      // this.advance_h.create_by = localStorage.getItem(server.USER_ID);
      // this.advance_h.create_date = this.dateCurrent;
      //   let formData = new FormData();
      //   formData.append("sum_amount", this.sumwithdrawamount);
      //   this.image_list.forEach((x, index) => {
      //     formData.append(`image`, x);
      //   });

      //   formData.append("status", 6);
      //   formData.append("remark", this.remark);
      //   console.log("advance_h : " + JSON.stringify(this.advance_h));
      //   console.log(formData);
      //   console.log("advance_d : " + this.advance_d);
      // for (var pair of formData.entries()) {
      //   console.log(pair[0] + ", " + JSON.stringify(pair[1]));
      // }

      const res_h = await api.updateclearAdvanceH(
        this.advance_h.id,
        this.advance_h
      );
      if (this.initial_accountant !== this.oldinitial_accountant) {
        api.updateUserAccount(localStorage.getItem(server.USER_ID), {
          signature: this.initial_accountant,
        });
      }
      if (res_h.status == 200 || res_h.status == 201) {
        // if (this.desserts.length > 0) {
        //   this.desserts.forEach((item) => {
        //     this.advance_d.advance_hid = res_h.data.id;
        //     this.advance_d.id = item.id;
        //     this.advance_d.advance_title = item.code;
        //     this.advance_d.description = item.description;
        //     this.advance_d.amount = item.amount;
        //     this.advance_d.clear_amount = item.clear_amount;
        //     api.updateAdvanceD(this.advance_d.id, this.advance_d);
        //   });

        // }

        const res_log = await api.addAdvanceLog(this.advance_log);
        if (res_log.status == 200 || res_log.status == 201) {
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "Information Saved",
            "Information successfully saved",
            "text-h5 green--text text-center",
            "/account-pending-advance-list"
          );
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "ไม่สามารถบันทึกได้",
            "text-h5 red--text text-center"
          );
        }
      } else {
        // alert("ไม่สามารถบันทึกได้");
        // this.messageSaveDraft = "ไม่สามารถบันทึกได้";
        // this.dialogSaveDraft = true;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้",
          "text-h5 red--text text-center"
        );
      }

      this.$hideLoader();

      // this.$router.push("/clearadvance-list").catch((err) => {});
    },

    changeFocusText(t) {
      // alert(t);
      if (t === 1) {
        this.$refs["txtCode"].focus();
      } else if (t === 2) {
        this.$refs["txtDescription"].focus();
      } else if (t === 3) {
        if (this.editedItem.advance_title == "") {
          alert("กรุณากรอกรหัสการขออนุมัติ");
          this.$refs["txtCode"].focus();
        } else {
          this.$refs["txtAmount"].focus();
        }
      }
    },
    initialize() {
      // this.sumwithdrawamount = 0;
      // this.desserts.forEach((item) => {
      //   this.sumwithdrawamount += item.amount;
      // });
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeSaveDraft() {
      this.dialogSaveDraft = false;
    },

    closeDelete() {
      // this.sumwithdrawamount = 0;

      // this.desserts.forEach((item) => {
      //   this.sumwithdrawamount += parseInt(item.amount, 10);
      // });

      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    save() {
      if (this.editedItem.advance_title == "") {
        alert("กรุณากรอกรหัสการขออนุมัติ");
        this.$refs["txtCode"].focus();
        return;
      }

      if (this.editedItem.amount == "0" || this.editedItem.amount == 0) {
        alert("กรุณากรอกจำนวนเงิน");
        this.$refs["txtAmount"].focus();
        return;
      }

      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }

      // this.sumwithdrawamount = 0;

      // this.desserts.forEach((item) => {
      //   this.sumwithdrawamount += parseInt(item.amount, 10);
      // });
      console.log("save : " + JSON.stringify(this.desserts));
      this.changeFocusText(1);
      this.close();
    },
    onSelectCustomerChange(search_customer) {
      var tmp = null;
      tmp = this.customer.filter((c) => c.name.includes(search_customer));

      this.customer_id = tmp[0].id;
      // console.log("customer : " + this.customer_id);
    },

    async loadCustomer() {
      // console.log("loadCustomer");
      const result = await api.getAllCustomerList();
      // console.log("result.data : ", JSON.stringify(result.data));
      this.customer = result.data;

      result.data.forEach((item) => {
        this.customer_list.push(item.name);
      });

      //   console.log("this customer:" + JSON.stringify(this.customer));

      //   console.log("this customer:" + this.advance_h.customer_id);
      //   var tmp = null;
      //   tmp = this.customer.filter((c) =>
      //     c.id.includes(1)
      //   );

      let cs_id = parseInt(this.advance_h.customer_id, 10);

      let fill_cus = this.customer.filter(function(c) {
        return c.id == cs_id;
      });
      //   console.log("this customer:" + fill_cus);
      //   console.log("this customer:" + JSON.stringify(fill_cus));
      this.customer_name = fill_cus[0].name;
    },

    editsig1() {
      this.dialogSignature = true;
      this.lbl_date_request = false;
      this.showsig1 = true;
      this.showbuttonsavesig1 = true;
      this.$refs.firstsignaturePad.openSignaturePad();
    },
    editsig2() {
      this.showsig2 = true;
      this.showbuttonsavesig2 = true;
      this.$refs.secondsignaturePad.openSignaturePad();
    },
    editsig3() {
      this.showsig3 = true;
      this.showbuttonsavesig3 = true;
      this.$refs.thirdsignaturePad.openSignaturePad();
    },
    clearsig1() {
      this.lbl_date_request = false;
      this.$refs.firstsignaturePad.clearSignature();
    },
    clearsig2() {
      this.$refs.secondsignaturePad.clearSignature();
    },
    clearsig3() {
      this.$refs.thirdsignaturePad.clearSignature();
    },
    savesig1() {
      this.lbl_date_request = true;
      this.defaultwordbuttonsig1 = "Edit";
      const { isEmpty, data } = this.$refs.firstsignaturePad.saveSignature();
      this.showbuttonsavesig1 = false;
      this.$refs.firstsignaturePad.lockSignaturePad();
      // console.log("initial_requester : " + data);
      // console.log("initial_requester1 : " + this.initial_requester);
      this.initial_requester = data;
    },
    savesig2() {
      this.defaultwordbuttonsig2 = "Edit";
      const { isEmpty, data } = this.$refs.secondsignaturePad.saveSignature();
      this.showbuttonsavesig2 = false;
      this.$refs.secondsignaturePad.lockSignaturePad();
    },
    savesig3() {
      this.defaultwordbuttonsig3 = "Edit";
      const { isEmpty, data } = this.$refs.thirdsignaturePad.saveSignature();
      this.showbuttonsavesig3 = false;
      this.$refs.thirdsignaturePad.lockSignaturePad();
      this.initial_accountant = data;
    },
    changeradio() {
      console.log(this.radioGroup);
      if (this.radioGroup === "cheque") {
        this.paymentType = "cheque";
        this.pickbank = false;
        this.pickcheque = true;
      } else if (this.radioGroup === "bank") {
        this.paymentType = "transfer";
        this.pickcheque = false;
        this.pickbank = true;
      } else {
        this.paymentType = "cash";
        this.pickcheque = false;
        this.pickbank = false;
      }
    },

    showdefaultsignature() {
      this.$nextTick(() => {
        // this.$refs.signaturePaddefault.fromDataURL(this.initial_data);
        // this.$refs.signaturePaddefault.lockSignaturePad();
        this.$refs.firstsignaturePad.fromDataURL(this.initial_requester);
        // this.requester_detail.signature
        this.$refs.firstsignaturePad.lockSignaturePad();
        this.$refs.secondsignaturePad.fromDataURL(this.initial_approver);
        this.$refs.secondsignaturePad.lockSignaturePad();
        this.$refs.thirdsignaturePad.fromDataURL(this.initial_accountant);
        this.$refs.thirdsignaturePad.lockSignaturePad();
      });
    },
    Caltotleamount(item) {
      this.sumamount = 0;
      this.sumclearamount = 0;
      this.sumwithdrawamount = 0;
      this.desserts.forEach((x) => {
        this.sumamount += parseFloat(x.amount);
        // if (x.clear_amount == "" || x.clear_amount == NaN) {
        //   x.clear_amount = 0;
        // }
        this.sumclearamount += parseFloat(x.clear_amount);
      });
      this.sumwithdrawamount = parseFloat(this.sumamount - this.sumclearamount);
    },
    restrictDecimal(item) {
      return item.match(/^\d+\.?\d{0,2}/);
    },
    previewImage: function(event) {
      var input = event.target;
      if (input.files) {
        var reader = new FileReader();
        reader.onload = (e) => {
          this.preview = e.target.result;
        };
        this.image = input.files[0];
        reader.readAsDataURL(input.files[0]);
      }
    },
    previewMultiImage: function(event) {
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.preview_list.push(e.target.result);
          };
          this.image_list.push(input.files[index]);
          reader.readAsDataURL(input.files[index]);
          index++;
        }
      }
      console.log(this.image_list);
    },
    reset: function() {
      this.image = null;
      this.preview = null;
      this.image_list = [];
      this.preview_list = [];
      this.oldpreview_list = [];
    },
  },
  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
    // this.showdefaultsignature();
    this.main_id = this.$route.params.id;
    // alert(this.$route.params.id);

    // advance_h: {
    //         advance_id: "",
    //         customer_id: 0,
    //         employee_id: 0,
    //         department_id: 0,
    //         advance_date: null,
    //         sum_amount: 0,
    //         clear_date: null,
    //         payment_type: null,
    //         bank_name: null,
    //         bank_number: null,
    //         cheque_number: null,
    //         status: null,
    //         remark: null,
    //         create_by: null,
    //         create_date: null,
    //       }
    const res_h = await api.getAllHById(this.main_id);
    // console.log("res_h : " + JSON.stringify(res_h.data));
    if (res_h.data[0].image) {
      let setiamggfile = res_h.data[0].image.split(",");
      setiamggfile.forEach((x) => {
        this.oldpreview_list.push(`${imageUrl}/${x}`);
      });
    }
    this.advance_h.id = res_h.data[0].id;
    this.advance_h.advance_id = res_h.data[0].advance_id;
    this.advance_h.customer_id = res_h.data[0].customer_id;
    this.advance_h.emp_draw = res_h.data[0].emp_draw;
    this.advance_h.emp_draw_jobtitle = res_h.data[0].emp_draw_jobtitle;

    // this.customer_name = tmp[0].name;

    this.advance_h.employee_id = res_h.data[0].employee_id;
    this.advance_h.department_id = res_h.data[0].department_id;
    // this.sumwithdrawamount = res_h.data[0].sum_amount;

    this.advance_h.payment_type = res_h.data[0].payment_type;
    this.advance_h.bank_name = res_h.data[0].bank_name;
    this.advance_h.bank_number = res_h.data[0].bank_number;
    this.advance_h.cheque_number = res_h.data[0].cheque_number;
    this.daterequester = unity.formatDate(res_h.data[0].advance_date);

    this.bankNumber = this.advance_h.bank_number;
    this.selectbankname = this.advance_h.bank_name;
    this.chequeNumber = this.advance_h.cheque_number;

    if (this.advance_h.payment_type === "cheque") {
      this.paymentType = "cheque";
      this.pickbank = false;
      this.pickcheque = true;
      this.radioGroup = "cheque";
    } else if (this.advance_h.payment_type === "transfer") {
      this.paymentType = "transfer";
      this.pickcheque = false;
      this.pickbank = true;
      this.radioGroup = "bank";
    } else {
      this.paymentType = "cash";
      this.pickcheque = false;
      this.pickbank = false;
      this.radioGroup = "cash";
    }

    this.advance_h.status = res_h.data[0].status;
    this.remark = res_h.data[0].remark;
    this.approver_remark = res_h.data[0].approver_remark;
    this.accountant_remark = res_h.data[0].accountant_remark;
    this.advance_h.create_by = res_h.data[0].create_by;
    this.advance_h.create_date = res_h.data[0].create_date;

    this.advance_h.advance_date = res_h.data[0].advance_date;
    this.advance_date_show = unity.formatDate(res_h.data[0].advance_date);

    const res_d = await api.getAllDById(this.$route.params.id);
    // console.log("res_d : " + JSON.stringify(res_d.data));
    this.desserts = res_d.data;

    if (this.desserts) {
      this.sumamount = 0;
      this.sumclearamount = 0;
      this.sumwithdrawamount = 0;
      this.desserts.forEach((x) => {
        this.sumamount += parseFloat(x.amount);
        this.sumclearamount += parseFloat(x.clear_amount);
      });
      this.sumwithdrawamount = parseFloat(this.sumamount);
    }

    // this.$showLoader();
    this.$hideLoader();
  },
  async created() {
    // this.showdefaultsignature();
  },
};
