import httpClient from "@/services/httpClient";
import {
  server
} from "@/services/constants";

// add leave history web
export const addLeaveHistory = (data) => {
  return httpClient.post(server.LEAVE_HISTORY + `/CreateLeaveWeb`, data);
};

// update leave history web
export const updateLeaveHistory = (id, data) => {
  return httpClient.post(server.LEAVE_HISTORY + `/UpdateLeaveWeb/${id}`, data);
};

// get leave history web
export const getLeaveHistoryByHistoryID = (id) => {
  return httpClient.get(
    server.LEAVE_HISTORY + `/getLeaveHistoryByHistoryID/${id}`
  );
};

//getListApproveIdNow
export const getListApproveIdNow = (id) => {
  return httpClient.get(server.LEAVE_HISTORY + `/getListApproveIdNow/${id}`);
};

// approve leave history web
export const updateApprove = (id, data) => {
  return httpClient.put(server.LEAVE_HISTORY + `/updateApprove/${id}`, data);
};

//list data table
export const getListApproveByUserID = (id) => {
  return httpClient.get(server.LEAVE_HISTORY + `/getListApproveByUserID/${id}`);
};

// saveApproval
export const saveApproval = (data) => {
  return httpClient.post(server.LEAVE_HISTORY + `/saveApproval`, data);
};

// saveApproval
export const saveReject = (data) => {
  return httpClient.post(server.LEAVE_HISTORY + `/saveReject`, data);
};

export const getListApproveByUserIDbyCompany = (id, company_id) => {
  return httpClient.get(
    server.LEAVE_HISTORY +
    `/getListApproveByUserIDbyCompany/${id}/${company_id}`
  );
};

export const getListApproveByRequestID = (id) => {
  return httpClient.get(
    server.LEAVE_HISTORY + `/getListApproveByRequestID/${id}`
  );
};

export const getListApproveByApproverID = (id) => {
  return httpClient.get(
    server.LEAVE_HISTORY + `/getListApproveByApproverID/${id}`
  );
};

export const getListApproveByApproverIDbyCompany = (id, company_id) => {
  return httpClient.get(
    server.LEAVE_HISTORY +
    `/getListApproveByApproverIDbyCompany/${id}/${company_id}`
  );
};

//drop downlist request page
export const getListByUserIDV2 = (id) => {
  return httpClient.get(server.LEAVE_QUOTA + `/getListByUserIDV2/${id}`);
};

export const getListByUserIDV2byCompany = (id, company_id) => {
  return httpClient.get(
    server.LEAVE_QUOTA + `/getListByUserIDV2byCompany/${id}/${company_id}`
  );
};

export const getListByUserIDV2byCompanyforEdit = (id, company_id) => {
  return httpClient.get(
    server.LEAVE_QUOTA + `/getListByUserIDV2byCompanyforEdit/${id}/${company_id}`
  );
};

export const getListByUserIDV3 = (id) => {
  return httpClient.get(server.LEAVE_QUOTA + `/getListByUserIDV3/${id}`);
};

export const getListByUserIDV3byCompany = (id, company_id) => {
  return httpClient.get(
    server.LEAVE_QUOTA + `/getListByUserIDV3byCompany/${id}/${company_id}`
  );
};

//list data table used request page
export const getListUseByUserIDV2 = (id) => {
  return httpClient.get(server.LEAVE_QUOTA + `/getListUseByUserIDV2/${id}`);
};

export const getListUseByUserIDV2byCompany = (id, company_id) => {
  return httpClient.get(
    server.LEAVE_QUOTA + `/getListUseByUserIDV2byCompany/${id}/${company_id}`
  );
};

//delete
export const delete_leaveH = (id) => {
  return httpClient.delete(server.LEAVE_HISTORY + `/delete/${id}`);
};

export const searchleavereport = (query) => {
  return httpClient.get(server.LEAVE_HISTORY + `/searchleavereport`, query);
};

