//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialogInvoice_Mis from "@/components/cards/SuccessDialogInvoice_Mis";
import api from "@/services/api";
import { server } from "@/services/constants";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "../computing/tolocalestringnumber";

export default {
  props: ["so_id", "datainv_mis", "datainv_disable"],
 async mounted() {
    // this.$showLoader();
    // setTimeout(() => {
    //   this.$hideLoader();
    // }, 500);
    this.$store.state.global_dialog_invoice_mis = false;
    this.$hideLoader();
    await this.loadmis_list();
    this.loadAdvanceCode();
    this.desserts = this.datainv_mis;
    this.amount_total = 0;
    this.desserts.forEach((item) => {
      if (item.miscellaneous_disbursements == "Others" && item.specify_type) {
        item.miscellaneous_disbursements = item.specify_type;
      }
      item.amount = parseFloat(item.amount);
      item.amount_show = tolocalestringnumber(item.amount);
      this.amount_total += parseFloat(item.amount);
    });
    this.amount_total = tolocalestringnumber(this.amount_total);
  },
  components: {
    SuccessDialogInvoice_Mis,
  },
  computed: {
    version() {
      return this.initial_data;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  data() {
    return {
      amount_total: 0.0,
      dialog: false,
      dialogEdit: false,
      dialogDelete: false,
      dialogAddMis: false,
      dialogSendMail: false,
      text_colorMis: "text-h5 green--text text-center",
      titleMis: "green",
      messageMis: "green",
      link: "",
      isShowPassword: false,
      fullPage: true,
      advance_code_list: [],
      desserts: [],
      headers: [
        {
          text: "Miscellaneous Disbursements",
          value: "miscellaneous_disbursements",
          class: "my-header4-style ",
        },
        // {
        //   text: "Description",
        //   value: "description",
        //   class: "my-header-style",
        // },
        {
          text: "Amount",
          value: "amount_show",
          class: "my-header2-style text-center",
          align: "end",
        },
        {
          text: "Action",
          value: "actions",
          class: "my-header3-style  text-center",
        },
      ],
      editedItem: {
        miscellaneous_disbursements: "",
        specify_type: "",
        // description: "",
        amount: 0,
      },
      miscellaneous_disbursementslist: [
        // "Publication Fees",
        // "Translation Fees",
        // "Legalization Fees",
        // "Courier Services",
        // "Transportation and photocopies",
        // "Government Fee for Work Permit",
        // "Government Fee for Visa",
        // "Certified Fee",
        // "Others",
      ],
      editedIndex: -1,
      dataeditItem:{
        miscellaneous_disbursements:"",
        amount:0,
      }
    };
  },
  methods: {
    async loadmis_list(){
      const result = await api.getInvMiscellaneousByCom_Dep({
        params: {
          company_id: localStorage.getItem(server.COMPANYID),
          department_id: localStorage.getItem(server.DEPARTMENT_ID),
        },
      }
      );
      this.miscellaneous_disbursementslist = result.data;
      this.miscellaneous_disbursementslist.push({title:"Others"})
    },
    async loadAdvanceCode() {
      // console.log("loadAdvanceCode");

      let company_id = localStorage.getItem(server.COMPANYID);

      // console.log("loadAdvanceCode company_id:" + company_id);

      const result = await api.getAllAdvanceCodeByCompany(company_id);

      // console.log("loadAdvanceCode : ", JSON.stringify(result.data));
      this.advance_code = result.data;

      result.data.forEach((item) => {
        this.advance_code_list.push(item.code + " " + item.description);
      });
    },
    onSelectAdvanceCodeChange(acvance_code) {
      // var tmp = null;
      // tmp = this.customer.filter((c) => c.name.includes(search_customer));

      // this.customer_id = tmp[0].id;
      // console.log("acvance_code : " + acvance_code);
      var tmp = acvance_code.split(" ");
      this.editedItem.code = tmp[0];
      this.editedItem.description = tmp[1];
    },
    async onSelectmiscellaneous_disbursementsChange() {},
    async saveeditItem() {
      this.desserts[this.editedIndex].miscellaneous_disbursements = this.dataeditItem.miscellaneous_disbursements;
      this.desserts[this.editedIndex].amount = this.dataeditItem.amount;
    console.log(this.desserts)
      this.desserts.forEach((item, index) => {
        item.amount = stringcommatonumber(item.amount.toString());
        item.amount_show = tolocalestringnumber(item.amount);
      });
      this.close();
      this.dialogEdit = false;
    },
    async closeeditItem() {
      this.dialogEdit = false;
    },
    async openeditItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.dataeditItem.miscellaneous_disbursements = item.miscellaneous_disbursements;
      this.dataeditItem.amount = stringcommatonumber(item.amount.toString());
      this.dialogEdit = true;
    },
    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },
    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },
    close() {
      console.log(this.desserts)
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.amount_total = 0;
      this.desserts.forEach((item,index) => {
        this.amount_total += parseFloat(item.amount);
      });
      this.amount_total = tolocalestringnumber(this.amount_total);
      this.$emit("inv_mistab_data", this.desserts);
    },
    closeDelete() {
      this.sumwithdrawamount = 0;

      this.desserts.forEach((item) => {
        this.sumwithdrawamount += parseInt(item.amount, 10);
      });

      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
      this.amount_total = 0;
      this.desserts.forEach((item) => {
        this.amount_total += parseFloat(item.amount);
      });
      this.amount_total = tolocalestringnumber(this.amount_total);

      this.$emit("inv_mistab_data", this.desserts);
    },
    setupAlertDialog(status, titleMis, messageMis, text_colorMis) {
      this.titleMis = titleMis;
      this.messageMis = messageMis;
      this.dialogAddMis = status;
      this.text_colorMis = text_colorMis;
    },

    changeFocusText(t) {
      // alert(t);
      if (t === 1) {
        this.$refs["txtCode"].focus();
      } else if (t === 2) {
        this.$refs["txtDescription"].focus();
      } else if (t === 3) {
        if (this.editedItem.code == "") {
          alert("กรุณากรอกรหัสการขออนุมัติ");
          this.$refs["txtCode"].focus();
        } else {
          this.$refs["txtAmount"].focus();
        }
      }
    },

    saveMisTab() {
      if (this.editedItem.miscellaneous_disbursements == "" || !this.editedItem.miscellaneous_disbursements) {
        this.$store.state.global_dialog_invoice_mis = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "กรุณาเลือก Miscellaneous Disbursements",
          "text-h5 red--text text-center"
        );
        this.$refs["MiscellaneousDisbursements"].focus();
        return;
      }
      if (
        this.editedItem.miscellaneous_disbursements == "Others" &&
        (this.editedItem.specify_type == "" || !this.editedItem.specify_type)
      ) {
        this.$store.state.global_dialog_invoice_mis = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "กรุณาเลือกกรอกข้อมูล specify_type",
          "text-h5 red--text text-center"
        );
        this.$refs["SpecifyType"].focus();
        return;
      }

      if (this.editedItem.amount == "0" || this.editedItem.amount == 0 || !this.editedItem.amount) {
        this.$store.state.global_dialog_invoice_mis = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "กรุณากรอกจำนวนเงิน",
          "text-h5 red--text text-center"
        );
        this.$refs["txtAmount"].focus();
        return;
      }

      // if (this.editedIndex > -1) {
      //   Object.assign(this.desserts[this.editedIndex], this.editedItem);
      // } else {
        this.desserts.push(this.editedItem);
      // }

      this.sumwithdrawamount = 0;

      this.desserts.forEach((item) => {
        item.amount = parseInt(item.amount);
        item.amount_show = tolocalestringnumber(item.amount);
        this.sumwithdrawamount += parseInt(item.amount, 10);
      });
      console.log("save : " + JSON.stringify(this.desserts));
      // this.changeFocusText(1);
      this.close();
    },
  },
  beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
};
