//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "../services/constants";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "./computing/tolocalestringnumber";

export default {
  name: "NewAdvance",
  data() {
    return {
      document_type: "",
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
    };
  },
  components: {
    SuccessDialog,
    SuccessDialogPush,
  },
  computed: {},
  watch: {},
  async mounted() {
    const userId = localStorage.getItem(server.USER_ID);
    if (userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
    this.$hideLoader();
  },
  methods: {
    async saveItem() {
      if (this.document_type == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check information",
          "Please Enter Document Type Data",
          "text-h5 red--text text-center"
        );
        this.$refs["myDocumentType"].focus();
        return;
      }
      await this.checkDocument();

      const additem = {
        doctype: this.document_type,
        department_authorization: "",
        user_create: localStorage.getItem(server.USER_ID),
      };
      this.$showLoader();
      const res_add = await api.createDoctype(additem);
      if (res_add.status == 200) {
        this.$hideLoader();
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "Information Saved",
          "Information successfully saved",
          "text-h5 green--text text-center",
          `/document-setup-edit/${res_add.data.id}`
        );
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้",
          "text-h5 red--text text-center"
        );
      }
      // this.$router.push(`/document-setup-edit/${1}`);
    },
    async checkDocument() {
      if (this.document_type != "") {
        const res_check = await api.getCheckDoctype(this.document_type);
        if (res_check.data[0].status != 0) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Check information",
            "Duplicate Document Type, Please Enter New Document Type Data",
            "text-h5 red--text text-center"
          );
          this.$refs["myDocumentType"].focus();
          return;
        }
      }
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
  },
  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
};
