//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const moment = require('moment-timezone');
import SuccessDialogPushpaymentIM from "@/components/cards/SuccessDialogPushpaymentIM";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import api from "@/services/api";
import {
  server,
  imageappointmentTMUrl,
  fileappointmentTMUrl,
} from "@/services/constants";
import pdfvuer from "pdfvuer";

export default {
  name: "AppoinmrntPatentOperation",
  async mounted() {
    // this.$showLoader();
    await this.loaddatafile_no();
    await this.loaddataobjecttypeBygroupId();
    await this.loaddatadocumentPTtype();
    this.$hideLoader();
  },
  components: {
    SuccessDialog,
    SuccessDialogPush,
    SuccessDialogPushpaymentIM,
    pdf: pdfvuer,
  },
  data() {
    return {
      querydata:{},
      dataappiontment:{},
      dialogDelete: false,
      isShowPassword: false,
      fullPage: true,
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      objecttype_list: [],
      menuReceive: false,
      menuStart: false,
      menuDeadline: false,
      sourcetypelist: ["Notice", "Notice-1", "Notice-2", "Mail"],
      category_ptlist: [
        "New Application",
        "Office Action",
        "Publication Fee",
        "Publication",
        "Substantive Examination",
        "Publication Fee & Registration Fee",
        "Publication Fee & Registration Fee & Annuity Fee",
        "Registration Fee",
        "Registration Fee & Annuity Fee",
        "Certificate",
        "Annuity Fee",
      ],
      typelist: [
        { type_name: "Design", type_number: "1" },
        { type_name: "Invention", type_number: "2" },
        { type_name: "Petty", type_number: "3" },
        { type_name: "Invention/PCT", type_number: "4" },
      ],
      documenttype_ptlist: [],
      app_noshow: "",
      typeshow: 0,
      titleshow: "",
      appointment_id: 0,
      receive_date: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      startdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      enddate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      internaldate: new Date(
        Date.now() - new Date().getTimezoneOffset() * 60000
      )
        .toISOString()
        .substr(0, 10),
      itemdata: {
        objecttype: 0,
        doctype: 0,
        trademark_name: "",
        refno: "",
        categories: "",
        receive_date: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        startdate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        enddate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
          .toISOString()
          .substr(0, 10),
        internaldate: new Date(
          Date.now() - new Date().getTimezoneOffset() * 60000
        )
          .toISOString()
          .substr(0, 10),
        sourcetype: "",
        description: "",
        remark: "",
        report: false,
        paymentrequest: false,
        cert: false,
        nextdeadlineninety: false,
        nextdeadlinesixty: false,
        done: false,
      },
      getpreview_listnotice: [],
      preview_listnotice: [],
      image_listnotice: [],
      filenamenotice: [],
      preview_listnotice2: [],
      preview_listattach: [],
      image_listattach: [],
      filenameattach: [],
      getpreview_listattach: [],
      preview_listattach2: [],
      previewpopupfile: [],
      previewpopupfile2: [],
      checkfileapreview: false,
      dialogpreviewfilenotice: false,
      page: 1,
      numPages: 0,
      numPagespreview: 0,
      numPagespreviewattach: 0,
      pdfdata: undefined,
      errors: [],
      scale: "page-width",
      previewpopupattachfile2: [],
      previewpopupattachfile: [],
      checkattachfileapreview: false,
      dialogpreviewattachfile: false,
      previewattachimage: "",
      dialogpreviewattachimage: false,
      dialogconfirmdeletenoticefile: false,
      dialogconfirmdeleteattachfile: false,
      indexdeleteattachfile: -1,
      customer_id_forpatmentrequest:0,
    };
  },
  methods: {
    async previewMultiFile(event) {
      this.getpreview_listnotice = [];
      this.preview_listnotice = [];
      this.image_listnotice = [];
      this.filenamenotice = [];
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.getpreview_listnotice.push(e.target.result);
            this.preview_listnotice.push(e.target.result);
          };
          if (input.files[index].type === "application/pdf") {
            this.image_listnotice.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
      console.log(this.getpreview_listnotice);
      // await this.getPdf(this.getpreview_listnotice, "newfile");
      this.preview_listnotice2 = this.preview_listnotice;
      this.image_listnotice.forEach((x) => {
        this.filenamenotice.push(x.name);
      });
      this.checkfilenotice = true;
      console.log(this.image_listnotice);
    },
    async previewnoticefilepdf() {
      let pdf_newTab = window.open("");
      pdf_newTab.document.write(
        "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
          this.getpreview_listnotice[0] +
          "'></iframe></body></html>"
      );

      return;
      this.previewpopupfile2 = [];
      await this.getPdfforpreview(this.getpreview_listnotice[0]);
      this.previewpopupfile2 = this.previewpopupfile;
      this.checkfileapreview = true;
      this.dialogpreviewfilenotice = true;
    },
    async closepreviewfile() {
      this.dialogpreviewfilenotice = false;
    },
    async getPdfforpreview(datafile) {
      this.previewpopupfile = [];
      let getfile = "";
      getfile = datafile;
      this.previewpopupfile.push(pdfvuer.createLoadingTask(`${getfile}`));
      this.previewpopupfile.forEach((item, index) => {
        item.then((pdf) => {
          this.numPagespreview = pdf.numPages;
        });
      });
      console.log(this.previewpopupfile);
    },
    async confirmdeletenoticefile() {
      this.dialogconfirmdeletenoticefile = true;
    },
    async closedialogconfirmdeletenoticefile() {
      this.dialogconfirmdeletenoticefile = false;
    },
    async deletedialogconfirmdeletenoticefile() {
      this.getpreview_listnotice = [];
      this.preview_listnotice = [];
      this.image_listnotice = [];
      this.filenamenotice = [];
      this.dialogconfirmdeletenoticefile = false;
    },
    async previewMultiAttachFile() {
      // this.preview_listattach = [];
      // this.image_listattach = [];
      this.filenameattach = [];
      var input = event.target;
      var count = input.files.length;
      var index = 0;
      if (input.files) {
        while (count--) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.getpreview_listattach.push(e.target.result);
            this.preview_listattach.push(e.target.result);
          };
          console.log(input.files[index].type);
          if (
            input.files[index].type === "application/pdf" ||
            input.files[index].type.split("/")[0] === "image"
          ) {
            this.image_listattach.push(input.files[index]);
            reader.readAsDataURL(input.files[index]);
          }
          index++;
        }
      }
      console.log(this.getpreview_listattach);
      // await this.getPdf(this.getpreview_listattach, "newfile");
      this.preview_listattach2 = this.preview_listattach;
      this.image_listattach.forEach((x) => {
        this.filenameattach.push(x.name);
      });
      this.checkfileattach = true;
      console.log(this.image_listattach);
    },
    async previewattachfile(index) {
      if (this.filenameattach[index].split(/[\s.]+/).pop() == "pdf") {
        let pdf_newTab = window.open("");
        pdf_newTab.document.write(
          "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
            this.getpreview_listattach[index] +
            "'></iframe></body></html>"
        );

        return;
        this.previewpopupattachfile2 = [];
        await this.getPforpreviewattach(this.getpreview_listattach[index]);
        this.previewpopupattachfile2 = this.previewpopupattachfile;
        this.checkattachfileapreview = true;
        this.dialogpreviewattachfile = true;
      } else {
        this.previewattachimage = this.getpreview_listattach[index];
        this.dialogpreviewattachimage = true;
      }
    },
    async getPforpreviewattach(datafile) {
      this.previewpopupattachfile = [];
      let getfile = "";
      getfile = datafile;
      this.previewpopupattachfile.push(pdfvuer.createLoadingTask(`${getfile}`));
      this.previewpopupattachfile.forEach((item, index) => {
        item.then((pdf) => {
          this.numPagespreviewattach = pdf.numPages;
        });
      });
      console.log(this.previewpopupattachfile);
    },
    async closepreviewattachfile() {
      this.dialogpreviewattachfile = false;
    },
    async closepreviewattachimage() {
      this.dialogpreviewattachimage = false;
    },
    async confirmdeleteattachfile(index) {
      this.indexdeleteattachfile = index;
      this.dialogconfirmdeleteattachfile = true;
    },
    async closedialogconfirmdeleteattachfile() {
      this.indexdeleteattachfile = -1;
      this.dialogconfirmdeleteattachfile = false;
    },
    async deletedialogconfirmdeleteattachfile() {
      this.filenameattach.splice(this.indexdeleteattachfile, 1);
      this.preview_listattach.splice(this.indexdeleteattachfile, 1);
      this.image_listattach.splice(this.indexdeleteattachfile, 1);
      this.getpreview_listattach.splice(this.indexdeleteattachfile, 1);
      this.dialogconfirmdeleteattachfile = false;
    },
    async loaddatafile_no() {
      if (this.$route.params.appointment_id) {
        this.appointment_id = this.$route.params.appointment_id;
        const resultappointment = await api.AppointmentgetById(
          this.appointment_id
        );
        this.itemdata.refno = resultappointment.data[0].refno;
        this.itemdata.objecttype = resultappointment.data[0].objecttype;
        this.itemdata.receive_date =
          resultappointment.data[0].receive_date == "0000-00-00"
            ? new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10)
            : resultappointment.data[0].receive_date;
        this.itemdata.startdate = resultappointment.data[0].startdate;
        this.itemdata.enddate = resultappointment.data[0].enddate;
        this.itemdata.internaldate =
          resultappointment.data[0].internaldate == "0000-00-00"
            ? new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                .toISOString()
                .substr(0, 10)
            : resultappointment.data[0].internaldate;
        this.itemdata.doctype = resultappointment.data[0].doctype;
        this.itemdata.categories = resultappointment.data[0].categories;
        this.itemdata.description = resultappointment.data[0].description;
        this.itemdata.remark = resultappointment.data[0].remark;
        this.itemdata.nextdeadlineninety =
          resultappointment.data[0].nextdeadlineninety;
        this.itemdata.nextdeadlinesixty =
          resultappointment.data[0].nextdeadlinesixty;
        this.itemdata.report = resultappointment.data[0].report;
        this.itemdata.paymentrequest = resultappointment.data[0].paymentrequest;
        this.itemdata.cert = resultappointment.data[0].cert;
        this.itemdata.done = resultappointment.data[0].done;
        this.itemdata.sourcetype = resultappointment.data[0].sourcetype;
        if (this.itemdata.refno != " ") {
          this.onSearchfile_nosave(this.itemdata.refno);
        }
        this.itemdata.receive_date = this.formatDate(
          this.itemdata.receive_date
        );
        this.itemdata.startdate = this.formatDate(this.itemdata.startdate);
        this.itemdata.enddate = this.formatDate(this.itemdata.enddate);
        this.itemdata.internaldate = this.formatDate(
          this.itemdata.internaldate
        );
        if (resultappointment.data[0].notice_file) {
          this.filenamenotice[0] = resultappointment.data[0].notice_file;
          this.getpreview_listnotice[0] = `${fileappointmentTMUrl}/${this.$route.params.appointment_id}/${this.filenamenotice}`;
          this.image_listnotice.push(this.getpreview_listnotice[0]);
        }
        if (resultappointment.data[0].attachment_files) {
          this.filenameattach = resultappointment.data[0].attachment_files.split(
            ","
          );
          this.filenameattach.forEach((x, index) => {
            if (x.split(/[\s.]+/).pop() == "pdf") {
              this.image_listattach.push({name:
                `${fileappointmentTMUrl}/${this.$route.params.appointment_id}/${x}`}
              );
              this.getpreview_listattach.push(
                `${fileappointmentTMUrl}/${this.$route.params.appointment_id}/${x}`
              );
            } else {
              this.image_listattach.push({name:
                `${imageappointmentTMUrl}/${this.$route.params.appointment_id}/${x}`}
              );
              this.getpreview_listattach.push(
                `${imageappointmentTMUrl}/${this.$route.params.appointment_id}/${x}`
              );
            }
          });
        }
      }
    },
    async loaddataobjecttypeBygroupId() {
      const result = await api.getObjectTypeByGroupId(2);
      //console.log(result.data);
      this.objecttype_list = result.data;
    },
    async loaddatadocumentPTtype() {
      const result = await api.getdocumenttype_ptcode();
      //console.log(result.data);
      this.documenttype_ptlist = result.data;
    },
    async onSearchfile_no(file_no) {
      if (!file_no || file_no.trim() == "") {
        this.itemdata.objecttype = 0;
        this.itemdata.refno = "";
        this.app_noshow = "";
        this.titleshow = "";
        this.itemdata.trademark_name = "";
        this.typeshow = 0;
        return;
      }
      //alert(file_no);
      const result = await api.getfile_noPT(file_no);
      console.log(result.data[0]);
      if (result.data[0]) {
        //alert("hevedata");
        this.titleshow = result.data[0].title;
        this.itemdata.objecttype = result.data[0].tm_type;
        this.app_noshow = result.data[0].app_no;
        this.itemdata.trademark_name = result.data[0].name_of_client;
        this.typeshow = result.data[0].type;
        this.customer_id_forpatmentrequest = result.data[0].agent_contact_custid != 0 ? result.data[0].agent_contact_custid : result.data[0].owner_contact_custid != 0 ? result.data[0].owner_contact_custid : 0;
      } else {
        this.itemdata.objecttype = 0;
        this.itemdata.refno = "";
        this.app_noshow = "";
        this.titleshow = "";
        this.itemdata.trademark_name = "";
        this.typeshow = 0;
        this.customer_id_forpatmentrequest = 0;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Search File No. ",
          "File No. not found ",
          "text-h5 red--text text-center"
        );
        return;
      }
    },
    async onSearchfile_nosave(file_no) {
      if (!file_no || file_no.trim() == "") {
        this.itemdata.refno = "";
        this.app_noshow = "";
        this.titleshow = "";
        this.itemdata.trademark_name = "";
        this.typeshow = 0;
        return;
      }
      //alert(file_no);
      const result = await api.getfile_noPT(file_no);
      //console.log(result.data[0]);
      if (result.data[0]) {
        //alert("hevedata");
        this.titleshow = result.data[0].title;
        this.app_noshow = result.data[0].app_no;
        this.itemdata.trademark_name = result.data[0].name_of_client;
        this.typeshow = result.data[0].type;
        this.customer_id_forpatmentrequest = result.data[0].agent_contact_custid != 0 ? result.data[0].agent_contact_custid : result.data[0].owner_contact_custid != 0 ? result.data[0].owner_contact_custid : 0;
      } else {
        this.itemdata.refno = "";
        this.app_noshow = "";
        this.titleshow = "";
        this.itemdata.trademark_name = "";
        this.typeshow = 0;
        this.customer_id_forpatmentrequest = 0;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Search File No. ",
          "File No. not found ",
          "text-h5 red--text text-center"
        );
        return;
      }
    },
    async saveItem(mode) {
      this.onSearchfile_nosave(this.itemdata.refno);
      //console.log(this.itemdata);
      if (this.itemdata.refno == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check data ",
          "Put File NO ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.itemdata.objecttype == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check data ",
          "Put Object Type ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.itemdata.doctype == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check data ",
          "Put PF Work Type ",
          "text-h5 red--text text-center"
        );
        return;
      }
      if (this.itemdata.trademark_name == "") {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check data ",
          "Put Client Name ",
          "text-h5 red--text text-center"
        );
        return;
      }
      const checkfile_noPT = await api.getfile_noPT(this.itemdata.refno);
      if (checkfile_noPT.data[0]) {
        // console.log(this.showaddItem);
        // this.itemdata.refno = this.itemdata.refno.trim();
        // this.itemdata.categories = this.itemdata.categories.trim();
        // this.itemdata.description = this.itemdata.description.trim();
        // this.itemdata.remark = this.itemdata.remark.trim();
        // this.itemdata.receive_date = this.changeformatDatetoinsert(
        //   this.itemdata.receive_date
        // );
        // this.itemdata.startdate = this.changeformatDatetoinsert(
        //   this.itemdata.startdate
        // );
        // this.itemdata.enddate = this.changeformatDatetoinsert(
        //   this.itemdata.enddate
        // );
        // this.itemdata.internaldate = this.changeformatDatetoinsert(
        //   this.itemdata.internaldate
        // );
        let formData = new FormData();
        if (this.image_listnotice.length > 0) {
          formData.append(`file`, this.image_listnotice[0]);
        }
        if (this.image_listattach.length > 0) {
          this.image_listattach.forEach((x, index) => {
            formData.append(`file`, x);
          });
        }
        if (this.image_listnotice.length > 0) {
          formData.append(
            `notice_file`,
            this.filenamenotice[0].replace(/\s/g, "_")
          );
        } else {
          formData.append(`notice_file`, "");
        }
        if (this.image_listattach.length > 0) {
          let getnamefilenameattach = "";
          this.filenameattach.forEach((x, index) => {
            if (index > 0) {
              getnamefilenameattach += `,${x}`;
            } else {
              getnamefilenameattach += `${x}`;
            }
          });
          formData.append(
            `attachment_files`,
            getnamefilenameattach.replace(/\s/g, "_")
          );
        } else {
          formData.append(`attachment_files`, "");
        }

        formData.append("objecttype", this.itemdata.objecttype);
        formData.append("doctype", this.itemdata.doctype);
        formData.append("cert", this.itemdata.cert);
        formData.append("done", this.itemdata.done);
        formData.append("nextdeadlineninety", this.itemdata.nextdeadlineninety);
        formData.append("nextdeadlinesixty", this.itemdata.nextdeadlinesixty);
        if (mode == "paymentrequest") {
          this.itemdata.paymentrequest = 1;  
        }
        formData.append("paymentrequest", this.itemdata.paymentrequest);
        formData.append("report", this.itemdata.report);
        formData.append("sourcetype", this.itemdata.sourcetype);
        formData.append("trademark_name", this.itemdata.trademark_name);
        formData.append(`refno`, this.itemdata.refno.trim());
        formData.append(`categories`, this.itemdata.categories.trim());
        formData.append(`description`, this.itemdata.description.trim());
        formData.append(`remark`, this.itemdata.remark);
        formData.append(
          `receive_date`,
          this.changeformatDatetoinsert(this.itemdata.receive_date)
        );
        formData.append(
          `startdate`,
          this.changeformatDatetoinsert(this.itemdata.startdate)
        );
        formData.append(
          `enddate`,
          this.changeformatDatetoinsert(this.itemdata.enddate)
        );
        formData.append(
          `internaldate`,
          this.changeformatDatetoinsert(this.itemdata.internaldate)
        );

        this.$showLoader();
        const resultAppointment = await api.AppointmentUpdate(
          this.appointment_id,
          formData
        );

        //console.log(resultAppointment.status);
        if (
          resultAppointment.status == 200 ||
          resultAppointment.status == 201
        ) {

          var dataAdd = {
              user_id: localStorage.getItem(server.USER_ID),
              log_in_out_id: localStorage.getItem(server.LOGIN_ID),
              action: "Update",
              table_name: "appointment",
              table_id: this.$route.params.appointment_id,
              date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
          }

          await api.addTransectionLog(dataAdd)

          var dataEditLog = {
            last_update: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
          }
          await api.updateLogInOutLog(localStorage.getItem(server.LOGIN_ID),dataEditLog)

          this.$hideLoader();
          if (mode == "paymentrequest") {
            let res_shearch_data = {
              objecttype_id: this.itemdata.objecttype,
              work_type: this.itemdata.doctype,
            }
            const res_data_table = await api.getIPPriceListS("PT",res_shearch_data)
            // console.log(res_data_table.data)
            
            this.dataappiontment = {};
            if(res_data_table.data.length > 0){
              this.dataappiontment = {
                description_inv: res_data_table.data[0].price_desc,
                price_inv: Number(res_data_table.data[0].thb)
              }
            }
            this.$store.state.global_push_dialog = true;
            this.setupAlertDialogPushpaymentIM(
              true,
              "save data",
              "save data success",
              "text-h5 green--text text-center",
              `/new-free-invoice/0/invoice/D/${this.customer_id_forpatmentrequest}/IP`,
              this.dataappiontment
            );
            return;
          }else{
            this.$hideLoader();
            localStorage.setItem(server.MODELMENU, 6);
            this.$store.state.global_push_dialog = true;

            this.setupAlertDialogPush(
              true,
              "save data",
              "save data success",
              "text-h5 green--text text-center",
              "/appointment-patent-list"
            );
            return;
          }
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "save data",
            "can't save data",
            "text-h5 red--text text-center"
          );
          return;
        }
      } else {
        this.itemdata.objecttype = 0;
        this.itemdata.refno = "";
        this.app_noshow = "";
        this.titleshow = "";
        this.itemdata.trademark_name = "";
        this.typeshow = 0;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Search File No. ",
          "File No. not found ",
          "text-h5 red--text text-center"
        );
        return;
      }
    },
    deleteItem() {
      this.dialogDelete = true;
    },
    async deleteItemConfirm() {
      this.$showLoader();
      this.dialogDelete = false;
      const resdel = await api.AppointmentDelete(this.appointment_id);

      //console.log(resdel);
      if (resdel.status == 200) {

          var dataAdd = {
              user_id: localStorage.getItem(server.USER_ID),
              log_in_out_id: localStorage.getItem(server.LOGIN_ID),
              action: "Delete",
              table_name: "appointment",
              table_id: this.appointment_id,
              date: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
          }

          await api.addTransectionLog(dataAdd)

          var dataEditLog = {
            last_update: moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss')
          }

        // this.loaddatadetails();
        this.closeDelete();
        this.$hideLoader();

        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "delete data",
          "delete data success",
          "text-h5 green--text text-center",
          "/appointment-patent-list"
        );
        return;
      } else {
        // this.loaddatadetails();

        this.closeDelete();
        this.$hideLoader();

        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "delete data",
          "can't delete data",
          "text-h5 red--text text-center"
        );
        return;
      }
    },
    closeDelete() {
      this.dialogDelete = false;
    },
    changeformatDatetoinsert(date) {
      if (!date) return null;

      const [day, month, year] = date.split("/");
      return `${year}-${month}-${day}`;
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
    },
    async setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    async setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    async setupAlertDialogPushpaymentIM(status, title, message, text_color, link,querydata) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
      this.querydata = querydata;
    },
  },
  beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
  watch: {
    receive_date(val) {
      this.itemdata.receive_date = this.formatDate(this.receive_date);
    },
    startdate(val) {
      this.itemdata.startdate = this.formatDate(this.startdate);
    },
    enddate(val) {
      this.itemdata.enddate = this.formatDate(this.enddate);
    },
    internaldate(val) {
      this.itemdata.internaldate = this.formatDate(this.internaldate);
    },
  },
};
