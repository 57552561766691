//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server,leavequotaFile } from "@/services/constants";
import { language } from "./language/Advancelist.js";
import { tolocalestringnumber } from "./computing/tolocalestringnumber";
import {
  XlsxRead,
  XlsxTable,
  XlsxSheets,
  XlsxJson,
  XlsxWorkbook,
  XlsxSheet,
  XlsxDownload,
} from "vue-xlsx";
import unity from "@/unity/unity";


export default {
  name: "advance-list",
  components: {
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  data(vm) {
    return {
      linkdownloadleavequota:`${leavequotaFile}/leavequota_template.xlsx`,
      search_status: "",
      exportleavequota: "leavequota.xlsx",
      sheetName: "leavequota",
      sheets: [
        {
          name: "leavequota",
          data: [],
        },
      ],
      collection: [{ a: 1, b: 2 }],
      deletedItem_id: 0,
      deletedItem: {
        employee_id: 0,
        leave_period_from: "",
        leave_period_to: "",
      },
      menu: false,
      menu2: false,
      menuCPfrom: false,
      menuCPto: false,
      search: "",
      toggleEnable: false,
      selectstatus: "",
      dataAll: [],
      status: ["Active", "Inactive"],
      datefrom: null,
      dateto: null,
      // datefrom: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),
      // dateto: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      //   .toISOString()
      //   .substr(0, 10),
      periodC_datefrom: vm.formatDate(new Date().toISOString().substr(0, 10)),
      periodC_dateto: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      periodD_datefrom: vm.formatDate(new Date().toISOString().substr(0, 10)),
      periodD_dateto: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      seticonsearchmore: { icon: "mdi mdi-chevron-down", action: false },
      setlanguage: language.en,
      defaultlanguage: "en",
      // regexlang: "th",
      chooselanguage: [
        { name: "en", flag: "gb" },
        { name: "th", flag: "th" },
      ],
      aa: "สวัสดี",
      dialogEdit: false,
      dialogDelete: false,
      dialogClosePeriod: false,
      mDataArray: [],
      position: [],
      approver: [],
      department: [],
      department_list: [],
      position_list: [],
      company: [],
      authorize: [],
      headers: [
        { text: "Department", value: "company_name" },
        { text: "Employee ID", value: "employee_id" },
        { text: "Employee Name", value: "employee_name" },
        { text: "Period", value: "period" },
        { text: "Status", value: "status" },
        { text: "", value: "actions" },
      ],
      dialogAdd:false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
    };
  },
  components: {
    SuccessDialog,
  },
  async beforeCreate() {
    //console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
  async created() {},
  beforeUpdate() {},
  computed: {
    itemsForSelected() {
      if (this.search.length) {
        return this.dataSource.filter((item) => this.search.includes(item));
      }
      return this.dataSource;
    },
    computedDateFromFormatted() {
      return unity.formatDate(this.datefrom);
    },
    computedDateToFormatted() {
      return unity.formatDate(this.dateto);
    },
  },
  async mounted() {
    await api.checkVersion();
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);

    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed",
        "Please Logout And Login Again",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(this.userId, router_path);

    // console.log("res_auth:" + JSON.stringify(res_auth.data));

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

 
    // this.$router.back();

    if (!this.authorize_view) {
      this.$router.back();
    }

    // ----------------- Check Authorize ---------------------------

    let comp_id = localStorage.getItem(server.COMPANYID);
    // await this.loadCompanyMaster(comp_id);
    // await this.loadDepartmentMaster(comp_id);
    // await this.loadPositionMaster(comp_id);

    await this.loadAuthorize();
    await this.loaddatadetails();

    this.userId = localStorage.getItem(server.USER_ID);
    if (this.userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
  },
  methods: {
    openeditItem(item) {

      this.$router.push(`/edit-leave-quota/${item.employee_id}/${item.period_datefrom}/${item.period_dateto}`);
    },
    async openClosePeriod() {
      this.dialogClosePeriod = true;
    },
    async closedialogClosePeriod() {
      this.dialogClosePeriod = false;
      this.periodC_datefrom = this.periodD_datefrom;
      this.periodC_dateto = this.periodD_dateto;
    },
    deleteItem(item) {
      // this.editedIndex = this.desserts.indexOf(item);
      // this.editedItem = Object.assign({}, item);
      this.deletedItem_id = item.employee_id;
      this.deletedItem.employee_id = item.employee_id;
      this.deletedItem.leave_period_from = item.period_datefrom;
      this.deletedItem.leave_period_to = item.period_dateto;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.deletedItem_id = 0;
      this.deletedItem = {
        employee_id: 0,
        leave_period_from: "",
        leave_period_to: "",
      };
      this.dialogDelete = false;
    },
    ckeckperiod (){
      if(this.periodC_datefrom > this.periodC_dateto){
        this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Failed",
            "Period From > Period To",
            "text-h5 red--text text-center"
          );
          return;
      }
    },
    findChaege() {
      // alert(this.search_status)
      this.mDataArray = this.dataAll.filter((item) => {
        if(this.datefrom == null || this.dateto == null){
          if(this.search_status != null){
            if (
              item.status == this.search_status
              ) {
              return item;
            }
          }else{
            return item;
          }
        }
        if(this.datefrom != null && this.dateto != null){
          if(this.search_status != null){
            if (
                item.status == this.search_status &&
                item.period_datefrom >= this.datefrom &&
                item.period_dateto <= this.dateto  
                
                ) {
              return item;
            }
          }else{
            if (
                item.period_datefrom >= this.datefrom &&
                item.period_dateto <= this.dateto  
                
                ) {
              return item;
            }
          }
        }
      });
    },
    async saveCloesPeriod(){
      if(this.periodC_datefrom > this.periodC_dateto){
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
            true,
            "Failed",
            "Period From > Period To",
            "text-h5 red--text text-center"
        );
        return; 
      }
      const updatecloseperiod = await api.updateCloseLeaveQuota(this.periodC_datefrom,this.periodC_dateto);
      // console.log("updatecloseperiod.data:");
      // console.log(updatecloseperiod);
      if (updatecloseperiod.status == 200 || updatecloseperiod.status == 201) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
            true,
            "Information Saved",
            "Information successfully saved",
            "text-h5 green--text text-center"
        );
        this.closedialogClosePeriod();
        return;
      }else{
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
            true,
            "Information Saved",
            "ไม่สามารถบันทึกได้",
            "text-h5 red--text text-center"
        );
        this.closedialogClosePeriod();
        return;
      }
    },
    async deleteItemConfirm() {
  
      // this.desserts.splice(this.editedIndex, 1);
      const resdel = await api.deleteLeave_quota(this.deletedItem);
      if(resdel.data[0].status == 1){
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
            true,
            "ไม่สามารถลบข้อมูลได้",
            "มีการใช้งาน  LeaveQuota นี้อยู่",
            "text-h5 red--text text-center"
        )
      }else{
        if(resdel.data[0].status == 0){
            this.$store.state.global_dialog = true;
              this.setupAlertDialog(
                  true,
                  "ลบข้อมูล",
                  "ลบข้อมูลสำเร็จ",
                  "text-h5 green--text text-center"
            );
        }else{
          this.$store.state.global_dialog = true;
              this.setupAlertDialog(
                  true,
                  "ลบข้อมูล",
                  "ลบข้อมูลไม่สำเร็จ",
                  "text-h5 red--text text-center"
            );
        }
      }
      this.loaddatadetails();
      this.closeDelete();
      this.closedialogClosePeriod()
    },

    showsearchmore() {
      this.toggleEnable = !this.toggleEnable;
      this.seticonsearchmore.action = !this.seticonsearchmore.action;
      if (this.seticonsearchmore.action === true) {
        this.seticonsearchmore.icon = "mdi mdi-chevron-up";
      } else {
        this.seticonsearchmore.icon = "mdi mdi-chevron-down";
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    finddatafromdate() {
      this.mDataArray = this.dataAll.filter((item) => {
        if(this.search_status == "" ){
          if(this.datefrom != null && this.dateto != null){
            if(item.period_datefrom >= this.datefrom && item.period_dateto <= this.dateto){
              return item;
            }
          }
          if(this.datefrom == null && this.dateto == null){
            return item;
          }
        }else{
          if(this.datefrom != null && this.dateto != null){
            if (
              item.period_datefrom >= this.datefrom &&
              item.period_dateto <= this.dateto  &&
              item.status == this.search_status
            ) {
              return item;
            }
          }
          if(this.datefrom == null && this.dateto == null){
            if (
              item.status == this.search_status
            ) {
              return item;
            }
          }
          
        }
      });
      
    },
    onClicktoaddadvance(id) {
      this.$router.push(`/edit-advance/${id}/edit`);
    },

    async loadCompanyMaster(id) {
      const result = await api.getCompanyMaster(id);
      this.company = result.data;
    },

    async loadDepartmentMaster(id) {
      const result = await api.getDepartmentMaster(id);
      this.department = result.data;
      result.data.forEach((item) => {
        this.department_list.push(item.name);
      });
    },
    async loadAuthorize() {
      const res_get = await api.getSettingGroupMenu();
      // console.log("res loadAuthorize : ", JSON.stringify(res_get));
      this.authorize = res_get.data;
    },

    async loadPositionMaster(id) {
      const result = await api.getPositionMaster(id);
      // console.log("result.data : ", JSON.stringify(result.data));
      this.position = result.data;

      result.data.forEach((item) => {
        this.position_list.push(item.name);
      });
    },

    async loadDetailAccount(id) {
      // console.log("loadDetailAccount:", id + "//");
      const result = await api.getAccountDetail(id);
      // console.log("loadDetailAccount result: ", JSON.stringify(result));
      this.editedItem = Object.assign({}, result.data[0]);
    },
    async loaddatadetails() {
      const result = await api.getAllleave_quota_By_Company(
        localStorage.getItem(server.COMPANYID)
      );
      this.dataAll = result.data;

      this.mDataArray = this.dataAll;

      const result_export = await api.getexportLeave_quota();
      result_export.data.forEach((x) => {
        x.leave_period_from = new Date(x.leave_period_from);
        x.leave_period_to = new Date(x.leave_period_to);
      });
      this.sheets[0].data = result_export.data;
      // //console.log(this.approver)
      // result.data.forEach((item) => {
      //   this.approver.push(item.firstname);
      // });

      // this.$showLoader();
      this.$hideLoader();
    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
};
