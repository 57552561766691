//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import api from "@/services/api";
import { server } from "@/services/constants";
import {
  XlsxRead,
  XlsxTable,
  XlsxSheets,
  XlsxJson,
  XlsxWorkbook,
  XlsxSheet,
  XlsxDownload,
} from "vue-xlsx";
export default {
  components: {
    SuccessDialog,
    SuccessDialogPush,
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  name: "About",
  data() {
    return {
      datagetobjecttypefail: [],
      datagetdoctypefail: [],
      datagettmfromrefnofail: [],
      datashowintable: [],
      datafaildoctype: 0,
      datafailobjecttype: 0,
      datarefnofail: 0,
      refreshKey: 0,
      parsedataxlsx: [],
      btnconfirmimport: false,
      btnconfirmimportclose: false,
      datafile: null,
      filenameimport: "",
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      mDataArray: [],
      search: "",
      message: "Welcome to Vue.js!",
      headers: [
        { text: "Object Type", value: "objecttype" },
        { text: "Doc.Type", value: "doctype" },
        { text: "Our. Ref No.", value: "refno" },
        { text: "Trade Mark", value: "trademark_name" },
        { text: "Receive Date", value: "receive_date" },
        { text: "Remind Date", value: "internaldate" },
        { text: "End Date", value: "enddate" },
        { text: "Categories", value: "categories" },
        { text: "Description", value: "description" },
        { text: "Lawyer", value: " lower" },
        { text: "Fee", value: " fee" },
        { text: "Remark", value: "remark" },
      ],
    };
  },
  mounted() {
    this.$hideLoader();
  },
  methods: {
    async confirmimport() {
      this.btnconfirmimportclose = true;
      this.$showLoader();
      const result = await api.AppointmentImportxlsx(this.parsedataxlsx);
      let dataresult = result.data;
      console.log(dataresult);
      this.$hideLoader();
      if (dataresult.save_sta && dataresult.save_sta == true) {
        this.$store.state.global_push_dialog = true;
        this.setupAlertDialogPush(
          true,
          "บันทึกข้อมูล",
          "บันทึกข้อมูลสำเร็จ",
          "text-h5 green--text text-center",
          "/appointment-trademark-list"
        );
        return;
      } else {
        this.datafailobjecttype = dataresult.dataobjecttypefail;
        this.datafaildoctype = dataresult.datadoctypefail;
        this.datarefnofail = dataresult.datarefnofail;
        this.datagetobjecttypefail = dataresult.datagetobjecttypefail;
        this.datagetdoctypefail = dataresult.datagetdoctypefail;
        this.datagettmfromrefnofail = dataresult.datagettmfromrefnofail;

        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "บันทึกข้อมูล",
          "มีข้อมูลผิดพลาด",
          "text-h5 red--text text-center"
        );
        return;
      }
    },
    async Readappointmenttm(event) {
      this.refreshKey++;
      this.mDataArray = [];
      this.datafile = null;
      this.datafile = event.target.files ? event.target.files[0] : null;
      console.log(this.datafile);

      this.filenameimport = this.datafile.name;

      this.lockcollection = true;
    },
    async onParsed(data) {
      this.$showLoader();
      const excelEpoc = new Date(1900, 0, -1).getTime();
      const msDay = 86400000;
      // alert(new Date(excelEpoc + 44926.291712962964 * msDay));
      this.datafailobjecttype = 0;
      this.datafaildoctype = 0;
      this.countsuccess = 0;
      this.countfail = 0;
      this.employeenamefail = false;
      this.leavetypefail = false;
      this.mDataempfail = [];
      this.mDataleavetypefail = [];
      (this.sheetsdataallfail = [
        {
          name: "sheetsdataallfail",
          data: [],
        },
      ]),
        (this.sheetsempfail = [
          {
            name: "sheetsempfail",
            data: [],
          },
        ]),
        (this.sheetsleave_typefail = [
          {
            name: "sheetsleave_typefail",
            data: [],
          },
        ]),
        (this.mDataArray = data);
      let mDataArrayparsedata = [];
      this.mDataArray.forEach((item, index) => {
        // if (index == 0) {
        mDataArrayparsedata.push({
          objecttype: "",
          doctype: "",
          refno: "",
          trademark_name: "",
          receive_date: "",
          internaldate: "",
          enddate: "",
          categories: "",
          description: "",
          lower: "",
          fee: "",
          remark: "",
        });
        Object.keys(item).forEach(function(key) {
          console.log("Key : " + key + "\nValue : " + item[key]);

          if (key == "Object Type") {
            mDataArrayparsedata[index].objecttype = item[key].trim();
          }
          if (key == "Doc.Type") {
            mDataArrayparsedata[index].doctype = item[key].trim();
          }
          if (key == "Our. Ref No.") {
            mDataArrayparsedata[index].refno = item[key].trim();
          }
          if (key == "Trade Mark") {
            mDataArrayparsedata[index].trademark_name = item[key].trim();
          }
          if (key == "Receive Date") {
            mDataArrayparsedata[index].receive_date = item[key];
          }
          if (key == "Remind Date") {
            mDataArrayparsedata[index].internaldate = item[key];
          }
          if (key == "End Date") {
            mDataArrayparsedata[index].enddate = item[key];
          }
          if (key == "Categories") {
            mDataArrayparsedata[index].categories = item[key].trim();
          }
          if (key == "Description") {
            mDataArrayparsedata[index].description = item[key].trim();
          }
          if (key == "Lawyer") {
            mDataArrayparsedata[index].lower = item[key].trim();
          }
          if (key == "Fee") {
            mDataArrayparsedata[index].fee = item[key].trim();
          }
          if (key == "Remark") {
            mDataArrayparsedata[index].remark = item[key].trim();
          }
        });
        // }
      });
      this.parsedataxlsx = mDataArrayparsedata;
      console.log(this.parsedataxlsx);
      this.parsedataxlsx.forEach((item, index) => {
        let setreceive_date = "";
        let setinternaldate = "";
        let setenddate = "";
        if (item.fee == "") {
          item.fee = 0;
        }
        setreceive_date = new Date(
          excelEpoc + parseFloat(item.receive_date) * msDay
        );
        setinternaldate = new Date(
          excelEpoc + parseFloat(item.internaldate) * msDay
        );
        setenddate = new Date(excelEpoc + parseFloat(item.enddate) * msDay);

        item.receive_date = this.importconversedate(setreceive_date);
        item.internaldate = this.importconversedate(setinternaldate);
        item.enddate = this.importconversedate(setenddate);
      });

      await this.parsedatashowxlsx();

      this.btnconfirmimportclose = false;
      this.btnconfirmimport = true;
      this.lockcollection = false;
      this.$hideLoader();
    },
    async parsedatashowxlsx() {
      this.datashowintable = [];
      this.parsedataxlsx.forEach((item, index) => {
        this.datashowintable.push({
          categories: item.categories,
          description: item.description,
          doctype: item.doctype,
          enddate: this.formatDate(item.enddate),
          fee: item.fee,
          internaldate: this.formatDate(item.internaldate),
          lower: item.lower,
          objecttype: item.objecttype,
          receive_date: this.formatDate(item.receive_date),
          refno: item.refno,
          remark: item.remark,
          trademark_name: item.trademark_name,
        });
      });
    },
    importconversedate(datedate) {
      let date = new Date(datedate);
      let mnth = ("0" + (date.getMonth() + 1)).slice(-2);
      let day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
      // return [day, mnth, date.getFullYear()].join("/");
    },

    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    },
    async setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    async setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
  },
  beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
};
