//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "@/services/constants";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import unity from "@/unity/unity";
export default {
  name: "SO-list",
  data(vm) {
    return {
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      search: "",
      authorize_view: false,
      authorize_add: false,
      authorize_edit: false,
      authorize_del: false,
      toggleEnable: false,
      selectstatus: "",
      mDataArray: [],
      authorize: [],
      headers: [
        {
          text: "Company Name",
          value: "name",
          class: "bg-colorth tabletextwhite",
          align: "start",
          divider: true,
        },
        {
          text: "Training Name",
          value: "tranning_name",
          class: "bg-colorth tabletextwhite",
          align: "start",
          divider: true,
        },
        {
          text: "Router Register",
          value: "router",
          class: "bg-colorth tabletextwhite",
          align: "start",
          divider: true,
        },
        {
          text: "Date",
          value: "date_show",
          class: "bg-colorth tabletextwhite",
          align: "start",
          divider: true,
          width: "180px"
        },
        {
          text: "Status",
          value: "status",
          class: "bg-colorth tabletextwhite",
          align: "center",
          divider: true,
        },
        {
          text: "",
          value: "actions",
          align: "center",
          class: "bg-colorth tabletextwhite",
          divider: true,
        },
      ],
      dialogEdit: false,
      dataedit: { 
          id: 0,
          company_id: 0,
          department_id: 0,
          position_id: 0,
          tranning_name: "",
          admin_id: 0,
          status: "INACTIVE"
        },
      status_list: ["ACTIVE", "INACTIVE"],
      company_list: [],
      department_list: [],
      authorize_list: [],
      position_list: [],
      admin_list: [],
              menu_date: false,
      date_training: "",
      start_hours:false,
      start_time: "",
      end_hours:false,
      end_time: "",
      date: "",
      time: ""
    };
  },
  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
  components: {
    SuccessDialog,
    SuccessDialogPush,
    
  },
  beforeUpdate() {},
  computed: {
    itemsForSelected() {
      if (this.search.length) {
        return this.dataSource.filter((item) => this.search.includes(item));
      }
      return this.dataSource;
    },
    computedDateFromFormatted() {
      return unity.formatDate(this.date_training);
    },
  },
  watch: {
    dialogEdit(newValue) {
      // เมื่อค่า dialogVisible เปลี่ยนแปลง
      if (!newValue) {
        this.closeedit(); // เรียกใช้งานฟังก์ชันเมื่อ dialog ถูกปิด
      }
    }
  },
  async mounted() {
    await api.checkVersion();
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);

    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed",
        "Please Logout And Login Again",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(this.userId, router_path);

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    if (!this.authorize_view) {
      this.$router.back();
    }

    // ----------------- Check Authorize ---------------------------

    let comp_id = localStorage.getItem(server.COMPANYID);

    this.userId = localStorage.getItem(server.USER_ID);
    if (this.userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }

    //load data
    await this.loadCompany();
    await this.loaddata();
    this.$hideLoader();
  },
  methods: {
    async loadCompany(){
          const res_com = await api.getAllCompany();
          this.company_list = res_com.data;
          console.log("this.company_list")
          console.log(this.company_list)
    },
    async loaddata() {
      const res_tranning = await api.getTRANNING_HSQL()
      this.mDataArray = res_tranning.data;
      for (const [index, item] of this.mDataArray.entries()) {
        item.router = `https://training.dcm.co.th/register/${item.id}`;
      }
    },
    async showdepartName() {
        const res_dep = await api.getAllDepartment_By_CompanyID(this.dataedit.company_id);
        this.department_list = res_dep.data;
    },
    async showAuthorize() {
        const res_aut = await api.getSettingGroupMenu_By_CompanyID(this.dataedit.company_id);
        this.authorize_list = res_aut.data;
    },
    async showAdmin() {
        const res_adm = await api.getAccountsByCompany(this.dataedit.company_id);
        console.log("res_adm.data")
        console.log(res_adm.data)
        this.admin_list = res_adm.data;
      },
    async showPositionName() {
        const res_po = await api.getAllPositionTranning(this.dataedit.company_id,this.dataedit.department_id);
        this.position_list = res_po.data;
    },
    closeMenuStart() {
      this.start_hours = false; // ปิดเมนู
    },
    closeMenuEnd() {
      this.end_hours = false; // ปิดเมนู
    },
    async checktime(){  
        console.log(this.start_time)
        console.log(this.end_time)
        if(this.start_time != "" && this.end_time != ""){
          if (this.start_time >= this.end_time) {
              this.$store.state.global_dialog = true;
              this.setupAlertDialog(
                  true,
                  "Invalid Information",
                  "Time(Start<END), Please New choose a DateTime",
                  "text-h5 red--text text-center"
              );
              return;
          }
        }

      },
    async openeditItem(data) {
      console.log("data")
      console.log(data)
      if(data.date_training == "0000-00-00"){
        this.date_training = new Date(Date.now() - new Date().getTimezoneOffset() * 60000).toISOString().substr(0, 10);
      }else{
        this.date_training = data.date_training;
      }
      
      
      this.start_time = data.time_start;
      this.end_time = data.time_end;
      this.dataedit = {};
      this.dialogEdit = true;
      this.dataedit = data;

      this.$showLoader();
      await this.showdepartName();
      await this.showAuthorize();
      await this.showAdmin();
      await this.showPositionName();
      this.$hideLoader();
    },
    async openshowItem(tranning_id) {
      this.$router.push(`/training-edit/${tranning_id}`);
    },
    async saveedit(){
      if (this.dataedit.tranning_name == "") {
        this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Invalid Information",
            "Please enter the Training Course Name",
            "text-h5 red--text text-center"
        );
        this.$refs["tranning_name"].focus();
        return;
      }
      if (this.dataedit.department_id == 0) {
              this.$store.state.global_dialog = true;
              this.setupAlertDialog(
                  true,
                  "Invalid Information",
                  "Please choose a department",
                  "text-h5 red--text text-center"
              );
              this.$refs["department_id"].focus();
              return;
          }
      console.log("this.dataedit")
      if (this.dataedit.authorize_id == 0) {
              this.$store.state.global_dialog = true;
              this.setupAlertDialog(
                  true,
                  "Invalid Information",
                  "Please Enter Authorize Data",
                  "text-h5 red--text text-center"
              );
              this.$refs["authorize_id"].focus();
              return;
          }
          if(this.start_time == "" || this.end_time == ""){
              this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Invalid Information",
                    "Please choose a DateTime",
                    "text-h5 red--text text-center"
                );
              return;
          }
          if(this.start_time != "" && this.end_time != ""){
            if (this.start_time >= this.end_time) {
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Invalid Information",
                    "Time(Start<END), Please New choose a DateTime",
                    "text-h5 red--text text-center"
                );
                return;
            }
          }
      console.log("this.dataedit")
      console.log(this.dataedit)

      let d_start = "";
      let e_start = "";
      d_start = `${this.date_training} ${this.start_time}:00`
      e_start = `${this.date_training} ${this.end_time}:00`

      var dataitem = {
        company_id: this.dataedit.company_id,
        department_id: this.dataedit.department_id,
        authorize_id: this.dataedit.authorize_id,
        position_id: this.dataedit.position_id,
        tranning_name: this.dataedit.tranning_name,
        admin_id: this.dataedit.admin_id,
        status: this.dataedit.status,
        date_start: d_start,
        date_end: e_start,
      }
      
      this.$showLoader();
      const res_update = await api.updateTRANNING_H(this.dataedit.id,dataitem);

      if(res_update.status == 200 || res_update == 201){
          let jobupdate = {
            name: this.dataedit.tranning_name,
            company_id: this.dataedit.company_id,
            department_id: this.dataedit.department_id,
            status: this.dataedit.status,
            update_by: localStorage.getItem(server.USER_ID),
          }

          await api.updateJobtitle(this.dataedit.jobtitle_id,jobupdate)
          this.dialogEdit = false;
          await this.loaddata();
          this.$hideLoader();
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
              true,
              "Information Saved",
              "Information successfully saved",
              "text-h5 green--text text-center",
              `/training-list`
          );
      }else{
        this.dialogEdit = false;
        this.$hideLoader()
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้",
          "text-h5 red--text text-center"
        );
        return
      }
    },
    async closeedit(){
      this.$showLoader();
      this.loaddata();
      this.$hideLoader();
      this.dataedit = {};
      this.dialogEdit = false;
    },
    setupAlertDialog(status, title, message, text_color) {
          this.title = title;
          this.message = message;
          this.dialogAdd = status;
          this.text_color = text_color;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
          this.title = title;
          this.message = message;
          this.dialogAdd = status;
          this.text_color = text_color;
          this.link = link;
    },
  },
};
