//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import api from "@/services/api";
import { server } from "@/services/constants";
import { language } from "../language/Leavelist.js";
import html2pdf from "html2pdf.js";
var convert = require("decimal-number-to-words");
import unity from "@/unity/unity";
import {
  tolocalestringnumber,
  stringcommatonumber,
} from "../computing/tolocalestringnumber";

export default {
  name: "invoice",
  data(vm) {
    return {
      datefrom: null,
      dateto: null,
      menu: false,
      menu2: false,
      search_status: "",
      search: "",
      setlanguage: language.en,
      defaultlanguage: "en",
      mDataArray: [],
      authorize: [],
      status: ["Draft", "Send", "Approve", "Cashier","Reject"],
      seticonsearchmore: { icon: "mdi mdi-chevron-down", action: false },
      headers: [
        {
          text: "Doc No.",
          value: "pettycas_id",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Data Request",
          value: "petty_date",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Emp Name",
          value: "petty_emp",
          class: "bg-colorth tabletextwhite",
        },
        {
          text: "Amount",
          value: "petty_amount",
          class: "bg-colorth tabletextwhite",
          align: "end",
        },
        {
          text: "Status",
          value: "petty_status",
          class: "bg-colorth tabletextwhite",
          
        },

        {
          text: "",
          value: "actions",
          class: "bg-colorth tabletextwhite",
        },
      ],
    };
  },
  async beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
  async created() {
    // this.headers = language.en.headertable;
    // this.initialize();
  },
  beforeUpdate() {},
  computed: {
    computeddatefromFormatted() {
      return unity.formatDate(this.datefrom);
    },
    computeddateToFormatted() {
      return unity.formatDate(this.dateto);
    },
  },
  async mounted() {
    await api.checkVersion();
    this.userId = localStorage.getItem(server.USER_ID);
    let yourUrlString = window.location;
    // alert(yourUrlString);

    let parser = document.createElement("a");
    parser.href = yourUrlString;

    this.authorize_id = localStorage.getItem(server.AUTHORIZE_ID);

    // alert("authorize_id:" + this.authorize_id);
    if (this.authorize_id == null || this.authorize_id == 0) {
      // this.$router.push("/login");
      this.$store.state.global_dialog = true;
      this.setupAlertDialog(
        true,
        "Authorize Failed",
        "Please Logout And Login Again",
        "text-h5 red--text text-center"
      );
      this.$router.back();
    }

    const router_path = parser.pathname.replace("/", "");

    const res_auth = await api.getAuthorize(this.userId, router_path);

    // console.log("res_auth:" + JSON.stringify(res_auth.data));

    this.authorize_view = res_auth.data[0].smd_view >= 1 ? true : false;
    this.authorize_add = res_auth.data[0].smd_add >= 1 ? true : false;
    this.authorize_edit = res_auth.data[0].smd_edit >= 1 ? true : false;
    this.authorize_del = res_auth.data[0].smd_del >= 1 ? true : false;

    // console.log("res_auth:" + JSON.stringify(res_auth.data));
    // console.log("authorize_view:" + this.authorize_view);
    // console.log("authorize_add:" + this.authorize_add);
    // console.log("authorize_edit:" + this.authorize_edit);
    // console.log("authorize_del:" + this.authorize_del);
    // this.$router.back();

    if (!this.authorize_view) {
      this.$router.back();
    }

    // ----------------- Check Authorize ---------------------------

    let comp_id = localStorage.getItem(server.COMPANYID);

    this.userId = localStorage.getItem(server.USER_ID);
    if (this.userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
    await this.loaddata();
  },
  methods: {
    async loaddata() {
      const res_data = await api.getDataPC(localStorage.getItem(server.USER_ID))
      console.log("test load data")
      console.log(res_data.data)
      this.dataAll = res_data.data;
      
      for( const [index,item] of this.dataAll.entries()){
        if(item.petty_amount != ""){
          item.petty_amount = tolocalestringnumber(item.petty_amount);
        }
      }
      this.mDataArray = this.dataAll;
      
      this.$hideLoader();
    },

    showsearchmore() {
      this.toggleEnable = !this.toggleEnable;
      this.seticonsearchmore.action = !this.seticonsearchmore.action;
      if (this.seticonsearchmore.action === true) {
        this.seticonsearchmore.icon = "mdi mdi-chevron-up";
      } else {
        this.seticonsearchmore.icon = "mdi mdi-chevron-down";
      }
    },

    
    findChaege() {
      // alert(this.search_status)
      this.mDataArray = this.dataAll.filter((item) => {
          if (this.datefrom == null || this.dateto == null) {
            if (this.search_status != "") {
              if (item.petty_status == this.search_status) {
                return item;
              }
            } else {
              return item;
            }
          }
          if (this.datefrom != null && this.dateto != null) {
            if (this.search_status != "") {
              if (
                item.petty_status == this.search_status &&
                item.date_S >= this.datefrom &&
                item.date_S <= this.dateto
              ) {
                return item;
              }
            } else {
              if (item.date_S >= this.datefrom && item.date_S <= this.dateto) {
                return item;
              }
            }
          }
      });
    },

    async openPC(data){
      console.log(data)
      if(data.status == 0){
        this.$router.push(`/petty-cash/Edit/${data.id}`);
      }else if(data.status == 1){
        this.$router.push(`/petty-cash/send/${data.id}`);
      }else if(data.status == 2){
        this.$router.push(`/petty-cash/approve/${data.id}`);
      }else if(data.status == 3){
        this.$router.push(`/petty-cash/cashier/${data.id}`);
      }else if(data.status == 4){
        this.$router.push(`/petty-cash/reject/${data.id}`);
      }else if(data.status == 5){
        this.$router.push(`/petty-cash/reject_account/${data.id}`);
      }else{
        return;
      }
      // 
    }

  },
};
