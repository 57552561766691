//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server,POAUrl, } from "@/services/constants";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import SuccessDialog from "@/components/cards/SuccessDialog";
import pdfvuer from "pdfvuer";

export default {
    name: "POA",
    async mounted() {
      this.$showLoader();
      await this.loadData();
      this.$hideLoader();
    },  
    components: { SuccessDialog, SuccessDialogPush, pdf: pdfvuer },
      data() {
        return {
            //file
            preview_list2: [],
            getpreview_list: [],
            image: null,
            previewimage: null,
            previewimageedit: null,
            image_list: [],
            preview_list: [],
            dialogpreviewimage: false,
            checkfileapreview: false,
            previewpopupfile: [],
            previewpopupfile2: [],
            dialogpreviewfile: false,
            dialogDelete: false,
            dialogDeleteimage: false,
            filename: [],
            imagename: "",
            load_bg: false,
            checkfileadd: false,
            checkimageadd: false,
            //--
            index_del: {},
            dialogDeleteTable: false,
            userId: 0,
            description: "",
            version: "",
            dialogAdd: false,
            authorize: [],
            authorize_view: false,
            authorize_add: false,
            authorize_edit: false,
            authorize_del: false,
            authorize_id: 0,
            load_bg: false,
            isShowPassword: false,
            fullPage: true,
            search: "",
            mDataArray: [],
            text_color: "text-h5 green--text text-center",
            title: "green",
            message: "green",
            link: "",
            search_ct: "",
            headers: [
              {
                  text: "File",
                  value: "poafile",
                  class: "bg-colorth",
                  divider: true,
                  width: "40%",
              },
              {
                  text: "Description",
                  value: "description",
                  class: "bg-colorth",
                  divider: true,
                  width: "20%",
              },
              {
                  text: "Version",
                  value: "version",
                  class: "bg-colorth",
                  divider: true,
                  width: "20%",
              },
              {
                  text: "Date Upload",
                  value: "upload_date",
                  class: "bg-colorth",
                  divider: true,
                  width: "20%",
              },
              {
                  text: "",
                  value: "actions",
                  class: "bg-colorth",
                  divider: true,
                  align:"center",
                  width: "70px",
              },
            ],
        };
    },
    methods: {
      //---------------function file------------//
      async popupdeletefile(index) {
        this.fileindex = index;
        this.dialogDelete = true;
      },
      async closeDelete() {
        this.fileindex = -1;
        this.dialogDelete = false;
      },
      async deleteItemConfirm() {
        console.log(this.image_list);
        this.preview_list2 = [];
        this.getpreview_list.splice(this.fileindex, 1);
        this.image_list.splice(this.fileindex, 1);
        await this.getPdfforAdd(this.getpreview_list);

        this.filename.splice(this.fileindex, 1);
        this.preview_list2 = this.preview_list;
        this.fileindex = -1;
        this.dialogDelete = false;
      },
      async popupdeleteimage() {
        this.dialogDeleteimage = true;
      },
      async closeDeleteimage() {
        this.dialogDeleteimage = false;
      },
      async deleteItemConfirmimage() {
        this.checkimageadd = false;
        this.image = null;
        this.previewimage = "";
        this.imagename = "";
        this.dialogDeleteimage = false;
      },
      async previewnoticefilepdf(file_name) {
        this.previewpopupfile2 = [];
        console.log(file_name)
        let file_name_s = file_name;
        let pdf_newTab = window.open(
            `${POAUrl}/${this.$route.params.id}/${file_name_s}`
        );

          return;
      },
      async popuppreviewfile(fileindex) {
        let pdf_newTab = window.open("");
        pdf_newTab.document.write(
          "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
            this.getpreview_list[fileindex] +
            "'></iframe></body></html>"
        );

        return;
        this.previewpopupfile2 = [];
        await this.getPdfforpreview(this.getpreview_list[fileindex]);
        console.log(this.previewpopupfile);
        this.previewpopupfile2 = this.previewpopupfile;
        this.checkfileapreview = true;
        this.dialogpreviewfile = true;
      },
      async closepreviewfile() {
        this.dialogpreviewfile = false;
      },
      async popuppreviewimage() {
        this.dialogpreviewimage = true;
      },
      async closepreviewimage() {
        this.dialogpreviewimage = false;
      },
      previewImage: function(event) {
        var input = event.target;
        if (input.files) {
          var reader = new FileReader();
          reader.onload = (e) => {
            this.previewimage = e.target.result;
            this.checkimageadd = true;
          };
          this.image = input.files[0];
          reader.readAsDataURL(input.files[0]);
        }

        this.imagename = this.image.name;
      },
      async previewMultiImage(event) {
        this.filename = [];
        this.preview_list = [];
        this.getpreview_list = [];
        this.image_list = [];
        var input = event.target;
        var count = input.files.length;
        var index = 0;
        if (input.files) {
          while (count--) {
            var reader = new FileReader();
            reader.onload = (e) => {
              this.getpreview_list.push(e.target.result);
              this.preview_list.push(e.target.result);
            };
            if (input.files[index].type === "application/pdf") {
              this.image_list.push(input.files[index]);
              reader.readAsDataURL(input.files[index]);
            }
            index++;
          }
        }
        await this.getPdfforAdd(this.getpreview_list);
        this.preview_list2 = this.preview_list;
        this.image_list.forEach((x) => {
          this.filename.push(x.name);
        });
        this.checkfileeadd = true;
      },
      reset: function() {
        this.image = null;
        this.previewimage = null;
        this.image_list = [];
        this.preview_list = [];
      },
      async getPdfforpreview(datafile) {
        this.previewpopupfile = [];
        let getfile = [];
        getfile = datafile;
        this.previewpopupfile.push(pdfvuer.createLoadingTask(`${getfile}`));
        this.previewpopupfile.forEach((item, index) => {
          item.then((pdf) => {
            this.numPagespreview = pdf.numPages;
          });
        });
        console.log(this.previewpopupfile);
      },
      async getPdfforAdd(item) {
        this.preview_list = [];
        let getfile = [];
        getfile = item;
        getfile.forEach((file, index) => {
          this.preview_list.push(pdfvuer.createLoadingTask(`${file}`));
        });
        this.preview_list.forEach((item, index) => {
          item.then((pdf) => {
            this.numPages = pdf.numPages;
            // window.onscroll = function() {
            //   changePage();
            //   stickyNav();
            // };
            // Get the offset position of the navbar
            // var sticky = $("#buttons")[0].offsetTop;
            // // Add the sticky class to the this.$refs.nav when you reach its scroll position. Remove "sticky" when you leave the scroll position
            // function stickyNav() {
            //   if (window.pageYOffset >= sticky) {
            //     $("#buttons")[0].classList.remove("hidden");
            //   } else {
            //     $("#buttons")[0].classList.add("hidden");
            //   }
            // }
            function changePage() {
              var i = 1,
                count = Number(pdf.numPages);
              do {
                if (
                  window.pageYOffset >=
                    this.findPos(document.getElementById(i)) &&
                  window.pageYOffset <=
                    this.findPos(document.getElementById(i + 1))
                ) {
                  this.page = i;
                }
                i++;
              } while (i < count);
              if (
                window.pageYOffset >= this.findPos(document.getElementById(i))
              ) {
                this.page = i;
              }
            }
          });
        });
        this.checkfileadd = true;
      },
      handle_pdf_link: function(params) {
        // Scroll to the appropriate place on our page - the Y component of
        // params.destArray * (div height / ???), from the bottom of the page div
        var page = document.getElementById(String(params.pageNumber));
        page.scrollIntoView();
      },
      findPos(obj) {
        return obj.offsetTop;
      },
      //--------------------end funcfile--------------------//
      async savePOA(){
        let formData = new FormData();
        // formData.append(`file`, this.image);
        this.image_list.forEach((x, index) => {
          formData.append(`file`, x);
        });
        formData.append("contract_id", this.$route.params.id);
        formData.append("description", this.description);
        formData.append("version", this.version);
        formData.append("create_by", localStorage.getItem(server.USER_ID));
        formData.append("update_by", localStorage.getItem(server.USER_ID));
        this.$showLoader();
        const res_add = await api.createPOA(this.$route.params.id, formData)
        if(res_add.status == 201){
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialog(
              true,
              "save data",
              "save data success",
              "text-h5 green--text text-center",
          );
          this.description = "";
          this.version = "";
          this.preview_list2 = [];
          this.getpreview_list= [];
          this.image= null;
          this.previewimage= null;
          this.previewimageedit= null;
          this.image_list= [];
          this.preview_list= [];
          this.dialogpreviewimage= false;
          this.checkfileapreview= false;
          this.previewpopupfile= [];
          this.previewpopupfile2= [];
          this.dialogpreviewfile= false;
          this.dialogDelete= false;
          this.dialogDeleteimage= false;
          this.filename = [];
          this.imagename= "";
          this.load_bg= false;
          this.checkfileadd= false;
          this.checkimageadd= false;
          await this.loadData();
          this.$hideLoader();
        }else{
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "save data",
            "can't save data",
            "text-h5 red--text text-center"
          );
        }
      },
      async loadData(){
        const res_poa = await api.getPOAById(this.$route.params.id)
        this.mDataArray = res_poa.data;
        // this.mDataArray.forEach((item)=>{  
        //   item.file_array = item.poafile.split(',')
        // })
        // console.log(this.mDataArray)
      },
      deleteItemTable(item) {
        this.dialogDeleteTable = true;
        this.index_del = item;
      },
      closeDeleteTable() {
        this.dialogDeleteTable = false;
        this.index_del = 0;
      },
      async deleteItemConfirmTable() {
        this.$showLoader();
        // console.log(this.index_del)
        let file_name = this.index_del.poafile.split(',')
        const req_del = await api.deletePOA(this.index_del.id,this.index_del.contract_id,{file_name_del: file_name})
        // console.log(req_del.status)
        
        if(req_del.status == 200){
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialog(
              true,
              "save data",
              "save data success",
              "text-h5 green--text text-center",
          );
          await this.loadData();
        }else{
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "save data",
            "can't save data",
            "text-h5 red--text text-center"
          );
        }
        this.closeDeleteTable();
        this.$hideLoader();
      },
      setupAlertDialogPush(status, title, message, text_color, link) {
          this.title = title;
          this.message = message;
          this.dialogAdd = status;
          this.text_color = text_color;
          this.link = link;
      },
      setupAlertDialog(status, title, message, text_color) {
          this.title = title;
          this.message = message;
          this.dialogAdd = status;
          this.text_color = text_color;
      },
    },
    beforeCreate() {
      this.$store.state.navMenu = true;
    },
    watch: {
      show: function(s) {
        if (s) {
          this.getPdf();
        }
      },
      page: function(p) {
        if (
          window.pageYOffset <= this.findPos(document.getElementById(p)) ||
          (document.getElementById(p + 1) &&
            window.pageYOffset >= this.findPos(document.getElementById(p + 1)))
        ) {
          // window.scrollTo(0,this.findPos(document.getElementById(p)));
          document.getElementById(p).scrollIntoView();
        }
      },
    },
};
