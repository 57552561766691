//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "../services/constants";
import StockCard from "@/components/cards/StockCard";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import html2pdf from "html2pdf.js";
import unity from "@/unity/unity";

export default {
  name: "edit-advance",
  data() {
    return {
      ad_status_id: 0,
      ad_status_switch: false,
      ad_status_step0_in_request: false,
      ad_status_step1_in_approve: false,
      ad_status_step2_in_paid: false,
      ad_status_step3_receive: false,
      ad_status_step4_in_clearing: false,
      ad_status_step5_in_cancel: false,
      close_status: false,
      doc_title: "",
      mode_edit: false,
      mode_approve: false,
      advance_code: [],
      advance_code_list: [],
      advance_code_id: 0,
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      customer: [],
      customer_list: [],
      customer_id: 0,
      options: {
        penColor: "#000000",
        backgroundColor: "rgb(255,255,255)",
      },
      user_sign2: "",
      user_sign3: "",
      initial_data: "",
      pickbank: false,
      pickcheque: false,
      checkcheque: false,
      checkchequefilled: false,
      showsig1: false,
      showsig2: false,
      showsig3: false,
      showbuttonsavesig1: false,
      showbuttonsavesig2: false,
      showbuttonsavesig3: false,
      defaultwordbuttonsig1: "Signature",
      defaultwordbuttonsig2: "Signature",
      defaultwordbuttonsig3: "Signature",
      totalleftoveramount: 0.0,
      sumwithdrawamount: 0.0,
      sunamountcleared: 0.0,
      showallamount: "",
      desserts: [
        {
          no: "1",
          detail: "",
          withdrawamount: 0,
        },
      ],
      selectbankname: "",
      bankname: [
        "ธนาคารกรุงเทพ",
        "ธนาคารกสิกรไทย",
        "ธนาคารกรุงไทย",
        "ธนาคารทหารไทยธนชาต",
        "ธนาคารไทยพาณิชย์",
        "ธนาคารกรุงศรีอยุธยา",
        "ธนาคารเกียรตินาคินภัทร",
        "ธนาคารซีไอเอ็มบีไทย",
        "ธนาคารทิสโก้",
        "ธนาคารยูโอบี",
        "ธนาคารไทยเครดิตเพื่อรายย่อย",
        "ธนาคารแลนด์ แอนด์ เฮ้าส์",
        "ธนาคารไอซีบีซี (ไทย)",
        "ธนาคารพัฒนาวิสาหกิจขนาดกลางและขนาดย่อมแห่งประเทศไทย",
        "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
        "ธนาคารเพื่อการส่งออกและนำเข้าแห่งประเทศไทย",
        "ธนาคารออมสิน",
        "ธนาคารอาคารสงเคราะห์",
        "ธนาคารอิสลามแห่งประเทศไทย",
      ],
      radioType: ["cash", "cheque"],
      radioGroup: "",
      setusername: "",
      role: localStorage.getItem(server.ROLE),
      department: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      dateCurrent: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      modal: false,
      menu2: false,
      search: "",
      selectedProductId: "",
      confirmDeleteDlg: false,
      mDataArray: [],
      // mDataArray: [{ id: "1", detail: "gg", amout: "1000" }],
      fullPage: true,
      dialog: false,
      dialogDelete: false,
      dialogDeleteForm: false,
      dialogSendMail: false,
      headers: [
        {
          text: "Code",
          value: "advance_title",
          class: "my-header4-style ",
        },
        {
          text: "Description",
          value: "description",
          class: "my-header-style",
        },
        { text: "Amount", value: "amount", class: "my-header2-style " },
        {
          text: "Action",
          value: "actions",
          class: "my-header3-style  text-center",
        },
      ],
      desserts: [],
      editedIndex: -1,
      editedItem: {
        code: "",
        description: "",
        amount: 0,
      },
      defaultItem: {
        code: "",
        description: "",
        amount: 0,
      },
      advance_d: {
        advance_hid: "",
        advance_title: "",
        description: "",
        amount: 0,
      },
      advance_h: {
        advance_id: "",
        customer_id: 0,
        employee_id: 0,
        department_id: 0,
        company_id: localStorage.getItem(server.COMPANYID),
        advance_date: null,
        sum_amount: 0,
        clear_date: null,
        payment_type: null,
        bank_name: null,
        bank_number: null,
        cheque_number: null,
        status: null,
        remark: null,
        create_by: null,
        create_date: null,
      },
      advance_h_update_status: {
        status: null,
      },
      advance_h_update_approve: {
        status: null,
        remark: null,
      },
      lbl_date_request: false,
      dialogSignature: false,
      dialogSaveDraft: false,
      messageSaveDraft: "",
      paymentType: "",
      bankName: "",
      bankNumber: "",
      chequeNumber: "",
      remark: "",
      approver_remark: "",
      approver_accountant: "",
      main_id: "",
      customer_name: "",
      sendMail: {
        name: "",
        email: "",
        advance_id: "",
        link: "",
      },
      account: {
        signature: "",
      },
      advance_log: {
        module: "AD",
        module_id: 0,
        approve_id: 0,
        approve_status: 0,
        approve_date: null,
      },
      department_view: "",
      emp_list: [],
      jobtitle_list: [],
      emp_draw: 0,
      emp_draw_jobtitle: 0,
    };
  },
  components: {
    StockCard,
    SuccessDialog,
    SuccessDialogPush,
  },
  computed: {
    version() {
      return this.initial_data;
    },
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    computedExpectedClearDateFormatted() {
      return unity.formatDate(this.date);
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    dialogDelete(val) {
      val || this.closeDelete();
    },
  },

  async mounted() {
    // alert("mounted");
    console.log("mounted");
    // this.$showLoader();
    this.$hideLoader();

    const userId = localStorage.getItem(server.USER_ID);
    if (userId && api.isLoggedIn()) {
      // this.$router.push("/");
    } else {
      this.$store.state.isLogged = false;
      this.$router.push("/login");
    }
    console.log("mounted12");

    // load page
    // let loader = this.$loading.show({
    //   // Optional parameters
    //   color: "green",
    //   loader: "dots",
    //   container: this.fullPage ? null : this.$refs.formContainer,
    //   canCancel: false,
    //   width: 120,
    //   height: 120,
    //   backgroundColor: "#ffffff",
    //   opacity: 0.5,
    //   zIndex: 999,
    //   onCancel: this.onCancel,
    // });
    console.log("mounted1");
    this.$store.state.navMenu = true;
    // this.showdefaultsignature();
    this.main_id = this.$route.params.id;
    // alert(this.$route.params.id);
    console.log("mounted2");
    const res_h = await api.getAllHById(this.main_id);
    console.log("res_h : " + JSON.stringify(res_h.data));
    this.advance_h.advance_id = res_h.data[0].advance_id;
    this.advance_h.customer_id = res_h.data[0].customer_id;
    this.emp_draw = res_h.data[0].emp_draw;
    this.emp_draw_jobtitle = res_h.data[0].emp_draw_jobtitle;

    // this.customer_name = tmp[0].name;

    this.ad_status_id = res_h.data[0].status;

    this.advance_h.employee_id = res_h.data[0].employee_id;
    this.advance_h.department_id = res_h.data[0].department_id;
    this.sumwithdrawamount = res_h.data[0].sum_amount;

    this.advance_h.payment_type = res_h.data[0].payment_type;
    this.advance_h.bank_name = res_h.data[0].bank_name;
    this.advance_h.bank_number = res_h.data[0].bank_number;
    this.advance_h.cheque_number = res_h.data[0].cheque_number;

    this.bankNumber = this.advance_h.bank_number;
    this.selectbankname = this.advance_h.bank_name;
    this.chequeNumber = this.advance_h.cheque_number;
    console.log("mounted3");
    if (this.advance_h.payment_type === "cheque") {
      this.paymentType = "cheque";
      this.pickbank = false;
      this.pickcheque = true;
      this.radioGroup = "cheque";
    } else if (this.advance_h.payment_type === "transfer") {
      this.paymentType = "transfer";
      this.pickcheque = false;
      this.pickbank = true;
      this.radioGroup = "bank";
    } else {
      this.paymentType = "cash";
      this.pickcheque = false;
      this.pickbank = false;
      this.radioGroup = "cash";
    }
    console.log("mounted4");
    this.advance_h.status = res_h.data[0].status;
    this.advance_h.remark = res_h.data[0].remark;
    this.remark = res_h.data[0].remark;
    this.approver_remark = res_h.data[0].approver_remark;
    this.approver_accountant = res_h.data[0].accountant_remark;

    this.advance_h.create_by = res_h.data[0].create_by;
    this.advance_h.create_date = res_h.data[0].create_date;

    this.advance_h.advance_date = res_h.data[0].advance_date;
    this.advance_h.clear_date = res_h.data[0].clear_date;
    console.log("mounted5");
    const res_d = await api.getAllDById(this.$route.params.id);
    // console.log("res_d : " + JSON.stringify(res_d.data));
    this.desserts = res_d.data;
    console.log("mounted6");
    // loader.hide();
    // load page

    console.log("mounted7");
    this.$forceUpdate();
    this.initialize();
    this.loadCustomer();
    this.loadAdvanceCode();
    console.log("mounted8");
    // load signature

    let id = null;
    if (this.$route.params.mode === "approve") {
      console.log("approve_mode load signature pad 1 by advance_h.employee_id");
      id = this.advance_h.employee_id;
    } else {
      console.log("edit_mode load signature pad 1 by localStorage USER_ID");
      id = localStorage.getItem(server.USER_ID);
    }
    console.log("mounted9");
    const res_acc = await api.getAccountDetail(id);

    this.setusername =
      res_acc.data[0].prename_e_n +
      " " +
      res_acc.data[0].firstname_e_n +
      " " +
      res_acc.data[0].lastname_e_n;

    const res_department = await api.getDepartmentByID(
      res_acc.data[0].department_i_d
    );
    this.department_view = res_department.data[0].name;

    if (this.$route.params.mode === "approve") {
      // console.log("mode approve");
      this.mode_edit = false;
      this.mode_approve = true;
      this.doc_title = "Approve";

      const res_app = await api.getAccountDetail(
        localStorage.getItem(server.USER_ID)
      );

      console.log("approve_mode load signature pad 2 by localStorage USER_ID");
      this.user_sign2 = res_app.data[0].signature;

      // console.log("signpad res_app", this.user_sign2);
      // console.log("res_app", JSON.stringify(res_app.data[0].signature));
    } else {
      // console.log("mode edit");
      // this.mode_edit = true;
      // this.mode_approve = false;
      this.doc_title = "Add Advance";

      const res_id = await api.getDetailiIdAdvance(this.$route.params.id);
      console.log("res_id : " + JSON.stringify(res_id.data));

      // [{"id":48,"employee_id":163,
      // "approve_level1_id":80,
      // "approve_sucess_id":0,
      // "approve_account_id":0}]

      // alert(this.ad_status_id);
      if (this.ad_status_id == 2) {
        this.ad_status_step0_in_request = true;
        this.ad_status_switch = true;
        this.mode_edit = false;
        this.mode_approve = true;
      } else if (this.ad_status_id == 5) {
        this.ad_status_step0_in_request = true;
        this.ad_status_step5_in_cancel = true;
        this.ad_status_switch = true;
        this.mode_edit = false;
        this.mode_approve = true;
      } else if (this.ad_status_id == 3 || this.ad_status_id == 4) {
        const res_app = await api.getAccountDetail(
          res_id.data[0].approve_level1_id
        );

        console.log(
          "approve_mode load signature pad 2 by localStorage USER_ID"
        );
        this.user_sign2 = res_app.data[0].signature;

        this.ad_status_step0_in_request = true;
        this.ad_status_step5_in_cancel = true;
        this.ad_status_step1_in_approve = true;
        this.ad_status_switch = true;
        this.mode_edit = false;
        this.mode_approve = true;
      } else if (this.ad_status_id == 6) {
        const res_app2 = await api.getAccountDetail(
          res_id.data[0].approve_sucess_id
        );
        this.user_sign2 = res_app2.data[0].signature;
        const res_app3 = await api.getAccountDetail(
          res_id.data[0].approve_account_id
        );
        this.user_sign3 = res_app3.data[0].signature;
        this.ad_status_step0_in_request = true;
        this.ad_status_step5_in_cancel = true;
        this.ad_status_step1_in_approve = true;
        this.ad_status_step2_in_paid = true;
        this.ad_status_switch = true;
        this.mode_edit = false;
        this.mode_approve = true;
        this.showsig3 = true;
      } else if (this.ad_status_id == 7) {
        this.ad_status_step3_receive = true;
        const res_app2 = await api.getAccountDetail(
          res_id.data[0].approve_sucess_id
        );
        this.user_sign2 = res_app2.data[0].signature;
        const res_app3 = await api.getAccountDetail(
          res_id.data[0].approve_account_id
        );
        this.user_sign3 = res_app3.data[0].signature;
        this.ad_status_step0_in_request = true;
        this.ad_status_step5_in_cancel = true;
        this.ad_status_step1_in_approve = true;
        this.ad_status_step2_in_paid = true;
        this.ad_status_switch = true;
        this.mode_edit = false;
        this.mode_approve = true;
        this.showsig3 = true;
      } else if (this.ad_status_id == 8 || this.ad_status_id == 9) {
        // this.ad_status_step4_in_clearing = true;

        this.ad_status_step0_in_request = true;
        this.ad_status_switch = true;
        this.ad_status_step5_in_cancel = true;
        this.ad_status_step1_in_approve = true;
        this.mode_edit = false;
        this.mode_approve = true;
      } else {
        this.mode_edit = true;
        this.mode_approve = false;
      }
    }
    // console.log("mode_edit:" + this.mode_edit);
    // console.log("mode_approve:" + this.mode_approve);

    // console.log("res_acc : ", JSON.stringify(res_acc.data[0].signature));
    console.log("mounted10");
    this.user_sign = res_acc.data[0].signature;
    this.main_id = this.$route.params.id;
    // alert(this.$route.params.mode);

    // this.date = Date.parse(this.advance_h.advance_date);
    this.date = new Date(this.advance_h.clear_date).toISOString().substr(0, 10);

    this.showsig1 = true;
    if (this.mode_approve) {
      this.showsig2 = true;
    }
    this.dialogSignature = true;
    this.showdefaultsignature();
    this.loadEmp();
    this.loadJobtitle();
    this.savesig1();
    this.savesig2();
    this.savesig3();
    console.log("mounted11");
    // loader.hide();
    // load signature
  },
  methods: {
    exportToPDF(advance_id) {
      html2pdf(this.$refs.document, {
        margin: 1,
        filename: `${advance_id}.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 3 },
        jsPDF: { unit: "in", format: "letter", orientation: "portrait" },
      });
    },
    closeDialogSendmail() {
      this.dialogSendMail = false;
      // this.$router.push("/advance-list");
      this.$store.state.global_push_dialog = true;
      this.setupAlertDialogPush(
        true,
        "Information Saved",
        "Information successfully saved",
        "text-h5 green--text text-center",
        "/advance-list"
      );
    },
    async loadEmp() {
      const result = await api.getAccountslist_by_ComID(
        localStorage.getItem(server.COMPANYID)
      );

      this.emp_list = result.data;
    },
    async loadJobtitle() {
      const result = await api.getAllJobtitleByCompany(
        localStorage.getItem(server.COMPANYID)
      );

      this.jobtitle_list = result.data;
    },
    async updateApprove(id) {
      this.advance_h_update_status.status = id;

      this.advance_log.module_id = this.$route.params.id;
      this.advance_log.approve_id = localStorage.getItem(server.USER_ID);
      this.advance_log.approve_status = id;
      this.advance_log.approve_date = new Date();

      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });

      const res_update = await api.updateAdvanceH(
        this.$route.params.id,
        this.advance_h_update_status
      );
      if (res_update.status == 200) {
        const res_log = await api.addAdvanceLog(this.advance_log);
        // console.log("res_log : " + JSON.stringify(res_log.status));

        if (res_log.status == 201) {
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "Information Saved",
            "Information successfully saved",
            "text-h5 green--text text-center",
            "/advance-list"
          );
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "ไม่สามารถบันทึกได้",
            "text-h5 red--text text-center"
          );
        }
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้",
          "text-h5 red--text text-center"
        );
      }
      loader.hide();
      // console.log("res_update : ", JSON.stringify(res_update));
    },
    async updateRequest(id) {
      this.advance_h_update_status.status = id;

      this.advance_log.module_id = this.$route.params.id;
      this.advance_log.approve_id = localStorage.getItem(server.USER_ID);
      this.advance_log.approve_status = id;
      this.advance_log.approve_date = new Date();

      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });

      const res_update = await api.updateAdvanceH(
        this.$route.params.id,
        this.advance_h_update_status
      );
      if (res_update.status == 200) {
        // this.$store.state.global_dialog = true;
        // this.setupAlertDialogPush(
        //   true,
        //   "Information Saved",
        //   "Information successfully saved",
        //   "text-h5 green--text text-center",
        //   "/advance-list"
        // );

        const res_log = await api.addAdvanceLog(this.advance_log);
        // console.log("res_log : " + JSON.stringify(res_log.status));

        if (res_log.status == 201) {
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "Information Saved",
            "Information successfully saved",
            "text-h5 green--text text-center",
            "/advance-list"
          );
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "ไม่สามารถบันทึกได้",
            "text-h5 red--text text-center"
          );
        }
      } else {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้",
          "text-h5 red--text text-center"
        );
      }
      loader.hide();
      // console.log("res_update : ", JSON.stringify(res_update));
    },
    async deleteForm() {
      // alert("deleteForm");
      this.close_status = false;
      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });
      this.dialogDeleteForm = true;

      await api.deleteAdvanceH(this.$route.params.id);
      await api.deleteAdvanceD(this.$route.params.id);
      this.$store.state.global_push_dialog = true;
      this.setupAlertDialogPush(
        true,
        "ลบข้อมูล",
        "ลบข้อมูลสำเร็จ",
        "text-h5 green--text text-center",
        "/advance-list"
      );

      loader.hide();
    },
    async loadAdvanceCode() {
      // console.log("loadAdvanceCode");

      let company_id = localStorage.getItem(server.COMPANYID);

      // console.log("loadAdvanceCode company_id:" + company_id);

      const result = await api.getAllAdvanceCodeByCompany(company_id);

      // console.log("loadAdvanceCode : ", JSON.stringify(result.data));
      this.advance_code = result.data;

      result.data.forEach((item) => {
        this.advance_code_list.push(item.code + " " + item.description);
      });
    },
    onSelectAdvanceCodeChange(acvance_code) {
      // var tmp = null;
      // tmp = this.customer.filter((c) => c.name.includes(search_customer));

      // this.customer_id = tmp[0].id;
      // console.log("acvance_code : " + acvance_code);
      var tmp = acvance_code.split(" ");
      this.editedItem.advance_title = tmp[0];
      this.editedItem.description = tmp[1];
    },
    setupAlertDialog(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },
    async saveAdvanceDraft() {
      // alert("saveAdvanceDraft");
      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });

      this.advance_log.module_id = this.$route.params.id;
      this.advance_log.approve_id = localStorage.getItem(server.USER_ID);
      this.advance_log.approve_status = 1;
      this.advance_log.approve_date = new Date();

      if (this.customer_id != 0) {
        this.advance_h.customer_id = this.customer_id;
      }
      this.advance_h.employee_id = localStorage.getItem(server.USER_ID);
      this.advance_h.department_id = localStorage.getItem(server.DEPARTMENT_ID);
      //   this.advance_h.advance_date = this.dateCurrent;
      this.advance_h.sum_amount = this.sumwithdrawamount;
      this.advance_h.clear_date = this.date;
      this.advance_h.payment_type = this.paymentType;
      this.advance_h.bank_name = this.selectbankname;
      this.advance_h.bank_number = this.bankNumber;
      this.advance_h.cheque_number = this.chequeNumber;
      this.advance_h.status = 1;
      this.advance_h.remark = this.remark;
      this.advance_h.create_by = localStorage.getItem(server.USER_ID);
      this.advance_h.create_date = this.dateCurrent;
      this.advance_h.emp_draw = this.emp_draw;
      this.advance_h.emp_draw_jobtitle = this.emp_draw_jobtitle;

      //   console.log("advance_h : " + JSON.stringify(this.advance_h));
      //   console.log("advance_d : " + JSON.stringify(this.advance_d));

      const res_h = await api.updateAdvanceH(
        this.$route.params.id,
        this.advance_h
      );
      //   console.log("res_h : " + JSON.stringify(res_h.data));
      if (res_h.status == 200) {
        await api.deleteAdvanceD(this.$route.params.id);
        if (this.desserts.length > 0) {
          this.desserts.forEach((item) => {
            this.advance_d.advance_hid = this.$route.params.id;
            this.advance_d.advance_title = item.advance_title;
            this.advance_d.description = item.description;
            this.advance_d.amount = item.amount;
            // console.log("advance_d : " + JSON.stringify(this.advance_d));
            api.addAdvanceD(this.advance_d);
          });
        }
        // this.$store.state.global_dialog = true;
        // this.setupAlertDialog(
        //   true,
        //   "Information Saved",
        //   "Information successfully saved",
        //   "text-h5 green--text text-center"
        // );
        // this.$router.push("/advance-list");

        const res_log = await api.addAdvanceLog(this.advance_log);
        // console.log("res_log : " + JSON.stringify(res_log.status));

        if (res_log.status == 201) {
          this.$store.state.global_dialog = true;
          this.setupAlertDialogPush(
            true,
            "Information Saved",
            "Information successfully saved",
            "text-h5 green--text text-center",
            "/advance-list"
          );
        } else {
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "ไม่สามารถบันทึกได้",
            "text-h5 red--text text-center"
          );
        }
      } else {
        // alert("ไม่สามารถบันทึกได้");
        // this.messageSaveDraft = "ไม่สามารถบันทึกได้";
        // this.dialogSaveDraft = true;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้",
          "text-h5 red--text text-center"
        );
      }

      loader.hide();
    },

    async saveAdvanceRequest() {
      // alert("saveAdvanceDraft");
      if (this.emp_draw == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณาเลือกผู้เบิก",
          "text-h5 red--text text-center"
        );

        return;
      }
      if (this.emp_draw_jobtitle == 0) {
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "Please Select Job Title",
          "text-h5 red--text text-center"
        );

        return;
      }
      if (this.advance_h.customer_id == 0) {
        // alert("กรุณาเลือกลูกค้า");
        // this.messageSaveDraft = "กรุณาเลือกลูกค้า";
        // this.dialogSaveDraft = true;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณาเลือกลูกค้า",
          "text-h5 red--text text-center"
        );

        return;
      }

      if (this.desserts.length <= 0) {
        // alert("กรุณาเลือกลูกค้า");
        // this.messageSaveDraft = "กรุณากรอกรายละเอียด Advance Detail";
        // this.dialogSaveDraft = true;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณากรอกรายละเอียด Advance Detail",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (this.paymentType == "") {
        // alert("กรุณาเลือกลูกค้า");
        // this.messageSaveDraft = "กรุณาเลือกวิธีการชำระเงิน";
        // this.dialogSaveDraft = true;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณาเลือกวิธีการชำระเงิน",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (this.paymentType == "transfer") {
        // alert("กรุณาเลือกลูกค้า");
        if (this.selectbankname == "" || this.bankNumber == null) {
          // this.messageSaveDraft = "กรุณากรอกข้อมูลธนาคารให้ถูกต้อง";
          // this.dialogSaveDraft = true;
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Check your information again",
            "กรุณากรอกข้อมูลธนาคารให้ถูกต้อง",
            "text-h5 red--text text-center"
          );
          return;
        }
      } else if (this.paymentType == "cheque") {
        // alert("กรุณาเลือกลูกค้า");
        if (this.chequeNumber == "") {
          // this.messageSaveDraft = "กรุณากรอกเลขที่เช็คให้ถูกต้อง";
          // this.dialogSaveDraft = true;
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Check your information again",
            "กรุณากรอกเลขที่เช็คให้ถูกต้อง",
            "text-h5 red--text text-center"
          );
          return;
        }
      }

      if (this.user_sign == "" || this.user_sign == null) {
        // alert("กรุณาเลือกลูกค้า");
        // this.messageSaveDraft = "กรุณาเลือกลงลายมือชื่อ";
        // this.dialogSaveDraft = true;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณาเลือกลงลายมือชื่อ",
          "text-h5 red--text text-center"
        );
        return;
      }

      if (
        this.mode_approve == true &&
        (this.user_sign2 == "" || this.user_sign2 == null)
      ) {
        // alert("กรุณาเลือกลูกค้า");
        // this.messageSaveDraft = "กรุณาเลือกลงลายมือชื่อ";
        // this.dialogSaveDraft = true;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Check your information again",
          "กรุณาเลือกลงลายมือชื่อ",
          "text-h5 red--text text-center"
        );
        return;
      }

      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });

      //   this.advance_h.customer_id = this.customer_id;
      if (this.customer_id != 0) {
        this.advance_h.customer_id = this.customer_id;
      }
      this.advance_h.employee_id = localStorage.getItem(server.USER_ID);
      this.advance_h.department_id = localStorage.getItem(server.DEPARTMENT_ID);
      //   this.advance_h.advance_date = this.dateCurrent;
      this.advance_h.sum_amount = this.sumwithdrawamount;
      this.advance_h.clear_date = this.date;
      this.advance_h.payment_type = this.paymentType;
      this.advance_h.bank_name = this.selectbankname;
      this.advance_h.bank_number = this.bankNumber;
      this.advance_h.cheque_number = this.chequeNumber;
      this.advance_h.status = 2;
      this.advance_h.remark = this.remark;
      this.advance_h.create_by = localStorage.getItem(server.USER_ID);
      this.advance_h.create_date = this.dateCurrent;
      this.advance_h.emp_draw = this.emp_draw;
      this.advance_h.emp_draw_jobtitle = this.emp_draw_jobtitle;

      // console.log("advance_h : " + JSON.stringify(this.advance_h));
      // console.log("advance_d : " + JSON.stringify(this.advance_d));

      //   const res_h = await api.addAdvanceH(this.advance_h);

      this.advance_log.module_id = this.$route.params.id;
      this.advance_log.approve_id = localStorage.getItem(server.USER_ID);
      this.advance_log.approve_status = 2;
      this.advance_log.approve_date = new Date();

      const res_h = await api.updateAdvanceH(
        this.$route.params.id,
        this.advance_h
      );

      if (res_h.status == 200) {
        await api.deleteAdvanceD(this.$route.params.id);
        if (this.desserts.length > 0) {
          this.desserts.forEach((item) => {
            // this.advance_d.advance_hid = res_h.data.id;
            this.advance_d.advance_hid = this.$route.params.id;
            this.advance_d.advance_title = item.advance_title;
            this.advance_d.description = item.description;
            this.advance_d.amount = item.amount;
            api.addAdvanceD(this.advance_d);
          });

          this.sendMail.name = localStorage.getItem(server.FULLNAME);
          this.sendMail.email = localStorage.getItem(
            server.APPROVE_LEVEL1_EMAIL
          );
          this.sendMail.advance_id = this.$route.params.id;
          console.log("send_request1 : " + JSON.stringify(this.sendMail));
          const send_request = await api.sendMail(this.sendMail);

          console.log("send_request2 : " + JSON.stringify(send_request));
          if (send_request.status == 200) {
            // this.$store.state.global_dialog = true;

            // this.setupAlertDialogPush(
            //   true,
            //   "Information Saved",
            //   "บันทึกข้อมูลและส่งอีเมล์ถึงผู้อนุมัติสำเร็จ",
            //   "text-h5 green--text text-center",
            //   "/advance-list"
            // );

            const res_log = await api.addAdvanceLog(this.advance_log);
            // console.log("res_log : " + JSON.stringify(res_log.status));

            if (res_log.status == 201) {
              this.$store.state.global_push_dialog = true;
              this.setupAlertDialogPush(
                true,
                "Information Saved",
                "บันทึกข้อมูลและส่งอีเมล์ถึงผู้อนุมัติสำเร็จ",
                "text-h5 green--text text-center",
                "/advance-list"
              );
            } else {
              this.$store.state.global_dialog = true;
              this.setupAlertDialog(
                true,
                "Information Saved",
                "ไม่สามารถบันทึกได้",
                "text-h5 red--text text-center"
              );
            }
          } else {
            // alert("ไม่สามารถบันทึกได้");
            // this.messageSaveDraft = "ไม่สามารถบันทึกได้";
            // this.dialogSaveDraft = true;
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Information Saved",
              "ไม่สามารถบันทึกได้",
              "text-h5 red--text text-center"
            );
          }
        }
      } else {
        // alert("ไม่สามารถบันทึกได้");
        // this.messageSaveDraft = "ไม่สามารถบันทึกได้";
        // this.dialogSaveDraft = true;
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้",
          "text-h5 red--text text-center"
        );
      }

      loader.hide();
    },

    changeFocusText(t) {
      // alert(t);
      if (t === 1) {
        this.$refs["txtCode"].focus();
      } else if (t === 2) {
        this.$refs["txtDescription"].focus();
      } else if (t === 3) {
        if (this.editedItem.advance_title == "") {
          alert("กรุณากรอกรหัสการขออนุมัติ");
          this.$refs["txtCode"].focus();
        } else {
          this.$refs["txtAmount"].focus();
        }
      }
    },
    initialize() {
      this.sumwithdrawamount = 0;

      this.desserts.forEach((item) => {
        this.sumwithdrawamount += parseInt(item.amount, 10);
      });
    },

    editItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      this.desserts.splice(this.editedIndex, 1);
      this.closeDelete();
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeSaveDraft() {
      this.dialogSaveDraft = false;
    },

    closeDelete() {
      this.sumwithdrawamount = 0;

      this.desserts.forEach((item) => {
        this.sumwithdrawamount += parseInt(item.amount, 10);
      });

      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },
    deleteItemConfirmForm() {
      // alert("deleteItemConfirmForm");
      this.deleteForm();
      this.closeDeleteForm();
    },
    closeDeleteForm() {
      // alert("closeDeleteForm");
      this.dialogDeleteForm = false;
      this.close_status = true;
      this.closeStatus();
    },

    save() {
      if (this.editedItem.advance_title == "") {
        alert("กรุณากรอกรหัสการขออนุมัติ");
        this.$refs["txtCode"].focus();
        return;
      }

      if (this.editedItem.amount == "0" || this.editedItem.amount == 0) {
        alert("กรุณากรอกจำนวนเงิน");
        this.$refs["txtAmount"].focus();
        return;
      }

      if (this.editedIndex > -1) {
        Object.assign(this.desserts[this.editedIndex], this.editedItem);
      } else {
        this.desserts.push(this.editedItem);
      }

      this.sumwithdrawamount = 0;

      this.desserts.forEach((item) => {
        this.sumwithdrawamount += parseInt(item.amount, 10);
      });
      console.log("save : " + JSON.stringify(this.desserts));
      this.changeFocusText(1);
      this.close();
    },
    onSelectCustomerChange(search_customer) {
      var tmp = null;
      tmp = this.customer.filter((c) => c.name.includes(search_customer));

      this.customer_id = tmp[0].id;
      console.log("customer : " + this.customer_id);

      this.advance_h.customer_id = this.customer_id;
    },

    async loadCustomer() {
      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });

      // console.log("loadCustomer");
      const result = await api.getAllCustomerListactiveCompany({
        params: {
          company_id: localStorage.getItem(server.COMPANYID),
        },
      });
      // console.log("result.data : ", JSON.stringify(result.data));
      this.customer = result.data;

      loader.hide();

      result.data.forEach((item) => {
        this.customer_list.push(item.name);
      });

      //   console.log("this customer:" + JSON.stringify(this.customer));

      //   console.log("this customer:" + this.advance_h.customer_id);
      //   var tmp = null;
      //   tmp = this.customer.filter((c) =>
      //     c.id.includes(1)
      //   );

      let cs_id = parseInt(this.advance_h.customer_id, 10);

      let fill_cus = this.customer.filter(function(c) {
        return c.id == cs_id;
      });
      //   console.log("this customer:" + fill_cus);
      //   console.log("this customer:" + JSON.stringify(fill_cus));
      this.customer_name = fill_cus[0].name;
    },
    submit() {
      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#F1F4F7",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });
      // simulate AJAX
      setTimeout(() => {
        loader.hide();
      }, 2000);
    },
    editsig1() {
      this.dialogSignature = true;
      this.lbl_date_request = false;
      this.showsig1 = true;
      this.showbuttonsavesig1 = true;
      this.$refs.firstsignaturePad.openSignaturePad();
    },
    editsig2() {
      // this.showsig2 = true;
      // this.showbuttonsavesig2 = true;
      // this.$refs.secondsignaturePad.openSignaturePad();

      this.dialogSignature = true;
      this.lbl_date_request = false;
      this.showsig2 = true;
      this.showbuttonsavesig2 = true;
      this.$refs.secondsignaturePad.openSignaturePad();
    },
    clearsig1() {
      this.lbl_date_request = false;
      this.$refs.firstsignaturePad.clearSignature();
    },
    clearsig2() {
      // this.$refs.secondsignaturePad.clearSignature();

      this.lbl_date_request = false;
      this.$refs.secondsignaturePad.clearSignature();
    },
    async savesig1() {
      console.log("signpad : savesig1");
      this.lbl_date_request = true;
      this.defaultwordbuttonsig1 = "Edit";
      const { isEmpty, data } = this.$refs.firstsignaturePad.saveSignature();
      this.showbuttonsavesig1 = false;
      this.$refs.firstsignaturePad.lockSignaturePad();
      // console.log("user_sign : " + data);
      // console.log("user_sign1 : " + this.user_sign);
      this.user_sign = data;
      this.account.signature = data;
      let loader = this.$loading.show({
        // Optional parameters
        color: "green",
        loader: "dots",
        container: this.fullPage ? null : this.$refs.formContainer,
        canCancel: false,
        width: 120,
        height: 120,
        backgroundColor: "#ffffff",
        opacity: 0.5,
        zIndex: 999,
        onCancel: this.onCancel,
      });
      await api.updateAccount(
        localStorage.getItem(server.USER_ID),
        this.account
      );
      loader.hide();
    },
    async savesig2() {
      console.log("signpad : savesig2");
      this.lbl_date_request = true;
      this.defaultwordbuttonsig2 = "Edit";
      const { isEmpty, data } = this.$refs.secondsignaturePad.saveSignature();
      this.showbuttonsavesig2 = false;
      this.$refs.secondsignaturePad.lockSignaturePad();

      console.log("signpad res_app", data);

      this.user_sign2 = data;

      console.log("signpad res_app", data);
      this.account.signature = data;

      // let loader = this.$loading.show({
      //   // Optional parameters
      //   color: "green",
      //   loader: "dots",
      //   container: this.fullPage ? null : this.$refs.formContainer,
      //   canCancel: false,
      //   width: 120,
      //   height: 120,
      //   backgroundColor: "#ffffff",
      //   opacity: 0.5,
      //   zIndex: 999,
      //   onCancel: this.onCancel,
      // });
      // await api.updateAccount(
      //   localStorage.getItem(server.USER_ID),
      //   this.account
      // );
      // loader.hide();
    },
    async savesig3() {
      console.log("signpad : savesig3");
      this.lbl_date_request = true;
      this.defaultwordbuttonsig3 = "Edit";
      const { isEmpty, data } = this.$refs.thirdsignaturePad.saveSignature();
      this.showbuttonsavesig3 = false;
      this.$refs.thirdsignaturePad.lockSignaturePad();

      console.log("signpad res_app", data);

      this.user_sign3 = data;

      console.log("signpad res_app", data);
      this.account.signature = data;

      // let loader = this.$loading.show({
      //   // Optional parameters
      //   color: "green",
      //   loader: "dots",
      //   container: this.fullPage ? null : this.$refs.formContainer,
      //   canCancel: false,
      //   width: 120,
      //   height: 120,
      //   backgroundColor: "#ffffff",
      //   opacity: 0.5,
      //   zIndex: 999,
      //   onCancel: this.onCancel,
      // });
      // await api.updateAccount(
      //   localStorage.getItem(server.USER_ID),
      //   this.account
      // );
      // loader.hide();
    },
    changeradio() {
      console.log(this.radioGroup);
      if (this.radioGroup === "cheque") {
        this.paymentType = "cheque";
        this.pickbank = false;
        this.pickcheque = true;
      } else if (this.radioGroup === "bank") {
        this.paymentType = "transfer";
        this.pickcheque = false;
        this.pickbank = true;
      } else {
        this.paymentType = "cash";
        this.pickcheque = false;
        this.pickbank = false;
      }
    },

    showdefaultsignature() {
      this.$nextTick(() => {
        // this.$refs.signaturePaddefault.fromDataURL(this.initial_data);
        // this.$refs.signaturePaddefault.lockSignaturePad();
        this.$refs.firstsignaturePad.fromDataURL(this.user_sign);
        // this.$refs.firstsignaturePad.lockSignaturePad();
        this.$refs.secondsignaturePad.fromDataURL(this.user_sign2);

        this.$refs.thirdsignaturePad.fromDataURL(this.user_sign3);
        // this.$refs.secondsignaturePad.lockSignaturePad();
      });
    },
  },
  async beforeCreate() {
    console.log("beforeCreate");
    // this.mounted();
  },
  async created() {
    // this.showdefaultsignature();
  },
};
