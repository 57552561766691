//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import { server } from "@/services/constants";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";

export default {
    name: "NewAdvance",
    data() {
        return {
            document_type: "",
            dialogAdd: false,
            text_color: "text-h5 green--text text-center",
            title: "green",
            message: "green",
            link: "",
            title_inv: "",
        };
    },
    components: {
        SuccessDialog,
        SuccessDialogPush,
    },
    computed: {
    },
    watch: {
    },
    async mounted() {
        // const userId = localStorage.getItem(server.USER_ID);
        // if (userId && api.isLoggedIn()) {
        //     // this.$router.push("/");
        // } else {
        //     this.$store.state.isLogged = false;
        //     this.$router.push("/login");
        // }

        if(this.$route.params.mode == "Edit"){
            await this.loaddata();
        }
        this.$hideLoader();
    },
    methods: {     
        async loaddata(){
            const res_result = await api.getImMasterWorkPlaceofissueByID(this.$route.params.id) 
            // console.log(res_result.data)
            this.title_inv = res_result.data[0].title;
        },
        async saveaddItem(){
            if (this.title_inv == "") {
                this.$store.state.global_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Check information",
                    "Please Enter Title Data",
                    "text-h5 red--text text-center"
                );
                return;
            }
            if(this.$route.params.mode == "Add"){
                const additem = {
                    title: this.title_inv,
                    dept_id: localStorage.getItem(server.DEPARTMENT_ID),
                    company_id: localStorage.getItem(server.COMPANYID),
                    user_create: localStorage.getItem(server.USER_ID),

                }
                // console.log(additem)
                this.$showLoader();
                const res_add = await api.addImMasterWorkPlaceofissue(additem)
                if(res_add.status == 200){
                    this.$hideLoader();
                    this.$store.state.global_push_dialog = true;
                    this.setupAlertDialogPush(
                    true,
                        "Information Saved",
                        "Information successfully saved",
                        "text-h5 green--text text-center",
                        `/im-master-workplaceofissue-list`
                    );
                }else{
                    this.$hideLoader();
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                        "Information Saved",
                        "ไม่สามารถบันทึกได้",
                        "text-h5 red--text text-center"
                    );
                }
            }else{
                const editItem = {
                    title: this.title_inv,
                    user_update: localStorage.getItem(server.USER_ID),
                }
                this.$showLoader();
                const res_edit = await api.UpdateImMasterWorkPlaceofissue(this.$route.params.id,editItem)
                if(res_edit.status == 200){
                    this.$hideLoader();
                    this.$store.state.global_push_dialog = true;
                    this.setupAlertDialogPush(
                    true,
                        "Information Saved",
                        "Information successfully saved",
                        "text-h5 green--text text-center",
                        `/im-master-workplaceofissue-list`
                    );
                }else{
                    this.$hideLoader();
                    this.$store.state.global_dialog = true;
                    this.setupAlertDialog(
                    true,
                        "Information Saved",
                        "ไม่สามารถบันทึกได้",
                        "text-h5 red--text text-center"
                    );
                }
            }
        },
        setupAlertDialog(status, title, message, text_color) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
        },
        setupAlertDialogPush(status, title, message, text_color, link) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
            this.link = link;
        },
    },
    async beforeCreate() {
        console.log("beforeCreate");
        this.$store.state.navMenu = true ;
    },
};
