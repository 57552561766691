//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import api from "@/services/api";
import { server, leavequotaFile } from "@/services/constants";
import moment from 'moment';


import {
  XlsxRead,
  XlsxTable,
  XlsxSheets,
  XlsxJson,
  XlsxWorkbook,
  XlsxSheet,
  XlsxDownload,
} from "vue-xlsx";
export default {
  mounted() {
    // this.$showLoader();
    setTimeout(() => {
      this.$hideLoader();
    }, 500);
  },
  components: {
    SuccessDialog,
    SuccessDialogPush,
    XlsxRead,
    XlsxTable,
    XlsxSheets,
    XlsxJson,
    XlsxWorkbook,
    XlsxSheet,
    XlsxDownload,
  },
  data() {
    return {
      linkdownloadleavequota:`${leavequotaFile}/leave_holiday_template.xlsx`,
      oper: 0,
      lockcollection: false,
      filenameimport: "",
      oldfilenameimport: "",
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      countsuccess: 0,
      countfail: 0,
      datafailleavequota: 0,
      datafailleavetype: 0,
      sheetsdataallfail: [
        {
          name: "sheetsdataallfail",
          data: [],
        },
      ],
      sheetsempfail: [
        {
          name: "sheetsempfail",
          data: [],
        },
      ],
      sheetsleave_typefail: [
        {
          name: "sheetsleave_typefail",
          data: [],
        },
      ],

      btnconfirmimportclose: false,
      exportleavequotaempfail: "leavequota_duplicate_data.xlsx",
      exportleavequotaleavetypefail: "leavequota_leavetype_fail.xlsx",
      exportleavequotadataallfail: "leavequota_dataall_fail.xlsx",
      employeenamefail: false,
      leavetypefail: false,
      btnconfirmimport: false,
      showcheckdatafile: false,
      datafile: null,
      mDataempfail: [],
      mDataleavetypefail: [],
      DataAll: [],
      mDataArray: [],
      data_import: [],
      mDatafailall: [],
      search: "",
      headers: [
        { text: "NO.", value: "no" ,width:"80px"},
        { text: "Holiday Name", value: "h_name" },
        { text: "Date", value: "date_show",align:"center" ,width:"150px"},
        { text: "Status", value: "status",align:"center" ,width:"100px"},

        // { text: "status", value: "status" },
      ],
      isShowPassword: false,
      fullPage: true,
    };
  },
  methods: {
    async popuppreviewfile(fileindex) {
              let pdf_newTab = window.open("");
              pdf_newTab.document.write(
                  "<html><head><title>pdf file</title></head><body><iframe title='MY title'  width='100%' height='100%' src='" +
                  this.getpreview_list[fileindex] +
                  "'></iframe></body></html>"
              );

              return;
              this.previewpopupfile2 = [];
              await this.getPdfforpreview(this.getpreview_list[fileindex]);
              console.log(this.previewpopupfile);
              this.previewpopupfile2 = this.previewpopupfile;
              this.checkfileapreview = true;
              this.dialogpreviewfile = true;
          },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },

    setupAlertDialogPush(status, title, message, text_color, link) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
      this.link = link;
    },


    
    async Readleavequota(event) {
      this.mDataArray = [];
      this.data_import = [];
      this.datafile = null;
      this.datafile = event.target.files ? event.target.files[0] : null;

      this.filenameimport = this.datafile.name;

      this.lockcollection = true;
    },

    onParsed(data) {

      const excelEpoc = new Date(1900, 0, -1).getTime();
      const msDay = 86400000;

      this.datafailleavequota = 0;
      this.datafailleavetype = 0;
      this.countsuccess = 0;
      this.countfail = 0;
      this.employeenamefail = false;
      this.leavetypefail = false;
      this.mDataempfail = [];
      this.mDataleavetypefail = [];

      (this.sheetsdataallfail = [
        {
          name: "sheetsdataallfail",
          data: [],
        },
      ]),

      (this.sheetsempfail = [
          {
            name: "sheetsempfail",
            data: [],
          },
      ]),
      (this.sheetsleave_typefail = [
          {
            name: "sheetsleave_typefail",
            data: [],
          },
      ]),
        
      
      (this.data_import = data);
          
      console.log("this.data_import")
      console.log(this.data_import)

      this.data_import.forEach((item, index) => {
        
          var data_set = {};
          data_set.no = (index + 1)
          data_set.h_name = item.__EMPTY_3;
          data_set.date_show = item.__EMPTY_1;
          data_set.date = moment(item.__EMPTY_1, 'DD/MM/YYYY').format('YYYY-MM-DD');
          data_set.status = 1;
          data_set.company_id = Number(localStorage.getItem(server.COMPANYID));
          this.mDataArray.push(data_set)
      });

      console.log("this.mDataArray")
      console.log(this.mDataArray)
      this.btnconfirmimportclose = false;
      this.btnconfirmimport = true;
      this.lockcollection = false;
      this.oper = 0;
    },

  async confirmimport() {
      this.btnconfirmimport = false;

      this.oper = 0;

      try {

          this.$showLoader();

          const result = await api.importHoliday(this.mDataArray);
          let data_result = result;

          this.$hideLoader();
          
          this.mDataArray = [];
          console.log("data_result.data")
          console.log(data_result.data)
          this.mDataArray = data_result.data

          this.countsuccess = 0;
          this.countfail = 0;

          var countsuccess_o = 0;
          var countfail_0 = 0;

          this.mDataArray.forEach((item) => {
              if(item.status == 3){
                  countsuccess_o += 1;
              }else if(item.status == 2){
                  countfail_0 += 1;
              }else{
                  return;
              }
          })
          
          this.countsuccess = countsuccess_o;
          this.countfail = countfail_0;

          if(data_result.status == 200){
              this.$store.state.global_dialog = true;
              this.setupAlertDialog(
              true,
                  "Information Saved",
                  "Information successfully saved",
                  "text-h5 green--text text-center",
              );
          }else{
              this.$store.state.global_dialog = true;
              this.setupAlertDialog(
              true,
                  "Information Saved",
                  "Information Date Duplicate",
                  "text-h5 red--text text-center"
              );
          }
          
      } catch (error) {                            // Step 2: Error get caught here
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
          true,
              "Information Saved",
              "Information fail saved",
              "text-h5 red--text text-center"
          );
      }

     

      // this.countsuccess = dataresult.countsuccess;
      // this.countfail = dataresult.countfail;
      // this.datafailleavequota = dataresult.datafailleavequota.length;
      // if (dataresult.datafailleavequota.length > 0) {
      //   let setdataexportarray = [];
      //   this.mDataempfail = dataresult.datafailleavequota;
      //   this.mDataempfail.forEach((x) => {
      //     let setdataexport = {
      //       employee_id: "",
      //       employee_name: "",
      //       leave_period_from: "",
      //       leave_period_to: "",
      //       leave_type: "",
      //       quota: "",
      //       use_leave: "",
      //     };
      //     setdataexport.employee_id = x.employee_id;
      //     setdataexport.employee_name = x.employee_name;
      //     setdataexport.leave_period_from = new Date(x.leave_period_from);
      //     setdataexport.leave_period_to = new Date(x.leave_period_to);
      //     setdataexport.leave_type = x.leave_type;
      //     setdataexport.quota = x.quota;
      //     setdataexport.use_leave = x.use_leave;
      //     setdataexportarray.push(setdataexport);
      //   });

      //   this.sheetsempfail[0].data = setdataexportarray;
      //   this.employeenamefail = true;


      // this.datafailleavetype = dataresult.datafailleavetype.length;
      // if (dataresult.datafailleavetype.length > 0) {
      //   let setdataexportarray = [];
      //   this.mDataleavetypefail = dataresult.datafailleavetype;
      //   this.mDataleavetypefail.forEach((x) => {
      //     let setdataexport = {
      //       employee_id: "",
      //       employee_name: "",
      //       leave_period_from: "",
      //       leave_period_to: "",
      //       leave_type: "",
      //       quota: "",
      //       use_leave: "",
      //     };
      //     setdataexport.employee_id = x.employee_id;
      //     setdataexport.employee_name = x.employee_name;
      //     setdataexport.leave_period_from = new Date(x.leave_period_from);
      //     setdataexport.leave_period_to = new Date(x.leave_period_to);
      //     setdataexport.leave_type = x.leave_type;
      //     setdataexport.quota = x.quota;
      //     setdataexport.use_leave = x.use_leave;
      //     setdataexportarray.push(setdataexport);
      //   });

      //   this.sheetsleave_typefail[0].data = setdataexportarray;
      //   this.leavetypefail = true;
      // }
      // if (dataresult.datafaileall.length > 0) {
      //   let setdataexportarray = [];
      //   this.mDatafailall = dataresult.datafaileall;
      //   this.mDatafailall.forEach((x) => {
      //     let setdataexport = {
      //       employee_id: "",
      //       employee_name: "",
      //       leave_period_from: "",
      //       leave_period_to: "",
      //       leave_type: "",
      //       quota: "",
      //       use_leave: "",
      //     };
      //     setdataexport.employee_id = x.employee_id;
      //     setdataexport.employee_name = x.employee_name;
      //     setdataexport.leave_period_from = new Date(x.leave_period_from);
      //     setdataexport.leave_period_to = new Date(x.leave_period_to);
      //     setdataexport.leave_type = x.leave_type;
      //     setdataexport.quota = x.quota;
      //     setdataexport.use_leave = x.use_leave;
      //     setdataexportarray.push(setdataexport);
      //   });
      //   this.sheetsdataallfail[0].data = setdataexportarray;
      // }
    },
  },

  beforeCreate() {
    console.log("beforeCreate");
    this.$store.state.navMenu = true;
  },
};
