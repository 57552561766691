//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import api from "@/services/api";
import SuccessDialog from "@/components/cards/SuccessDialog";
import SuccessDialogPush from "@/components/cards/SuccessDialogPush";
import unity from "@/unity/unity";
const moment = require('moment-timezone');

export default {
  data() {
    return {
      dialogAdd: false,
      text_color: "text-h5 green--text text-center",
      title: "green",
      message: "green",
      link: "",
      title_name: "",
      check_prename_th: true,
      check_prename_en: true,
      prename_th_list: ["นาย","นาง","นางสาว","อื่นๆ"],
      prename_en_list: ["Mr.","Mrs.","Miss.","Other"],
      menu_date: false,
      check_status_tranning: false,
      header_list: [],
      footer_list: [],
      email: "",
      check_email: false,
      otp: "",
      check_otp: false,
      dialogOldEmail: false,
      email_disable: false,
      dataitem: {
        prename_th: "",
        pre_ohter_th: "",
        first_name_th: "",
        last_name_th: "",
        prename_en: "",
        pre_ohter_en: "",
        first_name_en: "",
        last_name_en: "",
        birth_day: null,
        age: 0,
        ethnicity: "",
        nationality: "",
        religion: "",
        id_card_number: "",
        phone_number: "",
        image: ""
        
      },
      imageUrl: "https://upload.wikimedia.org/wikipedia/en/2/24/Crystal_personal.svg",
      data_tranning: {},
      email_id: 0,
      tu_user_id: 0,
    };
  },
  components: {
    SuccessDialog,
    SuccessDialogPush,
  },
  computed: {
    computedDateRes() {
      return unity.formatDate(this.dataitem.birth_day);
    },
  },
  watch: {
  },
  async mounted() {
    this.$hideLoader();
    this.loadData();
    this.$hideLoader();
  },
  methods: {


    async loadData(){
      const res_data = await api.getTRANNING_HById(this.$route.params.tranning_id)
      console.log("res_data.data")
      console.log(res_data.data)
      if(res_data.data.length > 0){

        this.check_status_tranning = true;
        this.data_tranning = res_data.data[0];
        this.title_name = res_data.data[0].tranning_name;

        const res_h = await api.getTRANNING_DByIdType(this.$route.params.tranning_id,"Header")
        const res_f = await api.getTRANNING_DByIdType(this.$route.params.tranning_id,"Footer")


        this.header_list = res_h.data;
        console.log(res_f.data)
        this.footer_list = res_f.data;
      }
    },

    async checkEmail(){
      if (this.email == "") {
          this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Check your email",
              "Please input valid email address",
              "text-h5 red--text text-center"
          );
          this.$refs["email"].focus();
          return;
      }
      this.$showLoader()
      const check_email_cond = await api.getDataEmailByEmailSQL(this.email)
      this.$hideLoader();
      console.log("check_email_cond.data")
      console.log(check_email_cond.data)

      let check_t = 0;
      if(check_email_cond.data.length >0){
        for (const [index, item] of check_email_cond.data.entries()){
          if(item.tranning_id == this.$route.params.tranning_id){
            check_t = 1;
          }

        }
        if(check_t == 1){
          this.email="";

          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Check your email",
            "E-mail นี้เคยสมัคร Training นี้ไปเเล้ว",
            "text-h5 red--text text-center"
          );
          this.$refs["email"].focus();
          return;
        }
      }

      if(check_email_cond.data.length > 0 && check_email_cond.data[0].status == 2){
          this.tu_user_id = check_email_cond.data[0].tu_id;
          this.dialogOldEmail = true;
      } else{
        await this.checkEmailaction();
      }

    },
    async checkEmailaction(){

        let data = { email: "",}
        data.email = this.email;

        this.$showLoader()

        const res_check = await api.confirmemailRegister(data);

        if(res_check.status == 200){

          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Check your email",
            `${res_check.data.message}`,
            "text-h5 green--text text-center"
          );
          this.$hideLoader();
          this.check_otp = true;
        }
    },

    async AddOldEmailConfirm(){
      this.dialogOldEmail = false;
      await this.checkEmailaction();
    },

    async closeOldEmail(){
      this.dialogOldEmail = false;
      this.email = "";

    },
    async checkEmailFormat() {
      if (this.email.trim() !== "") {
        // ใช้ Regex ที่ถูกต้องเพื่อตรวจสอบรูปแบบของอีเมล
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
        if (!emailPattern.test(this.email.trim())) {
          // รูปแบบของอีเมลไม่ถูกต้อง
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Check your email",
            "Invalid email format. Please enter a valid email.",
            "text-h5 red--text text-center"
          );
          this.$refs["email"].focus();
          return;
        }else{
          await this.checkEmail()
        }
      }
    },
    async checkNumber(){
      if(this.otp != ""){
        if (!/^[0-9]{0,6}$/.test(this.otp.trim())) {
          this.otp = "";
          this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Check your email",
              "Invalid OTP. Please try again.",
              "text-h5 red--text text-center"
          );
          this.$refs["otp"].focus();
          return;
        }
      }
    },
    async checkIDCardNumber(){
      if(this.dataitem.id_card_number != ""){
        if (!/^[0-9]{0,13}$/.test(this.dataitem.id_card_number.trim())) {
          this.dataitem.id_card_number = "";
          this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Invalid Information",
              "Please Enter New ID Card Number Data",
              "text-h5 red--text text-center"
          );
          this.$refs["id_card_number"].focus();
          return;
        }
      }
    },
    async checkPhoneNumber(){
      if(this.dataitem.phone_number != ""){
        if (!/^[0-9]{0,10}$/.test(this.dataitem.phone_number.trim())) {
          this.dataitem.phone_number = "";
          this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Check your email",
              "Please Enter New Phone Number Data",
              "text-h5 red--text text-center"
          );
          this.$refs["phone_number"].focus();
          return;
        }
      }
    },

    async VerifyEmail(){
      if (this.otp.trim() == "") {
          this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Check your email",
              " Please enter OTP sent to your email.",
              "text-h5 red--text text-center"
          );
          this.$refs["otp"].focus();
          return;
      }
      let  data_ver = {
        email: "",
        otp_number: ""
      }
      data_ver.email = this.email.trim();
      data_ver.otp_number = this.otp.trim();

      // this.$showLoader();

      const res_ver = await api.verifyemailRegister(data_ver);

      console.log("res_ver.data")
      console.log(res_ver.data)
      if(res_ver.status == 200){
        this.email_id = res_ver.data.email_id;
        this.$hideLoader();
        if(res_ver.data.action_check){

          this.$showLoader();
          var data_user_o = {
            t_user_id: this.tu_user_id,
            tranning_id: this.$route.params.tranning_id,
          }

          const res_add_user_o =  await api.addTRANNING_USER_D(data_user_o);

          if(res_add_user_o.status == 200 || res_add_user_o.status == 201){
            this.$hideLoader();
            this.$store.state.global_push_dialog = true;
            this.setupAlertDialogPush(
              true,
              "Information Saved",
              "Information successfully saved",
              "text-h5 green--text text-center",
              `/login`
            );
          }else{
            this.$hideLoader()
            this.$store.state.global_dialog = true;
            this.setupAlertDialog(
              true,
              "Information Saved",
              "ไม่สามารถบันทึกได้",
              "text-h5 red--text text-center"
            );
            return;
          }
        }else{
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Email Verified",
            "Verify Email successfully",
            "text-h5 green--text text-center",
          );
          this.check_otp = false
          this.email_disable = true;
          this.check_email = true;
        }
      }else if(res_ver.status == 204){
                this.$hideLoader();
                this.$store.state.global_push_dialog = true;
                this.setupAlertDialog(
                    true,
                    "Informations Fail",
                    "Invalid OTP. Please try again.",
                    "text-h5 red--text text-center"
                );
                this.otp = ""
                this.$refs["otp"].focus();
                return;
      }else{
        this.$store.state.global_dialog = true;
        this.setupAlertDialog(
          true,
          "Information Saved",
          "ไม่สามารถบันทึกได้",
          "text-h5 red--text text-center"
        );
        return;
      }
    },
    async setPreOhter(){

      if(this.dataitem.prename_th == "อื่นๆ"){
        this.check_prename_th = false;
      }else{
        this.dataitem.pre_ohter_th = "";
        this.check_prename_th = true;
      }

      if(this.dataitem.prename_en == "Other"){
        this.check_prename_en = false;
      }else{
        this.dataitem.pre_ohter_en = "";
        this.check_prename_en = true;
      }

    },
    onFileSelected(event) {
      const file = event.target.files[0];
      this.dataitem.image = event.target.files[0];
      this.imageUrl = URL.createObjectURL(file);
    },
    async saveItem(){
      // console.log("this.dataitem:")
      // console.log(this.dataitem)

      let formData = new FormData();

      formData.append("emp_i_d", "");
      formData.append("user_role","");
      formData.append("username", this.email);
      formData.append("password", this.dataitem.id_card_number);
      formData.append("email", this.email);
      formData.append("phone", this.dataitem.phone_number);

      if(this.dataitem.prename_th == "อื่นๆ"){
        formData.append("prename_t_h", this.dataitem.pre_ohter_th);
      }else{
        formData.append("prename_t_h", this.dataitem.prename_th);
      }   

      formData.append("firstname", this.dataitem.first_name_th);
      formData.append("lastname", this.dataitem.last_name_th);

      if(this.dataitem.prename_en == "Other"){
        formData.append("prename_e_n", this.dataitem.pre_ohter_en);
      }else{
        formData.append("prename_e_n", this.dataitem.prename_en);
      }  

      formData.append("firstname_e_n", this.dataitem.first_name_en);
      formData.append("lastname_e_n", this.dataitem.last_name_en);

      let abb = "";
      abb = this.dataitem.first_name_en.split("")[0] + this.dataitem.last_name_en.split("")[0];
      formData.append("abbname_e_n", abb);

      //รอดึงค่า
      formData.append("company_i_d", this.data_tranning.company_id);
      formData.append("department_i_d", this.data_tranning.department_id);
      formData.append("position_i_d", this.data_tranning.position_id);
      formData.append("authorize_id", this.data_tranning.authorize_id);

      formData.append("emp_rate", 0);
      formData.append("emp_status", "A");
      console.log("this.dataitem.image")
      console.log(this.dataitem.image)
      formData.append("image", this.dataitem.image);
      formData.append("approver_level1", this.data_tranning.admin_id);
      formData.append("approver_level2", 0);
      formData.append("approver_level3", 0);
      formData.append("ip_authentication", "");
      formData.append("signature", "");
      formData.append("user_create", 0);
      formData.append("user_update", 0);

      this.$showLoader()
      const res_add = await api.addUserAccount(formData);
      console.log(res_add.data)

      if(res_add.status == 200 || res_add.status == 201){

     

        //create user
        let dataAdd_user = {
          user_id: 0,
          tranning_id: 0,
          te_id: 0,
          email: "",
          date: "",
          pre_name_th: "",
          first_name_th: "",
          last_name_th: "",
          pre_name_en: "",
          first_name_en: "",
          last_name_en: "",
          birth_day: "",
          age: 0,
          ethnicity: "",
          nationality: "",
          religion: "",
          id_card_number: "",
          phone_number: "",
        }

        dataAdd_user.user_id = res_add.data.id;
        dataAdd_user.te_id = this.email_id;
        dataAdd_user.email = this.email;
        dataAdd_user.date = moment().tz('Asia/Bangkok').format('YYYY-MM-DD HH:mm:ss');

        if(this.dataitem.prename_th == "อื่นๆ"){
          dataAdd_user.pre_name_th = this.dataitem.pre_ohter_th;
        }else{
          dataAdd_user.pre_name_th = this.dataitem.prename_th;
        } 
        dataAdd_user.first_name_th = this.dataitem.first_name_th;
        dataAdd_user.last_name_th = this.dataitem.last_name_th;

        if(this.dataitem.prename_en == "Other"){
          dataAdd_user.pre_name_en = this.dataitem.pre_ohter_en;
        }else{
          dataAdd_user.pre_name_en = this.dataitem.prename_en;
        }  
        dataAdd_user.first_name_en = this.dataitem.first_name_en;
        dataAdd_user.last_name_en = this.dataitem.last_name_en;

        dataAdd_user.birth_day = this.dataitem.birth_day;
        dataAdd_user.age = this.dataitem.age;
        dataAdd_user.ethnicity = this.dataitem.ethnicity;
        dataAdd_user.nationality = this.dataitem.nationality;
        dataAdd_user.religion = this.dataitem.religion;
        dataAdd_user.id_card_number = this.dataitem.id_card_number;
        dataAdd_user.phone_number = this.dataitem.phone_number;

        console.log("dataAdd_user: ")
        console.log(dataAdd_user)

        const res_add_user = await api.addTRANNING_USER(dataAdd_user)

        if(res_add_user.status == 200 || res_add_user.status == 201){
          let data_user_d = {
            t_user_id: res_add_user.data.id,
            tranning_id: this.$route.params.tranning_id,
          }
          await api.addTRANNING_USER_D(data_user_d);

          let data_send_m = {
            email: this.email,
            fist_name: this.first_name_en,
            user_name: this.email,
            password: this.dataitem.id_card_number
          }
          await api.sendUserRegister(data_send_m)
          await api.UpdateTranningEmail(this.email_id,{"status": 2});
          this.$hideLoader();
          this.$store.state.global_push_dialog = true;
          this.setupAlertDialogPush(
            true,
            "Information Saved",
            "Information successfully saved",
            "text-h5 green--text text-center",
            `/login`
          );

        }else{
          this.$hideLoader()
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "ไม่สามารถบันทึกได้",
            "text-h5 red--text text-center"
          );
          return;
        }
      }else{
          this.$hideLoader()
          this.$store.state.global_dialog = true;
          this.setupAlertDialog(
            true,
            "Information Saved",
            "ไม่สามารถบันทึกได้",
            "text-h5 red--text text-center"
          );
          return;
      }

    },
    setupAlertDialog(status, title, message, text_color) {
      this.title = title;
      this.message = message;
      this.dialogAdd = status;
      this.text_color = text_color;
    },
    setupAlertDialogPush(status, title, message, text_color, link) {
            this.title = title;
            this.message = message;
            this.dialogAdd = status;
            this.text_color = text_color;
            this.link = link;
      },
  },
  async beforeCreate() {
    // console.log("beforeCreate");
    this.$store.state.navMenu = false;

    // this.showdefaultsignature();
  },
  async created() {
  },
};
